import { AuthSessionStatus } from "api/generated/graphql";
import AuthContext, {
  AuthContextActionType,
} from "components/auth/AuthContext";
import { ToastStyle } from "components/toast/Toast";
import { getToastUrl, MessageCode } from "components/toast/ToastUrlParser";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RequestState } from "utils/common";

export const Unauthenticated = () => {
  const history = useHistory();
  const { authState, authDispatch } = useContext(AuthContext);

  useEffect(() => {
    const locationURL = (): string => {
      const loc = history.location;
      return encodeURIComponent(loc.pathname + loc.search + loc.hash);
    };

    // TODO: https://opalsecurity.atlassian.net/browse/OPAL-455
    if (
      !authState.authSessionStatus ||
      authState.authSessionStatus === AuthSessionStatus.SessionNotFound ||
      authState.authSessionStatus === AuthSessionStatus.SessionExpired
    ) {
      let nextLocalUrl = null;
      if (
        history.location.pathname !== "/sign-in" &&
        history.location.pathname !== "/"
      ) {
        nextLocalUrl = locationURL();

        if (
          authState.authClient &&
          nextLocalUrl &&
          authState.authClient.config.nextLocalUrl !== nextLocalUrl
        ) {
          authDispatch({
            type: AuthContextActionType.Auth0Config,
            payload: {
              auth0Config: {
                domain: authState.authClient.config.domain,
                audience: authState.authClient.config.audience,
                clientId: authState.authClient.config.clientId,
                redirectUri: authState.authClient.config.redirectUri,
                logoutUri: authState.authClient.config.logoutUri,
                nextLocalUrl: nextLocalUrl,
              },
            },
          });
        }
      }

      if (
        !authState.authSessionStatus ||
        authState.authSessionStatus === AuthSessionStatus.SessionNotFound
      ) {
        let signInUrl = "/sign-in";
        if (nextLocalUrl) {
          signInUrl += `?next=${nextLocalUrl}`;
        }
        history.replace(signInUrl);
      } else if (
        authState.authSessionStatus === AuthSessionStatus.SessionExpired
      ) {
        history.replace(
          getToastUrl(
            "/sign-out",
            RequestState.Warning,
            MessageCode.ErrorAuthSessionInvalid,
            ToastStyle.Banner
          )
        );
      }
    }
  }, [
    authDispatch,
    authState.authClient,
    authState.authSessionStatus,
    history,
  ]);

  return null;
};

export default Unauthenticated;
