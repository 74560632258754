import {
  Maybe,
  SignInOrganization,
  useAllowedToCreateOrgQuery,
  useSignInMethodLazyQuery,
  useSignInMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import styles from "components/auth/SignIn.module.scss";
import CenteredDisplay from "components/centered_display/CenteredDisplay";
import { Button, Checkbox, FormGroup, Input, Loader } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Auth0Connection } from "utils/auth/AuthClient";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";

const localStorageEmailKey = "signin-email";
export const SignInForm = () => {
  const { authState } = useContext(AuthContext);

  const [email, setEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [executing, setExecuting] = useState(false);
  // After going through the "continue" step, if the user changes the email address again,
  // this is set to true to re-show the "continue" button
  const [isForcedEmailEntry, setIsForcedEmailEntry] = useState(false);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);

  const [getSignInMethod, { data, loading }] = useSignInMethodLazyQuery();
  let signInOrganizations: SignInOrganization[] | undefined;
  if (data && data?.signInMethod?.__typename === "SignInMethodResult") {
    signInOrganizations = data.signInMethod.signInOrganizations;
  }

  const [signIn] = useSignInMutation();

  const { data: allowedToCreateOrgData } = useAllowedToCreateOrgQuery();
  const allowedToCreateOrg =
    allowedToCreateOrgData?.allowedToCreateOrganizations.allowed ?? false;

  const executeSaml = useCallback(
    async (signInOrganization: SignInOrganization) => {
      try {
        setExecuting(true);
        const { data } = await signIn({
          variables: {
            input: { organizationId: signInOrganization.organizationId },
          },
        });
        switch (data?.signIn.__typename) {
          case "SignInResult": {
            authState.authClient?.samlSignIn(
              data.signIn.state,
              signInOrganization.organizationId,
              data.signIn.forceExtraStep
            );
            break;
          }
          default:
            setExecuting(false);
            logError(new Error(`Error: failed to sign in with email`));
        }
      } catch (error) {
        setExecuting(false);
        logError(error, `failed to sign in with email`);
      }
    },
    [signIn, authState]
  );

  const executeGoogle = useCallback(
    async (signInOrganization: SignInOrganization) => {
      try {
        setExecuting(true);
        const { data } = await signIn({
          variables: {
            input: { organizationId: signInOrganization.organizationId },
          },
        });
        switch (data?.signIn.__typename) {
          case "SignInResult":
            authState.authClient?.socialSignIn(
              data.signIn.state,
              data.signIn.forceExtraStep
            );
            break;
          default:
            setExecuting(false);
            logError(new Error(`failed to social sign in`));
        }
      } catch (error) {
        setExecuting(false);
        logError(error, `failed to social sign in`);
      }
    },
    [signIn, authState]
  );

  const executeMicrosoft = useCallback(
    async (signInOrganization: SignInOrganization) => {
      try {
        setExecuting(true);
        const { data } = await signIn({
          variables: {
            input: { organizationId: signInOrganization.organizationId },
          },
        });
        switch (data?.signIn.__typename) {
          case "SignInResult":
            authState.authClient?.socialSignIn(
              data.signIn.state,
              data.signIn.forceExtraStep,
              Auth0Connection.Microsoft
            );
            break;
          default:
            setExecuting(false);
            logError(new Error(`failed to social sign in`));
        }
      } catch (error) {
        setExecuting(false);
        logError(error, `failed to social sign in`);
      }
    },
    [signIn, authState]
  );

  useEffect(() => {
    // Auto-execute a sign-in method when there is only 1 organization with 1 option.
    if (
      !signInOrganizations ||
      signInOrganizations.length !== 1 ||
      !signInOrganizations[0]
    ) {
      return;
    }
    const signInOrganization = signInOrganizations[0];

    if (
      signInOrganization.enableSAMLSignIn &&
      !signInOrganization.enableSocialSignIn
    ) {
      executeSaml(signInOrganization);
    }
    if (
      signInOrganization.enableSocialSignIn &&
      !signInOrganization.enableSAMLSignIn
    ) {
      // TODO: We preciously only supported Google for social sign in, but now
      // we support Microsoft as well. Hence, we can no longer assume Google
      // here, so disabling this for now, but if we can store the provider per
      // org, then we can re-add this.
      // executeGoogle(signInOrganization);
    }
  }, [signInOrganizations, executeSaml, executeGoogle]);

  useEffect(() => {
    if (data?.signInMethod?.__typename === "InvalidEmailError") {
      setErrorMessage("Please provide a valid email address.");
    }
  }, [data]);
  useMountEffect(() => {
    let email = localStorage.getItem(localStorageEmailKey);
    if (email) {
      setEmail(email);
      setRememberMeChecked(true);
    }
  });

  const signInContent = (
    <form
      className={styles.signInFrame}
      onSubmit={async (e) => {
        e.preventDefault();
        if (!email) {
          setErrorMessage("Please provide your email.");
          return;
        }
        setErrorMessage(null);
        try {
          setIsForcedEmailEntry(false);
          await getSignInMethod({
            variables: {
              input: {
                email: email!,
              },
            },
          });
        } catch (error) {
          logError(error, "failed to get sign-in method");
        }
        if (rememberMeChecked) {
          localStorage.setItem(localStorageEmailKey, email);
        }
      }}
    >
      <FormGroup marginSize="sm" label="Email">
        <Input
          type="email"
          name="email"
          autoComplete="email"
          onChange={(value) => {
            setIsForcedEmailEntry(true);
            setEmail(value);
          }}
          value={email}
          autoFocus
        />
      </FormGroup>

      {errorMessage && (
        <div className={styles.submissionErrorMessage}>{errorMessage}</div>
      )}

      {loading || executing ? (
        <div className={styles.spinner}>
          <Loader size="sm" />
        </div>
      ) : isForcedEmailEntry || !signInOrganizations ? (
        <>
          <div className={styles.checkboxFrame}>
            <Checkbox
              size="sm"
              label="Remember my email"
              checked={rememberMeChecked}
              onChange={(checked) => {
                setRememberMeChecked(checked);
                if (!checked) {
                  localStorage.removeItem(localStorageEmailKey);
                }
              }}
            />
          </div>
          <div></div>
          <Button submit label="Continue" type="primary" fullWidth />
          {allowedToCreateOrg && (
            <div className={styles.accountText}>
              <div className={styles.accountTextSignIn}>
                <div className={styles.newToOpal}>
                  <span>✨&nbsp;</span>
                  <b>New to Opal?</b>
                  <span>&nbsp;✨</span>
                </div>
                <Link className={styles.link} to="/onboarding">
                  Sign up your org
                </Link>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {signInOrganizations.map((signInOrganization) => (
            <OrgSignInSection
              signInOrganization={signInOrganization}
              key={signInOrganization.organizationName}
              executeSaml={executeSaml}
              executeGoogle={executeGoogle}
              executeMicrosoft={executeMicrosoft}
            />
          ))}
        </>
      )}
    </form>
  );

  return <CenteredDisplay>{signInContent}</CenteredDisplay>;
};

const OrgSignInSection = ({
  signInOrganization,
  executeSaml,
  executeGoogle,
  executeMicrosoft,
}: {
  signInOrganization: SignInOrganization;
  executeSaml: (org: SignInOrganization) => void;
  executeGoogle: (org: SignInOrganization) => void;
  executeMicrosoft: (org: SignInOrganization) => void;
}) => {
  const googleSignInButton = (
    <Button
      onClick={() => {
        executeGoogle(signInOrganization);
      }}
      label="Continue with Google"
      leftIconName="google"
      fullWidth
      outline
    />
  );

  const microsoftSignInButton = (
    <Button
      onClick={() => {
        executeMicrosoft(signInOrganization);
      }}
      label="Continue with Microsoft"
      leftIconName="microsoft"
      fullWidth
      outline
    />
  );

  const samlSignInButton = (
    <Button
      onClick={() => {
        executeSaml(signInOrganization);
      }}
      label="Continue with SAML"
      type="primary"
      fullWidth
    />
  );
  return (
    <div className={styles.organizationFrame}>
      <div className={styles.organizationName}>
        {signInOrganization.organizationName}
      </div>

      {signInOrganization.enableSAMLSignIn && samlSignInButton}

      {signInOrganization.enableSocialSignIn &&
        signInOrganization.enableSAMLSignIn && (
          <div className={styles.divider}>
            <div className={styles.dividerOverlayBox}>
              <div className={styles.dividerText}>or</div>
            </div>
            <div className={styles.dividerLine} />
          </div>
        )}

      {signInOrganization.enableSocialSignIn && (
        <div>
          {googleSignInButton}
          <div className={sprinkles({ marginTop: "sm" })}>
            {microsoftSignInButton}
          </div>
        </div>
      )}
    </div>
  );
};
