import {
  ConnectionType,
  EntityType,
  GroupType,
  RiskLevel,
  ThirdPartyProvider,
  Visibility,
} from "api/generated/graphql";
import { ResourceConfig } from "components/forms/common";
import BreakGlassUsersRow from "components/forms/rows/BreakGlassUsersRow";
import ConditionalConfigs from "components/forms/rows/conditional_config/ConditionalConfigs";
import ConfigurationTemplateRow from "components/forms/rows/ConfigurationTemplateRow";
import GroupLeaderUsersRow from "components/forms/rows/GroupLeaderUsersRow";
import ResourceTicketPropagationRow from "components/forms/rows/ResourceTicketPropagationRow";
import SlackChannelsRowV3 from "components/forms/rows/SlackChannelsRowV3";
import TagsRowV3 from "components/forms/rows/TagsRowV3";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import { TooltipPlacement } from "components/label/Label";
import FullscreenView from "components/layout/FullscreenView";
import OwnerDropdown from "components/owners/OwnerDropdown";
import RiskSensitivityDropdown from "components/risksensitivity/RiskSensitivityDropdown";
import { ToastStyle, useToast } from "components/toast/Toast";
import {
  Banner,
  Checkbox,
  Divider,
  FormGroup,
  Icon,
  Input,
  Switch,
  Tooltip,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useEffect, useState } from "react";
import { isSessionableType } from "utils/directory/connections";
import { resourceTypeCanBeAccessed } from "utils/directory/resources";
import { isPropertyValue } from "utils/enums";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import GroupBindingWarningBanner from "views/group_bindings/banners/GroupBindingWarningBanner";
import { GroupOnCallSchedulesEdit } from "views/groups/oncall/GroupOnCallSchedulesModal";
import OrgContext from "views/settings/OrgContext";

interface Props {
  config: Partial<ResourceConfig>;
  onChange: (config: Partial<ResourceConfig>) => void;
  errors: string[];
  isImport?: boolean;
}

const ResourceEditForm = (props: Props) => {
  const { orgState } = useContext(OrgContext);
  const { config } = props;
  const nameToolTipText =
    "When selected, this item's name in Opal will match its name on the end system. To reflect newer changes, sync this item.";
  const descriptionToolTipText =
    "When selected, this item's description in Opal will match its name on the end system. To reflect newer changes, sync this item.";

  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const hasConfigurationTemplates = useFeatureFlag(
    FeatureFlag.ConfigurationTemplates
  );
  const allowUseParentConfig = useFeatureFlag(FeatureFlag.UseParentConfig);
  const canUseGroupProjects = useFeatureFlag(FeatureFlag.GroupProjects);
  const hasTicketPropagation = useFeatureFlag(
    FeatureFlag.ResourceTicketPropagation
  );
  const canUseRiskSensitivity = useFeatureFlag(FeatureFlag.RiskSensitivity);

  const { displayErrorToast } = useToast(ToastStyle.Banner);

  const entityType = isPropertyValue(GroupType, config.entityType)
    ? EntityType.Group
    : EntityType.Resource;
  const isSlackIntegrationEnabled =
    orgState.orgThirdPartyIntegrations?.some(
      (integration) =>
        integration.thirdPartyProvider === ThirdPartyProvider.Slack
    ) ?? false;
  const isOktaResource = config.connectionType === ConnectionType.OktaDirectory;
  const isSourceOfTruthGroup =
    config.groupBinding && hasGroupBindings
      ? config.groupBinding.sourceGroupId === config.entityId
      : true;
  const isRequestable =
    config.entityType == null || resourceTypeCanBeAccessed(config.entityType);
  const canBeAccessed = isRequestable && isSourceOfTruthGroup;
  const isSessionable = config.connectionType
    ? isSessionableType(config.connectionType)
    : false;
  const isChildResource = Boolean(props.config.parentResourceId);
  const hasConfigTemplateForkRequired =
    hasConfigurationTemplates && config.configurationTemplate != null;
  const showTemplatableRows =
    !hasConfigTemplateForkRequired || config.forkConfigurationTemplates;
  const showMessageChannels = isRequestable && isSlackIntegrationEnabled;
  const [initialSensitivity] = useState(config.riskSensitivity);

  const handleChange = (key: keyof ResourceConfig) => (
    val: ResourceConfig[keyof ResourceConfig]
  ) => {
    props.onChange({
      ...config,
      [key]: val,
    });
  };

  const handleConfigTemplateAndUseParentConfig = (
    configurationTemplateName?: string | null,
    configurationTemplateId?: string | null,
    useParent?: boolean
  ) => {
    if (props.onChange) {
      const templateDict =
        configurationTemplateName != null && configurationTemplateId != null
          ? {
              configurationTemplate: {
                name: configurationTemplateName,
                id: configurationTemplateId,
              },
            }
          : {};
      props.onChange({
        ...props.config,
        ...templateDict,
        useParentConfig: useParent,
      });
    }
  };

  const handleUseParentConfig = (useParent: boolean) => {
    if (useParent) {
      handleConfigTemplateAndUseParentConfig(
        props.config.parentConfigurationTemplateName,
        props.config.parentConfigurationTemplateId,
        useParent
      );
      return;
    }
    handleChange("useParentConfig")(useParent);
    return;
  };

  const handleRiskSensitivityChange = (sensitivity?: RiskLevel) => {
    if (sensitivity) {
      props.onChange({
        ...props.config,
        riskSensitivity: {
          value: sensitivity,
          isDefault: false,
        },
      });
    }
  };

  useEffect(() => {
    if (props.errors.length > 0) {
      displayErrorToast(props.errors[0]);
    }
  }, [displayErrorToast, props.errors]);

  return (
    <>
      <FullscreenView.Sidebar>
        {!props.isImport && (
          <FormGroup label="Name">
            <Input
              value={
                config.commonMetadata?.matchRemoteName
                  ? `${config.remoteName} (will be synced from end system)`
                  : config.name
              }
              onChange={handleChange("name")}
              placeholder="Enter name"
              disabled={config.commonMetadata?.matchRemoteName ?? false}
            />
            <div
              className={sprinkles({
                display: "flex",
                gap: "xs",
                paddingTop: "sm",
              })}
            >
              <Checkbox
                checked={config.commonMetadata?.matchRemoteName ?? false}
                label="Use name from end system"
                onChange={(checked) => {
                  handleChange("commonMetadata")({
                    ...config.commonMetadata,
                    matchRemoteName: checked,
                  });
                }}
              />
              <Tooltip
                tooltipText={nameToolTipText}
                placement={TooltipPlacement.Right}
              >
                <Icon name="info" size="xs" color="gray900" />
              </Tooltip>
            </div>
          </FormGroup>
        )}
        <FormGroup label="Description">
          <Input
            value={
              config.commonMetadata?.matchRemoteDescription
                ? "Description will be synced from end system"
                : config.description
            }
            onChange={handleChange("description")}
            placeholder="Enter description"
            type="textarea"
            disabled={config.commonMetadata?.matchRemoteDescription ?? false}
          />
          <div
            className={sprinkles({
              display: "flex",
              gap: "xs",
              paddingTop: "sm",
            })}
          >
            <Checkbox
              checked={config.commonMetadata?.matchRemoteDescription ?? false}
              label="Use description from end system"
              onChange={(checked) => {
                handleChange("commonMetadata")({
                  ...config.commonMetadata,
                  matchRemoteDescription: checked,
                });
              }}
            />
            <Tooltip
              tooltipText={descriptionToolTipText}
              placement={TooltipPlacement.Right}
            >
              <Icon name="info" size="xs" color="gray900" />
            </Tooltip>
          </div>
        </FormGroup>
        <TagsRowV3
          mode="edit"
          selectedTagIds={config.tagIds}
          onChange={handleChange("tagIds")}
        />
        {hasConfigurationTemplates && !config.forkConfigurationTemplates ? (
          <ConfigurationTemplateRow
            mode="edit"
            onChange={handleChange("configurationTemplate")}
            configurationTemplate={config.configurationTemplate}
            showParentConfigSettings={isChildResource}
            useParentConfig={config.useParentConfig ?? false}
            onChangeUseParentConfig={handleUseParentConfig}
            isV3
          />
        ) : null}
        {showTemplatableRows && (
          <>
            <FormGroup label="Admin">
              <OwnerDropdown
                selectedOwnerId={config.adminOwner?.id}
                onSelectOwner={handleChange("adminOwner")}
              />
            </FormGroup>
            {showMessageChannels && (
              <SlackChannelsRowV3
                mode="edit"
                messageChannels={config.messageChannels}
                adminOwnerName={config.adminOwner?.name ?? ""}
                onChange={handleChange("messageChannels")}
                resourceId={config.entityId}
              />
            )}
            {entityType === EntityType.Group && isSourceOfTruthGroup && (
              <>
                <FormGroup
                  label="Members on-call schedules"
                  infoTooltip="Choose the on-call schedule(s) that define this group's members. This
        group's member list will be updated each time a shift changes."
                >
                  <GroupOnCallSchedulesEdit
                    onCallSchedules={config.onCallSchedules ?? []}
                    onChange={handleChange("onCallSchedules")}
                    isV3
                  />
                </FormGroup>
                <BreakGlassUsersRow
                  mode="edit"
                  breakGlassUsers={config.breakGlassUsers}
                  onChange={handleChange("breakGlassUsers")}
                  isV3
                />
              </>
            )}
            {canBeAccessed && (
              <>
                <FormGroup
                  label="MFA to approve requests"
                  infoTooltip="Require reviewers to have completed an MFA challenge in the past 5 minutes before approving a request for this resource."
                >
                  <Switch
                    label="Required"
                    checked={config.requireMfaToApprove ?? false}
                    onChange={(checked) => {
                      handleChange("requireMfaToApprove")(checked);
                    }}
                  />
                </FormGroup>
                {isSessionable && (
                  <FormGroup
                    label="MFA to connect"
                    infoTooltip="Require that users must complete a MFA challenge within 5 minutes before connecting to this resource."
                  >
                    <Switch
                      label="Required"
                      checked={config.requireMfaToConnect ?? false}
                      onChange={(checked) => {
                        handleChange("requireMfaToConnect")(checked);
                      }}
                    />
                  </FormGroup>
                )}
                {entityType === EntityType.Resource && hasTicketPropagation && (
                  <ResourceTicketPropagationRow
                    value={config.ticketPropagation}
                    onChange={handleChange("ticketPropagation")}
                    mode="edit"
                    isV3
                  />
                )}
              </>
            )}
          </>
        )}
      </FullscreenView.Sidebar>
      <FullscreenView.Content>
        {props.errors.map((error) => (
          <Banner message={error} type="error" marginBottom="md" />
        ))}
        {canUseRiskSensitivity &&
          !props.isImport &&
          entityType == EntityType.Resource && (
            <>
              <FormGroup
                label="Risk Sensitivity"
                infoTooltip={
                  "Indicates level of concern/responsiveness towards potential risks affecting this resource."
                }
              >
                <div
                  className={sprinkles({
                    display: "flex",
                    flexDirection: "column",
                    gap: "sm",
                  })}
                >
                  {initialSensitivity?.isDefault &&
                  initialSensitivity.value != RiskLevel.Unknown ? (
                    <div
                      className={sprinkles({
                        display: "inline",
                      })}
                    >
                      <Icon name="zap" color="purple500" size="sm" /> Opal
                      assigns a{" "}
                      <span
                        className={sprinkles({
                          fontWeight: "semibold",
                        })}
                      >
                        {initialSensitivity.value.toLowerCase()}
                      </span>{" "}
                      default risk sensitivity to this resource based on its
                      application and resource type.
                    </div>
                  ) : null}

                  <div
                    className={sprinkles({
                      width: "fit-content",
                    })}
                  >
                    {initialSensitivity?.isDefault &&
                    initialSensitivity.value != RiskLevel.Unknown ? (
                      <div>Manually set resource sensitivity</div>
                    ) : null}

                    <RiskSensitivityDropdown
                      selectedSensitivity={
                        config.riskSensitivity?.isDefault
                          ? undefined
                          : config.riskSensitivity?.value
                      }
                      onSelectSensitivity={handleRiskSensitivityChange}
                      size="md"
                    />
                  </div>
                </div>
              </FormGroup>
              <Divider margin="md" />
            </>
          )}
        {hasConfigTemplateForkRequired ? (
          <div className={sprinkles({ marginBottom: "md" })}>
            {!config.forkConfigurationTemplates ? (
              <Banner
                type="warning"
                marginBottom="md"
                message="This item is linked to a configuration template. To change settings controlled by templates, you must first unlink the template. If you want to make a mass-change to templated items, please edit the template instead."
              />
            ) : null}
            <Checkbox
              label={
                config.useParentConfig && allowUseParentConfig
                  ? "To unlink this template, first unlink any parent templates"
                  : "Unlink configuration template"
              }
              disabled={config.useParentConfig && allowUseParentConfig}
              checked={config.forkConfigurationTemplates ?? false}
              onChange={(checked) => {
                handleChange("forkConfigurationTemplates")(checked);
              }}
            />
          </div>
        ) : null}
        {showTemplatableRows && (
          <>
            <div>
              <FormGroup label="Who can view and request this item">
                <VisibilityRow
                  mode="edit"
                  entityType={entityType}
                  visibility={config.visibility}
                  visibilityGroups={config.visibilityGroups ?? []}
                  parentResourceName={config.parentResourceName}
                  parentResourceVisibility={config.parentResourceVisibility}
                  parentResourceVisibilityGroups={
                    config.parentResourceVisibilityGroups
                  }
                  // For okta resources, things work a little differently. We don't
                  // consider the visibility of the connection when computing the
                  // final visibility of an app or group, rather we only consider
                  // the final visibility of the item.
                  connectionName={
                    isOktaResource ? undefined : config.connectionName
                  }
                  connectionVisibility={
                    isOktaResource ? undefined : config.connectionVisibility
                  }
                  connectionVisibilityGroups={
                    isOktaResource
                      ? undefined
                      : config.connectionVisibilityGroups
                  }
                  onChangeVisibilityAndGroups={(val: {
                    visibility?: Visibility;
                    groupIds?: string[];
                    setDoNotChange?: boolean;
                  }) => {
                    const { visibility, groupIds, setDoNotChange } = val;
                    if (setDoNotChange) {
                      props.onChange({
                        ...props.config,
                        visibility: undefined,
                        visibilityGroups: undefined,
                      });
                      return;
                    }
                    const visDict = visibility ? { visibility } : {};
                    const visGroupsDict = groupIds
                      ? { visibilityGroups: groupIds }
                      : {};
                    props.onChange({
                      ...props.config,
                      ...visDict,
                      ...visGroupsDict,
                    });
                  }}
                  contentOnly
                  isV3
                />
              </FormGroup>
            </div>
            <Divider margin="lg" />
            {canUseGroupProjects && entityType === EntityType.Group ? (
              <>
                <GroupLeaderUsersRow
                  groupLeaderUsers={config.groupLeaderUsers}
                  onChange={handleChange("groupLeaderUsers")}
                />
                <Divider margin="md" />
              </>
            ) : null}

            {!isSourceOfTruthGroup && (
              <GroupBindingWarningBanner
                groupBinding={config.groupBinding}
                bulkMode={false}
              />
            )}
            {canBeAccessed && (
              <ConditionalConfigs
                mode="edit"
                entityId={config.entityId}
                entityType={entityType}
                requestConfigs={config.requestConfigs ?? []}
                onChange={handleChange("requestConfigs")}
                isViewingAsNonAdmin={false}
                customRequestNotification={config.customRequestNotification}
                onCustomRequestNotificationChange={handleChange(
                  "customRequestNotification"
                )}
                isV3
              />
            )}
          </>
        )}
      </FullscreenView.Content>
    </>
  );
};

export default ResourceEditForm;
