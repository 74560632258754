import { useApolloClient } from "@apollo/client";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessLevel,
  AccessReviewAction,
  AccessReviewConnectionUserFragment,
  AccessReviewGroupUserFragment,
  AccessReviewResourceUserFragment,
  ConnectionPreviewSmallFragment,
  EntityFragment,
  EntityType,
  GroupAccessLevel,
  GroupPreviewSmallFragment,
  Maybe,
  PrincipalFragment,
  PropagationStatusCode,
  PropagationTaskType,
  ResourceAccessLevel,
  ResourceDetailColumnDocument,
  ResourcePreviewSmallFragment,
  useClearResourceUserPropagationStatusMutation,
  useMarkGroupUserPropagationAsSuccessMutation,
  usePerformReviewMutation,
  useRemediateAddGroupResourcesMutation,
  useRemediateAddGroupUsersMutation,
  useRemediateAddResourceUsersMutation,
  useRemediateAddRoleAssignmentsMutation,
  useRemoveConnectionUsersMutation,
  useRemoveGroupResourcesMutation,
  useRemoveGroupUsersMutation,
  useRemoveResourceUsersMutation,
  useRemoveRoleAssignmentsMutation,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import { PropagationStatus } from "components/propagation/PropagationStatusLabelWithModal";
import { useToast } from "components/toast/Toast";
import { logError, logWarning } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";

import { FeatureFlag, useFeatureFlag } from "./feature_flags";

export enum PropagationType {
  ResourceUser,
  GroupUser,
  GroupResource,
  ConnectionUser,
  RoleAssignment,
}

export interface PropagationRemediation {
  label: string;
  onRemediate: ({ onSuccess }: { onSuccess?: () => void }) => void;
}

/*** When entity references access to a resource, its role must be defined.
 * Similarly, when entity references access to a group, its memberRole must be defined.
 * When isAccessReview is true, accessReviewId must not be null and one of the three accessReview___User must be defined.
 * i.e. when propagatedEntity is a resourceUser or a groupResource, role must be defined.
 * when propagatedEntity is a groupUser, memberRole must be defined.
 * when propagatedEntity is an accessReviewResourceUser,
 * role, accessReviewId, and accessReviewResourceUser must be defined.
 */
export interface PropagatedEntityInfo {
  resource?: ResourcePreviewSmallFragment | null;
  group?: GroupPreviewSmallFragment | null;
  connection?: ConnectionPreviewSmallFragment | null;
  user?: UserPreviewSmallFragment | null;
  entity?: EntityFragment | null;
  principal?: PrincipalFragment | null;

  role?: ResourceAccessLevel | GroupAccessLevel | AccessLevel | null;
  lastExpiringAccessPointExpiration?: string | null;

  accessReviewId?: string;
  accessReviewConnectionUser?: AccessReviewConnectionUserFragment;
  accessReviewGroupUser?: AccessReviewGroupUserFragment;
  accessReviewResourceUser?: AccessReviewResourceUserFragment;
}

export function useRemediations(
  isAccessReview: boolean,
  entityInfo: PropagatedEntityInfo,
  setErrorMessage: (errorMessage: string) => void,
  propagationStatus?: Maybe<PropagationStatus>
): PropagationRemediation[] {
  const apolloClient = useApolloClient();
  const hasRevocationRemediation = useFeatureFlag(
    FeatureFlag.RevocationRemediation
  );

  const startPushTaskPoll = usePushTaskLoader();
  const { displaySuccessToast } = useToast();
  const [removeResourceUsers] = useRemoveResourceUsersMutation();

  const [
    clearResourceUserPropagationStatus,
  ] = useClearResourceUserPropagationStatusMutation();

  const [remediateAddGroupResources] = useRemediateAddGroupResourcesMutation();
  const [removeGroupResources] = useRemoveGroupResourcesMutation();

  const [removeGroupUsers] = useRemoveGroupUsersMutation();
  const [remediateAddGroupUsers] = useRemediateAddGroupUsersMutation();

  const [remediateAddResourceUsers] = useRemediateAddResourceUsersMutation();

  const [removeConnectionUsers] = useRemoveConnectionUsersMutation();

  const [performReview] = usePerformReviewMutation();
  const [removeRoleAssignments] = useRemoveRoleAssignmentsMutation();
  const [
    remediateAddRoleAssignments,
  ] = useRemediateAddRoleAssignmentsMutation();

  const [
    markGroupUserPropagationAsSuccess,
  ] = useMarkGroupUserPropagationAsSuccessMutation();
  if (
    !propagationStatus ||
    propagationStatus.statusCode === PropagationStatusCode.Success ||
    propagationStatus.statusCode === PropagationStatusCode.Pending ||
    propagationStatus.statusCode ===
      PropagationStatusCode.PendingManualPropagation
  ) {
    return [];
  }

  if (isAccessReview) {
    switch (propagationStatus?.taskType) {
      case PropagationTaskType.ResourcesDeleteUsers: {
        return [
          {
            label: "Retry revoke",
            onRemediate: async () => {
              if (!entityInfo.accessReviewResourceUser) {
                setErrorMessage("Internal error: remediation entity not found");
                logError("Internal error: remediation entity not found");
                return;
              }
              if (!entityInfo.accessReviewId) {
                setErrorMessage("Internal error: access review not found");
                logError("Internal error: access review not found");
                return;
              }
              const accessReviewResourceUser =
                entityInfo.accessReviewResourceUser;

              await performReview({
                variables: {
                  input: {
                    accessReviewId: entityInfo.accessReviewId,
                    resourceUserActions: [
                      {
                        accessReviewResourceUserId: accessReviewResourceUser.id,
                        action: AccessReviewAction.Revoke,
                      },
                    ],
                  },
                },
                refetchQueries: [
                  "AccessReviewRevocations",
                  "OngoingAccessReviewStats",
                  "OngoingAccessReviewTabStats",
                  "OngoingAccessReviewSubtabStats",
                ],
              });
            },
          },
        ];
      }
      case PropagationTaskType.GroupsDeleteUsers: {
        const remediations = [
          {
            label: "Retry revoke",
            onRemediate: async () => {
              if (!entityInfo.accessReviewGroupUser) {
                setErrorMessage("Internal error: remediation entity not found");
                logError("Internal error: remediation entity not found");
                return;
              }
              if (!entityInfo.accessReviewId) {
                setErrorMessage("Internal error: access review not found");
                logError("Internal error: access review not found");
                return;
              }
              const accessReviewGroupUser = entityInfo.accessReviewGroupUser;
              await performReview({
                variables: {
                  input: {
                    accessReviewId: entityInfo.accessReviewId,
                    groupUserActions: [
                      {
                        accessReviewGroupUserId: accessReviewGroupUser.id,
                        action: AccessReviewAction.Revoke,
                      },
                    ],
                  },
                },
                refetchQueries: ["AccessReviewRevocations"],
              });
            },
          },
        ];
        // This is a one-off to unblock Kraken. We should remove this remediation option
        // once Kraken has completed their UAR, and we have a better way to investigate
        // Opal Internal errors.
        if (hasRevocationRemediation) {
          remediations.push({
            label: "Mark revocation as successful",
            onRemediate: async () => {
              if (!entityInfo.accessReviewGroupUser) {
                setErrorMessage("Internal error: remediation entity not found");
                logError("Internal error: remediation entity not found");
                return;
              }
              const accessReviewGroupUser = entityInfo.accessReviewGroupUser;
              await markGroupUserPropagationAsSuccess({
                variables: {
                  accessReviewGroupUserId: accessReviewGroupUser.id,
                },
                refetchQueries: ["AccessReviewRevocations"],
              });
            },
          });
        }
        return remediations;
      }
      case PropagationTaskType.ConnectionsDeleteUsers: {
        return [
          {
            label: "Retry revoke",
            onRemediate: async () => {
              if (!entityInfo.accessReviewConnectionUser) {
                setErrorMessage("Internal error: remediation entity not found");
                logError("Internal error: remediation entity not found");
                return;
              }
              if (!entityInfo.accessReviewId) {
                setErrorMessage("Internal error: access review not found");
                logError("Internal error: access review not found");
                return;
              }
              const accessReviewConnectionUser =
                entityInfo.accessReviewConnectionUser;
              await performReview({
                variables: {
                  input: {
                    accessReviewId: entityInfo.accessReviewId,
                    connectionUserActions: [
                      {
                        accessReviewConnectionUserId:
                          accessReviewConnectionUser.id,
                        action: AccessReviewAction.Revoke,
                      },
                    ],
                  },
                },
              });
            },
          },
        ];
      }
    }
    return [];
  }

  switch (propagationStatus?.taskType) {
    case PropagationTaskType.ResourcesDeleteUsers: {
      return [
        {
          label: "Retry removing user",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.resource || !entityInfo.user || !entityInfo.role) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeResourceUsers({
                variables: {
                  input: {
                    resourceUsers: [
                      {
                        resourceId: entityInfo.resource.id,
                        userId: entityInfo.user.id,
                        accessLevel: {
                          accessLevelName: entityInfo.role.accessLevelName,
                          accessLevelRemoteId:
                            entityInfo.role.accessLevelRemoteId,
                        },
                      },
                    ],
                  },
                },
                refetchQueries: ["ResourceDetailColumn"],
              });
              switch (data?.removeResourceUsers.__typename) {
                case "RemoveResourceUsersResult": {
                  startPushTaskPoll(data.removeResourceUsers.taskId, {
                    // Not sure which one we need to refetch, so let's just do both
                    refetchOnComplete: {
                      resourceId: entityInfo.resource.id,
                      userId: entityInfo.user.id,
                    },
                  });
                  onSuccess && onSuccess();
                  break;
                }
                case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
                case "CallToWebhookFailedError":
                  setErrorMessage(data.removeResourceUsers.message);
                  logWarning(new Error(data.removeResourceUsers.message));
                  break;
                default:
                  setErrorMessage(
                    `Error: ${data?.removeResourceUsers.message}`
                  );
                  logWarning(
                    new Error(
                      `failed to update resource user access: ${data?.removeResourceUsers.message}`
                    )
                  );
              }
            } catch (error) {
              logWarning(
                new Error(`failed to update resource user access: ${error}`)
              );
              setErrorMessage(`${error}`);
            }
          },
        },
        {
          label: "Cancel removal",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.resource || !entityInfo.user || !entityInfo.role) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await clearResourceUserPropagationStatus({
                variables: {
                  input: {
                    resourceId: entityInfo.resource.id,
                    userId: entityInfo.user.id,
                    accessLevelRemoteId: entityInfo.role.accessLevelRemoteId,
                  },
                },
                refetchQueries: [
                  {
                    query: ResourceDetailColumnDocument,
                    variables: {
                      id: entityInfo.resource.id,
                    },
                  },
                ],
              });
              switch (data?.clearResourceUserPropagationStatus.__typename) {
                case "ClearResourceUserPropagationStatusResult": {
                  onSuccess && onSuccess();
                  displaySuccessToast("Success: Canceled user removal");
                  break;
                }
                default: {
                  const message = `Failed to cancel removal of user ${entityInfo.user.fullName} from resource ${entityInfo.resource.name}`;
                  setErrorMessage(message);
                  logWarning(new Error(message));
                }
              }
            } catch (error) {
              logWarning(
                new Error(`failed to cancel removal of resource user: ${error}`)
              );
              setErrorMessage(`${error}`);
            }
          },
        },
      ];
    }
    case PropagationTaskType.ResourcesCreateUsers: {
      return [
        {
          label: "Remove user",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.resource || !entityInfo.user || !entityInfo.role) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeResourceUsers({
                variables: {
                  input: {
                    resourceUsers: [
                      {
                        resourceId: entityInfo.resource.id,
                        userId: entityInfo.user.id,
                        accessLevel: {
                          accessLevelName: entityInfo.role.accessLevelName,
                          accessLevelRemoteId:
                            entityInfo.role.accessLevelRemoteId,
                        },
                      },
                    ],
                    noPush: true,
                  },
                },
                refetchQueries: ["ResourceDetailColumn", "User"],
              });
              switch (data?.removeResourceUsers.__typename) {
                case "RemoveResourceUsersResult": {
                  displaySuccessToast("Success: user removed");
                  const normalizedId = apolloClient.cache.identify({
                    resourceId: entityInfo.resource.id,
                    userId: entityInfo.user.id,
                    __typename: "ResourceUser",
                  });
                  apolloClient.cache.evict({
                    id: normalizedId,
                  });
                  onSuccess && onSuccess();
                  break;
                }
                case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
                case "CallToWebhookFailedError":
                  setErrorMessage(data.removeResourceUsers.message);
                  logWarning(new Error(data.removeResourceUsers.message));
                  break;
                default:
                  setErrorMessage(
                    `Error: ${data?.removeResourceUsers.message}`
                  );
                  logWarning(
                    new Error(
                      `failed to update resource user access: ${data?.removeResourceUsers.message}`
                    )
                  );
              }
            } catch (error) {
              logWarning(
                new Error(`failed to update resource user access: ${error}`)
              );
              setErrorMessage(`${error}`);
            }
          },
        },
        {
          label: "Retry adding user",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.resource || !entityInfo.user || !entityInfo.role) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await remediateAddResourceUsers({
                variables: {
                  input: {
                    resourceUsers: [
                      {
                        resourceId: entityInfo.resource.id,
                        userId: entityInfo.user.id,
                        accessLevel: {
                          accessLevelName: entityInfo.role.accessLevelName,
                          accessLevelRemoteId:
                            entityInfo.role.accessLevelRemoteId,
                        },
                      },
                    ],
                  },
                },
                refetchQueries: ["ResourceDetailColumn", "Resource"],
              });
              switch (data?.remediateAddResourceUsers.__typename) {
                case "AddResourceUsersResult": {
                  startPushTaskPoll(data.remediateAddResourceUsers.taskId);
                  onSuccess && onSuccess();
                  break;
                }
                case "CannotAddSystemUserToResourceError":
                case "OpalGlobalImpersonationResourceDirectAddNotAllowedError":
                case "CallToWebhookFailedError":
                case "ResourceUserAlreadyExists":
                  setErrorMessage(data.remediateAddResourceUsers.message);
                  logWarning(Error(data.remediateAddResourceUsers.message));
                  break;
                default:
                  setErrorMessage(
                    "Error: failed to update resource user access"
                  );
                  logWarning(
                    new Error("failed to update resource user access")
                  );
              }
            } catch (error) {
              setErrorMessage(`${error}`);
              logWarning(
                error as Error,
                "failed to update resource user access"
              );
            }
          },
        },
      ];
    }
    case PropagationTaskType.GroupsCreateUsers: {
      return [
        {
          label: "Remove user",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.group || !entityInfo.user) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeGroupUsers({
                variables: {
                  input: {
                    groupUsers: [
                      {
                        groupId: entityInfo.group.id,
                        userId: entityInfo.user.id,
                      },
                    ],
                    noPush: true,
                  },
                },
                refetchQueries: ["Group", "User"],
              });
              switch (data?.removeGroupUsers.__typename) {
                case "RemoveGroupUsersResult": {
                  displaySuccessToast("Success: user removed");
                  startPushTaskPoll(data.removeGroupUsers.taskId, {
                    // Not sure which one we need to refetch, so let's just do both
                    refetchOnComplete: {
                      groupId: entityInfo.group.id,
                      userId: entityInfo.user.id,
                    },
                  });
                  onSuccess && onSuccess();
                  break;
                }
                case "CallToWebhookFailedError":
                  setErrorMessage(data.removeGroupUsers.message);
                  break;
                default:
                  logWarning(new Error(`failed to remove group users`));
                  setErrorMessage("Error: failed to remove group users");
              }
            } catch (error) {
              logWarning(error as Error, "failed to remove group users");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to remove group users",
                  error as Error
                )
              );
            }
          },
        },
        {
          label: "Retry adding user",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.group || !entityInfo.user) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await remediateAddGroupUsers({
                variables: {
                  input: {
                    groupUsers: [
                      {
                        groupId: entityInfo.group.id,
                        userId: entityInfo.user.id,
                      },
                    ],
                  },
                },
                refetchQueries: ["Group"],
              });
              switch (data?.remediateAddGroupUsers.__typename) {
                case "AddGroupUsersResult": {
                  const taskId = data.remediateAddGroupUsers.taskId;
                  startPushTaskPoll(taskId);
                  onSuccess && onSuccess();
                  break;
                }
                case "GroupNotFoundError":
                case "CallToWebhookFailedError":
                case "CannotAddSystemUserToGroupError":
                case "GroupUserAlreadyExists":
                  setErrorMessage(data.remediateAddGroupUsers.message);
                  logWarning(Error(data.remediateAddGroupUsers.message));
                  break;
                default:
                  setErrorMessage("Error: failed to add group user");
                  logWarning(new Error("failed to add group user"));
              }
            } catch (error) {
              setErrorMessage(`${error}`);
              logWarning(error as Error, "failed to add group user");
            }
          },
        },
      ];
    }
    case PropagationTaskType.GroupsDeleteUsers: {
      return [
        {
          label: "Retry removing user",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.group || !entityInfo.user) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }

            try {
              const { data } = await removeGroupUsers({
                variables: {
                  input: {
                    groupUsers: [
                      {
                        groupId: entityInfo.group.id,
                        userId: entityInfo.user.id,
                      },
                    ],
                  },
                },
                refetchQueries: ["Group"],
              });
              switch (data?.removeGroupUsers.__typename) {
                case "RemoveGroupUsersResult": {
                  startPushTaskPoll(data.removeGroupUsers.taskId, {
                    // Not sure which one we need to refetch, so let's just do both
                    refetchOnComplete: {
                      groupId: entityInfo.group.id,
                      userId: entityInfo.user.id,
                    },
                  });
                  onSuccess && onSuccess();
                  break;
                }
                case "CallToWebhookFailedError":
                  setErrorMessage(data.removeGroupUsers.message);
                  break;
                default:
                  logWarning(new Error(`failed to remove group users`));
                  setErrorMessage("Error: failed to remove group users");
              }
            } catch (error) {
              logWarning(error as Error, "failed to remove group users");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to remove group users",
                  error as Error
                )
              );
            }
          },
        },
      ];
    }
    case PropagationTaskType.GroupsCreateResources: {
      return [
        {
          label: "Remove resource",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.resource || !entityInfo.group || !entityInfo.role) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeGroupResources({
                variables: {
                  input: {
                    groupResources: [
                      {
                        groupId: entityInfo.group.id,
                        resourceId: entityInfo.resource.id,
                        accessLevel: {
                          accessLevelName: entityInfo.role.accessLevelName,
                          accessLevelRemoteId:
                            entityInfo.role.accessLevelRemoteId,
                        },
                      },
                    ],
                    noPush: true,
                  },
                },
                refetchQueries: ["Group"],
              });
              switch (data?.removeGroupResources.__typename) {
                case "RemoveGroupResourcesResult": {
                  displaySuccessToast("Success: resource removed");
                  const normalizedId = apolloClient.cache.identify({
                    resourceId: entityInfo.resource.id,
                    groupId: entityInfo.group.id,
                    accessLevel: {
                      accessLevelName: entityInfo.role.accessLevelName,
                      accessLevelRemoteId: entityInfo.role.accessLevelRemoteId,
                    },
                    __typename: "GroupResource",
                  });
                  apolloClient.cache.evict({
                    id: normalizedId,
                  });
                  onSuccess && onSuccess();
                  break;
                }
                case "CallToWebhookFailedError":
                  setErrorMessage(data.removeGroupResources.message);
                  logWarning(new Error(data.removeGroupResources.message));
                  break;
                case "UserFacingError":
                  setErrorMessage(data.removeGroupResources.message);
                  break;
                default:
                  setErrorMessage(
                    `Error: ${data?.removeGroupResources.message}`
                  );
                  logWarning(
                    new Error(
                      `failed to remove group resource: ${data?.removeGroupResources.message}`
                    )
                  );
              }
            } catch (error) {
              logWarning(
                new Error(`failed to remove group resource: ${error}`)
              );
              setErrorMessage(`${error}`);
            }
          },
        },
        {
          label: "Retry adding resource",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.resource || !entityInfo.group || !entityInfo.role) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await remediateAddGroupResources({
                variables: {
                  input: {
                    groupResources: [
                      {
                        groupId: entityInfo.group.id,
                        resourceId: entityInfo.resource.id,
                        accessLevel: {
                          accessLevelName: entityInfo.role.accessLevelName,
                          accessLevelRemoteId:
                            entityInfo.role.accessLevelRemoteId,
                        },
                      },
                    ],
                  },
                },
                refetchQueries: ["Group"],
              });
              switch (data?.remediateAddGroupResources.__typename) {
                case "AddGroupResourcesResult": {
                  const taskId = data.remediateAddGroupResources.taskId;
                  startPushTaskPoll(taskId);
                  onSuccess && onSuccess();
                  break;
                }
                case "GroupNotFoundError":
                case "CallToWebhookFailedError":
                case "GroupResourceAlreadyExists":
                  setErrorMessage(data.remediateAddGroupResources.message);
                  logWarning(Error(data.remediateAddGroupResources.message));
                  break;
                default:
                  setErrorMessage("Error: failed to add group resource");
                  logWarning(new Error("failed to add group resource"));
              }
            } catch (error) {
              setErrorMessage(`${error}`);
              logWarning(error as Error, "failed to add group resource");
            }
          },
        },
      ];
    }
    case PropagationTaskType.GroupsDeleteResources: {
      return [
        {
          label: "Retry removing resource",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.resource || !entityInfo.group || !entityInfo.role) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeGroupResources({
                variables: {
                  input: {
                    groupResources: [
                      {
                        groupId: entityInfo.group.id,
                        resourceId: entityInfo.resource.id,
                        accessLevel: {
                          accessLevelName: entityInfo.role.accessLevelName,
                          accessLevelRemoteId:
                            entityInfo.role.accessLevelRemoteId,
                        },
                      },
                    ],
                  },
                },
                refetchQueries: ["Group"],
              });
              switch (data?.removeGroupResources.__typename) {
                case "RemoveGroupResourcesResult": {
                  const taskId = data.removeGroupResources.taskId;
                  startPushTaskPoll(taskId);
                  onSuccess && onSuccess();
                  break;
                }
                case "CallToWebhookFailedError":
                  setErrorMessage(data.removeGroupResources.message);
                  logWarning(new Error(data.removeGroupResources.message));
                  break;
                default:
                  setErrorMessage(
                    `Error: ${data?.removeGroupResources.message}`
                  );
                  logWarning(
                    new Error(
                      `failed to remove group resource: ${data?.removeGroupResources.message}`
                    )
                  );
              }
            } catch (error) {
              logWarning(
                new Error(`failed to remove group resource: ${error}`)
              );
              setErrorMessage(`${error}`);
            }
          },
        },
      ];
    }
    case PropagationTaskType.ConnectionsDeleteUsers: {
      return [
        {
          label: "Retry removing user",
          onRemediate: async ({ onSuccess }) => {
            if (!entityInfo.user || !entityInfo.connection) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeConnectionUsers({
                variables: {
                  input: {
                    connectionUsers: [
                      {
                        userId: entityInfo.user.id,
                        connectionId: entityInfo.connection.id,
                      },
                    ],
                  },
                },
                refetchQueries: ["ConnectionUsersRow"],
              });
              switch (data?.removeConnectionUsers.__typename) {
                case "RemoveConnectionUsersResult": {
                  startPushTaskPoll(data.removeConnectionUsers.taskId, {
                    // Not sure which one we need to refetch, so let's just do both
                    refetchOnComplete: {
                      connectionId: entityInfo.connection.id,
                      userId: entityInfo.user.id,
                    },
                  });
                  onSuccess && onSuccess();
                  break;
                }
                case "ConnectionNotFoundError":
                case "ConnectionUserNotFound":
                  setErrorMessage(data.removeConnectionUsers.message);
                  break;
                default:
                  logWarning(new Error(`failed to remove app users`));
                  setErrorMessage("Error: failed to remove app users");
              }
            } catch (error) {
              logWarning(error as Error, "failed to remove app users");
              setErrorMessage("Error: failed to remove app users");
            }
          },
        },
      ];
    }
    case PropagationTaskType.ResourcesCreateResources: {
      return [
        {
          label: "Remove resource",
          onRemediate: async ({ onSuccess }) => {
            if (
              !entityInfo.principal ||
              entityInfo.principal.__typename !== "Resource" ||
              !entityInfo.entity ||
              entityInfo.entity.__typename !== "Resource"
            ) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeRoleAssignments({
                variables: {
                  input: {
                    roleAssignments: [
                      {
                        entityID: entityInfo.entity.resourceId,
                        entityType: EntityType.Resource,
                        principalID: entityInfo.principal.resourceId,
                        principalType: EntityType.Resource,
                        accessLevel: entityInfo.role
                          ? {
                              accessLevelName: entityInfo.role.accessLevelName,
                              accessLevelRemoteId:
                                entityInfo.role.accessLevelRemoteId,
                            }
                          : undefined,
                      },
                    ],
                    noPush: true,
                  },
                },
                refetchQueries: ["ResourceDetailColumn"],
              });
              switch (data?.removeRoleAssignments.__typename) {
                case "RemoveRoleAssignmentsResult": {
                  displaySuccessToast("Success: resource removed");
                  const normalizedId = apolloClient.cache.identify({
                    entityID: entityInfo.entity.resourceId,
                    principalID: entityInfo.principal.resourceId,
                    __typename: "RoleAssignment",
                  });
                  apolloClient.cache.evict({
                    id: normalizedId,
                  });
                  onSuccess && onSuccess();
                  break;
                }
                default:
                  setErrorMessage(`Error: failed to remove resource access`);
                  logWarning(new Error("failed to remove resource access"));
              }
            } catch (error) {
              logWarning(
                new Error(`failed to remove resource access: ${error}`)
              );
              setErrorMessage(`${error}`);
            }
          },
        },
        {
          label: "Retry adding resource",
          onRemediate: async ({ onSuccess }) => {
            if (
              !entityInfo.principal ||
              entityInfo.principal.__typename !== "Resource" ||
              !entityInfo.entity ||
              entityInfo.entity.__typename !== "Resource"
            ) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await remediateAddRoleAssignments({
                variables: {
                  input: {
                    roleAssignments: [
                      {
                        entityID: entityInfo.entity.resourceId,
                        entityType: EntityType.Resource,
                        principalID: entityInfo.principal.resourceId,
                        principalType: EntityType.Resource,
                        accessLevel: entityInfo.role
                          ? {
                              accessLevelName: entityInfo.role.accessLevelName,
                              accessLevelRemoteId:
                                entityInfo.role.accessLevelRemoteId,
                            }
                          : undefined,
                      },
                    ],
                  },
                },
                refetchQueries: ["ResourceDetailColumn"],
              });
              switch (data?.remediateAddRoleAssignments.__typename) {
                case "AddRoleAssignmentsResult": {
                  // TODO: handle list of taskIDs
                  startPushTaskPoll(
                    data.remediateAddRoleAssignments.taskIds[0],
                    {
                      refetchOnComplete: {
                        resourceId: entityInfo.entity.resourceId,
                      },
                    }
                  );
                  onSuccess && onSuccess();
                  break;
                }
                case "RoleAssignmentNotFoundError":
                  setErrorMessage(data.remediateAddRoleAssignments.message);
                  logWarning(Error(data.remediateAddRoleAssignments.message));
                  break;
                default:
                  setErrorMessage("Error: failed to update resource access");
                  logWarning(new Error("failed to update resource access"));
              }
            } catch (error) {
              setErrorMessage(`${error}`);
              logWarning(error as Error, "failed to update resource access");
            }
          },
        },
      ];
    }
    case PropagationTaskType.ResourcesDeleteResources: {
      return [
        {
          label: "Retry removing resource",
          onRemediate: async ({ onSuccess }) => {
            if (
              !entityInfo.principal ||
              entityInfo.principal.__typename !== "Resource" ||
              !entityInfo.entity ||
              entityInfo.entity.__typename !== "Resource"
            ) {
              setErrorMessage("Internal error: remediation entity not found");
              logError("Internal error: remediation entity not found");
              return;
            }
            try {
              const { data } = await removeRoleAssignments({
                variables: {
                  input: {
                    roleAssignments: [
                      {
                        entityID: entityInfo.entity.resourceId,
                        entityType: EntityType.Resource,
                        principalID: entityInfo.principal.resourceId,
                        principalType: EntityType.Resource,
                        accessLevel: entityInfo.role
                          ? {
                              accessLevelName: entityInfo.role.accessLevelName,
                              accessLevelRemoteId:
                                entityInfo.role.accessLevelRemoteId,
                            }
                          : undefined,
                      },
                    ],
                  },
                },
                refetchQueries: ["ResourceDetailColumn"],
              });
              switch (data?.removeRoleAssignments.__typename) {
                case "RemoveRoleAssignmentsResult": {
                  // TODO: handle list of taskIDs
                  startPushTaskPoll(data.removeRoleAssignments.taskIds[0], {
                    refetchOnComplete: {
                      resourceId: entityInfo.entity.resourceId,
                    },
                  });
                  onSuccess && onSuccess();
                  break;
                }
                default:
                  setErrorMessage(`Error: failed to remove access`);
                  logWarning(new Error("failed to remove access"));
              }
            } catch (error) {
              logWarning(new Error(`failed to remove access: ${error}`));
              setErrorMessage(`${error}`);
            }
          },
        },
      ];
    }
  }
  return [];
}
