import {
  EntityType,
  useConfigurationTemplatesViewQuery,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { ButtonV3, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { useTransitionTo } from "utils/router/hooks";

interface ConfigurationTemplateRow {
  id: string;
  name: string;
  adminOwner: string;
  visibility: string;
  requireMfaToApprove: boolean;
  requireMfaToConnect: boolean;
}

const CONFIGURATION_TEMPLATE_COLUMNS: Header<ConfigurationTemplateRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
  },
  {
    id: "adminOwner",
    label: "Admin",
    sortable: false,
  },
  {
    id: "visibility",
    label: "Visibility",
    sortable: false,
    customCellRenderer: (row) => {
      return (
        <div>{row.visibility === Visibility.Global ? "Global" : "Limited"}</div>
      );
    },
  },
  {
    id: "requireMfaToApprove",
    label: "Require MFA to approve requests",
    sortable: false,
    customCellRenderer: (row) => {
      return <div>{row.requireMfaToApprove ? "Required" : "Not Required"}</div>;
    },
  },
  {
    id: "requireMfaToConnect",
    label: "Require MFA to connect",
    sortable: false,
    customCellRenderer: (row) => {
      return <div>{row.requireMfaToConnect ? "Required" : "Not Required"}</div>;
    },
  },
];

const ConfigurationTemplatesView = () => {
  const history = useHistory();
  const transitionTo = useTransitionTo();
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const { data, loading } = useConfigurationTemplatesViewQuery({
    fetchPolicy: "cache-and-network",
  });

  const templates = data?.configurationTemplates.configurationTemplates ?? [];

  const rows: ConfigurationTemplateRow[] = templates.map((template) => {
    return {
      id: template.id,
      name: template.name,
      adminOwner: template.adminOwner.name,
      visibility: template.visibility,
      requireMfaToApprove: template.requireMfaToApprove,
      requireMfaToConnect: template.requireMfaToConnect,
    };
  });

  return (
    <>
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: "md",
        })}
      >
        <span
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
          })}
        >
          {loading && !data ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            `${pluralize(
              "Configuration Template",
              data?.configurationTemplates.configurationTemplates.length,
              true
            )}`
          )}
        </span>
        {isAdmin && (
          <ButtonV3
            label="Configuration Template"
            type="main"
            leftIconName="plus"
            onClick={() => {
              history.push("/templates/configurations/create");
            }}
            size="sm"
          />
        )}
      </div>

      {loading && !data ? (
        <ColumnListItemsSkeleton />
      ) : (
        <Table
          rows={rows}
          totalNumRows={
            data?.configurationTemplates.configurationTemplates.length ?? 0
          }
          getRowId={(ru) => ru.id}
          columns={CONFIGURATION_TEMPLATE_COLUMNS}
          onRowClick={(row, event) => {
            transitionTo(
              {
                pathname: getResourceUrlNew({
                  entityId: row.id,
                  entityType: EntityType.ConfigurationTemplate,
                }),
              },
              event
            );
          }}
        />
      )}
    </>
  );
};

export default ConfigurationTemplatesView;
