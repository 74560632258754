import {
  ConnectionType,
  EntityType,
  GroupForRemediationFragment,
  GroupResourceAccessFragment,
  GroupUserAccessFragment,
  Maybe,
  RecommendationsEntityType,
  RecommendationsSubscoreType,
  RemoveGroupResourceInput,
  ResourceForRemediationFragment,
  ResourceType,
  ResourceUserAccessFragment,
  UiSource,
  UpdateGroupUserInput,
  UpdateResourceUserInput,
  useRemoveGroupResourcesMutation,
  useUpdateGroupUsersMutation,
  useUpdateUserResourcesMutation,
} from "api/generated/graphql";
import {
  GroupForAccessLabel,
  ResourceForAccessLabel,
  UserForAccessLabel,
} from "components/label/AccessPointsLabel";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { PillV3 } from "components/pills/PillsV3";
import { Breadcrumbs, EntityIcon, Select } from "components/ui";
import Icon from "components/ui/icon/Icon";
import { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import { cloneDeep, isEmpty, omit } from "lodash";
import moment from "moment";
import { SetStateAction } from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { useGetResourceBreadcrumbs } from "utils/hooks";
import AppCell, { AppForAppCell } from "views/access_reviews/common/AppCell";
import ResourceCell, {
  ResourceForResourceCell,
} from "views/access_reviews/common/ResourceCell";
import UserCell, {
  UserForUserCell,
} from "views/access_reviews/common/UserCell";
import AppIcon from "views/apps/AppIcon";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
  minutesToExpirationValue,
} from "views/requests/utils";
import {
  getGroupResourceAccessPathsInfo,
  GroupResourceAccessPointsLabel,
} from "views/resources/GroupResourceAccessPointsLabel";
import {
  getGroupUserAccessPathsInfo,
  GroupUserAccessPointsLabel,
} from "views/resources/GroupUserAccessPointsLabel";
import { ResourceUserAccessPointsLabel } from "views/resources/ResourceUserAccessPointsLabel";

import * as styles from "./Resolutions.css";

export const REVOKE = "Revoke";
export type ExtendedExpirationOptions = ExpirationValue | typeof REVOKE;

type EntityForRemediation =
  | GroupForRemediationFragment
  | ResourceForRemediationFragment;

const selectOptions: ExtendedExpirationOptions[] = [
  REVOKE,
  ...Object.values(ExpirationValue).filter(
    (val) => val !== ExpirationValue.Indefinite
  ),
];

const revokeOnlySelectOptions: ExtendedExpirationOptions[] = [REVOKE];
type UserRemediationData = {
  user: Maybe<UserForAccessLabel & UserForUserCell> | undefined;
  access:
    | Maybe<ResourceUserAccessFragment | GroupUserAccessFragment>
    | undefined;
  entity: Maybe<ResourceForAccessLabel | GroupForAccessLabel> | undefined;
};

interface UserRemediationRow {
  id: string;
  name: string;
  title: string;
  lastUsed: string;
  role: string;
  source: string;
  provisionSource: string;
  data: UserRemediationData;
  expiration: string;
}

type ResourceRemediationData = {
  resource: Maybe<ResourceForAccessLabel> | undefined;
  access: Maybe<GroupResourceAccessFragment> | undefined;
  entity: Maybe<GroupForAccessLabel> | undefined;
};

// Name
// Type
// Role
// Source Of Access
// App
// Expires

interface ResourceRemediationRow {
  id: string;
  name: string;
  type: string;
  role: string;
  source: string;
  app: {
    appType: string;
    appName: string;
    appID: string;
  };
  lastUsed: string;
  data: ResourceRemediationData;
  expiration: string;
}

export type RemediationRow = UserRemediationRow | ResourceRemediationRow;

export function isUserRemediationRow(
  row: RemediationRow
): row is UserRemediationRow {
  return "title" in row;
}
const makeRowId = (targetId: string, accessLevelRemoteId: string) =>
  accessLevelRemoteId == "" ? targetId : targetId + ":" + accessLevelRemoteId;

const suggestionToIncludeLastUsed = [
  RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess,
  RecommendationsSubscoreType.SuggestionNotUsingAccess,
  RecommendationsSubscoreType.SuggestionUnusedAccess,
];

export const getMutationHookAndConverter = (
  entityType: RecommendationsEntityType,
  targetType: RecommendationsEntityType
) => {
  if (
    entityType === RecommendationsEntityType.Resource &&
    targetType === RecommendationsEntityType.User
  ) {
    const converter = (
      rows: UserRemediationRow[],
      newAccessByRowId: Record<string, number>
    ) => {
      const resourceUsersToUpdate: UpdateResourceUserInput[] = [];
      rows.forEach((row) => {
        if (row.data.access?.__typename !== "ResourceUserAccess") {
          return;
        }
        const rowId = makeRowId(
          row.data.user?.id ?? "",
          row.data.access?.accessLevel.accessLevelRemoteId ?? ""
        );
        const newAccess = newAccessByRowId[rowId];

        if (newAccess != null) {
          resourceUsersToUpdate.push({
            userId: row.data.user?.id ?? "",
            resourceId: row.data.entity?.id ?? "",
            accessLevel: {
              accessLevelRemoteId:
                row.data.access?.accessLevel.accessLevelRemoteId ?? "",
              accessLevelName:
                row.data.access?.accessLevel.accessLevelName ?? "",
            },
            durationInMinutes: newAccess,
          });
        }
      });
      return {
        resourceUsers: resourceUsersToUpdate,
        source: UiSource.ThreatCenter,
      };
    };

    const handleErrors = (
      mutationResponse: { updateResourceUsers?: { __typename?: string } },
      onSuccess: (message: string) => void,
      onError: (message: string) => void
    ) => {
      switch (mutationResponse?.updateResourceUsers?.__typename) {
        case "UpdateResourceUsersResult":
          onSuccess("Updated user resource access");
          break;
        default:
          onError("Failed to update user access.");
      }
    };
    return {
      useMutationHook: useUpdateUserResourcesMutation,
      convertRowsToMutationInput: converter,
      handleMutationErrors: handleErrors,
    };
  }
  if (
    entityType === RecommendationsEntityType.Group &&
    targetType === RecommendationsEntityType.User
  ) {
    const converter = (
      rows: UserRemediationRow[],
      newAccessByRowId: Record<string, number>
    ) => {
      const groupUsersToUpdate: UpdateGroupUserInput[] = [];
      rows.forEach((row) => {
        if (row.data.access?.__typename !== "GroupUserAccess") {
          return;
        }
        const rowId = row.data.user?.id ?? "";
        const newAccess = newAccessByRowId[rowId];

        if (newAccess != null) {
          groupUsersToUpdate.push({
            userId: row.data.user?.id ?? "",
            groupId: row.data.entity?.id ?? "",
            accessLevel: {
              accessLevelRemoteId:
                row.data.access?.latestExpiringAccessPoint.accessLevel
                  ?.accessLevelRemoteId ?? "",
              accessLevelName:
                row.data.access?.latestExpiringAccessPoint.accessLevel
                  ?.accessLevelName ?? "",
            },
            durationInMinutes: newAccess,
          });
        }
      });
      return {
        groupUsers: groupUsersToUpdate,
        source: UiSource.ThreatCenter,
      };
    };

    const handleErrors = (
      mutationResponse: { updateGroupUsers?: { __typename?: string } },
      onSuccess: (message: string) => void,
      onError: (message: string) => void
    ) => {
      switch (mutationResponse?.updateGroupUsers?.__typename) {
        case "UpdateGroupUsersResult":
          onSuccess("Updated group user access");
          break;
        default:
          onError("Failed to update group access.");
      }
    };
    return {
      useMutationHook: useUpdateGroupUsersMutation,
      convertRowsToMutationInput: converter,
      handleMutationErrors: handleErrors,
    };
  }
  if (
    entityType === RecommendationsEntityType.Group &&
    targetType === RecommendationsEntityType.Resource
  ) {
    const converter = (
      rows: ResourceRemediationRow[],
      newAccessByRowId: Record<string, number>
    ) => {
      const groupResourcesToUpdate: RemoveGroupResourceInput[] = [];
      rows.forEach((row) => {
        if (row.data.access?.__typename !== "GroupResourceAccess") {
          return;
        }

        const rowId = makeRowId(
          row.data.resource?.id ?? "",
          row.data.access?.accessLevel.accessLevelRemoteId ?? ""
        );
        const newAccess = newAccessByRowId[rowId];

        if (newAccess == 0) {
          groupResourcesToUpdate.push({
            groupId: row.data.entity?.id ?? "",
            resourceId: row.data.resource?.id ?? "",
            accessLevel: {
              accessLevelRemoteId:
                row.data.access?.accessLevel.accessLevelRemoteId ?? "",
              accessLevelName:
                row.data.access?.accessLevel.accessLevelName ?? "",
            },
          });
        }
      });
      return {
        groupResources: groupResourcesToUpdate,
        source: UiSource.ThreatCenter,
      };
    };

    const handleErrors = (
      mutationResponse: { updateGroupResources?: { __typename?: string } },
      onSuccess: (message: string) => void,
      onError: (message: string) => void
    ) => {
      switch (mutationResponse?.updateGroupResources?.__typename) {
        case "UpdateGroupResourcesResult":
          onSuccess("Updated group resource access");
          break;
        default:
          onError("Failed to update group access.");
      }
    };
    return {
      useMutationHook: useRemoveGroupResourcesMutation,
      convertRowsToMutationInput: converter,
      handleMutationErrors: handleErrors,
    };
  }
  return {
    useMutationHook: () => {
      throw new Error("Unsupported entity type");
    },
    convertRowsToMutationInput: () => {
      throw new Error("Unsupported entity type");
    },
    handleMutationErrors: () => {
      throw new Error("Unsupported entity type");
    },
  };
};

export const entityHasRoles = (rows: RemediationRow[]): boolean => {
  return rows.some((row) => row.role !== "--");
};

export const getRemediationRows = (
  entityForRemediation: EntityForRemediation | undefined,
  targetType: RecommendationsEntityType
): RemediationRow[] => {
  if (
    entityForRemediation?.__typename === "Resource" &&
    targetType === RecommendationsEntityType.User
  ) {
    const resource = {
      ...entityForRemediation,
      id: entityForRemediation?.resourceId,
    };

    const rows: RemediationRow[] = [];

    const directResourceUsers = resource?.resourceUsers.filter(
      (resourceUser) => {
        return resourceUser.access?.directAccessPoint;
      }
    );

    directResourceUsers.forEach((user) => {
      if (!user.user || !user.access) {
        return;
      }

      rows.push({
        id: makeRowId(user.userId, user.access.accessLevel.accessLevelRemoteId),
        name: user.user.fullName,
        title: user.user.position || "--",
        lastUsed: user.mostRecentSessionEnded
          ? moment(user.mostRecentSessionEnded).fromNow()
          : "Never",
        role: user.accessLevel.accessLevelName || "--",
        source: "",
        provisionSource: user.provisionSource || "",
        data: {
          user: user.user,
          access: user.access,
          entity: resource,
        },
        expiration: "",
      });
    });

    return rows;
  } else if (
    entityForRemediation?.__typename === "Group" &&
    targetType === RecommendationsEntityType.User
  ) {
    const group = {
      ...entityForRemediation,
      id: entityForRemediation?.groupId,
    };

    const rows: RemediationRow[] = [];

    entityForRemediation?.groupUsers.forEach((user) => {
      if (!user.user || !user.access) {
        return;
      }

      rows.push({
        id: user.userId,
        name: user.user.fullName,
        title: user.user.position || "--",
        lastUsed: user.lastUsedAt ? moment(user.lastUsedAt).fromNow() : "Never",
        role: user.accessLevel?.accessLevelName || "--",
        source: "",
        provisionSource: user.provisionSource || "",
        data: {
          user: user.user,
          access: user.access,
          entity: group,
        },
        expiration: "",
      });
    });

    return rows;
  } else if (
    entityForRemediation?.__typename === "Group" &&
    targetType === RecommendationsEntityType.Resource
  ) {
    const group = {
      ...entityForRemediation,
      id: entityForRemediation?.groupId,
    };

    const rows: ResourceRemediationRow[] = [];

    entityForRemediation?.groupResources.forEach((resource) => {
      if (!resource.resource || !resource.access) {
        return;
      }
      rows.push({
        id: makeRowId(
          resource.resourceId,
          resource.access.accessLevel.accessLevelRemoteId
        ),
        name: resource.resource.name,
        app: {
          appType: resource.resource.connection?.connectionType || "--",
          appName: resource.resource.connection?.name || "--",
          appID: resource.resource.connection?.id || "--",
        },
        type: resource.resource.resourceType || "--",
        lastUsed: resource.lastUsedAt
          ? moment(resource.lastUsedAt).fromNow()
          : "Never",
        role: resource.access.accessLevel.accessLevelName || "--",
        source: "",
        data: {
          resource: resource.resource,
          access: resource.access,
          entity: group,
        },
        expiration: "",
      });
    }, []);

    return rows;
  }
  return [];
};

export const getRemediationColumns = (
  entity: EntityForRemediation,
  targetEntityType: RecommendationsEntityType,
  suggestionType: RecommendationsSubscoreType,
  newAccessByRowId: Record<string, number>,
  setNewAccessByRowId: (value: SetStateAction<Record<string, number>>) => void,
  editMode: boolean,
  hasRole: boolean
): Header<UserRemediationRow>[] | Header<ResourceRemediationRow>[] => {
  const entityType = entityForRemediationToType(entity);
  if (targetEntityType === RecommendationsEntityType.User) {
    return getUserRemediationColumns(
      newAccessByRowId,
      setNewAccessByRowId,
      editMode,
      (row) =>
        entityType === RecommendationsEntityType.Resource
          ? getResourceUserAccessPointsLabel(row, newAccessByRowId)
          : getGroupUserAccessPointsLabel(row, newAccessByRowId),
      hasRole,
      suggestionToIncludeLastUsed.includes(suggestionType),
      entityType,
      suggestionType
    );
  } else if (targetEntityType === RecommendationsEntityType.Resource) {
    return getResourceRemediationColumns(
      newAccessByRowId,
      setNewAccessByRowId,
      editMode,
      hasRole
    );
  }
  return [];
};

export const RemediationEntityLabel = (props: RemediationEntityLabelProps) => {
  const { entity, setError } = props;

  if (entity?.__typename === "Resource") {
    return (
      <ResourceEntityLabel
        resource={entity}
        setError={setError}
        isOktaApp={props.isOktaApp}
      />
    );
  }
  if (entity?.__typename === "Group") {
    return <GroupEntityLabel group={entity} setError={setError} />;
  }
  return null;
};

export const entityForRemediationToType = (
  entityForRemediation: EntityForRemediation | undefined
): RecommendationsEntityType => {
  switch (entityForRemediation?.__typename) {
    case "Resource":
      return RecommendationsEntityType.Resource;
    case "Group":
      return RecommendationsEntityType.Group;
    default:
      throw new Error("Unsupported entity type");
  }
};

const getResourceRemediationColumns = (
  newAccessByRowId: Record<string, number>,
  setNewAccessByRowId: (value: SetStateAction<Record<string, number>>) => void,
  editMode: boolean,
  hasRoles: boolean
): Header<ResourceRemediationRow>[] | Header<UserRemediationRow>[] => {
  const columns: Header<ResourceRemediationRow>[] = [
    {
      id: "name",
      label: "Name",
      customCellRenderer: (row) => {
        const resource: ResourceForResourceCell = {
          id: row.id,
          name: row.name,
          type: row.data.resource?.resourceType as ResourceType,
        };
        return (
          <>
            <ResourceCell resource={resource} openInNewTab={true} />
          </>
        );
      },
    },
    {
      id: "app",
      label: "App",
      customCellRenderer: (row) => {
        const app: AppForAppCell = {
          appID: row.app.appID,
          appName: row.app.appName,
          appType: row.app.appType,
        };
        return <AppCell app={app} openInNewTab={true} />;
      },
    },
    {
      id: "lastUsed",
      label: "Last Used",
    },
    {
      id: "source",
      label: "Access Paths",
      sortable: false,
      width: 120,
      customCellRenderer: (row) => {
        if (row.data.access?.__typename !== "GroupResourceAccess") {
          return <div>{"--"}</div>;
        }
        return (
          <div>
            <GroupResourceAccessPointsLabel
              accessPathsInfo={getGroupResourceAccessPathsInfo({
                group: row.data.entity,
                access: row.data.access,
                resource: row.data.resource,
              })}
              group={row.data.entity}
              access={row.data.access}
              resource={row.data.resource}
              role={row.data.access?.accessLevel}
            />
          </div>
        );
      },
    },
    {
      id: "expiration",
      label: "Expires",
      sortable: false,
      width: 150,
      customCellRenderer: (row: ResourceRemediationRow) => {
        if (editMode) {
          const getCurrentSelectOption = () => {
            const durationMin = newAccessByRowId[row.id];
            if (durationMin === 0) {
              return REVOKE;
            } else if (durationMin == null) {
              return undefined;
            } else {
              return minutesToExpirationValue(durationMin);
            }
          };
          return (
            <Select
              size="xs"
              options={revokeOnlySelectOptions}
              value={getCurrentSelectOption()}
              getOptionLabel={(opt) => opt}
              placeholder="Convert access to..."
              onChange={(opt) => {
                if (!opt) {
                  setNewAccessByRowId((prev: Record<string, number>) => {
                    delete prev[row.id];
                    return {
                      ...prev,
                    };
                  });
                  return;
                }
                if (opt === REVOKE) {
                  setNewAccessByRowId((prev: Record<string, number>) => ({
                    ...prev,
                    [row.id]: 0,
                  }));
                }
              }}
              clearable
            />
          );
        }
        const rowId =
          row.data.access?.__typename === "GroupResourceAccess"
            ? makeRowId(
                row.data.resource?.id ?? "",
                row.data.access?.accessLevel.accessLevelRemoteId ?? ""
              )
            : row.data.resource?.id ?? "";

        if (newAccessByRowId[rowId] != null) {
          const newAccess = newAccessByRowId[rowId ?? ""];
          return (
            <div
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <div
                className={sprinkles({
                  textDecoration: "line-through",
                })}
              >
                {row.data?.access?.latestExpiringAccessPoint?.expiration
                  ? moment(
                      row.data?.access?.latestExpiringAccessPoint?.expiration
                    ).fromNow()
                  : "Permanent Access"}
              </div>
              <div>
                <Icon name="arrow-right" size="xs" />
                {newAccess === 0
                  ? "Revoke Now"
                  : minutesToExpirationValue(newAccess)}
              </div>
            </div>
          );
        }
        return row.data?.access?.latestExpiringAccessPoint?.expiration
          ? moment(
              row.data?.access?.latestExpiringAccessPoint?.expiration
            ).fromNow()
          : "Permanent Access";
      },
    },
  ];
  if (hasRoles) {
    columns.splice(2, 0, {
      id: "role",
      label: "Role",
    });
  }

  return columns;
};

const getUserRemediationColumns = (
  newAccessByRowId: Record<string, number>,
  setNewAccessByRowId: (value: SetStateAction<Record<string, number>>) => void,
  editMode: boolean,
  accessPointsLabelRenderer: (row: UserRemediationRow) => React.ReactElement,
  hasRoles: boolean,
  includeUsageColumn: boolean,
  targetEntityType: RecommendationsEntityType,
  suggestionType: RecommendationsSubscoreType
): Header<UserRemediationRow>[] | Header<ResourceRemediationRow>[] => {
  const columns: Header<UserRemediationRow>[] = [
    {
      id: "name",
      label: "Name",
      customCellRenderer: (row) => {
        if (!row.data.user) {
          return <></>;
        }
        return <UserCell user={row.data.user} openInNewTab={true} />;
      },
    },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "source",
      label: "Access Paths",
      sortable: false,
      width: 120,
      customCellRenderer: accessPointsLabelRenderer,
    },
    {
      id: "provisionSource",
      label: "Source",
      sortable: false,
      width: 120,
      customCellRenderer: (row) => {
        switch (row.provisionSource) {
          case "EXTERNAL":
            return "External";
          case "OPAL":
            return "In Opal";
          default:
            return "Unknown";
        }
      },
    },
    {
      id: "expiration",
      label: "Expires",
      sortable: false,
      width: 150,
      customCellRenderer: (row: UserRemediationRow) => {
        if (editMode) {
          const getCurrentSelectOption = () => {
            const durationMin = newAccessByRowId[row.id];
            if (durationMin === 0) {
              return REVOKE;
            } else if (durationMin == null) {
              return undefined;
            } else {
              return minutesToExpirationValue(durationMin);
            }
          };
          return (
            <Select
              size="xs"
              options={
                suggestionType ==
                  RecommendationsSubscoreType.SuggestionUnusedAccess &&
                targetEntityType == RecommendationsEntityType.Group
                  ? revokeOnlySelectOptions
                  : selectOptions
              }
              value={getCurrentSelectOption()}
              getOptionLabel={(opt) => opt}
              placeholder="Convert access to..."
              onChange={(opt) => {
                if (!opt) {
                  setNewAccessByRowId((prev: Record<string, number>) => {
                    delete prev[row.id];
                    return {
                      ...prev,
                    };
                  });
                  return;
                }
                if (opt === REVOKE) {
                  setNewAccessByRowId((prev: Record<string, number>) => ({
                    ...prev,
                    [row.id]: 0,
                  }));
                } else {
                  const durationMin = expirationValueToDurationInMinutes(
                    opt as ExpirationValue
                  )?.asMinutes();
                  if (typeof durationMin === "number") {
                    setNewAccessByRowId((prev: Record<string, number>) => ({
                      ...prev,
                      [row.id]: durationMin,
                    }));
                  }
                }
              }}
              clearable
            />
          );
        }
        const rowId =
          row.data.access?.__typename === "ResourceUserAccess"
            ? makeRowId(
                row.data.user?.id ?? "",
                row.data.access?.accessLevel.accessLevelRemoteId ?? ""
              )
            : row.data.user?.id ?? "";

        if (newAccessByRowId[rowId] != null) {
          const newAccess = newAccessByRowId[rowId ?? ""];
          return (
            <div
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <div
                className={sprinkles({
                  textDecoration: "line-through",
                })}
              >
                {row.data?.access?.latestExpiringAccessPoint?.expiration
                  ? moment(
                      row.data?.access?.latestExpiringAccessPoint?.expiration
                    ).fromNow()
                  : "Permanent Access"}
              </div>
              <div>
                <Icon name="arrow-right" size="xs" />
                {newAccess === 0
                  ? "Revoke Now"
                  : minutesToExpirationValue(newAccess)}
              </div>
            </div>
          );
        }
        return row.data?.access?.latestExpiringAccessPoint?.expiration
          ? moment(
              row.data?.access?.latestExpiringAccessPoint?.expiration
            ).fromNow()
          : "Permanent Access";
      },
    },
  ];
  if (includeUsageColumn) {
    columns.splice(2, 0, {
      id: "lastUsed",
      label: "Last Used",
    });
  }
  if (hasRoles) {
    columns.splice(2, 0, {
      id: "role",
      label: "Role",
    });
  }

  return columns;
};

interface RemediationEntityLabelProps {
  entity: EntityForRemediation | undefined;
  setError?: (message: string) => void;
  isOktaApp: boolean;
}

export const ResourceEntityIcon = (props: {
  isOktaApp: boolean;
  iconUrl: string | null | undefined;
  connectionType?: ConnectionType;
  iconSize: PropsFor<typeof EntityIcon>["size"];
}) => {
  const { isOktaApp, iconUrl, connectionType, iconSize } = props;

  return (
    <>
      {isOktaApp ? (
        <AppIcon
          app={{
            __typename: "OktaResourceApp",
            iconUrl: iconUrl,
          }}
          iconSize={iconSize}
          iconStyle="rounded"
        />
      ) : (
        connectionType && (
          <EntityIcon
            type={connectionType}
            size={iconSize}
            iconStyle="rounded"
          />
        )
      )}
    </>
  );
};

const ResourceEntityLabel = (props: {
  resource: ResourceForRemediationFragment;
  setError?: (message: string) => void;
  isOktaApp: boolean;
}) => {
  const { resource, setError, isOktaApp } = props;
  const entity = {
    ...resource,
    id: resource?.resourceId,
  };

  const {
    data: breadcrumbs,
    error: breadcrumbsError,
    loading: breadcrumbsLoading,
  } = useGetResourceBreadcrumbs(entity);

  if (breadcrumbsError) {
    setError && setError("Failed to load resource breadcrumbs.");
  }
  return (
    <>
      <ResourceEntityIcon
        isOktaApp={isOktaApp}
        iconUrl={resource.iconUrl}
        connectionType={resource.connection?.connectionType}
        iconSize={"xxl"}
      />
      <div
        className={sprinkles({
          flexDirection: "column",
          display: "flex",
          paddingLeft: "md",
          paddingTop: "xs", // fix later
        })}
      >
        {!breadcrumbsLoading && (
          <Breadcrumbs breadcrumbInfos={breadcrumbs} openInNewTab={true} />
        )}
        <div
          className={sprinkles({
            flexDirection: "row",
            display: "flex",
            paddingTop: "xs",
          })}
        >
          <div className={styles.title}>
            <Link
              to={getResourceUrlNew(
                {
                  entityId: resource.resourceId,
                  entityType: EntityType.Resource,
                },
                EntityType.Resource
              )}
              target="_blank"
            >
              {resource.name}
            </Link>
          </div>
          <PillV3
            keyText={resourceTypeInfoByType[resource.resourceType].fullName}
            pillColor="Teal"
            icon={{
              type: "entity",
              entityType: resource.resourceType,
            }}
          />
        </div>
      </div>
    </>
  );
};

const GroupEntityLabel = (props: {
  group: GroupForRemediationFragment;
  setError?: (message: string) => void;
}) => {
  const { group, setError } = props;
  const {
    data: breadcrumbs,
    error: breadcrumbsError,
    loading: breadcrumbsLoading,
  } = useGetResourceBreadcrumbs(group);
  if (breadcrumbsError) {
    setError && setError("Failed to load resource breadcrumbs.");
  }
  return (
    <>
      <EntityIcon
        type={group.connection?.connectionType ?? ConnectionType.Custom}
        iconStyle="rounded"
        size="xxl"
      />
      <div
        className={sprinkles({
          flexDirection: "column",
          display: "flex",
          paddingLeft: "md",
          paddingTop: "xs", // fix later
        })}
      >
        {!breadcrumbsLoading && (
          <Breadcrumbs breadcrumbInfos={breadcrumbs} openInNewTab={true} />
        )}

        <div
          className={sprinkles({
            flexDirection: "row",
            display: "flex",
            paddingTop: "xs",
          })}
        >
          <div className={styles.title}>
            <Link
              to={getResourceUrlNew(
                {
                  entityId: group.groupId,
                  entityType: EntityType.Group,
                },
                EntityType.Group
              )}
              target="_blank"
            >
              {group.name}
            </Link>
          </div>
          <PillV3
            keyText={groupTypeInfoByType[group.groupType].name}
            pillColor="Teal"
            icon={{
              type: "entity",
              entityType: group.groupType,
            }}
          />
        </div>
      </div>
    </>
  );
};

const getResourceUserAccessPointsLabel = (
  row: UserRemediationRow,
  newAccessByRowId: Record<string, number>
) => {
  if (row.data.access?.__typename !== "ResourceUserAccess") {
    return <div>{"--"}</div>;
  }

  const revokeNow =
    newAccessByRowId[
      makeRowId(
        row.data.user?.id ?? "",
        row.data.access?.accessLevel.accessLevelRemoteId ?? ""
      )
    ] === 0;
  const oldAccess = row.data.access;
  const newAccess = cloneDeep(oldAccess);
  if (revokeNow) {
    omit(newAccess, "directAccessPoint");
  }
  const hasOtherAccess =
    !isEmpty(oldAccess?.groupUserAccesses) ||
    !isEmpty(oldAccess?.inheritedAccessPoints);

  if (row.data.entity?.__typename !== "Resource") {
    return <div>{"--"}</div>;
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        gap: "sm",
        alignItems: "center",
      })}
    >
      <div
        className={sprinkles({
          textDecoration: revokeNow ? "line-through" : undefined,
        })}
      >
        <ResourceUserAccessPointsLabel
          user={row.data.user}
          resource={row.data.entity}
          access={row.data.access}
          isNotClickable={hasOtherAccess && revokeNow}
        />
      </div>
      {hasOtherAccess && revokeNow && (
        <>
          <Icon name="arrow-right" size="xs" />
          <ResourceUserAccessPointsLabel
            user={row.data.user}
            resource={row.data.entity}
            access={newAccess}
          />
        </>
      )}
    </div>
  );
};

const getGroupUserAccessPointsLabel = (
  row: UserRemediationRow,
  newAccessByRowId: Record<string, number>
) => {
  if (row.data.access?.__typename !== "GroupUserAccess") {
    return <div>{"--"}</div>;
  }
  const revokeNow = newAccessByRowId[row.data.user?.id ?? ""] === 0;
  const oldAccess = row.data.access;

  const newAccess = cloneDeep(oldAccess);
  if (revokeNow) {
    omit(newAccess, "directAccessPoint");
  }
  const hasOtherAccess = !isEmpty(oldAccess?.indirectAccessPointPaths);

  if (row.data.entity?.__typename !== "Group") {
    return <div>{"--"}</div>;
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        gap: "sm",
        alignItems: "center",
      })}
    >
      <div
        className={sprinkles({
          textDecoration: revokeNow ? "line-through" : undefined,
        })}
      >
        <GroupUserAccessPointsLabel
          user={row.data.user}
          group={row.data.entity}
          accessPathsInfo={getGroupUserAccessPathsInfo({
            user: row.data.user,
            access: row.data.access,
            group: row.data.entity,
          })}
        />
      </div>
      {hasOtherAccess && revokeNow && (
        <>
          <Icon name="arrow-right" size="xs" />
          <GroupUserAccessPointsLabel
            user={row.data.user}
            group={row.data.entity}
            accessPathsInfo={getGroupUserAccessPathsInfo({
              user: row.data.user,
              access: newAccess,
              group: row.data.entity,
            })}
          />
        </>
      )}
    </div>
  );
};
