import {
  AccessReviewItemOutcome,
  AccessReviewItemStatus,
  ConnectionType,
  EntityType,
  GroupPreviewTinyFragment,
  GroupType,
  ResourcePreviewTinyFragment,
  ResourceType,
  SupportTicketFragment,
  useAccessReviewChangesWithDetailsQuery,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { ButtonV3, Icon, Input, Label, Tooltip } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

import * as styles from "./AccessChangesV3.css";
import AccessReviewReviewersCell from "./AccessReviewReviewersCell";
import { getReviewersSortValue } from "./common/Common";
import StatusCell from "./common/StatusCell";
import { ConfirmRevocationActionsModal } from "./revocations/AccessReviewRevocationsActionButtons";
import AccessReviewRevocationsSupportTicket from "./revocations/AccessReviewRevocationsSupportTicket";
import { RevocationAction } from "./revocations/AccessReviewRevocationsToggleButton";

interface AccessChangeRow {
  id: string;
  name: string;
  resource: string;
  itemType?: ResourceType | GroupType | ConnectionType;
  role?: string;
  reviewers: string;
  outcome: AccessReviewItemOutcome;
  data: {
    entityId?: string;
    entityType: EntityType;
    user?: UserPreviewSmallFragment;
    group?: GroupPreviewTinyFragment;
    resource?: ResourcePreviewTinyFragment;
    reviewers?: Array<{
      name: string;
      userId: string;
      avatarUrl: string;
      note?: string | null;
    }>;
    status: AccessReviewItemStatus;
    supportTicket?: SupportTicketFragment;
  };
}

const AccessChangesV3 = ({ accessReviewId }: { accessReviewId: string }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showReviewerModal, setShowReviewerModal] = useState(false);
  const [selectedReviewerUsers, setSelectedReviewerUsers] = useState<
    Array<{
      name: string;
      userId: string;
      avatarUrl: string;
    }>
  >();
  const { authState } = useContext(AuthContext);
  const isMember = hasBasicPermissions(authState.user);
  const { accessReviewDispatch } = useContext(AccessReviewContext);
  const [showRevocationModal, setShowRevocationModal] = useState(false);

  const updateResourceUserRevocationAction = (
    resourceUserId: string,
    action: RevocationAction
  ) => {
    const newActionByUARResourceUserId: Record<string, RevocationAction> = {
      [resourceUserId]: action,
    };
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        revocationActionByUARResourceUserId: newActionByUARResourceUserId,
      },
    });
    setShowRevocationModal(true);
  };

  const ACCESS_CHANGE_COLUMNS: Header<AccessChangeRow>[] = [
    {
      id: "name",
      label: "Principal",
      sortable: true,
      customCellRenderer: (row) => {
        if (row.data.user) {
          return (
            <ResourceLabel
              text={row.name}
              avatar={row.data.user.avatarUrl}
              pointerCursor={true}
              entityId={row.data.user.id}
              entityTypeNew={EntityType.User}
            />
          );
        } else if (row.data.group) {
          return (
            <ResourceLabel
              text={row.name}
              pointerCursor={true}
              groupType={row.data.group.groupType}
              entityId={row.data.group.id}
              entityTypeNew={EntityType.Group}
            />
          );
        } else if (row.data.resource) {
          return (
            <ResourceLabel
              text={row.name}
              pointerCursor={true}
              resourceType={row.data.resource.resourceType}
              entityId={row.data.resource.id}
              entityTypeNew={EntityType.Resource}
            />
          );
        }
        return <></>;
      },
    },
    {
      id: "resource",
      label: "Resource",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <ResourceLabel
            text={row.resource}
            pointerCursor={true}
            hideIcon
            entityId={row.data.entityId}
            entityTypeNew={row.data.entityType}
          />
        );
      },
    },
    {
      id: "itemType",
      label: "Resource Type",
      sortable: true,
      customCellRenderer: (row) => {
        if (!row.itemType) {
          return <></>;
        }
        let label: string = row.itemType;
        if (
          Object.values(ResourceType).includes(row.itemType as ResourceType)
        ) {
          label = resourceTypeInfoByType[row.itemType as ResourceType].fullName;
        } else if (
          Object.values(GroupType).includes(row.itemType as GroupType)
        ) {
          label = groupTypeInfoByType[row.itemType as GroupType].name;
        } else if (
          Object.values(ConnectionType).includes(row.itemType as ConnectionType)
        ) {
          label = connectionTypeInfoByType[row.itemType as ConnectionType].name;
        }
        return (
          <Label
            label={label}
            icon={{ type: "entity", entityType: row.itemType }}
          />
        );
      },
    },
    {
      id: "role",
      label: "Role",
      customCellRenderer: (row) => {
        return <Label label={row.role || "--"} truncateLength={28} />;
      },
    },
    {
      id: "reviewers",
      label: "Reviewers",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <AccessReviewReviewersCell
            itemType={
              row.data.entityType === EntityType.Resource
                ? "resource"
                : row.data.entityType === EntityType.Group
                ? "group"
                : "app"
            }
            canEditReviewers
            reviewerUsers={row.data.reviewers}
            onClick={() => {
              setSelectedReviewerUsers(row.data.reviewers);
              setShowReviewerModal(true);
            }}
          />
        );
      },
    },
    {
      id: "outcome",
      label: "Outcome",
      sortable: true,
      customCellRenderer: (row) => {
        if (row.data.supportTicket) {
          return (
            <AccessReviewRevocationsSupportTicket
              supportTicket={row.data.supportTicket}
            />
          );
        }

        const isCustom =
          row.itemType === ResourceType.Custom ||
          row.itemType === ConnectionType.Custom;
        const requiresAction =
          row.data.status === AccessReviewItemStatus.NeedsEndSystemRevocation;

        if (isCustom && requiresAction) {
          return (
            <div
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <Tooltip tooltipText="Access to this resource was revoked in this access review, but is awaiting manual revocation on the end system.">
                <Icon name="alert-circle" color="yellow700" size="xs" />
              </Tooltip>

              <ButtonV3
                label="Revoke"
                onClick={() => {
                  updateResourceUserRevocationAction(
                    row.id,
                    RevocationAction.MarkRevoked
                  );
                }}
                leftIconName="x-circle"
                size="xs"
                outline={true}
              />
              <ButtonV3
                label="Link ticket"
                onClick={() => {
                  updateResourceUserRevocationAction(
                    row.id,
                    RevocationAction.LinkTicket
                  );
                }}
                leftIconName="link"
                size="xs"
                outline={true}
              />
            </div>
          );
        }

        return (
          <StatusCell
            outcome={row.outcome}
            status={row.data.status}
            notes={row.data.reviewers?.map((reviewer) => {
              return { name: reviewer.name, note: reviewer.note || "" };
            })}
          />
        );
      },
    },
  ];

  const { data, loading, error } = useAccessReviewChangesWithDetailsQuery({
    variables: {
      input: {
        accessReviewId,
      },
    },
    skip: !accessReviewId,
  });

  if (loading) {
    return <ViewSkeleton />;
  }
  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const items = data?.accessReviewChanges.items ?? [];

  let rows: AccessChangeRow[] = [];
  items.forEach((item) => {
    item.groupResources?.forEach((groupResource) => {
      rows.push({
        id: groupResource.id,
        name: item.item.group?.group?.name ?? "",
        resource: groupResource.resource?.name ?? "",
        itemType: groupResource.resource?.resourceType ?? ResourceType.Custom,
        role: groupResource.accessLevel.accessLevelName,
        reviewers: getReviewersSortValue(groupResource.reviewerUsers ?? []),
        outcome: groupResource.statusAndOutcome.outcome,
        data: {
          entityId: groupResource.resource?.id,
          entityType: EntityType.Resource,
          group: item.item.group?.group ?? undefined,
          reviewers: groupResource.reviewerUsers ?? undefined,
          status: groupResource.statusAndOutcome.status,
        },
      });
    });
    item.users?.resourceUsers?.forEach((user) => {
      rows.push({
        id: user.id,
        name: user.user?.fullName ?? "",
        resource: item.item.resource?.resource?.name ?? "",
        itemType:
          item.item.resource?.resource?.resourceType ?? ResourceType.Custom,
        role: user.accessLevel.accessLevelName,
        reviewers: getReviewersSortValue(user.reviewerUsers ?? []),
        outcome: user.statusAndOutcome.outcome,
        data: {
          entityId: item.item.resource?.resource?.id,
          entityType: EntityType.Resource,
          user: user.user ?? undefined,
          reviewers: user.reviewerUsers ?? undefined,
          status: user.statusAndOutcome.status,
          supportTicket: user.supportTicket ?? undefined,
        },
      });
    });

    item.users?.groupUsers?.forEach((user) => {
      rows.push({
        id: user.id,
        name: user.user?.fullName ?? "",
        resource: item.item.group?.group?.name ?? "",
        itemType: item.item.group?.group?.groupType ?? GroupType.OpalGroup,
        role: user.accessLevel.accessLevelName,
        reviewers: getReviewersSortValue(user.reviewerUsers ?? []),
        outcome: user.statusAndOutcome.outcome,
        data: {
          entityId: item.item.group?.group?.id,
          entityType: EntityType.Group,
          user: user.user ?? undefined,
          reviewers: user.reviewerUsers ?? undefined,
          status: user.statusAndOutcome.status,
        },
      });
    });

    item.users?.connectionUsers?.forEach((user) => {
      rows.push({
        id: user.id,
        name: user.user?.fullName ?? "",
        resource: item.item.connection?.connection?.name ?? "",
        itemType: item.item.connection?.connection?.connectionType,
        reviewers: getReviewersSortValue(user.reviewerUsers ?? []),
        outcome: user.statusAndOutcome.outcome,
        data: {
          entityId: item.item.connection?.connection?.id,
          entityType: EntityType.Connection,
          user: user.user ?? undefined,
          reviewers: user.reviewerUsers ?? undefined,
          status: user.statusAndOutcome.status,
        },
      });
    });

    item.nhis?.resourceResources?.forEach((resourceResource) => {
      rows.push({
        id: resourceResource.id,
        name: resourceResource.resourcePrincipal?.name ?? "",
        resource: resourceResource.resourceEntity?.name ?? "",
        itemType:
          resourceResource.resourceEntity?.resourceType ?? ResourceType.Custom,
        role: resourceResource.accessLevel.accessLevelName,
        reviewers: getReviewersSortValue(resourceResource.reviewerUsers ?? []),
        outcome: resourceResource.statusAndOutcome.outcome,
        data: {
          entityId: resourceResource.resourcePrincipal?.id,
          entityType: EntityType.Resource,
          resource: resourceResource.resourcePrincipal ?? undefined,
          reviewers: resourceResource.reviewerUsers ?? undefined,
          status: resourceResource.statusAndOutcome.status,
        },
      });
    });
  });

  if (searchQuery) {
    rows = rows.filter(
      (row) =>
        row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.resource.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  if (isMember) {
    return <ForbiddenPage />;
  }

  return (
    <div
      className={sprinkles({
        height: "100%",
        display: "flex",
        flexDirection: "column",
      })}
    >
      <div className={styles.searchInput}>
        <Input
          leftIconName="search"
          type="search"
          style="search"
          placeholder="Search by name"
          value={searchQuery}
          onChange={setSearchQuery}
        />
      </div>
      <TableHeader entityName="Access Changes" totalNumRows={rows.length} />
      <Table
        rows={rows}
        defaultSortBy="name"
        totalNumRows={rows.length}
        loadingRows={loading}
        getRowId={(ru) => ru.id}
        columns={ACCESS_CHANGE_COLUMNS}
      />
      {selectedReviewerUsers && showReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showReviewerModal}
          onClose={() => {
            setShowReviewerModal(false);
          }}
          loading={loading}
          onSubmit={() => {}}
          errorMessage={""}
          entryInfos={
            selectedReviewerUsers?.map((ru) => ({
              id: ru.userId,
              name: ru.name,
              avatarUrl: ru.avatarUrl,
              canBeRemoved: false,
              entityIds: [],
            })) ?? []
          }
          canEditReviewers={false}
        />
      )}
      {showRevocationModal && (
        <ConfirmRevocationActionsModal
          accessReviewId={accessReviewId}
          showModal={showRevocationModal}
          setShowModal={setShowRevocationModal}
        />
      )}
    </div>
  );
};

export default AccessChangesV3;
