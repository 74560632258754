import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupResourceInput,
  ConnectionType,
  GroupDropdownPreviewFragment,
  GroupType,
  ResourceAccessLevel,
  ResourcePreviewWithGroupsFragment,
  useAddGroupResourcesMutation,
  usePaginatedGroupDropdownLazyQuery,
  useResourceAddGroupsQuery,
  useSearchGroupsQuery,
} from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Select,
} from "components/ui";
import List from "components/ui/list/ListV3";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import {
  resourceRequiresAtLeastOneRole,
  serviceTypeHasMaxOneRole,
} from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import * as styles from "./GroupAddResources.css";

const PAGE_SIZE = 100;

interface ListItem {
  id: string;
  icon: IconData;
  name: string;
  connectionType?: ConnectionType;
  groupType?: GroupType;
}

const ResourceAddGroupsView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { resourceId } = useParams<{ resourceId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [groupById, setGroupById] = useState<{
    [groupId: string]: GroupDropdownPreviewFragment;
  }>({});
  const [groupsByConnectionId, setGroupsByConnectionId] = useState<{
    [connectionId: string]: GroupDropdownPreviewFragment[];
  }>({});
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [
    accessDurationByGroupIdToAdd,
    setAccessDurationByGroupIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [addError, setAddError] = useState("");

  const [getGroups] = usePaginatedGroupDropdownLazyQuery();
  const { data, loading, error } = useResourceAddGroupsQuery({
    variables: {
      resourceId,
    },
  });
  const {
    data: searchGroupsData,
    loading: searchGroupsLoading,
    error: searchGroupsError,
  } = useSearchGroupsQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },

    skip: debouncedSearchQuery === "",
  });
  const [
    addGroupResources,
    { loading: addLoading },
  ] = useAddGroupResourcesMutation();

  let resource: ResourcePreviewWithGroupsFragment | undefined;
  if (data?.resource.__typename === "ResourceResult") {
    resource = data.resource.resource;
  }
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numGroups)
  );
  const allRoles = resource?.accessLevels ?? [];

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!resource?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!resource || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const resourceHasRoles = resourceRequiresAtLeastOneRole(resource);
  const directRolesByGroupId: Record<string, ResourceAccessLevel[]> = {};
  resource.containingGroups.forEach((groupResource) => {
    if (!groupResource.access.directAccessPoint) {
      return;
    }
    if (!directRolesByGroupId[groupResource.groupId]) {
      directRolesByGroupId[groupResource.groupId] = [];
    }
    directRolesByGroupId[groupResource.groupId] = _.uniqBy(
      [
        ...directRolesByGroupId[groupResource.groupId],
        groupResource.accessLevel,
      ],
      "accessLevelRemoteId"
    );
  });

  const handleClose = () => {
    transitionBack(`/resources/${resourceId}#groups`);
  };

  const handleFetchGroups = async (connectionId: string) => {
    try {
      const { data } = await getGroups({
        variables: {
          input: {
            connectionIds: [connectionId],
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      setGroupsByConnectionId((resourcesByConnectionId) => {
        return {
          ...resourcesByConnectionId,
          [connectionId]: data?.groups.groups ?? [],
        };
      });

      setGroupById((groupById) => {
        return {
          ...groupById,
          ...data?.groups.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {} as typeof groupById),
        };
      });
    } catch (err) {
      logError(err, "Failed to fetch groups for connection " + connectionId);
    }
  };

  const title = (
    <>
      Add Groups:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <EntityIcon type={resource.resourceType} size="lg" />
      </div>
      {resource.name}
    </>
  );

  const numGroupsToAdd = Object.keys(roleByGroupIdToAdd).length;
  const disabledGroupIds = new Set();
  resource.containingGroups.forEach((groupResource) => {
    const allRolesAdded =
      allRoles.length === directRolesByGroupId[groupResource.groupId]?.length;
    if (allRolesAdded) {
      disabledGroupIds.add(groupResource.groupId);
    }
  });

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_resource_to_groups",
      properties: {
        numGroupsAddedTo: Object.entries(roleByGroupIdToAdd).length,
      },
    });
    try {
      const groupResourcesToAdd: AddGroupResourceInput[] = [];
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        // Convert expiration value to duration in minutes, default to undefined.
        const expirationVal =
          accessDurationByGroupIdToAdd[groupId] || ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();
        if (roles.length === 0) {
          // If resource requires a role, but none are selected,
          // show an error.
          if (resourceHasRoles) {
            setAddError("Please select at least one role for each group.");
            return;
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            groupResourcesToAdd.push({
              groupId,
              resourceId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: accessDurationInMinutes,
            });
          }
        }

        roles.forEach((role) => {
          groupResourcesToAdd.push({
            groupId,
            resourceId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToAdd,
          },
        },
        refetchQueries: ["ResourceDetailColumn"],
      });
      switch (data?.addGroupResources.__typename) {
        case "AddGroupResourcesResult":
          if (data.addGroupResources.taskId) {
            startPushTaskPoll(data.addGroupResources.taskId);
          }
          handleClose();
          break;
        case "GroupNotFoundError":
        case "GroupResourceAlreadyExists":
          setAddError(data.addGroupResources.message);
          break;
        default:
          logError(new Error(`failed to add resource to groups`));
          setAddError("Error: failed to add resource to groups");
      }
    } catch (error) {
      logError(error, "failed to add resource to groups");
      setAddError(
        getModifiedErrorMessage(
          "Error: failed to add resource to groups",
          error
        )
      );
    }
  };

  const renderConnectionsList = () => {
    const listItems: ListItem[] = connections.map((connection) => {
      return {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
    });

    return (
      <List
        items={listItems}
        getItemKey={(item) => item.id}
        getItemLabel={(item) => item.name}
        getItemSublabel={(item) => {
          if (disabledGroupIds.has(item.id)) {
            return "Already in group";
          }
          return "";
        }}
        getItemDisabled={(item) => {
          return disabledGroupIds.has(item.id);
        }}
        getIcon={(item) => item.icon}
        getActionLabel={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "Remove" : "Add";
          }
          return "";
        }}
        getActionIcon={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "x" : "plus";
          }
        }}
        onSelectItem={(item) => {
          if (item.connectionType) {
            setExpandedItems((expandedItems) => {
              if (expandedItems.includes(item.id)) {
                return expandedItems.filter((id) => id !== item.id);
              } else {
                return [...expandedItems, item.id];
              }
            });
            if (item.connectionType && !groupsByConnectionId[item.id]) {
              handleFetchGroups(item.id);
            }
          } else {
            setRoleByGroupIdToAdd((prev) => {
              const newRoles = { ...prev };
              if (item.id in newRoles) {
                delete newRoles[item.id];
                return newRoles;
              } else {
                return {
                  ...prev,
                  [item.id]: [],
                };
              }
            });
          }
        }}
        hasNestedItems={(item) => Boolean(item.connectionType)}
        getNestedItems={(item) => {
          const groups = groupsByConnectionId[item.id];
          return groups?.map((group) => {
            const iconData: IconData = {
              type: "entity",
              entityType: group.groupType,
            };
            return {
              id: group.id,
              icon: iconData,
              name: group.name,
              groupType: group.groupType,
            };
          });
        }}
        expandedItems={expandedItems}
      />
    );
  };

  const renderSearchList = () => {
    if (searchGroupsError) {
      return <ModalErrorMessage errorMessage={searchGroupsError.message} />;
    }
    return (
      <List
        items={searchGroupsData?.groups.groups ?? []}
        loading={loading || searchGroupsLoading}
        getItemKey={(item) => item.id}
        getItemLabel={(item) => item.name}
        getIcon={(item) => {
          return {
            type: "entity",
            entityType: item.groupType,
          };
        }}
        getItemSublabel={(item) => {
          if (disabledGroupIds.has(item.id)) {
            return "Already in group";
          }
          return "";
        }}
        getItemDisabled={(item) => {
          return disabledGroupIds.has(item.id);
        }}
        noItemsMessage="No search results"
        onSelectItem={(item) => {
          setRoleByGroupIdToAdd((prev) => {
            const newRoles = { ...prev };
            if (item.id in newRoles) {
              delete newRoles[item.id];
              return newRoles;
            } else {
              return {
                ...prev,
                [item.id]: [],
              };
            }
          });
          setAccessDurationByGroupIdToAdd((prev) => {
            const newAccessDurations = { ...prev };
            if (item.id in newAccessDurations) {
              delete newAccessDurations[item.id];
              return newAccessDurations;
            } else {
              return {
                ...prev,
                [item.id]: ExpirationValue.Indefinite,
              };
            }
          });
          setGroupById((groupById) => {
            return {
              ...groupById,
              [item.id]: item,
            };
          });
        }}
        getActionLabel={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "Remove" : "Add";
          }
          return "";
        }}
        getActionIcon={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "x" : "plus";
          }
        }}
      />
    );
  };

  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numGroupsToAdd === 0}
      primaryButtonLabel={`Add ${
        numGroupsToAdd ? numGroupsToAdd : ""
      } ${pluralize("group", numGroupsToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select groups to add the resource to:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 groups in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numGroupsToAdd} {pluralize("Group", numGroupsToAdd)}
        </div>
        {Object.keys(roleByGroupIdToAdd).map((groupId) => {
          const group = groupById[groupId];
          if (!group || !group.connection) {
            return null;
          }
          const existingRoles = directRolesByGroupId[groupId] || [];
          const rolesToAdd = roleByGroupIdToAdd[groupId] || [];
          const roleOptions = allRoles.filter((role) => {
            return ![...existingRoles, ...rolesToAdd].some(
              (existingRole) =>
                existingRole.accessLevelRemoteId === role.accessLevelRemoteId
            );
          });

          return (
            <div key={group.id} className={styles.resourceCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                })}
              >
                <div
                  className={sprinkles({
                    flexShrink: 0,
                  })}
                >
                  <EntityIcon
                    type={group.connection.connectionType}
                    iconStyle="rounded"
                  />
                </div>
                <div className={styles.resourceInfoSection}>
                  <div className={styles.resourceCardHeader}>{group.name}</div>
                  <div className={styles.resourceCardSubtitle}>
                    {group.connection.name}
                  </div>
                  <div className={styles.resourceCardType}>
                    <EntityIcon type={group.groupType} includeBrand={false} />
                    {groupTypeInfoByType[group.groupType].name}
                  </div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setRoleByGroupIdToAdd((prev) => {
                        const newRoles = { ...prev };
                        delete newRoles[group.id];
                        return newRoles;
                      });
                      setAccessDurationByGroupIdToAdd((prev) => {
                        const newAccessDurations = { ...prev };
                        delete newAccessDurations[group.id];
                        return newAccessDurations;
                      });
                    }}
                  />
                </div>
              </div>
              <div className={sprinkles({ marginTop: "md" })}>
                <Select
                  key={group.id}
                  options={Object.values(ExpirationValue)}
                  value={accessDurationByGroupIdToAdd[group.id]}
                  onChange={(val) => {
                    if (val) {
                      setAccessDurationByGroupIdToAdd((prev) => {
                        return {
                          ...prev,
                          [group.id]: val,
                        };
                      });
                    }
                  }}
                  disableBuiltInFiltering
                  getOptionLabel={(expirationVal) =>
                    expirationVal === ExpirationValue.Indefinite
                      ? "Indefinite access"
                      : `Access for ${expirationVal}`
                  }
                />
              </div>
              {resourceHasRoles && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    options={roleOptions}
                    loading={loading}
                    placeholder="Select role"
                    getOptionLabel={(role) => role.accessLevelName}
                    onChange={(role) => {
                      if (role) {
                        setRoleByGroupIdToAdd((prev) => {
                          const newRoles = { ...prev };
                          if (serviceTypeHasMaxOneRole(resource?.serviceType)) {
                            newRoles[groupId] = [role];
                          } else {
                            newRoles[groupId] = [
                              ...(newRoles[groupId] || []),
                              role,
                            ];
                          }
                          return newRoles;
                        });
                      }
                    }}
                    selectOnly
                  />
                  {rolesToAdd.map((role) => {
                    return (
                      <div
                        key={role.accessLevelRemoteId}
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            setRoleByGroupIdToAdd((prev) => {
                              const newRoles = { ...prev };
                              newRoles[groupId] = newRoles[groupId].filter(
                                (existingRole) =>
                                  existingRole.accessLevelRemoteId !==
                                  role.accessLevelRemoteId
                              );
                              return newRoles;
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default ResourceAddGroupsView;
