import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewLargeFragment,
  ConnectionUserFragment,
  EntityType,
  Maybe,
  useAddConnectionUsersMutation,
  useConnectionUsersRowQuery,
  useRemoveConnectionUsersMutation,
  UserPreviewLargeFragment,
  useUsersQuery,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import UserLabel from "components/label/item_labels/UserLabel";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { useToast } from "components/toast/Toast";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import ConnectionUsersTable from "views/connections/ConnectionUsersTable";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

type MenuOption = {
  label: string;
  handler: () => void;
  warning?: boolean;
  disabled?: boolean;
  hide?: boolean;
};

/* Limit for performance reasons - user needs to use search beyond the first N */
const MAX_USERS_TO_DISPLAY = 100;

type ConnectionUsersRowProps = {
  connectionId: string;
};

export const ConnectionUsersRow = (props: ConnectionUsersRowProps) => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showRemoveUsersModal, setShowRemoveUsersModal] = useState(false);

  const { data, error, loading } = useConnectionUsersRowQuery({
    /**
     * Do not cache results here because we potentially return way too many
     * objects at once, and the cache update is performed upfront, which adds a
     * long blocking operation that slow's down the component's mount by 2-10s.
     *
     * This policy can be changed back to "cache-first" (default) once we have
     * server-side pagination on this query.
     */
    fetchPolicy: "no-cache",
    variables: {
      input: {
        id: props.connectionId,
      },
    },
  });

  let connection: Maybe<ConnectionPreviewLargeFragment> = null;
  let connectionUsers: ConnectionUserFragment[] = [];

  if (data) {
    switch (data.connection.__typename) {
      case "ConnectionResult":
        connection = data.connection.connection;
        connectionUsers = data.connection.connection.connectionUsers;

        break;
      default:
        logError(new Error(`failed to get app`));
    }
  } else if (error) {
    logError(error, `failed to get app`);
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (!connection || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const users: UserPreviewLargeFragment[] = [];
  connectionUsers.forEach((cu) => {
    if (!cu.user) {
      return;
    }
    users.push(cu.user);
  });

  const menuOptions: MenuOption[] = [];

  // TODO: Add this back once we have a story for user provisioning
  // menuOptions.push({
  //   label: "Add users",
  //   handler: () => {
  //     setShowAddUserModal(true);
  //   },
  // });
  menuOptions.push({
    label: "Remove users",
    handler: () => {
      setShowRemoveUsersModal(true);
    },
  });

  let editor;
  if (menuOptions.length) {
    editor = (
      <ConditionalEditor menuOptions={menuOptions} disabledInReadOnlyMode />
    );
  }

  return (
    <EntityViewerRow
      title={"Users"}
      content={
        <EmptyStateContentWrapper
          content={
            <ConnectionUsersTable
              connection={connection}
              users={connectionUsers}
            />
          }
          entityType={EntityType.User}
          title={`No users for connection`}
          subtitle={`Ensure that ${connection.name}'s API token has permissions to read users`}
          isEmpty={users.length === 0}
        />
      }
      editor={editor}
      modals={
        <>
          {showAddUserModal && (
            <AddUsersModal
              connection={connection}
              existingUsers={users}
              showModal={showAddUserModal}
              setShowModal={setShowAddUserModal}
            />
          )}
          {showRemoveUsersModal && (
            <RemoveUsersModal
              connection={connection}
              users={users}
              showModal={showRemoveUsersModal}
              setShowModal={setShowRemoveUsersModal}
            />
          )}
        </>
      }
      isTable={true}
    />
  );
};

type RemoveUsersModalProps = {
  connection: {
    id: string;
    name: string;
  };
  users: UserPreviewLargeFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const RemoveUsersModal = (props: RemoveUsersModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [
    removeConnectionUsers,
    { loading },
  ] = useRemoveConnectionUsersMutation();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);

  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  const modalReset = () => {
    props.setShowModal(false);
    setIdsToRemove([]);
    setErrorMessage(null);
    setSearchQuery("");
  };

  return (
    <SelectItemsModal
      key={"users_remove"}
      title={"Remove users"}
      selectType={SelectType.Remove}
      itemName={"user"}
      entryInfos={props.users
        .slice()
        .filter((user) => {
          return (
            user.fullName
              .toLowerCase()
              .includes(debouncedSearchQuery.toLocaleLowerCase()) ||
            user.email
              .toLowerCase()
              .includes(debouncedSearchQuery.toLocaleLowerCase())
          );
        })
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.fullName || "";
            const bSortString = b.fullName || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .slice(0, MAX_USERS_TO_DISPLAY)
        .map((user) => {
          return {
            entityId: { entityId: user.id, entityType: EntityType.User },
            label: (
              <UserLabel
                name={user.fullName}
                avatar={user.avatarUrl}
                large
                bold
              />
            ),
            clickHandler: () => {},
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      idsToUpdate={idsToRemove}
      setIdsToUpdate={setIdsToRemove}
      isModalOpen={true}
      onClose={modalReset}
      onSubmit={async () => {
        try {
          const { data } = await removeConnectionUsers({
            variables: {
              input: {
                connectionUsers: idsToRemove.map((id) => {
                  return {
                    connectionId: props.connection.id,
                    userId: id,
                  };
                }),
              },
            },
            refetchQueries: ["ConnectionUsersRow"],
          });
          switch (data?.removeConnectionUsers.__typename) {
            case "RemoveConnectionUsersResult":
              startPushTaskPoll(data.removeConnectionUsers.taskId, {
                refetchOnComplete: { connectionId: props.connection.id },
              });
              modalReset();
              break;
            case "ConnectionNotFoundError":
              setErrorMessage(data.removeConnectionUsers.message);
              break;
            case "ConnectionUserNotFound":
              setErrorMessage(data.removeConnectionUsers.message);
              break;
            default:
              logError(new Error(`failed to remove app users`));
              setErrorMessage("Error: failed to remove app users");
          }
        } catch (error) {
          logError(error, "failed to remove app users");
          setErrorMessage(
            getModifiedErrorMessage("Error: failed to remove app users", error)
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitDisabled={idsToRemove.length === 0}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      warningMessage={`Removing a user here will cause them to be deprovisioned on ${props.connection.name}. This action may be irreversible depending on whether the end system supports un-deleting deprovisioned users.`}
    />
  );
};

type AddUsersModalProps = {
  connection: {
    id: string;
  };
  existingUsers: UserPreviewLargeFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const AddUsersModal = (props: AddUsersModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [addConnectionUsers, { loading }] = useAddConnectionUsersMutation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [idsToAdd, setIdsToAdd] = useState<string[]>([]);

  const {
    data: usersData,
    error: usersError,
    loading: usersLoading,
    refetch,
  } = useUsersQuery({
    variables: {
      input: {
        maxNumEntries: MAX_USERS_TO_DISPLAY,
      },
    },
  });

  useEffect(() => {
    refetch({
      input: {
        searchQuery: debouncedSearchQuery || undefined,
        maxNumEntries: MAX_USERS_TO_DISPLAY,
      },
    });
  }, [refetch, debouncedSearchQuery]);

  const users = usersData?.users.users;
  if (usersError) {
    logError(usersError, `failed to list users`);
  }

  const modalReset = () => {
    props.setShowModal(false);
    setIdsToAdd([]);
    setErrorMessage(null);
    setSearchQuery("");
  };

  const existingUserIdSet = new Set(props.existingUsers.map((user) => user.id));

  const entityInfos =
    users
      ?.slice()
      .slice()
      .sort((a, b) => {
        if (a && b) {
          const aSortString = a.fullName || "";
          const bSortString = b.fullName || "";
          return aSortString.localeCompare(bSortString);
        }
        return 0;
      })
      .filter((user) => {
        return !user.isSystemUser && !existingUserIdSet.has(user.id);
      })
      .map((user) => {
        return {
          entityId: { entityId: user.id, entityType: EntityType.User },
          label: (
            <UserLabel
              name={user.fullName}
              avatar={user.avatarUrl}
              large
              bold
            />
          ),
          clickHandler: () => {},
          isBold: false,
          isBreadcrumb: false,
        };
      }) || [];

  return (
    <SelectItemsModal
      key={"users_add"}
      title={"Add users"}
      selectType={SelectType.Add}
      itemName={"user"}
      entryInfos={entityInfos}
      idsToUpdate={idsToAdd}
      setIdsToUpdate={setIdsToAdd}
      isModalOpen={true}
      onClose={modalReset}
      onSubmit={async () => {
        try {
          const { data } = await addConnectionUsers({
            variables: {
              input: {
                connectionUsers: idsToAdd.map((id) => {
                  return {
                    connectionId: props.connection.id,
                    userId: id,
                  };
                }),
              },
            },
            refetchQueries: ["ConnectionUsersRow"],
          });
          switch (data?.addConnectionUsers.__typename) {
            case "AddConnectionUsersResult": {
              modalReset();
              displaySuccessToast(
                `Success: ${pluralize(
                  "user",
                  idsToAdd.length,
                  true
                )} added to app`
              );
              break;
            }
            case "ConnectionNotFoundError":
              setErrorMessage(data.addConnectionUsers.message);
              break;
            case "ConnectionUserAlreadyExists":
              setErrorMessage(data.addConnectionUsers.message);
              break;
            default:
              logError(new Error(`failed to add app users`));
              setErrorMessage("Error: failed to add app users");
          }
        } catch (error) {
          logError(error, "failed to add app users");
          setErrorMessage(
            getModifiedErrorMessage("Error: failed to add app users", error)
          );
        }
      }}
      loading={loading || usersLoading}
      errorMessage={errorMessage}
      submitDisabled={idsToAdd.length === 0}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default ConnectionUsersRow;
