import {
  EntityType,
  GroupUserSource,
  UserGroupFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { TimeLabel, timeValueIndefinite } from "components/label/Label";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import moment from "moment";
import { useContext } from "react";
import { useHistory } from "react-router";
import { IndexRange, SortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import { PropagationType } from "utils/useRemediations";
import GroupUserSourceLabel, {
  getGroupUserSourceSortValue,
} from "views/groups/GroupUserSourceLabel";

import { groupTypeHasRoles } from "../../utils/directory/groups";
import { AppsContext } from "../apps/AppsContext";

interface GroupUsersTableRow {
  name: string;
  role: string;
  connection: string;
  source: string;
  expires: string;
  status: string;
}

type GroupUsersTableProps = {
  user: UserPreviewSmallFragment;
  userGroups: UserGroupFragment[];
  denseFormatting?: boolean;
  loadMoreRows?: (params: IndexRange) => Promise<void>;
  allRowsLoaded: boolean;
  urlOverride?: EntityType;
  showUnmanagedGroups: boolean;
};

export const GroupUsersTable = (props: GroupUsersTableProps) => {
  const history = useHistory();

  const { toggleUnmanagedItem } = useContext(AppsContext);

  const headers: Header<GroupUsersTableRow>[] = [
    { id: "name", label: "Name", width: 280, minWidth: 230 },
  ];

  if (
    props.userGroups.some(
      (userGroup) =>
        userGroup.group && groupTypeHasRoles(userGroup.group.groupType)
    )
  ) {
    headers.push({
      id: "role",
      label: "Role",
      width: 200,
      minWidth: 180,
    });
  }

  headers.push(
    { id: "connection", label: "App", width: 200, minWidth: 160 },
    { id: "source", label: "Source of Access", width: 150, minWidth: 150 },
    { id: "expires", label: "Expires", width: 120, minWidth: 100 },
    { id: "status", label: "Status", width: 80, minWidth: 80 }
  );

  const rows: CellRow<GroupUsersTableRow>[] = [];
  for (const userGroup of props.userGroups) {
    const group = userGroup.group;

    if (!props.showUnmanagedGroups && !group?.isManaged) {
      continue;
    }

    const name = group?.name || "";

    const supportTicket =
      userGroup?.access?.latestExpiringAccessPoint?.supportTicket;

    const expirationTime = userGroup?.access?.latestExpiringAccessPoint
      ?.expiration
      ? moment(new Date(userGroup?.access.latestExpiringAccessPoint.expiration))
      : null;

    const connection = group?.connection;

    rows.push({
      id: group?.id || "",
      data: {
        name: {
          value: name,
          element: (
            <GroupTableCellResourceLabel
              groupName={name}
              groupType={group?.groupType}
              isOnCall={group?.isOnCallSynced}
              isManaged={group?.isManaged}
            />
          ),
          clickHandler: () => {
            if (group && group.isManaged) {
              history.push(
                getResourceUrlNew({
                  entityId: group?.id ?? null,
                  entityType: EntityType.Group,
                })
              );
            } else {
              if (group) {
                toggleUnmanagedItem(group);
              }
            }
          },
        },
        role: {
          value:
            userGroup.access?.directAccessPoint?.accessLevel?.accessLevelName ||
            "",
          element: (
            <div>
              {userGroup.access?.directAccessPoint?.accessLevel
                ?.accessLevelName || "--"}
            </div>
          ),
        },
        connection: {
          sortValue: connection?.name || "",
          value: connection?.id || "",
          element: connection ? (
            <ConnectionLabel
              text={connection.name}
              connectionId={connection.id}
              connectionType={connection.connectionType}
              pointerCursor={true}
            />
          ) : (
            <>{`--`}</>
          ),
        },
        source: {
          value: userGroup.source,
          sortValue: getGroupUserSourceSortValue(userGroup.source),
          element: <GroupUserSourceLabel source={userGroup.source} />,
        },
        expires: {
          value: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={supportTicket}
              useExpiringLabel
              isOncallAccess={userGroup.source === GroupUserSource.OnCall}
            />
          ),
        },
        status: {
          value: userGroup.groupId,
          sortValue: userGroup.propagationStatus?.statusCode,
          element: (
            <PropagationStatusLabelWithModal
              propagationType={PropagationType.GroupUser}
              propagationStatus={userGroup.propagationStatus}
              isAccessReview={false}
              entityInfo={{
                user: props.user,
                group: group,
                lastExpiringAccessPointExpiration:
                  userGroup.access?.latestExpiringAccessPoint.expiration,
              }}
            />
          ),
        },
      },
    });
  }

  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"name"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded={props.allRowsLoaded}
      loadMoreRows={props.loadMoreRows}
    />
  );
};

export default GroupUsersTable;
