import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import CreateAD from "views/connections/create/CreateAD";
import CreateAWS from "views/connections/create/CreateAWS";
import CreateAWSSSO from "views/connections/create/CreateAWSSSO";
import CreateAzureAD, {
  CreateAzureADCallback,
} from "views/connections/create/CreateAzureAD";
import CreateCustom from "views/connections/create/CreateCustom";
import {
  CreateMariaDB,
  CreateMongo,
  CreateMySql,
  CreatePostgres,
} from "views/connections/create/CreateDatabase";
import CreateDuo from "views/connections/create/CreateDuo";
import CreateGCP from "views/connections/create/CreateGCP";
import CreateGitHub from "views/connections/create/CreateGitHub";
import CreateGitLab from "views/connections/create/CreateGitLab";
import CreateGoogleGroups from "views/connections/create/CreateGoogleGroups";
import CreateGoogleWorkspace from "views/connections/create/CreateGoogleWorkspace";
import CreateLDAP from "views/connections/create/CreateLDAP";
import CreateMongoAtlas from "views/connections/create/CreateMongoAtlas";
import CreateOktaDirectory from "views/connections/create/CreateOktaDirectory";
import CreatePagerDuty from "views/connections/create/CreatePagerDuty";
import CreateSalesforce from "views/connections/create/CreateSalesforce";
import CreateSnowflake from "views/connections/create/CreateSnowflake";
import CreateTailscale from "views/connections/create/CreateTailscale";
import CreateTeleport from "views/connections/create/CreateTeleport";
import CreateWorkday from "views/connections/create/CreateWorkday";

const CreateConnection = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/apps/create/active_directory`}
        component={CreateAD}
      />
      <Route exact path={`/apps/create/aws`} component={CreateAWS} />
      <Route exact path={`/apps/create/aws_sso`} component={CreateAWSSSO} />
      <Route exact path={`/apps/create/custom`} component={CreateCustom} />
      <Route exact path={`/apps/create/duo`} component={CreateDuo} />
      <Route exact path={`/apps/create/gcp`} component={CreateGCP} />
      <Route exact path={`/apps/create/github`} component={CreateGitHub} />
      <Route exact path={`/apps/create/gitlab`} component={CreateGitLab} />
      <Route exact path={`/apps/create/ldap`} component={CreateLDAP} />
      <Route
        exact
        path={`/apps/create/google_groups`}
        component={CreateGoogleGroups}
      />
      <Route
        exact
        path={`/apps/create/google_workspace`}
        component={CreateGoogleWorkspace}
      />
      <Route exact path={`/apps/create/mongo`} component={CreateMongo} />
      <Route
        exact
        path={`/apps/create/mongo_atlas`}
        component={CreateMongoAtlas}
      />
      <Route
        exact
        path={`/apps/create/okta_directory`}
        component={CreateOktaDirectory}
      />
      <Route
        exact
        path={`/apps/create/pagerduty`}
        component={CreatePagerDuty}
      />
      <Route
        exact
        path={`/apps/create/tailscale`}
        component={CreateTailscale}
      />
      <Route
        exact
        path={`/apps/create/salesforce`}
        component={CreateSalesforce}
      />
      <Route
        exact
        path={`/apps/create/snowflake`}
        component={CreateSnowflake}
      />
      <Route exact path={`/apps/create/workday`} component={CreateWorkday} />
      <Route exact path={`/apps/create/mysql`} component={CreateMySql} />
      <Route exact path={`/apps/create/mariadb`} component={CreateMariaDB} />
      <Route exact path={`/apps/create/postgres`} component={CreatePostgres} />
      <Route exact path={`/apps/create/teleport`} component={CreateTeleport} />
      <Route exact path={`/apps/create/azure_ad`} component={CreateAzureAD} />
      <Route
        exact
        path={`/apps/create/azure_ad/callback`}
        component={CreateAzureADCallback}
      />
      <Redirect to="/apps/browse" />
    </Switch>
  );
};

export default CreateConnection;
