import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessLevel,
  AccessLevelInput,
  AddRoleAssignmentInput,
  EntityType,
  PrincipalFragment,
  ResourceAccessLevel,
  ResourcePreviewWithRoleAssignmentsFragment,
  ResourceType,
  useAddRoleAssignmentsMutation,
  useResourceAddPrincipalsQuery,
} from "api/generated/graphql";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Select,
} from "components/ui";
import List from "components/ui/list/ListV3";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { connectionTypeHasNHIs } from "utils/directory/connections";
import {
  resourceHasOnlyOneRole,
  resourceTypeHasRoles,
  serviceTypeHasMaxOneRole,
} from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import {
  ForbiddenPage,
  NotFoundPage,
  UnexpectedErrorPage,
} from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./ResourceAddPrincipalsView.css";

const getPrincipalID = (principal: PrincipalFragment) => {
  switch (principal.__typename) {
    case "Resource":
      return principal.resourceId;
    case "Group":
      return principal.groupId;
    case "User":
      return principal.userId;
    default:
      logError(new Error(`Unknown principal type: ${principal.__typename}`));
      return "";
  }
};

const getPrincipalEntityType = (principal: PrincipalFragment) => {
  switch (principal.__typename) {
    case "User":
      return EntityType.User;
    case "Resource":
      return EntityType.Resource;
    case "Group":
      return EntityType.Group;
  }
  logError(new Error(`Unknown principal type: ${principal.__typename}`));
};

const getPrincipalIcon = (principal: PrincipalFragment): IconData => {
  if (principal.__typename === "User") {
    return getUserAvatarIcon(principal);
  } else if (principal.__typename === "Group") {
    return {
      type: "entity",
      entityType: principal.groupType,
    };
  } else if (principal.__typename === "Resource") {
    return {
      type: "entity",
      entityType: principal.resourceType,
    };
  }
  logError(new Error(`Unknown principal type: ${principal.__typename}`));
  return { type: "name", icon: "cube" };
};

const getPrincipalSublabel = (principal: PrincipalFragment) => {
  switch (principal.__typename) {
    case "User":
      return principal.email;
    case "Group":
      return principal.groupRemoteId ?? "";
    case "Resource":
      return principal.resourceRemoteId;
    default:
      logError(new Error(`Unknown principal type: ${principal.__typename}`));
      return "";
  }
};

type PrincipalWithExpiration = {
  principal: {
    id: string;
    name: string;
    principalType: EntityType;
    icon: IconData;
  };
  expiration: ExpirationValue;
};

const ResourceAddPrincipalsView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { resourceId } = useParams<{ resourceId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [rolesByPrincipalIdToAdd, setRolesByPrincipalIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [principalsToAddById, setPrincipalsToAddById] = useState<
    Record<string, PrincipalWithExpiration>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [addPrincipalsErrorMessage, setAddPrincipalsErrorMessage] = useState(
    ""
  );

  const [
    addRoleAssignments,
    { loading: addRoleAssignmentsLoading },
  ] = useAddRoleAssignmentsMutation();
  const { data, previousData, loading, error } = useResourceAddPrincipalsQuery({
    variables: {
      id: resourceId,
      searchQuery: debouncedSearchQuery,
    },
  });

  let resource: ResourcePreviewWithRoleAssignmentsFragment | undefined;
  if (previousData?.resource.__typename === "ResourceResult") {
    resource = previousData.resource.resource;
  }
  if (data?.resource.__typename === "ResourceResult") {
    resource = data.resource.resource;
  }
  const allPrincipals =
    data?.principals.principals ?? previousData?.principals.principals ?? [];
  const roles: ResourceAccessLevel[] = resource?.accessLevels ?? [];

  if (loading && !(data || previousData)) {
    return <FullscreenSkeleton />;
  }
  if (!resource?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!resource || error) {
    return <UnexpectedErrorPage error={error} />;
  }
  if (!connectionTypeHasNHIs(resource.connection?.connectionType)) {
    return <NotFoundPage />;
  }

  const handleClose = () => {
    transitionBack(`/resources/${resourceId}#nonhuman-identities`);
  };

  const directRolesByPrincipalId: Record<string, AccessLevel[]> = {};
  const numDirectAccessPointsByPrincipalId: Record<string, number> = {};
  resource.principalAssignmentsForEntity.forEach((roleAssignment) => {
    if (roleAssignment.access?.directAccessPoint) {
      if (!directRolesByPrincipalId[roleAssignment.principalID]) {
        directRolesByPrincipalId[roleAssignment.principalID] = [];
      }
      if (roleAssignment.accessLevel) {
        directRolesByPrincipalId[roleAssignment.principalID] = _.uniqBy(
          [
            ...directRolesByPrincipalId[roleAssignment.principalID],
            roleAssignment.accessLevel,
          ],
          "accessLevelRemoteId"
        );
      }

      if (!numDirectAccessPointsByPrincipalId[roleAssignment.principalID]) {
        numDirectAccessPointsByPrincipalId[roleAssignment.principalID] = 0;
      }
      numDirectAccessPointsByPrincipalId[roleAssignment.principalID] += 1;
    }
  });

  let resourceHasOnlyOneAccessType =
    resource && resourceHasOnlyOneRole(resource);

  if (resource.resourceType === ResourceType.OktaApp) {
    resourceHasOnlyOneAccessType = true;
  }

  // Filter out principals who have full direct access already
  const principals = allPrincipals.filter((principal) => {
    const principalID = getPrincipalID(principal);
    if (principalID.length === 0 || principalID === resourceId) {
      return false;
    }
    const directRoleCount =
      numDirectAccessPointsByPrincipalId[principalID] || 0;

    // Resource does not have access levels
    if (
      (principal.__typename == "User" && principal.isSystemUser) ||
      (resourceHasOnlyOneAccessType && directRoleCount > 0)
    ) {
      return false;
    }

    // Resource has access levels
    const rolesForResource = resource?.accessLevels ?? [];
    if (
      rolesForResource.length > 0 &&
      directRoleCount === rolesForResource.length
    ) {
      return false;
    }

    return true;
  });

  const title = (
    <>
      Add Principals:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <EntityIcon type={resource.resourceType} size="lg" />
      </div>
      {resource.name}
    </>
  );

  const resourceHasRoles = resourceTypeHasRoles({
    resourceType: resource.resourceType,
  });
  const numPrincipalsToAdd = Object.keys(principalsToAddById).length;

  const handleAddPrincipals = async () => {
    if (resourceHasRoles) {
      if (Object.keys(rolesByPrincipalIdToAdd).length !== numPrincipalsToAdd) {
        setAddPrincipalsErrorMessage(
          "You must select at least one role for each principal"
        );
        return;
      }
      for (let roles of Object.values(rolesByPrincipalIdToAdd)) {
        if (roles.length === 0) {
          setAddPrincipalsErrorMessage(
            "You must select at least one role for each principal"
          );
          return;
        }
      }
    }

    logEvent({
      name: "apps_add_role_assignments",
      properties: {
        entityID: resourceId,
        entityType: EntityType.Resource,
        numRoleAssignmentsAdded: Object.entries(rolesByPrincipalIdToAdd).length,
      },
    });
    try {
      const { data } = await addRoleAssignments({
        variables: {
          input: {
            roleAssignments: Object.keys(principalsToAddById).flatMap(
              (principalID) => {
                const expirationVal =
                  principalsToAddById[principalID].expiration ||
                  ExpirationValue.Indefinite;
                const accessDurationInMinutes = expirationValueToDurationInMinutes(
                  expirationVal
                )?.asMinutes();

                const roleAssignentsToAdd: AddRoleAssignmentInput[] = [];
                if (rolesByPrincipalIdToAdd[principalID]) {
                  for (let role of rolesByPrincipalIdToAdd[principalID]) {
                    let accessLevelInput: AccessLevelInput = {
                      accessLevelName: role.accessLevelName,
                      accessLevelRemoteId: role.accessLevelRemoteId,
                    };

                    roleAssignentsToAdd.push({
                      entityID: resourceId,
                      entityType: EntityType.Resource,
                      principalID: principalID,
                      principalType:
                        principalsToAddById[principalID].principal
                          .principalType,
                      accessLevel: accessLevelInput,
                      durationInMinutes: accessDurationInMinutes,
                    });
                  }
                } else {
                  roleAssignentsToAdd.push({
                    entityID: resourceId,
                    entityType: EntityType.Resource,
                    principalID: principalID,
                    principalType:
                      principalsToAddById[principalID].principal.principalType,
                    durationInMinutes: accessDurationInMinutes,
                  });
                }
                return roleAssignentsToAdd;
              }
            ),
          },
        },
        refetchQueries: ["ResourceDetailColumn"],
      });
      switch (data?.addRoleAssignments.__typename) {
        case "AddRoleAssignmentsResult":
          startPushTaskPoll(data.addRoleAssignments.taskIds[0]); // TODO: handle list of taskIDs
          handleClose();
          break;
        case "UserFacingError":
          setAddPrincipalsErrorMessage(data.addRoleAssignments.message);
          break;
        default:
          logError(new Error(`failed to add resource principals`));
          setAddPrincipalsErrorMessage(
            "Error: failed to add resource principals"
          );
      }
    } catch (error) {
      logError(error, "failed to add resource principals");
      setAddPrincipalsErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to add resource principals",
          error
        )
      );
    }
  };

  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddPrincipals}
      primaryButtonLabel={`Add ${
        numPrincipalsToAdd > 0 ? numPrincipalsToAdd : ""
      } ${pluralize("principal", numPrincipalsToAdd)}`}
      primaryButtonDisabled={
        numPrincipalsToAdd === 0 || loading || addRoleAssignmentsLoading
      }
      primaryButtonLoading={addRoleAssignmentsLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select principals to add to the resource:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or email"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 principals. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          <List
            items={principals}
            getItemKey={(principal) => getPrincipalID(principal)}
            getItemLabel={(principal) => principal.name}
            getItemSublabel={getPrincipalSublabel}
            getIcon={getPrincipalIcon}
            getActionLabel={(principal) => {
              return getPrincipalID(principal) in principalsToAddById
                ? "Remove"
                : "Add";
            }}
            onSelectItem={(principal) => {
              const principalID = getPrincipalID(principal);
              const principalType = getPrincipalEntityType(principal);
              if (!principalType) {
                return;
              }
              if (principalID in principalsToAddById) {
                if (resourceHasRoles) {
                  const newRolesByPrincipalIdToAdd = {
                    ...rolesByPrincipalIdToAdd,
                  };
                  delete newRolesByPrincipalIdToAdd[principalID];
                  setRolesByPrincipalIdToAdd(newRolesByPrincipalIdToAdd);
                }
                const newPrincipalsToAddByPrincipalId = {
                  ...principalsToAddById,
                };
                delete newPrincipalsToAddByPrincipalId[principalID];
                setPrincipalsToAddById(newPrincipalsToAddByPrincipalId);
              } else {
                if (resourceHasRoles) {
                  const newRolesByPrincipalIdToAdd = {
                    ...rolesByPrincipalIdToAdd,
                  };
                  newRolesByPrincipalIdToAdd[principalID] = [];
                  setRolesByPrincipalIdToAdd(newRolesByPrincipalIdToAdd);
                }
                const newPrincipalsToAddByPrincipalId = {
                  ...principalsToAddById,
                };
                const principalWithExpiration: PrincipalWithExpiration = {
                  principal: {
                    id: principalID,
                    principalType: principalType,
                    icon: getPrincipalIcon(principal),
                    ...principal,
                  },
                  expiration: ExpirationValue.Indefinite,
                };
                newPrincipalsToAddByPrincipalId[
                  principalID
                ] = principalWithExpiration;
                setPrincipalsToAddById(newPrincipalsToAddByPrincipalId);
              }
            }}
            getActionIcon={(principal) => {
              return getPrincipalID(principal) in principalsToAddById
                ? "x"
                : "plus";
            }}
            noItemsMessage="No principals found"
          />
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addPrincipalsErrorMessage && (
          <Banner
            message={addPrincipalsErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numPrincipalsToAdd}{" "}
          {pluralize("Principal", numPrincipalsToAdd)}
        </div>
        {Object.keys(principalsToAddById).map((principalID) => {
          const principal = principalsToAddById[principalID].principal;
          if (!principal) {
            return null;
          }
          const expiration = principalsToAddById[principalID].expiration;

          return (
            <div key={principalID} className={styles.principalCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                  marginBottom: "lg",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={principal.icon} />
                </div>
                <div className={styles.principalInfoSection}>
                  <div className={styles.principalCardHeader}>
                    {principal.name}
                  </div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      if (resourceHasRoles) {
                        const newRolesByPrincipalIdToAdd = {
                          ...rolesByPrincipalIdToAdd,
                        };
                        delete newRolesByPrincipalIdToAdd[principalID];
                        setRolesByPrincipalIdToAdd(newRolesByPrincipalIdToAdd);
                      }
                      const newPrincipalsToAddByPrincipalId = {
                        ...principalsToAddById,
                      };
                      delete newPrincipalsToAddByPrincipalId[principalID];
                      setPrincipalsToAddById(newPrincipalsToAddByPrincipalId);
                    }}
                  />
                </div>
              </div>
              <Select
                key={principalID}
                options={Object.values(ExpirationValue)}
                value={expiration}
                onChange={(val) => {
                  if (val) {
                    const newPrincipalsToAddByPrincipalId = {
                      ...principalsToAddById,
                    };
                    newPrincipalsToAddByPrincipalId[
                      principal.id
                    ].expiration = val;
                    setPrincipalsToAddById(newPrincipalsToAddByPrincipalId);
                  }
                }}
                disableBuiltInFiltering
                getOptionLabel={(expirationVal) =>
                  expirationVal === ExpirationValue.Indefinite
                    ? "Indefinite access"
                    : `Access for ${expirationVal}`
                }
              />
              {resourceHasRoles && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    key={principalID}
                    options={roles.filter((role) => {
                      // Only include roles that the principal does not already have
                      // or are currently selected to be added
                      if (
                        directRolesByPrincipalId[principal.id]
                          ?.map((r) => r.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      if (
                        rolesByPrincipalIdToAdd[principal.id]
                          .map((role) => role.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      return true;
                    })}
                    selectOnly
                    placeholder="Select role"
                    onChange={(val) => {
                      if (!val) {
                        return;
                      }
                      const selectedAccessLevel = {
                        accessLevelName: val.accessLevelName,
                        accessLevelRemoteId: val.accessLevelRemoteId,
                      };
                      const newRolesByrincipalIdToAdd = {
                        ...rolesByPrincipalIdToAdd,
                      };
                      if (
                        serviceTypeHasMaxOneRole(resource?.serviceType) ||
                        (newRolesByrincipalIdToAdd[principal.id].length === 1 &&
                          newRolesByrincipalIdToAdd[principal.id][0]
                            .accessLevelName === "")
                      ) {
                        newRolesByrincipalIdToAdd[principal.id] = [
                          selectedAccessLevel,
                        ];
                      } else {
                        newRolesByrincipalIdToAdd[principal.id] = [
                          ...newRolesByrincipalIdToAdd[principal.id],
                          selectedAccessLevel,
                        ];
                      }
                      setRolesByPrincipalIdToAdd(newRolesByrincipalIdToAdd);
                    }}
                    getOptionLabel={(role) => role.accessLevelName}
                  />
                  {rolesByPrincipalIdToAdd[principal.id].map((role) => {
                    if (role.accessLevelName === "") {
                      return null;
                    }
                    return (
                      <div
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            const newRolesByPrincipalIdToAdd = {
                              ...rolesByPrincipalIdToAdd,
                            };
                            newRolesByPrincipalIdToAdd[
                              principal.id
                            ] = newRolesByPrincipalIdToAdd[principal.id].filter(
                              (r) =>
                                r.accessLevelRemoteId !==
                                role.accessLevelRemoteId
                            );
                            setRolesByPrincipalIdToAdd(
                              newRolesByPrincipalIdToAdd
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default ResourceAddPrincipalsView;
