import { useMetricsFiltersQuery } from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import ResourceSearchDropdown from "components/dropdown/ResourceSearchDropdown";
import { PillV3, TagPillV3 } from "components/pills/PillsV3";
import { ButtonV3, DataElementList, Select } from "components/ui";
import DateRangePicker from "components/ui/date_picker/DateRangePicker";
import sprinkles from "css/sprinkles.css";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";

import {
  useMetricsFilterDispatch,
  useMetricsFilterState,
} from "../MetricsDashboardContext";
import * as styles from "./MetricsFilterV3.css";
import TimeBucketPicker from "./TimeBucketPicker";

const MetricsFilterV3 = () => {
  const {
    connectionIds,
    resourceIds,
    groupIds,
    tagIds,
    dateRange,
  } = useMetricsFilterState();
  const filterDispatch = useMetricsFilterDispatch();
  const logEvent = useLogEvent();

  const { data, loading, error } = useMetricsFiltersQuery({
    variables: {
      resourceIds,
      groupIds,
    },
  });
  if (error) {
    logError(error, `failed to list connections`);
  }

  const allConnections = data?.connections.connections ?? [];
  const unselectedConnections = allConnections.filter(
    (connection) => !connectionIds.includes(connection.id)
  );
  const allTags = data?.tags.tags ?? [];
  const unselectedTags = allTags.filter((tag) => !tagIds.includes(tag.id));
  const resources = data?.resources.resources ?? [];
  const groups = data?.groups.groups ?? [];

  const hasFilters =
    connectionIds.length > 0 ||
    tagIds.length > 0 ||
    resourceIds.length > 0 ||
    groupIds.length > 0;

  return (
    <>
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <div className={styles.container}>
          <div className={styles.filter}>
            <Select
              loading={loading}
              options={unselectedConnections}
              onChange={(connection) => {
                if (connection) {
                  logEvent({
                    name: "metrics_dashboard_filter_select",
                    properties: {
                      filterType: "app",
                      filterValue: connection.id,
                    },
                  });
                  filterDispatch({
                    type: "SET_CONNECTION_IDS",
                    payload: {
                      ids: [...connectionIds, connection.id],
                    },
                  });
                }
              }}
              getOptionLabel={(connection) => connection.name}
              getIcon={(connection) => ({
                type: "entity",
                entityType: connection.connectionType,
              })}
              placeholder="Filter by app"
              placeholderIcon={{ type: "name", icon: "dots-grid" }}
              selectOnly
              size="sm"
            />
          </div>
          <div className={styles.filter}>
            <ResourceSearchDropdown
              selectedResourceIds={resourceIds}
              onSelect={({ actionType, resources }) => {
                if (actionType === "remove-option") {
                  filterDispatch({
                    type: "SET_RESOURCE_IDS",
                    payload: {
                      ids: resourceIds.filter(
                        (id) => !resources.map((r) => r.id).includes(id)
                      ),
                    },
                  });
                } else {
                  logEvent({
                    name: "metrics_dashboard_filter_select",
                    properties: {
                      filterType: "resource",
                      filterValue: resources.map((r) => r.id).join(", "),
                    },
                  });
                  filterDispatch({
                    type: "SET_RESOURCE_IDS",
                    payload: {
                      ids: [...resourceIds, ...resources.map((r) => r.id)],
                    },
                  });
                }
              }}
              placeholder="Filter by resource"
              placeholderIcon={{ type: "name", icon: "cube" }}
              alwaysShowPlaceholder
              size="sm"
            />
          </div>
          <div className={styles.filter}>
            <GroupSearchDropdown
              selectedGroupIds={groupIds}
              onSelect={({ actionType, groups }) => {
                if (actionType === "remove-option") {
                  filterDispatch({
                    type: "SET_GROUP_IDS",
                    payload: {
                      ids: groupIds.filter(
                        (id) => !groups.map((g) => g.id).includes(id)
                      ),
                    },
                  });
                } else {
                  logEvent({
                    name: "metrics_dashboard_filter_select",
                    properties: {
                      filterType: "group",
                      filterValue: groups.map((g) => g.id).join(", "),
                    },
                  });
                  filterDispatch({
                    type: "SET_GROUP_IDS",
                    payload: {
                      ids: [...groupIds, ...groups.map((g) => g.id)],
                    },
                  });
                }
              }}
              placeholder="Filter by group"
              placeholderIcon={{ type: "name", icon: "users" }}
              alwaysShowPlaceholder
              size="sm"
            />
          </div>
          <Select
            loading={loading}
            options={unselectedTags}
            onChange={(tag) => {
              if (tag) {
                logEvent({
                  name: "metrics_dashboard_filter_select",
                  properties: {
                    filterType: "tag",
                    filterValue: tag.id,
                  },
                });
                filterDispatch({
                  type: "SET_TAG_IDS",
                  payload: {
                    ids: [...tagIds, tag.id],
                  },
                });
              }
            }}
            getOptionLabel={(tag) =>
              tag.value ? `${tag.key}:${tag.value}` : tag.key
            }
            placeholder="Filter by tag"
            placeholderIcon={{ type: "name", icon: "tag" }}
            selectOnly
            size="sm"
          />
          {hasFilters && (
            <div className={styles.clearButton}>
              <ButtonV3
                label="Clear filters"
                type="mainSecondary"
                onClick={() => {
                  filterDispatch({
                    type: "CLEAR_FILTERS",
                  });
                }}
                size="xs"
              />
            </div>
          )}
        </div>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
          })}
        >
          <TimeBucketPicker />
          <div className={styles.datePicker}>
            <DateRangePicker
              selectedRange={{
                from: dateRange.from,
                // Always show highlighted date to end of range,
                // even if end is today (which is undefined).
                to: dateRange.to || new Date(),
              }}
              setSelectedRange={(dateRange) => {
                if (dateRange) {
                  logEvent({
                    name: "metrics_dashboard_time_range_select",
                    properties: {
                      start: dateRange.from?.toISOString() ?? "",
                      end: dateRange.to?.toISOString() ?? "",
                    },
                  });
                  filterDispatch({
                    type: "SET_DATE_RANGE",
                    payload: {
                      dateRange,
                    },
                  });
                }
              }}
              disableFutureDates
              size="sm"
              clearable={false}
            />
          </div>
        </div>
      </div>
      <div className={sprinkles({ paddingX: "md", marginBottom: "md" })}>
        <DataElementList>
          {connectionIds.map((connectionID) => {
            const connection = allConnections.find(
              (o) => o.id === connectionID
            );
            if (!connection) {
              return null;
            }
            return (
              <PillV3
                key={connection.id}
                keyText={connection.name}
                pillColor="Cyan"
                icon={{
                  type: "entity",
                  entityType: connection.connectionType,
                }}
                onRemove={() => {
                  filterDispatch({
                    type: "SET_CONNECTION_IDS",
                    payload: {
                      ids: connectionIds.filter((id) => id !== connectionID),
                    },
                  });
                }}
              />
            );
          })}
          {resourceIds.map((resourceID) => {
            const resource = resources.find((o) => o.id === resourceID);
            if (!resource) {
              return null;
            }
            return (
              <PillV3
                key={resource.id}
                keyText={resource.name}
                pillColor="Pink"
                icon={{
                  type: "entity",
                  entityType: resource.resourceType,
                }}
                onRemove={() => {
                  filterDispatch({
                    type: "SET_RESOURCE_IDS",
                    payload: {
                      ids: resourceIds.filter((id) => id !== resourceID),
                    },
                  });
                }}
              />
            );
          })}
          {groupIds.map((groupID) => {
            const group = groups.find((o) => o.id === groupID);
            if (!group) {
              return null;
            }
            return (
              <PillV3
                key={group.id}
                keyText={group.name}
                pillColor="Teal"
                icon={{
                  type: "entity",
                  entityType: group.groupType,
                }}
                onRemove={() => {
                  filterDispatch({
                    type: "SET_GROUP_IDS",
                    payload: {
                      ids: groupIds.filter((id) => id !== groupID),
                    },
                  });
                }}
              />
            );
          })}
          {tagIds.map((tagId) => {
            const tag = allTags.find((o) => o.id === tagId);
            if (!tag) {
              return null;
            }
            return (
              <TagPillV3
                key={tag.key}
                keyText={tag.key}
                valueText={tag.value ?? ""}
                pillColor="LightGreen"
                icon={{
                  type: "name",
                  icon: "tag",
                }}
                onRemove={() => {
                  filterDispatch({
                    type: "SET_TAG_IDS",
                    payload: {
                      ids: tagIds.filter((id) => id !== tagId),
                    },
                  });
                }}
              />
            );
          })}
        </DataElementList>
      </div>
    </>
  );
};

export default MetricsFilterV3;
