import { TimeZone } from "@vvo/tzdb";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewFilters,
  AccessReviewGroupResourceVisibilityPolicy,
  AccessReviewReviewerAssignmentPolicy,
  AccessReviewTemplateFragment,
  CreateAccessReviewTemplateInput,
  Maybe,
  UpdateAccessReviewTemplateInput,
  useAccessReviewTemplateQuery,
  useCreateAccessReviewTemplateMutation,
  useUpdateAccessReviewTemplateMutation,
} from "api/generated/graphql";
import AccessReviewMonthPicker from "components/access_reviews/AccessReviewMonthPicker";
import AssignmentPolicy from "components/access_reviews/AssignmentPolicy";
import ReminderNotifications, {
  DEFAULT_REMINDER_SCHEDULE,
  DEFAULT_REMINDER_TIME_OF_DAY,
} from "components/access_reviews/ReminderNotifications";
import StartAccessReviewStats from "components/access_reviews/scope/AccessReviewStats";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Divider,
  FormGroup,
  Input,
  RadioGroup,
  Select,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getNumberWithOrdinal } from "utils/common";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import {
  getTimeZoneDisplayText,
  getTimeZoneFromString,
  getTimeZoneGuess,
  timeZones,
} from "utils/time";
import FilterMatchModeSwitch from "views/access_reviews/common/FilterMatchModeSwitch";
import { getTemplateReviewExample } from "views/access_reviews/create/CreateAccessReviewScheduleColumn";
import {
  buildAccessReviewFilter,
  defaultAccessReviewFilters,
} from "views/access_reviews/create/utils";
import { NotFoundPage } from "views/error/ErrorCodePage";

import { AccessReviewGroupResourceVisibilityOptions } from "./create/CreateAccessReviewColumn";

interface AccessReviewScheduleConfig {
  name: string;
  timeZone?: TimeZone;
  reminderSchedule: number[];
  reminderTimeOfDay: Date;
  reminderIncludeManager: boolean;
  filters: AccessReviewFilters;
  assignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  sendReviewerAssignmentNotifications: boolean;
  monthSchedule: number[];
  startDayOfMonth: number;
  accessReviewDuration: number | undefined;
  selfReviewAllowed: boolean;
  includeGroupBindings: boolean;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
}

type AccessReviewScheduleOverviewProps = {
  templateId?: string;
  name?: string;
  timeZone?: string;
  reminderSchedule?: number[];
  reminderTimeOfDay?: Date;
  reminderIncludeManager?: boolean;
  filters?: AccessReviewFilters;
  reviewerAssignmentPolicy?: AccessReviewReviewerAssignmentPolicy;
  sendReviewerAssignmentNotifications?: boolean;
  monthSchedule?: number[];
  startDayOfMonth?: number;
  accessReviewDuration?: number;
  selfReviewAllowed?: boolean;
  includeGroupBindings?: boolean;
};

const DURATION_UPPER_BOUND = 30;
const DURATION_LOWER_BOUND = 1;

const AccessReviewScheduleEditorV3Wrapper = () => {
  const { scheduleId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewTemplateQuery({
    variables: {
      input: {
        accessReviewTemplateId: scheduleId,
      },
    },
    skip: !scheduleId || scheduleId === "new",
  });

  let accessReviewTemplate: Maybe<AccessReviewTemplateFragment> = null;
  let isLoading: boolean = false;
  if (scheduleId && scheduleId !== "new") {
    if (data) {
      switch (data.accessReviewTemplate.__typename) {
        case "AccessReviewTemplateResult":
          accessReviewTemplate =
            data.accessReviewTemplate.accessReviewTemplate || null;
          break;
        case "AccessReviewTemplateNotFound":
          logWarning(new Error("error: access review schedule not found"));
          return <NotFoundPage />;
        default:
          logWarning(new Error("error: access review schedule not found"));
      }
    } else if (error) {
      logError(error, `failed to retrieve access review schedule`);
    }
    isLoading = loading;
  }

  if (isLoading) {
    return <FullscreenSkeleton />;
  }

  return (
    <AccessReviewScheduleEditorV3
      templateId={accessReviewTemplate?.id}
      name={accessReviewTemplate?.name}
      reviewerAssignmentPolicy={accessReviewTemplate?.reviewerAssignmentPolicy}
      timeZone={accessReviewTemplate?.timeZone}
      reminderSchedule={accessReviewTemplate?.reminderSchedule}
      reminderTimeOfDay={
        accessReviewTemplate
          ? new Date(accessReviewTemplate.reminderTimeOfDay)
          : undefined
      }
      reminderIncludeManager={accessReviewTemplate?.reminderIncludeManager}
      accessReviewDuration={accessReviewTemplate?.accessReviewDuration}
      startDayOfMonth={accessReviewTemplate?.startDayOfMonth}
      sendReviewerAssignmentNotifications={
        accessReviewTemplate?.sendReviewerAssignmentNotification
      }
      monthSchedule={accessReviewTemplate?.monthSchedule}
      selfReviewAllowed={accessReviewTemplate?.selfReviewAllowed}
      filters={accessReviewTemplate?.filters ?? undefined}
      includeGroupBindings={accessReviewTemplate?.includeGroupBindings}
    />
  );
};

const AccessReviewScheduleEditorV3 = (
  props: AccessReviewScheduleOverviewProps
) => {
  const history = useHistory();
  const transitionBack = useTransitionBack();
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const getResetConfig = useCallback((): AccessReviewScheduleConfig => {
    const timezone =
      getTimeZoneFromString(props.timeZone) || getTimeZoneGuess();
    return {
      name: props.name || "",
      timeZone: timezone,
      reminderSchedule: props.reminderSchedule || DEFAULT_REMINDER_SCHEDULE,
      reminderTimeOfDay:
        props.reminderTimeOfDay || DEFAULT_REMINDER_TIME_OF_DAY,
      reminderIncludeManager: props.reminderIncludeManager || false,
      filters: props.filters ?? defaultAccessReviewFilters(),
      assignmentPolicy:
        props.reviewerAssignmentPolicy ||
        AccessReviewReviewerAssignmentPolicy.Manually,
      sendReviewerAssignmentNotifications:
        props.sendReviewerAssignmentNotifications || false,
      monthSchedule: props.monthSchedule || [],
      startDayOfMonth: props.startDayOfMonth || 1,
      accessReviewDuration: props.accessReviewDuration || 15,
      selfReviewAllowed: props.selfReviewAllowed ?? true,
      includeGroupBindings: props.includeGroupBindings ?? false,
      groupResourceVisibilityPolicy:
        AccessReviewGroupResourceVisibilityPolicy.Strict,
    };
  }, [
    props.accessReviewDuration,
    props.filters,
    props.monthSchedule,
    props.name,
    props.reminderIncludeManager,
    props.reminderSchedule,
    props.reminderTimeOfDay,
    props.reviewerAssignmentPolicy,
    props.selfReviewAllowed,
    props.sendReviewerAssignmentNotifications,
    props.startDayOfMonth,
    props.timeZone,
    props.includeGroupBindings,
  ]);
  const [config, setConfig] = useState<AccessReviewScheduleConfig>(
    getResetConfig()
  );
  const [
    createAccessReviewTemplate,
    { loading: createLoading },
  ] = useCreateAccessReviewTemplateMutation();
  const [
    updateAccessReviewTemplate,
    { loading: updateLoading },
  ] = useUpdateAccessReviewTemplateMutation();
  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const handleCreate = async () => {
    if (
      config.monthSchedule.length > 0 &&
      config.accessReviewDuration &&
      config.timeZone
    ) {
      try {
        const input: CreateAccessReviewTemplateInput = {
          name: config.name,
          accessReviewDuration: config.accessReviewDuration,
          monthSchedule: config.monthSchedule,
          startDayOfMonth: config.startDayOfMonth,
          reminderSchedule: config.reminderSchedule,
          reminderTimeOfDay: config.reminderTimeOfDay.toISOString(),
          reminderIncludeManager: config.reminderIncludeManager,
          timeZone: config.timeZone.name,
          reviewerAssignmentPolicy: config.assignmentPolicy,
          sendReviewerAssignmentNotification:
            config.sendReviewerAssignmentNotifications,
          selfReviewAllowed: config.selfReviewAllowed,
          filters: buildAccessReviewFilter(config.filters),
          includeGroupBindings: config.includeGroupBindings,
          groupResourceVisibilityPolicy: config.groupResourceVisibilityPolicy,
        };

        const { data } = await createAccessReviewTemplate({
          variables: { input },
          refetchQueries: ["AccessReviewTemplates"],
        });
        switch (data?.saveAccessReviewTemplate.__typename) {
          case "CreateAccessReviewTemplateResult":
            displaySuccessToast(`Success: access review schedule created`);
            history.replace("/access-reviews?category=schedules");
            break;
          default:
            logError(new Error(`failed to create access review schedule`));
            setErrorMessage(`Error: failed to create access review schedule`);
        }
      } catch (error) {
        logError(error, "failed to create access review schedule");
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to create access review schedule`,
            error
          )
        );
      }
    }
  };

  const handleUpdate = async () => {
    if (
      config.monthSchedule.length > 0 &&
      config.accessReviewDuration &&
      props.templateId &&
      config.timeZone
    ) {
      try {
        const input: UpdateAccessReviewTemplateInput = {
          id: props.templateId,
          name: config.name,
          accessReviewDuration: config.accessReviewDuration,
          monthSchedule: config.monthSchedule,
          startDayOfMonth: config.startDayOfMonth,
          reminderSchedule: config.reminderSchedule,
          reminderTimeOfDay: config.reminderTimeOfDay.toISOString(),
          reminderIncludeManager: config.reminderIncludeManager,
          timeZone: config.timeZone.name,
          reviewerAssignmentPolicy: config.assignmentPolicy,
          sendReviewerAssignmentNotification:
            config.sendReviewerAssignmentNotifications,
          selfReviewAllowed: config.selfReviewAllowed,
          filters: buildAccessReviewFilter(config.filters),
          includeGroupBindings: config.includeGroupBindings,
        };

        const { data } = await updateAccessReviewTemplate({
          variables: { input },
          refetchQueries: ["AccessReviewTemplates"],
        });
        switch (data?.updateAccessReviewTemplate.__typename) {
          case "UpdateAccessReviewTemplateResult":
            displaySuccessToast(`Success: access review schedule updated`);
            history.replace(
              "/access-reviews/t/" + props.templateId + "?category=schedules"
            );
            break;
          default:
            logError(new Error(`failed to update access review schedule`));
            setErrorMessage(`Error: failed to update access review schedule`);
        }
      } catch (error) {
        logError(error, "failed to create access review schedule");
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to create access review schedule`,
            error
          )
        );
      }
    }
  };

  return (
    <FullscreenView
      title={props.templateId ? <>Edit Schedule</> : <>Create Schedule</>}
      onCancel={() => {
        !createLoading &&
          !updateLoading &&
          transitionBack(
            props.templateId
              ? `/access-reviews/t/${props.templateId}`
              : "/access-reviews?category=schedules"
          );
      }}
      onPrimaryButtonClick={props.templateId ? handleUpdate : handleCreate}
      primaryButtonLoading={props.templateId ? updateLoading : createLoading}
      primaryButtonDisabled={
        config.monthSchedule.length === 0 ||
        !config.name ||
        !config.accessReviewDuration
      }
      primaryAdjacentTip={
        <StartAccessReviewStats styleTextOnly filters={config.filters} />
      }
    >
      <FullscreenView.Sidebar>
        <FormGroup label="Name" required>
          <div>
            Specifies the name of the access review as shown in the UI. It can
            contain the following placeholders:
            <ul>
              <li>{`{quarter} which will display as Q1, Q2, etc.`}</li>
              <li>{`{month} which will display as Jan, Feb, etc.`}</li>
              <li>{`{year} which will display as the year.`}</li>
            </ul>
          </div>
          <Input
            value={config.name}
            onChange={(newName) => setConfig({ ...config, name: newName })}
            placeholder="e.g. {quarter} access review"
          />
          {_.trim(config.name).length > 0 ? (
            <div className={sprinkles({ marginTop: "sm" })}>
              Example: {getTemplateReviewExample(config.name)}
            </div>
          ) : null}
        </FormGroup>
        <AssignmentPolicy
          assignmentPolicy={config.assignmentPolicy}
          onChange={(newValue) =>
            setConfig({ ...config, assignmentPolicy: newValue })
          }
        />
        <FormGroup
          label={`Self-review: ${
            config.selfReviewAllowed ? "Allowed" : "Not Allowed"
          }`}
        >
          <Switch
            label="Allow self-reviews. Self reviewed items will be noted in the review results report."
            checked={config.selfReviewAllowed}
            onChange={(checked) =>
              setConfig({
                ...config,
                selfReviewAllowed: checked,
              })
            }
            rightAlign
          />
        </FormGroup>
        <FormGroup label="Day of the month to start access review" required>
          <Select
            options={[...Array(28)].map((_, i) => ({ startDay: i + 1 }))}
            onChange={(value) => {
              if (value) {
                setConfig({ ...config, startDayOfMonth: value.startDay });
              }
            }}
            value={{ startDay: config.startDayOfMonth }}
            getOptionLabel={(value) => getNumberWithOrdinal(value.startDay)}
          />
        </FormGroup>
        <FormGroup label="Duration of access review" required>
          <Input
            type="number"
            rightText="days"
            value={config.accessReviewDuration}
            min={DURATION_LOWER_BOUND}
            max={DURATION_UPPER_BOUND}
            onChange={(newValue) =>
              setConfig({ ...config, accessReviewDuration: newValue })
            }
          />
        </FormGroup>
        <FormGroup label="Time zone" required>
          <Select
            value={config.timeZone}
            options={timeZones}
            getOptionLabel={getTimeZoneDisplayText}
            onChange={(newTimeZone) =>
              setConfig({ ...config, timeZone: newTimeZone })
            }
          />
        </FormGroup>
        <ReminderNotifications
          reminderSchedule={config.reminderSchedule}
          reminderTimeOfDay={config.reminderTimeOfDay}
          reminderIncludeManager={config.reminderIncludeManager}
          timezone={config.timeZone}
          onChange={(
            thisReminderSchedule,
            thisReminderTimeOfDay,
            thisReminderIncludeManager
          ) => {
            setConfig({
              ...config,
              reminderSchedule: thisReminderSchedule,
              reminderTimeOfDay: thisReminderTimeOfDay,
              reminderIncludeManager: thisReminderIncludeManager,
            });
          }}
        />
        <FormGroup
          label={`New review notifications: ${
            config.sendReviewerAssignmentNotifications ? "On" : "Off"
          }`}
        >
          <Switch
            label="Send a notification to reviewers when they're assigned a new review."
            checked={config.sendReviewerAssignmentNotifications}
            onChange={(checked) =>
              setConfig({
                ...config,
                sendReviewerAssignmentNotifications: checked,
              })
            }
            rightAlign
          />
        </FormGroup>
      </FullscreenView.Sidebar>
      <FullscreenView.Content>
        {errorMessage ? (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Banner type="error" message={errorMessage} />
          </div>
        ) : null}
        <p>Define Scope & Visibility</p>
        <FormGroup label="Group Resource Visibility">
          <RadioGroup
            value={AccessReviewGroupResourceVisibilityOptions.find(
              (opt) => opt.value === config.groupResourceVisibilityPolicy
            )}
            options={AccessReviewGroupResourceVisibilityOptions}
            getOptionKey={(opt) => opt.value}
            getOptionLabel={(opt) => opt.label}
            getOptionDescription={(opt) => opt.description}
            onSelectValue={(opt) => {
              setConfig((prevConfig) => ({
                ...prevConfig,
                groupResourceVisibilityPolicy: opt.value,
              }));
            }}
          />
        </FormGroup>
        <AccessReviewMonthPicker
          selectedMonths={config.monthSchedule}
          onChange={(newValue) =>
            setConfig({ ...config, monthSchedule: newValue })
          }
        />
        <div>
          <FormGroup label="Users">
            {config.filters.userIDs.length === 0 && "All users"}
            <UsersFilter
              userIDs={config.filters.userIDs}
              onChangeUserIDs={(newUserIDs) => {
                setConfig((prevConfig) => ({
                  ...prevConfig,
                  filters: {
                    ...prevConfig.filters,
                    userIDs: newUserIDs,
                  },
                }));
              }}
            />
          </FormGroup>
        </div>
        <Divider />
        <FilterMatchModeSwitch
          matchMode={config.filters.matchMode}
          onChange={(matchMode) => {
            setConfig((prevConfig) => ({
              ...prevConfig,
              filters: {
                ...prevConfig.filters,
                matchMode,
              },
            }));
          }}
        />
        <EntitiesIndividualFilter
          entityIds={config.filters.entityIDs}
          onChangeEntityIds={(newEntityIds) => {
            setConfig((prevConfig) => ({
              ...prevConfig,
              filters: {
                ...prevConfig.filters,
                entityIDs: newEntityIds,
              },
            }));
          }}
        />
        <ConnectionsFilter
          connectionIDs={config.filters.connectionIDs}
          onChangeConnectionIDs={(newConnectionIDs) => {
            setConfig((prevConfig) => ({
              ...prevConfig,
              filters: {
                ...prevConfig.filters,
                connectionIDs: newConnectionIDs,
              },
            }));
          }}
        />
        <EntitiesWithAdminFilter
          adminIDs={config.filters.adminIDs}
          onChangeAdminIDs={(newAdminIDs) => {
            setConfig({
              ...config,
              filters: {
                ...config.filters,
                adminIDs: newAdminIDs,
              },
            });
          }}
        />
        <EntityTypesFilter
          resourceTypes={config.filters.resourceTypes}
          groupTypes={config.filters.groupTypes}
          onChangeResourceTypes={(newTypes) => {
            setConfig((prevConfig) => ({
              ...prevConfig,
              filters: {
                ...prevConfig.filters,
                resourceTypes: newTypes,
              },
            }));
          }}
          onChangeGroupTypes={(newTypes) => {
            setConfig((prevConfig) => ({
              ...prevConfig,
              filters: {
                ...prevConfig.filters,
                groupTypes: newTypes,
              },
            }));
          }}
        />
        {hasGroupBindings && (
          <FormGroup
            label={`Include linked groups: ${
              config.includeGroupBindings ? "Yes" : "No"
            }`}
          >
            <Switch
              label="Include linked groups in the access review. Otherwise, only the groups strictly matching the filters will be included."
              checked={config.includeGroupBindings}
              onChange={(checked) =>
                setConfig({
                  ...config,
                  includeGroupBindings: checked,
                })
              }
            />
          </FormGroup>
        )}
        <TagFilter
          tags={config.filters.tags}
          onChangeTags={(newTags) => {
            setConfig({
              ...config,
              filters: {
                ...config.filters,
                tags: newTags,
              },
            });
          }}
        />
        <EntitiesNamedFilter
          names={config.filters.names}
          onChangeNames={(newNames) => {
            setConfig({
              ...config,
              filters: {
                ...config.filters,
                names: newNames,
              },
            });
          }}
        />
      </FullscreenView.Content>
    </FullscreenView>
  );
};

export default AccessReviewScheduleEditorV3Wrapper;
