import * as React from "react";

import { Icon } from "..";
import * as styles from "./Checkbox.css";

interface Props {
  label?: string | React.ReactNode;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  onChangeShift?: (checked: boolean) => void;
  disabled?: boolean;
  size?: "sm" | "md" | "lg";
  error?: boolean;
}

const Checkbox: React.FC<Props> = (props) => {
  const { size = "md" } = props;
  return (
    <div
      className={styles.container({ disabled: props.disabled })}
      onClick={(e) => {
        e.stopPropagation();

        if (e.shiftKey && props.onChangeShift) {
          props.onChangeShift &&
            !props.disabled &&
            props.onChangeShift(!props.checked);
          return;
        }

        props.onChange && !props.disabled && props.onChange(!props.checked);
      }}
    >
      <button
        className={styles.checkbox({
          size,
          selected: props.checked,
          disabledChecked: props.disabled && props.checked,
          error: props.error,
        })}
      >
        <Icon name="check" />
      </button>
      {props.label ? (
        <span className={styles.label({ disabled: props.disabled })}>
          {props.label}
        </span>
      ) : null}
    </div>
  );
};

export default Checkbox;
