import { NetworkStatus } from "@apollo/client";
import {
  AccessOption,
  AppCategory,
  AppDataFragment,
  AppsSortByField,
  SortDirection,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useAppsListColumnQuery,
  useSyncStatusQuery,
  Visibility,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import SyncStatusModal from "components/label/SyncStatusModal";
import useSyncActionIcon from "components/sync/useSyncActionIcon";
import useSyncMenuItem, { getSyncLabel } from "components/sync/useSyncMenuItem";
import { useToast } from "components/toast/Toast";
import {
  ButtonV3,
  Divider,
  Input,
  Label,
  Loader,
  Select,
  Skeleton,
  TabsV3,
} from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { hasBasicPermissions } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useDebouncedValue } from "utils/hooks";
import { usePageTitle } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo, useURLSearchParamAsEnum } from "utils/router/hooks";
import { getAppIcon, useAccessOptionKey } from "views/apps/utils";
import {
  CATEGORY_BY_CONNECTION_TYPE,
  categoryInfoByCategory,
} from "views/connections/create/BrowseServices";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import { ACCESS_OPTION_URL_KEY, ITEM_TYPE_URL_KEY } from "./AppsContext";
import * as styles from "./AppsListColumn.css";
import Suggestions from "./Suggestions";

const SORT_OPTIONS = [
  {
    label: "Name (A-Z)",
    value: {
      field: "name",
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: "name",
      direction: SortDirection.Desc,
    },
  },
];

interface AccessOptionInfo {
  label: string;
  sublabel: string;
  value: AccessOption;
}

const accessOptions: AccessOptionInfo[] = [
  {
    label: "Apps",
    sublabel: "Apps, groups and resources in Opal",
    value: AccessOption.All,
  },
  {
    label: "My Access",
    sublabel: "Resources I have access to",
    value: AccessOption.Mine,
  },
];

const NAME_COL_ID = AppsSortByField.Name;
const VISIBLITY_COL_ID = AppsSortByField.Visiblity;
const RESOURCE_COUNT_COL_ID = AppsSortByField.ResourceCount;
const SOURCE_COL_ID = AppsSortByField.Source;

interface AppRow {
  id: string;
  [NAME_COL_ID]: string;
  linkTo?: string;
  appData: AppDataFragment;
  [VISIBLITY_COL_ID]: Visibility;
  [RESOURCE_COUNT_COL_ID]: number;
  groupCount: number | undefined;
  userCount: number | undefined;
  [SOURCE_COL_ID]: string;
}

function isSortableField(str: string): str is AppsSortByField {
  return Object.values<string>(AppsSortByField).includes(str);
}

type SortValue = {
  field: AppsSortByField;
  direction: SortDirection;
};

const APP_COLUMNS: Header<AppRow>[] = [
  {
    id: NAME_COL_ID,
    label: "Name",
    width: 400,
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div className={sprinkles({ fontWeight: "medium" })}>
          <Label
            label={row[NAME_COL_ID]}
            icon={getAppIcon(row.appData)}
            iconSize="md"
          />
        </div>
      );
    },
  },
  {
    id: VISIBLITY_COL_ID,
    label: "Visibility",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div>
          {row[VISIBLITY_COL_ID] === Visibility.Global ? "Global" : "Limited"}
        </div>
      );
    },
  },
  {
    id: RESOURCE_COUNT_COL_ID,
    label: "Resources",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div>{`${pluralize(
          "Resource",
          row[RESOURCE_COUNT_COL_ID],
          true
        )}`}</div>
      );
    },
  },
  {
    id: "groupCount",
    label: "Group Access",
    sortable: false, // groupCount has its own resolver and so can't be sorted server-side
    customCellRenderer: (row) => {
      if (row.groupCount == null)
        return <Skeleton variant="text" width="50px" />;
      return <div>{`${pluralize("Group", row.groupCount, true)}`}</div>;
    },
  },
  {
    id: "userCount",
    label: "User Access",
    sortable: false, // userCount has its own resolver and so can't be sorted server-side
    customCellRenderer: (row) => {
      if (row.userCount == null)
        return <Skeleton variant="text" width="50px" />;
      return <div>{`${pluralize("User", row.userCount, true)}`}</div>;
    },
  },
  {
    id: SOURCE_COL_ID,
    label: "Source",
    sortable: true,
    customCellRenderer: (row) => {
      return <div>{row[SOURCE_COL_ID]}</div>;
    },
  },
];

// HACK!!! useAppsListQuery is a custom hook that returns the result of the query with
// all the items and handles lazy loading the access stats at the same time and
// keeps everything in sync.
function useAppsListQuery(...args: Parameters<typeof useAppsListColumnQuery>) {
  const argsWithoutCounts = args.map((arg) => {
    const argCopy = _.cloneDeep(arg);
    if (argCopy.variables) {
      argCopy.variables.fetchCounts = false;
    }
    return argCopy;
  }) as Parameters<typeof useAppsListColumnQuery>;

  const {
    data: appsListData,
    previousData: previousAppListData,
    loading: appsListLoading,
    error: appsListError,
    fetchMore: fetchMoreApps,
    ...rest
  } = useAppsListColumnQuery(...argsWithoutCounts);

  const {
    data: appsListCountsData,
    previousData: previousAppListCountsData,
    networkStatus: appsListCountsNetworkStatus,
  } = useAppsListColumnQuery({
    ...args[0],
    skip: !args[0].variables?.fetchCounts,
  });

  return {
    data: appsListCountsData || appsListData,
    previousData: previousAppListCountsData || previousAppListData,
    loading: appsListLoading,
    error: appsListError,
    fetchMore: fetchMoreApps,
    ...rest,
    networkStatus: appsListCountsNetworkStatus,
  };
}

// Admin UX app catalog
// TODO: this component handle both v2 and v3 layouts, which makes things even
// more complicated for no absolute reason.
// Remove v2 as soon as possible once it's rolled out.
const AppsListColumn = () => {
  const logEvent = useLogEvent();
  const [showSyncModal, setShowSyncModal] = useState(false);

  const [category, setCategory] = useURLSearchParamAsEnum(
    "category",
    AppCategory,
    AppCategory.All
  );

  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: AppsSortByField.Name,
    direction: SortDirection.Asc,
  });
  const history = useHistory();
  const transitionTo = useTransitionTo({
    preserveQueries: [ACCESS_OPTION_URL_KEY, ITEM_TYPE_URL_KEY],
  });
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  const hasBundles = useFeatureFlag(FeatureFlag.Bundles);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const syncMenuItem = useSyncMenuItem({
    syncType: SyncType.PullConnectionsAll,
    queriesToRefetch: ["AppsListColumn"],
  });
  const syncActionIcon = useSyncActionIcon({
    syncType: SyncType.PullConnectionsAll,
    queriesToRefetch: ["AppsListColumn"],
  });

  const { authState } = useContext(AuthContext);
  const [accessOptionKey, setAccessOptionKey] = useAccessOptionKey();

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200); // Loaded client-side so use a fast debounce
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);

  const canManage = authState.user?.isAdmin ?? false;

  const {
    data: appsListData,
    previousData: previousAppListData,
    loading: appsListLoading,
    error: appsListError,
    fetchMore: fetchMoreApps,
    networkStatus: appsListNetworkStatus,
  } = useAppsListQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      access: accessOptionKey,
      appCategory: category,
      searchQuery: hasV3 ? debouncedSearchQuery : undefined,
      fetchCounts: hasV3,
      sortBy: hasV3 ? sortBy : undefined,
      // Since we don't paginate this page, just put a very high limit..
      limit: hasV3 ? undefined : 100000,
    },
  });

  // fetchMore status is when we're fetching more items
  // loading is only used on first load
  // setVariables is used when changing the search query, category or access
  // If we're fetching more items, use the previous data until the new one comes in
  const appsFetchingMore = appsListNetworkStatus === NetworkStatus.fetchMore;
  const appsList = appsFetchingMore
    ? appsListData || previousAppListData
    : appsListData;

  usePageTitle("Apps");

  const { data, error, loading } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsAll,
      },
    },
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (data) {
    switch (data.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = data.syncStatus.lastSuccessfulSyncTask
          ? data.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = data.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
        logError(data.syncStatus.message);
        break;
    }
  }

  let syncStatus: string;
  if (error) {
    syncStatus = "Unable to get status";
  } else if (loading) {
    syncStatus = "Loading sync status";
  } else {
    syncStatus = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const handleChangeAccessMode = (option: AccessOption) => {
    logEvent({
      name: "apps_access_mode_change",
      properties: {
        access_mode: option,
      },
    });
    setAccessOptionKey(option);
  };

  const getTitleProps = (): Partial<PropsFor<typeof ColumnHeader>> => {
    if (accessOptionKey === AccessOption.Unmanaged) {
      return {
        title: "Import items",
      };
    }

    return {
      renderTitle: () => (
        <Select
          options={accessOptions}
          value={accessOptions.find((o) => o.value === accessOptionKey)}
          onChange={(value) => {
            if (value) handleChangeAccessMode(value?.value);
          }}
          getOptionLabel={(option) => option.label}
          getOptionSublabel={(option) => option.sublabel}
          searchable={false}
          style="borderless"
          disabled={appsListLoading && !appsList}
        />
      ),
    };
  };

  if (appsListLoading && !appsList && !hasV3) {
    return (
      <Column>
        <ColumnHeader
          {...getTitleProps()}
          icon={{ icon: "dots-grid", type: "name" }}
        />
        <Divider margin="md" />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (appsListError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={appsListError} />
      </Column>
    );
  }

  const appsToDisplay = appsList?.apps.apps || [];
  const cursor = appsList?.apps.cursor;

  const loadMoreRows = cursor
    ? async () => {
        await fetchMoreApps({
          variables: {
            cursor,
            access: accessOptionKey,
            appCategory: category,
            searchQuery: hasV3 ? debouncedSearchQuery : undefined,
            fetchCounts: hasV3,
            sortBy: sortBy,
          },
        });
      }
    : undefined;

  // V2: Search and sort are handled on the front-end for now, since the query is not paginated
  const filteredAppsToDisplay = hasV3
    ? appsToDisplay
    : appsToDisplay.filter((app) => {
        if (
          app.app.__typename === "ConnectionApp" &&
          category != AppCategory.All &&
          CATEGORY_BY_CONNECTION_TYPE[app.app.connectionType] != category
        ) {
          return false;
        }
        if (
          app.app.__typename === "OktaResourceApp" &&
          category != AppCategory.All &&
          category != AppCategory.OktaApp
        ) {
          return false;
        }
        if (debouncedSearchQuery === "") return true;
        return app.name
          .toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase());
      });

  const rows: AppRow[] = filteredAppsToDisplay.map((app) => {
    let id: string = "";
    let source: string = "";
    let linkTo: string | undefined;
    switch (app.app.__typename) {
      case "ConnectionApp":
        id = app.app.connectionId;
        source = "Native";
        linkTo = `/apps/${app.app.connectionId}`;
        break;
      case "OktaResourceApp":
        id = app.app.resourceId;
        source = "Okta";
        linkTo = `/resources/${app.app.resourceId}`;
        break;
    }

    return {
      id,
      [NAME_COL_ID]: app.name,
      linkTo,
      appData: app.app,
      [VISIBLITY_COL_ID]: app.visibility,
      [RESOURCE_COUNT_COL_ID]: app.resourceCount,
      groupCount: app.groupAccessCount ?? undefined,
      userCount: app.userAccessCount ?? undefined,
      [SOURCE_COL_ID]: source,
    };
  });

  const sortedAppsToDisplay = [...filteredAppsToDisplay].sort((a, b) => {
    if (sortOption.value.direction == SortDirection.Asc) {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });

  const handleExportUserClick = () => {
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "all",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/items/users`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_Bulk_Export_All_Apps_" + _.uniqueId() + "_Users.csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded users for all apps`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const renderRow = (index: number) => {
    const app = sortedAppsToDisplay[index];
    if (!app) return <></>;

    return (
      <ColumnListItem
        label={app.name}
        icon={getAppIcon(app.app)}
        largeIcon
        onClick={() => {
          if (app.app.__typename === "ConnectionApp") {
            transitionTo({
              pathname: `/apps/${app.app.connectionId}`,
            });
          } else if (app.app.__typename === "OktaResourceApp") {
            transitionTo({
              pathname: `/apps/okta/${app.app.resourceId}`,
            });
          }
        }}
      />
    );
  };

  let sublabel: string;
  if (error) {
    sublabel = "Unable to get status";
  } else if (loading) {
    sublabel = "Loading sync status";
  } else {
    sublabel = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const hasSyncErrors = canManage && syncErrors.length > 0;
  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (canManage) {
    if (syncMenuItem) {
      menuOptions.push({
        label: hasSyncErrors ? "View sync errors" : "View sync details",
        sublabel,
        onClick: () => {
          logEvent({
            name: "apps_view_sync_details",
            properties: {
              syncType: SyncType.PullConnectionsAll,
            },
          });
          setShowSyncModal(true);
        },
        icon: hasSyncErrors
          ? { type: "name", icon: "alert-circle" }
          : { type: "name", icon: "info" },
        type: hasSyncErrors ? "warning" : undefined,
        disabled: loading,
      });
      menuOptions.push(syncMenuItem);
    }
    menuOptions.push({
      type: "divider",
    });
    menuOptions.push({
      label: "Export all users",
      icon: { type: "name", icon: "users-right" },
      onClick: handleExportUserClick,
    });

    addMenuOptions.push({
      label: "Add integrations",
      icon: { type: "name", icon: "plus" },
      onClick: () => history.push("/apps/browse"),
    });
    if (accessOptionKey !== AccessOption.Unmanaged) {
      addMenuOptions.push({
        label: "Import items",
        icon: { type: "name", icon: "plus" },
        onClick: () => handleChangeAccessMode(AccessOption.Unmanaged),
      });
    }
  }

  const actionIcons: PropsFor<typeof ColumnHeaderV3>["actionIcons"] = [];
  if (!hasBasicPermissions(authState.user)) {
    if (canManage && syncActionIcon) {
      actionIcons.push(syncActionIcon);
    }
    actionIcons.push({
      label: "View Sync Details",
      sublabel: syncStatus,
      onClick: () => {
        logEvent({
          name: "apps_view_sync_details",
          properties: {
            syncType: SyncType.PullConnectionsAll,
          },
        });
        setShowSyncModal(true);
      },
      adminOnly: false,
    });
  }

  return (
    <>
      {showSyncModal ? (
        <SyncStatusModal
          syncType={SyncType.PullConnectionsAll}
          entity={null}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      ) : null}

      {hasV3 ? (
        <Column isContent maxWidth="none">
          <ColumnHeaderV3
            title="Catalog"
            icon={{ type: "name", icon: "apps" }}
            includeDefaultActions
            actionIcons={actionIcons}
          />
          <TabsV3
            tabInfos={[
              {
                title: "Apps",
                isSelected: history.location.pathname.endsWith("/apps"), // TODO
                onClick: () => history.push("/apps"),
              },
              {
                title: "Bundles",
                isSelected: history.location.pathname.endsWith("/bundles"), // TODO
                onClick: () => history.push("/bundles"),
              },
            ]}
          />
          <div className={styles.tableControls}>
            <div className={styles.searchInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Filter Apps by name"
              />
            </div>
            <ButtonGroup
              buttons={[
                {
                  label: "All",
                  onClick: () => handleChangeAccessMode(AccessOption.All),
                  selected: accessOptionKey === AccessOption.All,
                },
                {
                  label: "My Access",
                  onClick: () => handleChangeAccessMode(AccessOption.Mine),
                  selected: accessOptionKey === AccessOption.Mine,
                },
              ]}
            />
            <Select
              value={categoryInfoByCategory[category]}
              options={Object.values(categoryInfoByCategory).filter(
                (info) => info.category != AppCategory.All
              )}
              getOptionLabel={(info) => info.name.slice(0, 17)}
              getIcon={(info) => info.icon || undefined}
              getBrandIcon={(info) => info.brandIcon}
              onChange={(info) =>
                info
                  ? setCategory(info?.category)
                  : setCategory(AppCategory.All)
              }
              placeholder="App Category"
              size="sm"
              clearable={category != AppCategory.All}
            />
            {appsFetchingMore && <Loader size="md" />}
          </div>

          <div
            className={sprinkles({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "md",
            })}
          >
            <span
              className={sprinkles({
                fontSize: "textLg",
                fontWeight: "medium",
              })}
            >
              {appsListLoading && !appsList ? (
                <Skeleton variant="text" width="100px" />
              ) : (
                `${pluralize("App", appsList?.apps.totalNumApps, true)}`
              )}
            </span>
            {canManage && (
              <div
                className={sprinkles({
                  display: "flex",
                  gap: "sm",
                })}
              >
                <ButtonV3
                  label="App"
                  type="main"
                  leftIconName="plus"
                  onClick={() => history.push("/apps/add-app")}
                  size="sm"
                />
              </div>
            )}
          </div>

          {appsListLoading && !appsList ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              rows={rows}
              totalNumRows={cursor ? Number.MAX_SAFE_INTEGER : rows.length}
              emptyState={{
                title: "No apps",
              }}
              getRowId={(ru) => ru.id}
              columns={APP_COLUMNS}
              onRowClick={(row, event) => {
                if (row.linkTo) {
                  transitionTo(
                    {
                      pathname: row.linkTo,
                    },
                    event
                  );
                }
              }}
              loadingRows={appsFetchingMore}
              onLoadMoreRows={loadMoreRows}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;

                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </Column>
      ) : (
        <>
          <Column>
            <ColumnHeader
              {...getTitleProps()}
              icon={{ icon: "dots-grid", type: "name" }}
              menuError={hasSyncErrors}
              menuOptions={menuOptions}
              addMenuOptions={addMenuOptions}
            />
            <Divider margin="md" />
            {hasBundles && accessOptionKey !== AccessOption.Unmanaged && (
              <>
                <ColumnListItem
                  label="Bundles"
                  icon={{ type: "name", icon: "package" }}
                  largeIcon
                  onClick={() => history.push("/bundles")}
                />
                <Divider />
              </>
            )}

            <ColumnSearchAndSort
              placeholder="Search this column"
              sortOptions={SORT_OPTIONS}
              sortBy={sortOption}
              setSortBy={setSortOption}
              setSearchQuery={setSearchQuery}
              trackName="apps"
            />
            <ColumnListScroller
              numRows={filteredAppsToDisplay.length}
              renderRow={renderRow}
            />
          </Column>
          <Suggestions />
        </>
      )}
    </>
  );
};

export default AppsListColumn;
