import React, { useRef, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./Column.css";

export const ColumnContainer: React.FC<{}> = (props) => {
  return <div className={styles.container}>{props.children}</div>;
};

interface ColumnProps {
  isContent?: boolean;
  // Defaults to xl
  maxWidth?: "md" | "mdlg" | "lg" | "xl" | "none";
}

export const Column: React.FC<ColumnProps> = (props) => {
  const { maxWidth } = props;

  const getMaxWidth = () => {
    if (maxWidth) return maxWidth;
    if (props.isContent) return "xl";
    return "none";
  };

  const width = useRef(styles.MIN_WIDTH);
  const drag = useRef({ active: false, x: 0 });
  const [widthState, setWidthState] = useState(styles.MIN_WIDTH);
  const [dragActiveState, setDragActiveState] = useState(false);
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const startResize = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    drag.current = { active: true, x: e.clientX };
    setDragActiveState(true);
    window.addEventListener("mousemove", resizeFrame);
    window.addEventListener("mouseup", stopResize);
    window.addEventListener("selectstart", cancelSelection);
  };

  const stopResize = () => {
    // Clamp newly-set size to min/max width
    var newW = Math.max(styles.MIN_WIDTH, width.current);
    const maxWidth = getMaxWidth();
    if (maxWidth !== "none") {
      newW = Math.min(styles.MAX_WIDTHS[maxWidth], width.current);
    }
    width.current = newW;
    setWidthState(newW);

    drag.current = { active: false, x: drag.current.x };
    setDragActiveState(false);
    window.removeEventListener("mousemove", resizeFrame);
    window.removeEventListener("mouseup", stopResize);
    window.removeEventListener("selectstart", cancelSelection);
  };

  const cancelSelection = (e: Event) => {
    e.preventDefault();
  };

  const resizeFrame = (e: MouseEvent) => {
    e.preventDefault();
    const { active, x } = drag.current;
    if (active && x) {
      const xDiff = x - e.clientX;
      const newW = width.current - xDiff;
      drag.current = { active: drag.current.active, x: e.clientX };
      width.current = newW;
      setWidthState(newW);
    }
  };

  const showResizeHandle = !props.isContent;

  return showResizeHandle ? (
    <div className={styles.wrapper}>
      <div
        className={styles.resizeHandle({ isActive: dragActiveState })}
        onMouseDown={startResize}
      />
      <section
        style={{ width: widthState + "px" }}
        className={styles.column({
          isContent: props.isContent || undefined,
          maxWidth: getMaxWidth(),
          V3: hasV3Nav,
        })}
      >
        {props.children}
      </section>
    </div>
  ) : (
    <section
      className={styles.column({
        isContent: props.isContent ?? false,
        maxWidth: getMaxWidth(),
        V3: hasV3Nav,
      })}
    >
      {props.children}
    </section>
  );
};
