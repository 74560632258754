import {
  AuthType,
  AwsIdentityCenterImportSetting,
  ConnectionCredentialsInput,
  ConnectionMetadataInput,
  ConnectionPreviewLargeFragment,
  ConnectionPreviewSmallFragment,
  ConnectionType,
  Maybe,
  useUpdateConnectionMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useToast } from "components/toast/Toast";
import { Banner, Button, ButtonV3, FormRow, FormSection } from "components/ui";
import { FileAccept } from "components/ui/file_upload/FileUpload";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import AppHeader from "views/apps/AppHeader";
import ConnectionAppIconRow from "views/connections/viewer/rows/ConnectionAppIconRow";

import ConnectionCredsSection from "./ConnectionCredsSection";
import * as styles from "./ConnectionMetadataAndCredsSetupV3.css";
import ConnectionMetadataRow from "./ConnectionMetadataRow";
import ConnectionOAuthTokenRow from "./ConnectionOAuthTokenRow";

export enum InputType {
  TEXT,
  TEXTAREA,
  FILE,
  BOOLEAN,
  GENERATE_RANDOM_STRING,
}

type ConnectionMetadataAndCredsRowProps = {
  connection: ConnectionPreviewLargeFragment;
};

export type InputInfo = {
  inputKey: string;
  inputTitle: string;
  inputType: InputType;
  valueConversion?: conversion;
  fileAccept?: FileAccept[];
};

interface conversion {
  write: (a: string) => string;
  read: (a: string) => string;
}

export const useMetadataAndCredsUpdater = (
  objKeyInputInfo: Record<string, InputInfo>
) => {
  const updateObjVal = <T extends object>(
    obj: T,
    newKey: string,
    newVal: string | boolean
  ): T => {
    const updatedMetadata = obj;

    if (obj) {
      Object.keys(obj).forEach((key) => {
        const val = Reflect.get(obj, key);
        const inputInfo = objKeyInputInfo[key];

        if (val instanceof Object) {
          const updatedObj = updateObjVal(val, newKey, newVal);
          Reflect.set(obj, key, updatedObj);
        } else if (key === newKey) {
          // update this val
          if (inputInfo?.valueConversion && typeof newVal === "string") {
            Reflect.set(
              obj,
              key,
              inputInfo.valueConversion.write.call(null, newVal)
            );
          } else {
            Reflect.set(obj, key, newVal as typeof val);
          }
        }
      });
    }

    return updatedMetadata;
  };

  return updateObjVal;
};

export const ConnectionMetadataAndCredsSetup = (
  props: ConnectionMetadataAndCredsRowProps
) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const canUseAzureEDS = useFeatureFlag(FeatureFlag.AzureEDS);
  const canUseGcpEDS = useFeatureFlag(FeatureFlag.GcpEDS);
  const { authState } = useContext(AuthContext);
  const { displaySuccessToast } = useToast();
  const [metadataKeyVals, setMetadataKeyVals] = useState<
    Record<string, string>
  >(getObjKeyValues(props?.connection?.metadata));
  const {
    initialMetadata,
    initialCreds,
    objKeyInputInfo,
  } = getInitialMetadataInfo(props.connection);
  const hasMetadataOrCreds = !!initialMetadata || !!initialCreds;
  const [metadata, setMetadata] = useState<Maybe<ConnectionMetadataInput>>(
    initialMetadata
  );
  const [credsVal, setCredsVal] = useState<Maybe<ConnectionCredentialsInput>>(
    initialCreds
  );
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  // set disabled to true if some but not all of the credsVal fields are empty
  const disabled =
    !!credsVal &&
    _.some(getObjKeyValues(credsVal), (objVal) => objVal === "") &&
    !_.every(getObjKeyValues(credsVal), (objVal) => objVal === "");

  // update metadataKeyVals when metadata changes
  useEffect(() => {
    setMetadataKeyVals(getObjKeyValues(metadata));
  }, [metadata]);
  const [mode, setMode] = useState<"view" | "edit">("view");

  const [updateConnectionMutation, { loading }] = useUpdateConnectionMutation();

  const editModeReset = () => {
    setMetadataKeyVals(getObjKeyValues(props?.connection?.metadata));
    setMetadata(initialMetadata);
    setCredsVal(initialCreds);
    setErrorMessage(null);
    setMode("view");
  };

  const credentials = _.every(
    getObjKeyValues(credsVal),
    (objVal) => objVal === ""
  )
    ? {}
    : { credentials: credsVal };

  const handleSubmit = async () => {
    try {
      const { data } = await updateConnectionMutation({
        variables: {
          input: {
            id: props.connection.id,
            metadata,
            ...credentials,
          },
        },
      });
      if (data) {
        switch (data.updateConnection.__typename) {
          case "UpdateConnectionResult":
            displaySuccessToast(
              "Success: app metadata and credentials updated"
            );
            setErrorMessage(null);
            setMode("view");
            break;
          case "ConnectionNotFoundError":
          case "ConnectionBadMetadataError":
          case "UserFacingError":
            setErrorMessage(data.updateConnection.message);
            logWarning(new Error(data.updateConnection.message));
            break;
          default:
            logError(
              new Error(`failed to update app metadata and credentials`)
            );
            setErrorMessage(
              "Error: failed to update app metadata and credentials"
            );
        }
      }
    } catch (error) {
      setErrorMessage("Error: failed to update app metadata");
      // logError(error, `failed to update app metadata`);
    }
  };

  const keys = Object.keys(metadataKeyVals);
  const resultEntries = metadata
    ? keys.map((metadataKey) => {
        const metadataVal = metadataKeyVals[metadataKey];

        if (
          (metadataKey === "eventHubNamespace" || metadataKey === "eventHub") &&
          !canUseAzureEDS
        ) {
          return;
        }

        if (
          props.connection.connectionType == ConnectionType.Gcp &&
          (metadataKey === "pubSubProjectId" ||
            metadataKey === "subscriptionId") &&
          !canUseGcpEDS
        ) {
          return;
        }

        return (
          <ConnectionMetadataRow
            connection={props.connection}
            metadataKey={metadataKey}
            metadataVal={metadataVal}
            metadata={metadata}
            setMetadata={setMetadata}
            key={metadataKey}
            editMode={mode == "edit"}
            objKeyInputInfo={objKeyInputInfo}
          />
        );
      })
    : [];

  const headerButtons =
    mode === "view" ? (
      <Button
        label="Edit"
        leftIconName="edit"
        borderless
        onClick={() => setMode("edit")}
        size="md"
      />
    ) : (
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <Button label="Cancel" onClick={editModeReset} borderless size="md" />
        <Button
          label={loading ? "Saving..." : "Save"}
          leftIconName="check"
          type="primary"
          onClick={handleSubmit}
          disabled={disabled}
          disabledTooltip="If updating credentials, all fields must be filled out."
          size="md"
        />
      </div>
    );

  return (
    <>
      {!hasV3 && (
        <AppHeader editButtons={hasMetadataOrCreds && headerButtons} />
      )}
      <div
        className={
          hasV3
            ? styles.setupContainer
            : sprinkles({
                padding: "md",
                display: "flex",
                flexDirection: "column",
                gap: "xl",
              })
        }
      >
        {errorMessage && (
          <div className={sprinkles({ marginTop: "md" })}>
            <Banner message={errorMessage} type="error" />
          </div>
        )}
        {hasV3 && (
          <FormRow title="App Setup">
            {authState.user?.isAdmin && (
              <div
                className={sprinkles({
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                {mode === "view" ? (
                  <ButtonV3
                    type="mainBorderless"
                    label="Edit Setup"
                    leftIconName="edit"
                    size="md"
                    onClick={() => setMode("edit")}
                  />
                ) : (
                  <div className={styles.editButtons}>
                    <ButtonV3
                      type="dangerBorderless"
                      label="Cancel"
                      leftIconName="x-close"
                      size="md"
                      onClick={editModeReset}
                    />
                    <ButtonV3
                      type="mainBorderless"
                      label="Save"
                      leftIconName="check"
                      size="md"
                      onClick={handleSubmit}
                    />
                  </div>
                )}
              </div>
            )}
          </FormRow>
        )}
        {credsVal ? (
          <ConnectionCredsSection
            connection={props.connection}
            editMode={mode == "edit"}
            credsVal={credsVal}
            setCredsVal={setCredsVal}
            initialCreds={initialCreds}
            hasV3={hasV3}
            objKeyInputInfo={objKeyInputInfo}
          />
        ) : null}
        {resultEntries.length !== 0 &&
          (hasV3 ? (
            resultEntries
          ) : (
            <FormSection title="Metadata">{resultEntries}</FormSection>
          ))}
        {props.connection.connectionType == ConnectionType.GitLab && (
          <ConnectionOAuthTokenRow connection={props.connection} />
        )}
        {(props.connection.connectionType === ConnectionType.CustomConnector ||
          props.connection.connectionType === ConnectionType.Custom) && (
          <ConnectionAppIconRow connection={props.connection} />
        )}
      </div>
    </>
  );
};

export const getObjKeyValues = (obj?: object | null) => {
  let objKeyValues: Record<string, string> = {};

  const excludeSet = new Set([
    "__typename",
    "authType",
    "success",
    "ticketProvider",
    "connectionType",
  ]);

  if (obj) {
    Object.keys(obj).forEach((key) => {
      const val = Reflect.get(obj, key);
      if (val instanceof Object) {
        const objKeyVals = getObjKeyValues(val);
        for (const [objKey, objVal] of Object.entries(objKeyVals)) {
          objKeyValues[objKey] = objVal;
        }
      } else {
        if (!excludeSet.has(key)) {
          objKeyValues[key] = val;
        }
      }
    });
  }

  return objKeyValues;
};

type MetadataInfo = {
  initialMetadata: Maybe<ConnectionMetadataInput>;
  initialCreds: Maybe<ConnectionCredentialsInput>;
  objKeyInputInfo: Record<string, InputInfo>;
};

export const getInitialMetadataInfo = (
  connection: ConnectionPreviewSmallFragment
) => {
  const defaultObjKeyInputInfo: Record<string, InputInfo> = {
    tlsCertContent: {
      inputKey: "tlsCertContent",
      inputTitle: "TLS Certificate",
      inputType: InputType.FILE,
      valueConversion: {
        write: btoa,
        read: atob,
      },
      fileAccept: [".pem", ".crt"],
    },
    tlsCaCertContent: {
      inputKey: "tlsCaCertContent",
      inputTitle: "TLS CA Certificate",
      inputType: InputType.FILE,
      valueConversion: {
        write: btoa,
        read: atob,
      },
      fileAccept: [".pem", ".crt"],
    },
    tlsMode: {
      inputKey: "tlsMode",
      inputTitle: "TLS Mode",
      inputType: InputType.BOOLEAN,
    },
    credentialsFile: {
      inputKey: "credentialsFile",
      inputTitle: "Credentials File",
      inputType: InputType.TEXTAREA,
    },
    identifier: {
      inputKey: "identifier",
      inputTitle: "App Identifier",
      inputType: InputType.TEXT,
    },
    supportsGroups: {
      inputKey: "supportsGroups",
      inputTitle: "Connector Groups",
      inputType: InputType.BOOLEAN,
    },
    supportsNestedResources: {
      inputKey: "supportsNestedResources",
      inputTitle: "Connector Nested Resources",
      inputType: InputType.BOOLEAN,
    },
    signingSecret: {
      inputKey: "signingSecret",
      inputTitle: "Signing Secret",
      inputType: InputType.GENERATE_RANDOM_STRING,
    },
    azureInfraEnabled: {
      inputKey: "azureInfraEnabled",
      inputTitle: "Enable Infrastructure Management",
      inputType: InputType.BOOLEAN,
    },
  };
  let initialMetadata: Maybe<ConnectionMetadataInput> = null;
  let initialCreds: Maybe<ConnectionCredentialsInput> = null;
  let objKeyInputInfoOverrides: Record<string, InputInfo> = {};

  const connectionMetadata = connection.metadata;

  if (connectionMetadata) {
    switch (connectionMetadata.__typename) {
      case "ADConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.ActiveDirectory,
          ad: {
            serverHostname: connectionMetadata.serverHostname,
            serverPort: connectionMetadata.serverPort,
            groupsDistinguishedName: connectionMetadata.groupsDistinguishedName,
            usersDistinguishedName: connectionMetadata.usersDistinguishedName,
          },
        };
        initialCreds = {
          authType: AuthType.ActiveDirectory,
          ad: {
            rootUsername: "",
            rootPassword: "",
          },
        };
        break;
      }
      case "AwsConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Aws,
        };
        initialCreds = {
          authType: AuthType.Aws,
          aws: {
            accessKeyId: "",
            secretAccessKey: "",
          },
        };
        break;
      }
      case "AWSSSOConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.AwsSso,
          awsSso: {
            managementAccountId: connectionMetadata.managementAccountId,
            ssoInstanceArn: connectionMetadata.ssoInstanceArn,
            identityStoreId: connectionMetadata.identityStoreId,
            accessPortalUrl: connectionMetadata.accessPortalUrl,
            awsRegion: connectionMetadata.awsRegion,
            awsOrganizationEnabled: connectionMetadata.awsOrganizationEnabled,
            awsSsoEnabled: connectionMetadata.awsSsoEnabled,
            awsIdentityCenterImportSetting:
              connectionMetadata.awsIdentityCenterImportSetting ??
              AwsIdentityCenterImportSetting.Provisioned,
            awsOrganizationImportSetting:
              connectionMetadata.awsOrganizationImportSetting,
            ec2RoleChainingEnabled: connectionMetadata.ec2RoleChainingEnabled,
            podRoleChainingEnabled: connectionMetadata.podRoleChainingEnabled,
            cloudtrailEventsSqsUrl: connectionMetadata.cloudtrailEventsSqsUrl,
          },
        };
        initialCreds = {
          authType: AuthType.AwsSso,
          awsSso: {},
        };
        break;
      }
      case "GCPConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Gcp,
          gcp: {
            serviceAccount: {
              type: connectionMetadata.serviceAccount.type,
              projectId: connectionMetadata.serviceAccount.projectId,
              clientEmail: connectionMetadata.serviceAccount.clientEmail,
              clientId: connectionMetadata.serviceAccount.clientId,
              authUri: connectionMetadata.serviceAccount.authUri,
              tokenUri: connectionMetadata.serviceAccount.tokenUri,
              authProviderX509CertUrl:
                connectionMetadata.serviceAccount.authProviderX509CertUrl,
              clientX509CertUrl:
                connectionMetadata.serviceAccount.clientX509CertUrl,
            },
            pubSubProjectId: connectionMetadata.pubSubProjectId,
            subscriptionId: connectionMetadata.subscriptionId,
          },
        };
        initialCreds = {
          authType: AuthType.Gcp,
          gcp: {
            privateKeyId: "",
            privateKey: "",
          },
        };
        break;
      }
      case "GitHubConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.GitHub,
          gitHub: {
            orgName: connectionMetadata.orgName,
          },
        };
        initialCreds = {
          authType: AuthType.GitHub,
          gitHub: {
            adminToken: "",
            clientId: "",
            clientSecret: "",
          },
        };
        break;
      }
      case "GitLabConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.GitLab,
          gitLab: {
            appId: connectionMetadata.appId,
            hostName: connectionMetadata.hostName,
            tlsMode: connectionMetadata.tlsMode,
            tlsCaCertContent: connectionMetadata.tlsCaCertContent,
          },
        };
        initialCreds = {
          authType: AuthType.GitLab,
          gitLab: {
            appSecret: "",
          },
        };
        break;
      }
      case "GoogleGroupsConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.GoogleGroups,
          googleGroups: {
            serviceAccount: {
              type: connectionMetadata.serviceAccount.type,
              projectId: connectionMetadata.serviceAccount.projectId,
              clientEmail: connectionMetadata.serviceAccount.clientEmail,
              clientId: connectionMetadata.serviceAccount.clientId,
              authUri: connectionMetadata.serviceAccount.authUri,
              tokenUri: connectionMetadata.serviceAccount.tokenUri,
              authProviderX509CertUrl:
                connectionMetadata.serviceAccount.authProviderX509CertUrl,
              clientX509CertUrl:
                connectionMetadata.serviceAccount.clientX509CertUrl,
            },
            opalGroupEmail: connectionMetadata.opalGroupEmail,
            adminUserEmail: connectionMetadata.adminUserEmail,
            domain: connectionMetadata.domain,
          },
        };
        initialCreds = {
          authType: AuthType.GoogleGroups,
          googleGroups: {
            privateKeyId: "",
            privateKey: "",
          },
        };
        break;
      }
      case "GoogleWorkspaceConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.GoogleWorkspace,
          googleWorkspace: {
            serviceAccount: {
              type: connectionMetadata.serviceAccount.type,
              projectId: connectionMetadata.serviceAccount.projectId,
              clientEmail: connectionMetadata.serviceAccount.clientEmail,
              clientId: connectionMetadata.serviceAccount.clientId,
              authUri: connectionMetadata.serviceAccount.authUri,
              tokenUri: connectionMetadata.serviceAccount.tokenUri,
              authProviderX509CertUrl:
                connectionMetadata.serviceAccount.authProviderX509CertUrl,
              clientX509CertUrl:
                connectionMetadata.serviceAccount.clientX509CertUrl,
            },
            adminUserEmail: connectionMetadata.adminUserEmail,
            customerId: connectionMetadata.customerId,
          },
        };
        initialCreds = {
          authType: AuthType.GoogleWorkspace,
          googleWorkspace: {
            privateKeyId: "",
            privateKey: "",
          },
        };
        break;
      }
      case "TailscaleConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Tailscale,
          tailscale: {
            tailnet: connectionMetadata.tailnet,
          },
        };
        initialCreds = {
          authType: AuthType.Tailscale,
          tailscale: {
            apiToken: "",
          },
        };
        break;
      }
      case "LDAPConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Ldap,
          ldap: {
            serverHostname: connectionMetadata.serverHostname,
            serverPort: connectionMetadata.serverPort,
            groupsDistinguishedName: connectionMetadata.groupsDistinguishedName,
            usersDistinguishedName: connectionMetadata.usersDistinguishedName,
            groupsUidKey: connectionMetadata.groupsUidKey,
          },
        };
        initialCreds = {
          authType: AuthType.Ldap,
          ldap: {
            rootUserDistinguishedName: "",
            rootPassword: "",
          },
        };
        break;
      }
      case "MongoConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Mongo,
          mongo: {
            serverHostname: connectionMetadata.serverHostname,
            serverPort: connectionMetadata.serverPort,
            tlsMode: connectionMetadata.tlsMode,
            tlsCertContent: connectionMetadata.tlsCertContent,
            tlsCaCertContent: connectionMetadata.tlsCaCertContent,
          },
        };
        initialCreds = {
          authType: AuthType.Mongo,
          mongo: {
            rootUserName: "",
            rootPassword: "",
          },
        };
        break;
      }
      case "MongoAtlasConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.MongoAtlas,
          mongoAtlas: {
            serverHostname: connectionMetadata.serverHostname,
          },
        };
        initialCreds = {
          authType: AuthType.MongoAtlas,
          mongoAtlas: {
            publicKey: "",
            privateKey: "",
            projectID: "",
          },
        };
        break;
      }
      case "MySQLMariaDBConnectionMetadata": {
        initialMetadata = {
          connectionType: connection.connectionType,
          mySqlMariadb: {
            serverHostname: connectionMetadata.serverHostname,
            serverPort: connectionMetadata.serverPort,
            tlsMode: connectionMetadata.tlsMode,
            tlsCertContent: connectionMetadata.tlsCertContent,
            tlsCaCertContent: connectionMetadata.tlsCaCertContent,
          },
        };
        initialCreds = {
          authType: AuthType.Mysqlmariadb,
          mySqlMariadb: {
            rootUserName: "",
            rootPassword: "",
          },
        };
        break;
      }
      case "PostgresConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Postgres,
          postgres: {
            serverHostname: connectionMetadata.serverHostname,
            serverPort: connectionMetadata.serverPort,
            tlsMode: connectionMetadata.tlsMode,
            tlsCertContent: connectionMetadata.tlsCertContent,
            tlsCaCertContent: connectionMetadata.tlsCaCertContent,
          },
        };
        initialCreds = {
          authType: AuthType.Postgres,
          postgres: {
            rootUserName: "",
            rootPassword: "",
          },
        };
        break;
      }
      case "OktaDirectoryConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.OktaDirectory,
          oktaDirectory: {
            orgUrl: connectionMetadata.orgUrl,
          },
        };
        initialCreds = {
          authType: AuthType.OktaDirectory,
          oktaDirectory: {
            adminToken: "",
          },
        };
        break;
      }
      case "SalesforceConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Salesforce,
          salesforce: {
            email: connectionMetadata.email,
            hostname: connectionMetadata.hostname,
          },
        };
        initialCreds = {
          authType: AuthType.Salesforce,
          salesforce: {
            password: "",
            clientID: "",
            clientSecret: "",
          },
        };
        break;
      }
      case "TeleportConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Teleport,
          teleport: {
            host: connectionMetadata.host,
            tlsMode: connectionMetadata.tlsMode,
            tlsCaCertContent: connectionMetadata.tlsCaCertContent,
          },
        };

        initialCreds = {
          authType: AuthType.Teleport,
          teleport: {
            credentialsFile: "",
          },
        };
        break;
      }
      case "AzureADConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.AzureAd,
          azureAd: {
            clientId: connectionMetadata.clientId,
            tenantId: connectionMetadata.tenantId,
            azureInfraEnabled: connectionMetadata.azureInfraEnabled,
            eventHubNamespace: connectionMetadata.eventHubNamespace,
            eventHub: connectionMetadata.eventHub,
          },
        };

        initialCreds = {
          authType: AuthType.AzureAd,
          azureAd: {
            clientSecret: "",
          },
        };
        break;
      }
      case "DuoConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Duo,
          duo: {
            apiHostname: connectionMetadata.apiHostname,
          },
        };
        initialCreds = {
          authType: AuthType.Duo,
          duo: {
            integrationKey: "",
            secretKey: "",
          },
        };
        break;
      }
      case "PagerDutyConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Pagerduty,
        };
        initialCreds = {
          authType: AuthType.Pagerduty,
          pagerDuty: {
            generalAccessToken: "",
          },
        };
        break;
      }
      case "WorkdayConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Workday,
          workday: {
            tenantName: "",
            tenantUrl: "",
          },
        };
        initialCreds = {
          authType: AuthType.Workday,
          workday: {
            rootUserName: "",
            rootPassword: "",
          },
        };
        break;
      }
      case "SnowflakeConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.Snowflake,
          snowflake: {
            organization: connectionMetadata.organization,
            account: connectionMetadata.account,
            locator: connectionMetadata.locator,
            accountIdentifier: connectionMetadata.accountIdentifier,
            accountUrl: connectionMetadata.accountUrl,
            serviceAccountName: connectionMetadata.serviceAccountName,
            serviceAccountRole: connectionMetadata.serviceAccountRole,
          },
        };
        initialCreds = {
          authType: AuthType.Snowflake,
          snowflake: {
            publicKey: "",
            privateKey: "",
            privateKeyPassword: "",
          },
        };
        objKeyInputInfoOverrides = {
          publicKey: {
            inputKey: "publicKey",
            inputTitle: "Public Key",
            inputType: InputType.TEXTAREA,
          },
          privateKey: {
            inputKey: "privateKey",
            inputTitle: "Private Key",
            inputType: InputType.TEXTAREA,
          },
        };
        break;
      }
      case "CustomConnectorConnectionMetadata": {
        initialMetadata = {
          connectionType: ConnectionType.CustomConnector,
          customConnector: {
            identifier: connectionMetadata.identifier,
            baseUrl: connectionMetadata.baseUrl,
            tlsMode: connectionMetadata.tlsMode,
            tlsCaCertContent: connectionMetadata.tlsCaCertContent,
            supportsGroups: connectionMetadata.supportsGroups,
            supportsNestedResources: connectionMetadata.supportsNestedResources,
          },
        };
        initialCreds = {
          authType: AuthType.CustomConnector,
          customConnector: {
            signingSecret: "",
          },
        };
        break;
      }
    }
  }

  const result: MetadataInfo = {
    initialMetadata: initialMetadata,
    initialCreds: initialCreds,
    objKeyInputInfo: { ...defaultObjKeyInputInfo, ...objKeyInputInfoOverrides },
  };

  return result;
};

export default ConnectionMetadataAndCredsSetup;
