import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GeneralSettingType,
  Maybe,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import RoleCreationOwningTeamModal from "components/modals/update/RoleCreationOwningTeamModal";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import styles from "views/settings/OrgSettings.module.scss";

export type RoleCreationSettingProps = {
  labelText: string;
  tooltipText: string;
  currentSettings: GeneralSettingType[];
  roleCreationReviewerOwner?: {
    id: string;
    name: string;
  };
};

export const RoleCreationSetting = (props: RoleCreationSettingProps) => {
  const { orgDispatch } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [
    updateOrgSettings,
    { loading },
  ] = useUpdateOrganizationSettingsMutation();

  const { displaySuccessToast, displayErrorToast } = useToast();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const onSubmit = async (ownerId?: string) => {
    try {
      const { data } = await updateOrgSettings({
        variables: {
          input: {
            settings: props.currentSettings,
            roleCreationReviewerOwnerId: {
              ownerId: ownerId,
            },
          },
        },
      });

      switch (data?.updateOrganizationSettings.__typename) {
        case "UpdateOrganizationSettingsResult":
          orgDispatch({
            type: OrgContextActionType.OrgSettings,
            payload: {
              orgSettings: data.updateOrganizationSettings.settings,
            },
          });

          modalReset();
          displaySuccessToast("Success: settings updated");
          break;
        default:
          logError(new Error(`failed to update org settings`));
          displayErrorToast("Error: failed to update org settings");
      }
    } catch (error) {
      logError(error, "failed to update org settings");
      displayErrorToast(
        getModifiedErrorMessage("Error: failed to update org settings", error)
      );
    }
  };

  const roleCreationModal = (
    <RoleCreationOwningTeamModal
      isModalOpen={showModal}
      onClose={modalReset}
      loading={loading}
      errorMessage={errorMessage}
      onSubmit={onSubmit}
      roleCreationReviewerOwnerId={props.roleCreationReviewerOwner?.id}
      key={props.roleCreationReviewerOwner?.id}
    />
  );

  return (
    <div className={styles.switchesHeader}>
      <div className={styles.switches}>
        {roleCreationModal}
        <button
          onClick={() => setShowModal(true)}
          className={styles.orgSettingOpenModalButton}
          disabled={!authState.user?.isAdmin}
        >
          {props.roleCreationReviewerOwner ? (
            <Link
              to={getResourceUrlNew(
                {
                  entityId: props.roleCreationReviewerOwner.id,
                  entityType: EntityType.Owner,
                },
                EntityType.Owner
              )}
            >
              {props.roleCreationReviewerOwner.name}
            </Link>
          ) : (
            "None"
          )}
        </button>
      </div>
      <div className={styles.label}>{props.labelText}</div>
      <MoreInfo tooltipText={props.tooltipText} />
    </div>
  );
};
