import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GeneralSettingType,
  Maybe,
  OidcProviderType,
  ResourceAccessLevel,
  ResourceFragment,
  ResourceType,
  ServiceType,
  useAssumeImpersonationMutation,
  useCreateSessionMutation,
  useInitOidcAuthFlowMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { opalConfetti } from "components/confetti/Confetti";
import { FormMode } from "components/forms/common";
import { TooltipPlacement } from "components/label/Label";
import { ResourceExpirationLabel } from "components/label/ResourceExpirationLabel";
import { getServiceTypeInfo } from "components/label/ServiceTypeLabel";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import { RoleModal } from "components/modals/RoleModal";
import SessionDetailsModal from "components/modals/SessionDetailsModal";
import { PendingRequestDisplay } from "components/requests/PendingRequestDisplay";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { generateState } from "utils/auth/auth";
import {
  DEFAULT_SESSION_LENGTH_MINUTES,
  IMPERSONATION_SESSION_LENGTH_MINUTES,
  OPAL_GLOBAL_IMPERSONATION_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
  OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { clearMfaData, getMfaParams, MfaCustomParams } from "utils/mfa/mfa";
import {
  clearOidcData,
  getOidcData,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import { getResourcePendingRequestRoleRemoteIds } from "utils/resources";
import {
  CurrentUserResourceAccessStatus,
  currentUserResourceAccessStatusFromResource,
  getSessionWithExpiration,
  SessionWithExpiration,
} from "views/connect_sessions/utils";
import { ForfeitResourceButton } from "views/resources/ForfeitResourceButton";
import OrgContext from "views/settings/OrgContext";

import { AppsContext } from "./AppsContext";
import { ResourceViewKey } from "./utils";

function getExpirationText(currentSessions: SessionWithExpiration[]) {
  if (currentSessions.length === 1) {
    const currentSession = currentSessions[0];
    if (currentSession.isExpired) {
      return "Expired";
    }
    const { expirationHours, expirationMinutes } = currentSession;
    if (expirationHours === 0) {
      if (expirationMinutes >= 1) {
        return `Expires: ${expirationMinutes}m`;
      } else {
        return `Expires: <1m`;
      }
    }
    return `Expires: ${expirationHours}h ${expirationMinutes}m`;
  }
  if (currentSessions.length > 1) {
    return "Multiple Sessions";
  }
  return;
}

type ResourceActionButtonsV3Props = {
  resource: {
    id: string;
    name: string;
    resourceType: ResourceType;
    serviceType: ServiceType;
    remoteId: string;
    currentUserAccess: ResourceFragment["currentUserAccess"];
    connection?: ResourceFragment["connection"] | null;
    isRequestable: boolean;
    requireMfaToConnect: boolean;
  };
  resourceId: string;
  onNavigate: (resourceViewKey: ResourceViewKey) => void;
  mode?: FormMode;
  refetchResource?: () => void;
};

export const ResourceActionButtonsV3 = (
  props: ResourceActionButtonsV3Props
) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const { bulkMode } = useContext(AppsContext);

  const history = useHistory();
  const location = useLocation();
  const {
    displaySuccessToast,
    displayErrorToast,
    displayCustomToast,
  } = useToast();
  const logEvent = useLogEvent();

  const [assumeImpersonation] = useAssumeImpersonationMutation();

  const [showSessionDetailsModal, setShowSessionDetailsModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState<boolean>(false);
  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const isImpersonationResource =
    props.resource.remoteId === OPAL_IMPERSONATION_REMOTE_ID;
  const isGlobalImpersonationResource =
    props.resource.remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;
  const isProdGlobalImpersonationResource =
    props.resource.remoteId === OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID;

  const [selectedRole, setSelectedRole] = useState<ResourceAccessLevel>({
    accessLevelName: "",
    accessLevelRemoteId: "",
  });

  const [
    createSessionMutation,
    { loading: createSessionLoading },
  ] = useCreateSessionMutation();

  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();

  const queryParams = new URLSearchParams(location.search);
  // Our CLI sets the 'refresh=true' query param when it wants to force-create a new session.
  const pageForceNewSession = queryParams.get("refresh") === "true";
  useMountEffect(() => {
    if (
      props.resource.currentUserAccess.resourceUsers?.length == 1 &&
      !isImpersonationResource &&
      !isGlobalImpersonationResource &&
      !isProdGlobalImpersonationResource
    ) {
      const onlyRole =
        props.resource.currentUserAccess.resourceUsers[0].accessLevel;
      setSelectedRole(onlyRole);
    }
    if (queryParams.has("showModal")) {
      setShowRoleModal(true);
    }
    const performMfaActions = (mfaParams: MfaCustomParams) => {
      if (
        mfaParams &&
        mfaParams.resourceId === props.resource.id &&
        mfaParams.accessLevel
      ) {
        setSelectedRole(mfaParams.accessLevel);
        connectSessionAndDisplayDetails(
          mfaParams.accessLevel,
          mfaParams.forceNewSession
        );
        clearMfaData();
      } else if (
        mfaParams &&
        mfaParams.requestReason &&
        bulkMode !== "request"
      ) {
        props.onNavigate("request");
      }
    };
    if (queryParams.has("mfa")) {
      const mfaStatus = queryParams.get("mfa");
      if (mfaStatus === "success") {
        displaySuccessToast("MFA challenge completed.");
        const mfaParams = getMfaParams();
        if (mfaParams) {
          performMfaActions(mfaParams);
        }
      } else if (mfaStatus === "access_denied") {
        displayErrorToast(
          "Error: Please contact your Opal Admin to reset your MFA."
        );
      } else {
        displayErrorToast(
          "MFA challenge failed. Please try again or contact support if the issue remains."
        );
      }

      queryParams.delete("mfa");
      location.search = queryParams.toString();
    }
    if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.StartSession &&
          oidcData.params.resourceId === props.resource.id &&
          oidcData.params.accessLevel
        ) {
          setSelectedRole(oidcData.params.accessLevel);
          setShowRoleModal(true);
          connectSessionAndDisplayDetails(
            oidcData.params.accessLevel,
            oidcData.params.forceNewSession
          );
          clearOidcData();
          // Clear oidc_auth parameter
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        } else if (oidcData?.mfaParams) {
          performMfaActions(oidcData.mfaParams);
          clearOidcData();
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        }
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });

  // Re-render component every 30s
  const [, setCurrentTime] = useState<moment.Moment>(moment());
  useMountEffect(() => {
    const interval = setInterval(() => setCurrentTime(moment()), 30_000);
    return () => clearInterval(interval);
  });

  // Set of ids of the sessions that have expired and we have already sent out a toast for
  const notifiedExpiredSessions = useRef(new Set());

  const allSessions = props.resource.currentUserAccess.activeSessions.map(
    getSessionWithExpiration
  );

  // Remove notified ids of any sessions that we no longer have information on
  for (const notifiedSessId of Array.from(notifiedExpiredSessions.current)) {
    if (!allSessions.some((sess) => sess.session.id === notifiedSessId))
      notifiedExpiredSessions.current.delete(notifiedSessId);
  }

  // Send notification for expired sessions
  for (const session of allSessions) {
    if (
      session.isExpired &&
      !notifiedExpiredSessions.current.has(session.session.id)
    ) {
      let message;
      const role = session.session.accessLevel;
      if (role?.trim()) {
        message = `Your session for "${role}" access has expired.`;
      } else {
        message = "Your session has expired.";
      }
      displayCustomToast(message, "alert-triangle", false, true);
      notifiedExpiredSessions.current.add(session.session.id);
    }
  }

  // Hide expired sessions
  const currentSessions = allSessions.filter((s) => !s.isExpired);

  // TODO: this really shouldn't be "most recent". We should change the UI to support all of them
  const mostRecentUserAccess = _.maxBy(
    props.resource.currentUserAccess.resourceUsers.map(
      (resourceUser) => resourceUser.access,
      "createdAt"
    )
  );

  const hasForfeitableRole = props.resource.currentUserAccess.resourceUsers.some(
    (resourceUser) => resourceUser.access?.directAccessPoint
  );

  const mostRecentUserAccessStatus = currentUserResourceAccessStatusFromResource(
    props.resource,
    currentSessions.map((s) => s.session)
  );

  const getCurrentSession = (remoteId: string) => {
    for (const session of currentSessions) {
      // The current session must not have expired
      if (
        session.session.accessLevelRemoteId === remoteId &&
        !session.isExpired
      ) {
        return session;
      }
    }
    return null;
  };

  const session = getCurrentSession(selectedRole?.accessLevelRemoteId);

  const connectSessionAndDisplayDetails = async (
    role: ResourceAccessLevel,
    forceNewSession?: boolean
  ) => {
    logEvent({
      name: "apps_initiate_session_click",
      properties: { itemType: props.resource.resourceType },
    });

    if (
      props.resource.resourceType === ResourceType.AwsSsoPermissionSet &&
      props.resource.connection?.metadata?.__typename ===
        "AWSSSOConnectionMetadata"
    ) {
      // AWS SSO resources don't use sessions (yet, perhaps it's possible).
      // Redirect them to the AWS Portal which can grant console or CLI access.
      window.open(props.resource.connection.metadata.accessPortalUrl);
      return false;
    }

    // If an unexpired session already exists, no need to try to create a new one
    if (!forceNewSession && session) {
      if (isImpersonationResource || isProdGlobalImpersonationResource) {
        if (session.session.accessLevelRemoteId) {
          try {
            await assumeImpersonation({
              variables: {
                input: {
                  userId: session.session.accessLevelRemoteId,
                },
              },
            });
          } catch (e) {
            logError(e, "could not assume impersonation");
          }
        } else {
          logError("no role remote id found for impersonation session");
        }
        window.location.href = "/";
      } else {
        setShowSessionDetailsModal(true);
        setShowRoleModal(false);
      }
      return false;
    }

    try {
      const { data } = await createSessionMutation({
        variables: {
          input: {
            resourceId: props.resource.id,
            durationInMinutes:
              isImpersonationResource ||
              isGlobalImpersonationResource ||
              isProdGlobalImpersonationResource
                ? IMPERSONATION_SESSION_LENGTH_MINUTES
                : DEFAULT_SESSION_LENGTH_MINUTES,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
          },
        },
      });
      switch (data?.createSession.__typename) {
        case "CreateSessionResult":
          if (isImpersonationResource || isProdGlobalImpersonationResource) {
            if (data.createSession.session.accessLevelRemoteId) {
              try {
                await assumeImpersonation({
                  variables: {
                    input: {
                      userId: data.createSession.session.accessLevelRemoteId,
                    },
                  },
                });
              } catch (e) {
                logError(e, "could not assume impersonation");
              }
            } else {
              logError("no role remote id found for impersonation session");
            }
            window.location.href = "/";
          } else {
            props.refetchResource && props.refetchResource();
            setShowSessionDetailsModal(true);
            opalConfetti();
          }
          return true;
        case "MfaInvalidError": {
          const useOktaMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
          );
          const useOidcMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
          );
          if (useOidcMfa) {
            try {
              const state = generateState();
              const { data } = await initOidcAuthFlow({
                variables: {
                  input: {
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                  },
                },
              });
              switch (data?.initOidcAuthFlow.__typename) {
                case "InitOidcAuthFlowResult":
                  setOidcData({
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                    postAuthPath: window.location.pathname,
                    postAuthHash: window.location.hash,
                    mfaParams: {
                      resourceId: props.resource.id,
                      accessLevel: role,
                      forceNewSession: forceNewSession,
                    },
                  });
                  window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                  break;
                case "OidcProviderNotFoundError":
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please contact your admin."
                  );
                  break;
                default:
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                  );
              }
            } catch (e) {
              logError(e, "Error: could not complete MFA");
            }
          } else if (useOktaMfa) {
            setShowRoleModal(false);
            setIsIdpMfaModalOpen(true);
          } else {
            authState.authClient?.mfaFlow(history.location.pathname, {
              resourceId: props.resource.id,
              accessLevel: role,
              forceNewSession: forceNewSession,
            });
          }
          break;
        }
        case "OidcIDTokenNotFoundError": {
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    action: OidcPostAuthAction.StartSession,
                    resourceId: props.resource.id,
                    accessLevel: role,
                    forceNewSession: forceNewSession,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "session creation failed");
            setErrorMessage(
              getModifiedErrorMessage("Error: session creation failed", e)
            );
          }
          break;
        }
        case "ResourceNotFoundError":
        case "UserImpersonationDisabledError":
        case "ImpersonationSessionLengthError":
        case "EndSystemAuthorizationError":
        case "UserFacingError":
        case "VaultClientNotFoundError":
        case "VaultSessionError":
          setErrorMessage(data.createSession.message);
          break;
        default:
          logError(new Error(`session creation failed`));
          setErrorMessage("Error: session creation failed");
      }
    } catch (e) {
      logError(e, "session creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: session creation failed", e)
      );
    }
    return false;
  };

  const expirationTime = mostRecentUserAccess?.latestExpiringAccessPoint
    ?.expiration
    ? moment(
        new Date(mostRecentUserAccess.latestExpiringAccessPoint.expiration)
      )
    : null;

  const connectOrSelectRole = () => {
    if (
      props.resource.currentUserAccess.resourceUsers?.length == 1 &&
      !isImpersonationResource &&
      !isGlobalImpersonationResource &&
      !isProdGlobalImpersonationResource
    ) {
      connectSessionAndDisplayDetails(selectedRole, pageForceNewSession);
    } else {
      setShowRoleModal(true);
    }
  };

  // Display and Button Definitions
  let initiateSessionButton = null;
  let connectedButton = null;
  let expirationToolTip = null;
  let forfeitButton = null;
  let expirationLabel = null;
  let expirationText = null;

  let viewRequestButton = (
    <PendingRequestDisplay
      resourceId={props.resource.id}
      pendingRequests={props.resource.currentUserAccess.pendingRequests}
      renderButton={(onClick) => (
        <ButtonV3
          label="View request"
          leftIconName="send"
          type="defaultSecondary"
          onClick={onClick}
          key={props.resourceId}
          size="sm"
        />
      )}
    />
  );

  switch (mostRecentUserAccessStatus) {
    case CurrentUserResourceAccessStatus.Authorized:
      if (hasForfeitableRole && !isProdGlobalImpersonationResource) {
        forfeitButton = (
          <ForfeitResourceButton
            resource={props.resource}
            refetchResource={props.refetchResource}
            hasV3={hasV3}
            key={props.resourceId}
            buttonSize="sm"
          />
        );
      }

      expirationLabel = (
        <ResourceExpirationLabel
          user={authState.user?.user}
          resource={props.resource}
          entityType={EntityType.Resource}
          expiration={expirationTime}
          currentUserResourceUsers={
            props.resource.currentUserAccess.resourceUsers
          }
          iconOnly={true}
          hasV3={hasV3}
          key={props.resourceId}
          buttonSize="sm"
        />
      );
      break;
    case CurrentUserResourceAccessStatus.AuthorizedSessionStarted:
      expirationText = getExpirationText(currentSessions);

      connectedButton = (
        <ButtonV3
          label={"Connected"}
          leftIconName="zap"
          onClick={() => connectOrSelectRole()}
          type="successSecondary"
          key={props.resourceId}
          size="sm"
        />
      );

      expirationToolTip = (
        <Tooltip
          key={props.resourceId}
          tooltipText={expirationText}
          placement={TooltipPlacement.Top}
        >
          <Icon name="info" size="xs" color="gray600" />
        </Tooltip>
      );

      if (hasForfeitableRole) {
        forfeitButton = (
          <ForfeitResourceButton
            resource={props.resource}
            refetchResource={props.refetchResource}
            hasV3={hasV3}
            key={props.resourceId}
            buttonSize="sm"
          />
        );
      }

      expirationLabel = (
        <ResourceExpirationLabel
          user={authState.user?.user}
          resource={props.resource}
          entityType={EntityType.Resource}
          expiration={expirationTime}
          currentUserResourceUsers={
            props.resource.currentUserAccess.resourceUsers
          }
          iconOnly={true}
          hasV3={hasV3}
          key={props.resourceId}
          buttonSize="sm"
        />
      );
      break;
    case CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted:
      initiateSessionButton = (
        <ButtonV3
          label="Connect"
          leftIconName="zap"
          onClick={() => {
            connectOrSelectRole();
          }}
          type="success"
          key={props.resourceId}
          size="sm"
        />
      );

      if (hasForfeitableRole) {
        forfeitButton = (
          <ForfeitResourceButton
            resource={props.resource}
            refetchResource={props.refetchResource}
            hasV3={hasV3}
            key={props.resourceId}
            buttonSize="sm"
          />
        );
      }

      expirationLabel = (
        <ResourceExpirationLabel
          user={authState.user?.user}
          resource={props.resource}
          entityType={EntityType.Resource}
          expiration={expirationTime}
          currentUserResourceUsers={
            props.resource.currentUserAccess.resourceUsers
          }
          iconOnly={true}
          hasV3={hasV3}
          key={props.resourceId}
          buttonSize="sm"
        />
      );
      break;
  }

  // Request Button
  // Show a disabled request button if the user has no access to the resource and is not allowed to request it.
  const userPendingRoleRemoteIds = getResourcePendingRequestRoleRemoteIds(
    props.resource.currentUserAccess
  );
  const notRequestableMessage =
    "This resource cannot be requested, please see the resource admin";
  const hasOtherPrimaryButton = Boolean(
    connectedButton ||
      initiateSessionButton ||
      forfeitButton ||
      expirationLabel ||
      userPendingRoleRemoteIds.size > 0
  );

  let requestButton = (
    <ButtonV3
      label="Request"
      leftIconName="lock-unlocked"
      type={hasOtherPrimaryButton ? "mainSecondary" : "main"}
      data-test-id={"Request"}
      onClick={() => {
        props.onNavigate("request");
        logEvent({
          name: "apps_item_request_click",
          properties: {
            itemType: props.resource.resourceType,
          },
        });
      }}
      disabled={!props.resource.isRequestable}
      disabledTooltip={notRequestableMessage}
      key={props.resourceId}
      size="sm"
    />
  );

  // Populate buttons array
  const buttons = [];

  if (props.mode !== "edit") {
    // Request Button
    if (
      !isProdGlobalImpersonationResource &&
      (props.resource.isRequestable || !hasForfeitableRole)
    ) {
      buttons.push(requestButton);
    }
    // View Request Button
    if (userPendingRoleRemoteIds.size > 0) {
      buttons.push(viewRequestButton);
    }
    // Access Details Button
    if (expirationLabel) {
      buttons.push(expirationLabel);
    }
    // Forfeit Button
    if (forfeitButton) {
      buttons.push(forfeitButton);
    }
    // Connect Button
    if (initiateSessionButton) {
      buttons.push(initiateSessionButton);
    }
    // Connected Button
    if (connectedButton) {
      buttons.push(connectedButton);
      buttons.push(expirationToolTip);
    }
  }

  return (
    <>
      {session && showSessionDetailsModal && (
        <SessionDetailsModal
          entityType={EntityTypeDeprecated.Resource}
          resource={props.resource}
          session={session.session}
          serviceName={getServiceTypeInfo(props.resource.serviceType)?.name}
          onClose={() => {
            setShowSessionDetailsModal(false);
          }}
          selectedRole={selectedRole}
        />
      )}
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "flex-start",
          gap: "sm",
        })}
      >
        {buttons}
      </div>
      {showRoleModal && (
        <RoleModal
          errorMessage={errorMessage}
          isModalOpen={showRoleModal}
          onClose={() => {
            setErrorMessage(null);
            setShowRoleModal(false);
          }}
          resource={props.resource}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          isImpersonationResource={
            isImpersonationResource || isProdGlobalImpersonationResource
          }
          onSubmit={() => {
            return connectSessionAndDisplayDetails(
              selectedRole,
              pageForceNewSession
            );
          }}
          loading={createSessionLoading}
          getCurrentSession={getCurrentSession}
        />
      )}
      {isIdpMfaModalOpen && (
        <IdpMfaModal
          onClose={() => {
            setIsIdpMfaModalOpen(false);
            setShowRoleModal(true);
          }}
          onMfaSuccess={() => {
            setIsIdpMfaModalOpen(false);
            connectSessionAndDisplayDetails(selectedRole, pageForceNewSession);
          }}
        />
      )}
    </>
  );
};
