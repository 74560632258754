import {
  ErrorNotificationSettingType,
  GeneralSettingType,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { MoreInfo } from "components/more_info/MoreInfo";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { FormGroup, Modal, Select } from "components/ui";
import React, { useContext, useEffect, useState } from "react";
import { logError } from "utils/logging";
import styles from "views/settings/OrgSettings.module.scss";

export type ErrorNotificationSettingProps = {
  currentSettings: GeneralSettingType[];
  currentErrorNotificationSetting?: ErrorNotificationSettingType;
  ownerId?: string | null;
};

export const ErrorNotificationSetting = (
  props: ErrorNotificationSettingProps
) => {
  const { authState } = useContext(AuthContext);

  const { displaySuccessToast, displayErrorToast } = useToast();

  const [
    updateOrgSettings,
    { loading: updateOrgLoading },
  ] = useUpdateOrganizationSettingsMutation();

  const [selectedSetting, setSelectedSetting] = useState(
    props.currentErrorNotificationSetting
  );
  const [ownerId, setOwnerId] = useState(props.ownerId);
  const [showModal, setShowModal] = useState(false);

  const modalReset = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Org settings (from which these settings come) usually aren't loaded on first render.
    setSelectedSetting(props.currentErrorNotificationSetting);
  }, [props.ownerId, props.currentErrorNotificationSetting]);
  useEffect(() => {
    // Org settings (from which props.ownerId comes) usually aren't loaded on first render.
    if (props.ownerId) {
      setOwnerId(props.ownerId);
    }
  }, [props.ownerId, props.currentErrorNotificationSetting]);

  const getButtonLabel = () => {
    switch (selectedSetting) {
      case ErrorNotificationSettingType.NotifyAdmins:
        return "Notify Admins";
      case ErrorNotificationSettingType.NotifyOwner:
        return "Notify Owner";
      case ErrorNotificationSettingType.Disabled:
        return "Do not notify";
      default:
        return "Loading...";
    }
  };

  const settingsPanel = (
    <div>
      <div>
        <div className={styles.switchesHeader}>
          <div className={styles.switches}>
            <button
              disabled={!authState.user?.isAdmin}
              onClick={() => {
                setShowModal(true);
              }}
              className={styles.orgSettingOpenModalButton}
            >
              {getButtonLabel()}
            </button>
          </div>
          <div className={styles.label}>Error notification setting</div>
          <MoreInfo
            tooltipText={
              "Set up who gets notified when there are issues with your Opal configuration."
            }
          />
        </div>
      </div>
    </div>
  );

  const modal = (
    <Modal
      isOpen={showModal}
      title="Error notification settings"
      onClose={() => modalReset()}
    >
      <Modal.Body>
        <FormGroup label="Who is notified on error?">
          <p>
            If Opal encounters an error that requires administrator action, send
            a notification to:
          </p>
          <Select
            options={[
              ErrorNotificationSettingType.NotifyAdmins,
              ErrorNotificationSettingType.NotifyOwner,
              ErrorNotificationSettingType.Disabled,
            ]}
            getOptionLabel={(option) => {
              switch (option) {
                case ErrorNotificationSettingType.NotifyAdmins:
                  return "All Opal Admins";
                case ErrorNotificationSettingType.NotifyOwner:
                  return "An Owner";
                case ErrorNotificationSettingType.Disabled:
                  return "Do not notify anyone";
                default:
                  return "";
              }
            }}
            onChange={(v) => {
              if (v !== ErrorNotificationSettingType.NotifyOwner) {
                setOwnerId(null);
              }
              setSelectedSetting(v!);
            }}
            value={selectedSetting}
          />
        </FormGroup>
        {selectedSetting == ErrorNotificationSettingType.NotifyOwner && (
          <FormGroup label="Select owner">
            <OwnerDropdown
              selectedOwnerId={ownerId || undefined}
              onSelectOwner={(owner) => setOwnerId(owner?.id)}
              placeholder="Select owners"
            />
          </FormGroup>
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        primaryButtonDisabled={
          selectedSetting == ErrorNotificationSettingType.NotifyOwner &&
          !ownerId
        }
        primaryButtonLoading={updateOrgLoading}
        onPrimaryButtonClick={async () => {
          try {
            const { data } = await updateOrgSettings({
              variables: {
                input: {
                  settings: props.currentSettings,
                  errorNotificationSetting: selectedSetting,
                  syncErrorHandlerOwnerId: { ownerId: ownerId },
                },
              },
            });

            switch (data?.updateOrganizationSettings.__typename) {
              case "UpdateOrganizationSettingsResult":
                setShowModal(false);
                displaySuccessToast("Error notification settings updated");
                break;
            }
          } catch (error) {
            displayErrorToast("Failed to update error notification settings");
            logError(error, "Failed to update error notification settings");
          }
        }}
      ></Modal.Footer>
    </Modal>
  );
  return (
    <div>
      {settingsPanel}
      {modal}
    </div>
  );
};

export default ErrorNotificationSetting;
