export const NO_PERMISSION_TO_REQUEST =
  "You do not have permission to request this item";

// Breakpoints used to define how many columns we should have in our catalog layouts
// based on the width
// Cards need to be at least 300px wide, the breakpoint represents the lower bound limit
// in width for the parent component
export const BREAKPOINT_COLUMNS = {
  1800: 5,
  1500: 4,
  1200: 3,
  900: 2,
  600: 1,
};
