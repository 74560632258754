import { useMergeLink } from "@mergeapi/react-merge-link";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  IntegrationType,
  Maybe,
  ThirdPartyProvider,
  useCreateThirdPartyIntegrationMutation,
  useInitThirdPartyIntegrationLinkTokenMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import { useToast } from "components/toast/Toast";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { logError } from "utils/logging";
import { IntegrationButton } from "views/settings/third_party_authorizer/IntegrationButton";

export type ThirdPartyLinkFlowAuthorizerProps = {
  integrationType: IntegrationType;
  integrationInfo: IntegrationInfo;
  isConnected: boolean;
  openThirdPartyProvider: Maybe<ThirdPartyProvider>;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  connectionButtonDisabled?: boolean;
  simpleButton?: boolean;
};

export const ThirdPartyLinkFlowAuthorizer = (
  props: ThirdPartyLinkFlowAuthorizerProps
) => {
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  const { displayErrorToast } = useToast();

  const [
    createLinkToken,
    { loading: createLinkTokenLoading },
  ] = useInitThirdPartyIntegrationLinkTokenMutation();

  const [linkToken, setLinkToken] = useState<Maybe<string>>(null);

  const { open, isReady } = useMergeLink({
    linkToken: linkToken!,
    onSuccess: async (publicToken: string) => {
      try {
        await createThirdPartyOrgIntegration({
          variables: {
            input: {
              thirdPartyProvider: props.integrationInfo.thirdPartyProvider,
              token: publicToken,
            },
          },
        });
        history.push("/settings/");
        window.location.reload();
      } catch (error) {
        logError(error, "failed to send public token");
        displayErrorToast(
          getModifiedErrorMessage(`Error: failed to send public token`, error)
        );
      }
    },
  });

  const [
    createThirdPartyOrgIntegration,
    { loading: callbackLoading },
  ] = useCreateThirdPartyIntegrationMutation();

  useEffect(() => {
    if (
      props.openThirdPartyProvider === ThirdPartyProvider.Merge &&
      linkToken
    ) {
      open();
      props.setOpenThirdPartyProvider(null);
    }
  }, [props, linkToken, open]);

  return (
    <IntegrationButton
      integrationType={props.integrationType}
      integrationInfo={props.integrationInfo}
      isConnected={props.isConnected}
      setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
      simpleButton={props.simpleButton}
      onConnectClick={async () => {
        try {
          const { data } = await createLinkToken();
          switch (data?.initThirdPartyIntegrationLinkAuthFlow.__typename) {
            case "InitThirdPartyIntegrationLinkAuthFlowResult":
              setLinkToken(
                data.initThirdPartyIntegrationLinkAuthFlow.linkToken
              );
              break;
            default:
              logError(new Error(`failed to create link token`));
              displayErrorToast(`failed to create link token`);
          }
        } catch (error) {
          logError(error, `failed to create link token: ${error}`);
          displayErrorToast(
            getModifiedErrorMessage(`failed to create link token`, error)
          );
        }
      }}
      buttonDisabled={
        !isReady ||
        createLinkTokenLoading ||
        callbackLoading ||
        props.connectionButtonDisabled ||
        !authState.user?.isAdmin
      }
    />
  );
};
