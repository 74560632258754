import { TimeZone } from "@vvo/tzdb";
import { Col, DatePicker, FormGroup, Row, Select } from "components/ui";
import * as styles from "components/ui/form_group/FormGroup.css";
import moment from "moment";
import React, { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { getTimeZoneDisplayText, timeZones } from "utils/time";
interface DeadlineFormProps {
  onChange: (deadline: string, tz: TimeZone) => void;
  label: string;
  deadline?: string; // this should be a ISO 8601 datetime with timezone info
  timeZone?: TimeZone;
}

// The way times work here are a little annoying. We're tracking three things:
//   1. The displayed date: this is the tz agnostic date the date picker is tracking
//   2. The timezone: the user specified tz, we're going to use this to encode a tz aware iso datetime
//      when we talk to the backend
//   3. The deadline: this is going to be 23:59:59.999 on the date picked via the date picker,
//      i.e. midnight of the selected day
const DeadlineForm = ({
  onChange,
  label,
  deadline,
  timeZone,
}: DeadlineFormProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  // If a deadline is supplied, it's going to be an ISO 8601 encoded timestamp in UTC format.
  // Given that we need to convert it to a tz local Date, only take the date components and nothing else,
  // so that we get a (date, timezone) pair that makes sense, rather than using a timezone adjusted date.
  var parsedDate: Date | undefined;
  if (deadline && timeZone) {
    const deadlineInLocalTz = moment(deadline).tz(timeZone.name);
    parsedDate = new Date(
      deadlineInLocalTz.year(),
      deadlineInLocalTz.month(),
      deadlineInLocalTz.date()
    );
  }
  const [deadlineDate, setDeadlineDate] = useState<Date | undefined>(
    parsedDate
  );

  // Convert a deadline and tz pair into a tz aware datetime for midnight of the supplied day.
  const tzAwareMidnightDeadline = (
    displayDeadline: Date | undefined,
    tzName: string
  ): string => {
    if (displayDeadline) {
      // The displayedDeadline var is generated by the date picker and will be in local time.
      // We want to strip out any time information and just use the date elements to construct a
      // datetime for midnight on that date in the specified timezone.
      const deadline = moment()
        .year(displayDeadline.getFullYear())
        .month(displayDeadline.getMonth())
        .date(displayDeadline.getDate())
        .tz(tzName)
        .endOf("day");

      return deadline.toISOString(true);
    }

    return "";
  };

  const datePicker = (
    <FormGroup label={label} required>
      <DatePicker
        selectedDate={deadlineDate}
        setSelectedDate={(date) => {
          if (!date) return;
          setDeadlineDate(date);
          if (!timeZone) return;
          onChange(tzAwareMidnightDeadline(date, timeZone.name), timeZone);
        }}
        dateFormat="PPPP"
        fromDate={new Date()}
      />
    </FormGroup>
  );

  const tzSelect = (
    <FormGroup label="Select a time zone" required>
      <Select
        value={timeZone}
        options={timeZones}
        getOptionLabel={getTimeZoneDisplayText}
        onChange={(tz: TimeZone | undefined) => {
          if (tz) {
            onChange(tzAwareMidnightDeadline(deadlineDate, tz.name), tz);
          }
        }}
      />
    </FormGroup>
  );

  // The -8px offset is a bit of a hack to bridge the v2 vs v3 layout differences.
  // It should be removed once v3 is the only version we're running.
  const notifStyle = hasV3
    ? { marginBottom: "8px", marginTop: "-8px" }
    : { marginTop: "8px" };
  const tzNotif = (
    <span className={styles.description} style={notifStyle}>
      The deadline expires at midnight in the selected timezone on the selected
      date.
    </span>
  );

  const v2Component = (
    <>
      <Row>
        <Col>{datePicker}</Col>
        <Col>{tzSelect}</Col>
      </Row>
      <Row>{tzNotif}</Row>
    </>
  );

  const v3Component = (
    <>
      {datePicker}
      {tzSelect}
      {tzNotif}
    </>
  );

  if (!hasV3) {
    return v2Component;
  } else {
    return v3Component;
  }
};

export default DeadlineForm;
