import { refetchQueries } from "api/ApiContext";
import {
  AccessLevelNodeFragment,
  GroupPreviewSmallFragment,
  RemoveGroupUserInput,
  RemoveResourceUserInput,
  ResourceAccessLevel,
  ResourcePreviewLargeFragment,
  UpdateGroupUserInput,
  UpdateResourceUserInput,
  useRemoveGroupUsersMutation,
  useRemoveUserResourcesMutation,
  UserPreviewSmallFragment,
  useUpdateGroupUsersMutation,
  useUpdateUserResourcesMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { PillV3 } from "components/pills/PillsV3";
import { useToast } from "components/toast/Toast";
import {
  Button,
  ButtonV3,
  DataElementList,
  Divider,
  Header,
  Icon,
  Modal,
} from "components/ui";
import ConvertGroupAccessSection from "components/viz/actions/ConvertGroupAccessSection";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import React from "react";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { minutesToExpirationValue } from "views/requests/utils";

import ConvertResourceAccessSection from "./actions/ConvertResourceAccessSection";
import RemoveGroupSection from "./actions/RemoveGroupSection";
import RevokeResourceSection from "./actions/RevokeResourceSection";
import { GraphContext } from "./contexts/GraphContext";
import * as styles from "./Sidebar.css";
import { makeRolelNodeId } from "./utils";

interface SidebarProps {
  hidden: boolean;
}

const ActionsSidebar = ({ hidden }: SidebarProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const [revokeResource, setRevokeResource] = React.useState(false);
  const [removeGroup, setRemoveGroup] = React.useState(false);
  const [
    resourceNewAccessDurationMin,
    setResourceNewAccessDurationMin,
  ] = React.useState<number | undefined>();
  const [
    groupNewAccessDurationMin,
    setGroupNewAccessDurationMin,
  ] = React.useState<number | undefined>();
  const [error, setError] = React.useState("");
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const { graphState, graphDispatch } = React.useContext(GraphContext);
  const vizData = graphState.data;
  const startPushTaskPoll = usePushTaskLoader();
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();
  const [updateResourceUsers] = useUpdateUserResourcesMutation();
  const [removeResourceUsers] = useRemoveUserResourcesMutation();
  const [removeGroupUsers] = useRemoveGroupUsersMutation();
  const [updateGroupUsers] = useUpdateGroupUsersMutation();

  const setLoading = React.useCallback(
    (loading: boolean) => {
      graphDispatch({
        type: "SET_LOADING",
        payload: {
          loading,
        },
      });
    },
    [graphDispatch]
  );

  const edgesMap = graphState.neighbors;
  const connectedResourceIds: string[] = [];
  graphState.selectedResourceIds.forEach((resourceId) => {
    const neighbors = edgesMap[resourceId] ?? [];
    if (
      neighbors.some((nodeId) => graphState.selectedUserIds.includes(nodeId))
    ) {
      connectedResourceIds.push(resourceId);
    }
  });
  const connectedGroupIds: string[] = [];
  graphState.selectedGroupIds.forEach((groupId) => {
    const neighbors = edgesMap[groupId] ?? [];
    if (
      neighbors.some((nodeId) => graphState.selectedUserIds.includes(nodeId))
    ) {
      connectedGroupIds.push(groupId);
    }
  });

  const userById: { [userId: string]: UserPreviewSmallFragment } = {};
  const resourceById: {
    [resourceId: string]: ResourcePreviewLargeFragment;
  } = {};
  const roleByKey: {
    [resourceKey: string]: AccessLevelNodeFragment;
  } = {};
  const groupById: { [groupId: string]: GroupPreviewSmallFragment } = {};
  vizData?.users.forEach((user) => (userById[user.id] = user));
  vizData?.resources.forEach(
    (resource) => (resourceById[resource.id] = resource)
  );
  vizData?.accessLevels.forEach((role) => {
    const resourceKey = makeRolelNodeId(role.resourceId, role.accessLevel);
    roleByKey[resourceKey] = role;
  });
  vizData?.groups.forEach((group) => (groupById[group.id] = group));

  const usersList =
    graphState.selectedUserIds.length > 0 ? (
      <DataElementList maxHeight={280} noun="user">
        {graphState.selectedUserIds.map((selectedUserId) => {
          const user = userById[selectedUserId];
          if (!user) {
            return null;
          }
          return (
            <PillV3
              key={user.id}
              keyText={user.fullName}
              pillColor="DeepOrange"
              icon={{ type: "name", icon: "user" }}
            />
          );
        })}
      </DataElementList>
    ) : null;

  const resourcesToChange = connectedResourceIds
    .map((resourceId) => {
      const resource = resourceById[resourceId];
      if (resource) {
        return resource.name;
      }

      const role = roleByKey[resourceId];
      if (role) {
        const roleName = role.accessLevel.accessLevelName;
        const parentResource = resourceById[role.resourceId];
        return parentResource ? `${parentResource.name}:${roleName}` : roleName;
      }
      return "";
    })
    .filter((resourceName) => resourceName !== "");

  const groupsToChange = connectedGroupIds
    .map((groupId) => groupById[groupId]?.name ?? "")
    .filter((groupName) => groupName !== "");

  const resourceUsersToUpdate: UpdateResourceUserInput[] = [];
  const resourceUsersToRemove: RemoveResourceUserInput[] = [];
  const groupUsersToRemove: RemoveGroupUserInput[] = [];
  const groupUsersToUpdate: UpdateGroupUserInput[] = [];

  graphState.selectedUserIds.forEach((userId) => {
    const neighbors = edgesMap[userId] ?? [];
    neighbors.forEach((nodeId) => {
      if (graphState.selectedResourceIds.includes(nodeId)) {
        const resource = resourceById[nodeId];
        const roleNode = roleByKey[nodeId];
        // Resource with no roles
        if (resource) {
          if (resourceNewAccessDurationMin != null) {
            resourceUsersToUpdate.push({
              resourceId: resource.id,
              userId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: resourceNewAccessDurationMin,
            });
          } else if (revokeResource) {
            resourceUsersToRemove.push({
              resourceId: resource.id,
              userId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
            });
          }

          // A specific role of a resource
        } else if (roleNode) {
          if (resourceNewAccessDurationMin != null) {
            resourceUsersToUpdate.push({
              resourceId: roleNode.resourceId,
              userId,
              accessLevel: {
                accessLevelName: roleNode.accessLevel.accessLevelName,
                accessLevelRemoteId: roleNode.accessLevel.accessLevelRemoteId,
              },
              durationInMinutes: resourceNewAccessDurationMin,
            });
          } else if (revokeResource) {
            resourceUsersToRemove.push({
              resourceId: roleNode.resourceId,
              userId,
              accessLevel: {
                accessLevelName: roleNode.accessLevel.accessLevelName,
                accessLevelRemoteId: roleNode.accessLevel.accessLevelRemoteId,
              },
            });
          }
        }
      }
      if (graphState.selectedGroupIds.includes(nodeId)) {
        if (removeGroup) {
          groupUsersToRemove.push({
            groupId: nodeId,
            userId,
          });
        } else if (groupNewAccessDurationMin != null) {
          groupUsersToUpdate.push({
            groupId: nodeId,
            userId,
            durationInMinutes: groupNewAccessDurationMin,
          });
        }
      }
    });
  });

  const handleConfirm = async () => {
    setLoading(true);
    logEvent({
      name: "viz_confirm_modal_confirm_click",
      properties: {
        numResourceUsersToRemove: resourceUsersToRemove.length,
        numResourceUsersToUpdate: resourceUsersToUpdate.length,
        numGroupUsersToRemove: groupUsersToRemove.length,
        numGroupUsersToUpdate: groupUsersToUpdate.length,
      },
    });
    try {
      let error = "";
      if (resourceUsersToRemove.length > 0) {
        const { data: removeResourceUsersData } = await removeResourceUsers({
          variables: {
            input: {
              resourceUsers: resourceUsersToRemove,
            },
          },
        });
        switch (removeResourceUsersData?.removeResourceUsers.__typename) {
          case "RemoveResourceUsersResult": {
            const taskId = removeResourceUsersData.removeResourceUsers.taskId;
            if (taskId) {
              startPushTaskPoll(taskId, {
                onComplete: () =>
                  refetchQueries({ include: ["VisualizationData"] }),
              });
            } else {
              displaySuccessToast("Revoked user resource access");
              refetchQueries({ include: ["VisualizationData"] });
            }
            break;
          }

          default:
            error += "Failed to remove users from resources. ";
        }
      }

      if (groupUsersToRemove.length > 0) {
        const { data: removeGroupUsersData } = await removeGroupUsers({
          variables: {
            input: {
              groupUsers: groupUsersToRemove,
            },
          },
        });
        switch (removeGroupUsersData?.removeGroupUsers.__typename) {
          case "RemoveGroupUsersResult": {
            const taskId = removeGroupUsersData.removeGroupUsers.taskId;
            if (taskId) {
              startPushTaskPoll(taskId, {
                onComplete: () =>
                  refetchQueries({ include: ["VisualizationData"] }),
              });
            } else {
              displaySuccessToast("Removed users from groups");
              refetchQueries({ include: ["VisualizationData"] });
            }
            break;
          }

          default:
            error += "Failed to remove users from groups. ";
        }
      }

      if (resourceUsersToUpdate.length > 0) {
        const { data: updateResourceUsersData } = await updateResourceUsers({
          variables: {
            input: {
              resourceUsers: resourceUsersToUpdate,
            },
          },
          refetchQueries: ["VisualizationData"],
        });
        switch (updateResourceUsersData?.updateResourceUsers.__typename) {
          case "UpdateResourceUsersResult":
            displaySuccessToast("Updated user resource access");
            break;
          default:
            error += "Failed to update user access.";
        }
      }

      if (groupUsersToUpdate.length > 0) {
        const { data: updateGroupUsersData } = await updateGroupUsers({
          variables: {
            input: {
              groupUsers: groupUsersToUpdate,
            },
          },
          refetchQueries: ["VisualizationData"],
        });
        switch (updateGroupUsersData?.updateGroupUsers.__typename) {
          case "UpdateGroupUsersResult":
            displaySuccessToast("Updated user group access");
            break;
          default:
            error += "Failed to update user group access.";
        }
      }

      if (!error) {
        graphDispatch({
          type: "CLEAR_SELECTED",
        });
        setShowConfirmModal(false);
      } else {
        setError(error);
      }
    } catch (err) {
      logError(err, "failed to update resource users");
    } finally {
      setLoading(false);
    }
  };

  const numChanges =
    resourceUsersToRemove.length +
    resourceUsersToUpdate.length +
    groupUsersToRemove.length +
    groupUsersToUpdate.length;

  let selectionEmptyState = <></>;
  if (!graphState.selectedUserIds.length) {
    selectionEmptyState = (
      <div className={styles.emptyStateContainer}>
        Click a user on the graph to edit their access
      </div>
    );
  } else if (resourcesToChange.length + groupsToChange.length === 0) {
    selectionEmptyState = (
      <div className={styles.emptyStateContainer}>
        Click a connected resource or group on the graph to edit access
      </div>
    );
  }

  return (
    <>
      <div className={styles.actionsSidebarWrapper({ hidden })}>
        <div
          className={
            hasV3 ? styles.actionsSidebar : styles.sidebar({ type: "actions" })
          }
        >
          <div
            className={hasV3 ? styles.closeButtonV3 : styles.closeButton}
            onClick={() =>
              graphDispatch({
                type: "CLEAR_SELECTED",
              })
            }
          >
            <Icon name="x" size="sm" />
          </div>
          {hasV3 ? (
            <div
              className={sprinkles({
                fontSize: "textLg",
                fontWeight: "medium",
              })}
            >
              Edit Connections
            </div>
          ) : (
            <Header type="column" text="Edit Connections" />
          )}
          <p
            className={sprinkles({
              marginTop: "md",
              fontSize: "textXs",
              color: "gray700",
            })}
          >
            Quickly remove, revoke, or change a user's access to groups or
            resources
          </p>
          <p className={styles.title}>Users</p>
          <div>{usersList}</div>
          <div className={styles.actionsContainer}>
            {groupsToChange.length > 0 && (
              <p className={styles.title}>Groups</p>
            )}
            <RemoveGroupSection
              removeGroup={removeGroup}
              onChange={(remove) => {
                setRemoveGroup(remove);
                if (remove) {
                  setGroupNewAccessDurationMin(undefined);
                }
              }}
              groups={groupsToChange}
            />
            <ConvertGroupAccessSection
              groups={groupsToChange}
              accessDurationMin={groupNewAccessDurationMin}
              onChange={(durationMin) => {
                setGroupNewAccessDurationMin(durationMin);
                if (durationMin != null) {
                  setRemoveGroup(false);
                }
              }}
            />
            {resourcesToChange.length > 0 && (
              <p className={styles.title}>Resources</p>
            )}
            <RevokeResourceSection
              revokeResource={revokeResource}
              resources={resourcesToChange}
              onChange={(revoke) => {
                setRevokeResource(revoke);
                if (revoke) {
                  setResourceNewAccessDurationMin(undefined);
                }
              }}
            />
            <ConvertResourceAccessSection
              resources={resourcesToChange}
              accessDurationMin={resourceNewAccessDurationMin}
              onChange={(durationMin) => {
                setResourceNewAccessDurationMin(durationMin);
                if (durationMin != null) {
                  setRevokeResource(false);
                }
              }}
            />
            {selectionEmptyState}
          </div>
          {hasV3 ? (
            <ButtonV3
              label={
                numChanges > 0
                  ? `Confirm ${numChanges} ${pluralize("change", numChanges)}`
                  : "Confirm"
              }
              onClick={() => {
                setShowConfirmModal(true);
                logEvent({
                  name: "viz_confirm_actions_click",
                  properties: {
                    numResourceUsersToRemove: resourceUsersToRemove.length,
                    numResourceUsersToUpdate: resourceUsersToUpdate.length,
                    numGroupUsersToRemove: groupUsersToRemove.length,
                    numGroupUsersToUpdate: groupUsersToUpdate.length,
                  },
                });
              }}
              type="main"
              size="sm"
              disabled={numChanges === 0}
              fullWidth
            />
          ) : (
            <Button
              label={
                numChanges > 0
                  ? `Confirm ${numChanges} ${pluralize("change", numChanges)}`
                  : "Confirm"
              }
              onClick={() => {
                setShowConfirmModal(true);
                logEvent({
                  name: "viz_confirm_actions_click",
                  properties: {
                    numResourceUsersToRemove: resourceUsersToRemove.length,
                    numResourceUsersToUpdate: resourceUsersToUpdate.length,
                    numGroupUsersToRemove: groupUsersToRemove.length,
                    numGroupUsersToUpdate: groupUsersToUpdate.length,
                  },
                });
              }}
              type="primary"
              fullWidth
              disabled={numChanges === 0}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
          setError("");
        }}
        title="Confirm Changes"
      >
        <Modal.Body>
          {error ? <ModalErrorMessage errorMessage={error} /> : null}
          <p>The following changes will be made:</p>
          {getChangesSummary({
            resourceUsersToRemove,
            resourceUsersToUpdate,
            groupUsersToRemove,
            groupUsersToUpdate,
            resourceNewAccessDurationMin,
            groupNewAccessDurationMin,
            userById,
            resourceById,
            groupById,
          })}
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Yes, proceed"
          onPrimaryButtonClick={handleConfirm}
          secondaryButtonLabel="No, return to edit"
          onSecondaryButtonClick={() => {
            setShowConfirmModal(false);
            logEvent({
              name: "viz_confirm_modal_back_click",
              properties: {
                numResourceUsersToRemove: resourceUsersToRemove.length,
                numResourceUsersToUpdate: resourceUsersToUpdate.length,
                numGroupUsersToRemove: groupUsersToRemove.length,
                numGroupUsersToUpdate: groupUsersToUpdate.length,
              },
            });
          }}
        />
      </Modal>
    </>
  );
};

interface ChangesSummaryParams {
  resourceUsersToUpdate: UpdateResourceUserInput[];
  resourceUsersToRemove: RemoveResourceUserInput[];
  groupUsersToUpdate: UpdateGroupUserInput[];
  groupUsersToRemove: RemoveGroupUserInput[];
  userById: { [userId: string]: UserPreviewSmallFragment };
  resourceById: { [resourceId: string]: ResourcePreviewLargeFragment };
  groupById: { [groupId: string]: GroupPreviewSmallFragment };
  resourceNewAccessDurationMin?: number;
  groupNewAccessDurationMin?: number;
}

interface UserData {
  type: "user";
  resourcesToRemove: RemoveResourceUserInput[];
  resourcesToUpdate: UpdateResourceUserInput[];
  groupsToRemove: RemoveGroupUserInput[];
  groupsToUpdate: UpdateGroupUserInput[];
}

interface GroupData {
  type: "group";
  usersToRemove: RemoveGroupUserInput[];
  usersToUpdate: UpdateGroupUserInput[];
}

interface ResourceData {
  type: "resource";
  usersToRemove: RemoveResourceUserInput[];
  usersToUpdate: UpdateResourceUserInput[];
}

interface NeighborsData {
  [nodeId: string]: UserData | ResourceData | GroupData;
}

const getChangesSummary = ({
  resourceUsersToRemove,
  resourceUsersToUpdate,
  groupUsersToUpdate,
  groupUsersToRemove,
  userById,
  resourceById,
  groupById,
  resourceNewAccessDurationMin,
  groupNewAccessDurationMin,
}: ChangesSummaryParams) => {
  const neighborsMap: NeighborsData = {};
  const allNodeIdsToUpdate = new Set<string>();
  const allUserIds = new Set<string>();
  const allGroupIds = new Set<string>();
  const allResourceRoleIds = new Set<string>();

  resourceUsersToUpdate.forEach((ru) => {
    if (ru.userId in neighborsMap) {
      const userData = neighborsMap[ru.userId];
      if (userData.type === "user") {
        userData.resourcesToUpdate.push(ru);
      }
    } else {
      neighborsMap[ru.userId] = {
        type: "user",
        resourcesToRemove: [],
        resourcesToUpdate: [ru],
        groupsToRemove: [],
        groupsToUpdate: [],
      };
    }

    const resourceRoleId = makeRolelNodeId(ru.resourceId, ru.accessLevel);
    if (resourceRoleId in neighborsMap) {
      const resourceData = neighborsMap[resourceRoleId];
      if (resourceData.type === "resource") {
        resourceData.usersToUpdate.push(ru);
      }
    } else {
      neighborsMap[resourceRoleId] = {
        type: "resource",
        usersToUpdate: [ru],
        usersToRemove: [],
      };
    }

    allNodeIdsToUpdate.add(ru.userId);
    allNodeIdsToUpdate.add(resourceRoleId);
    allUserIds.add(ru.userId);
    allResourceRoleIds.add(resourceRoleId);
  });

  resourceUsersToRemove.forEach((ru) => {
    if (ru.userId in neighborsMap) {
      const userData = neighborsMap[ru.userId];
      if (userData.type === "user") {
        userData.resourcesToRemove.push(ru);
      }
    } else {
      neighborsMap[ru.userId] = {
        type: "user",
        resourcesToRemove: [ru],
        resourcesToUpdate: [],
        groupsToRemove: [],
        groupsToUpdate: [],
      };
    }

    const resourceRoleId = makeRolelNodeId(ru.resourceId, ru.accessLevel);
    if (resourceRoleId in neighborsMap) {
      const resourceData = neighborsMap[resourceRoleId];
      if (resourceData.type === "resource") {
        resourceData.usersToRemove.push(ru);
      }
    } else {
      neighborsMap[resourceRoleId] = {
        type: "resource",
        usersToUpdate: [],
        usersToRemove: [ru],
      };
    }

    allNodeIdsToUpdate.add(ru.userId);
    allNodeIdsToUpdate.add(resourceRoleId);
    allUserIds.add(ru.userId);
    allResourceRoleIds.add(resourceRoleId);
  });

  groupUsersToUpdate.forEach((gu) => {
    if (gu.userId in neighborsMap) {
      const userData = neighborsMap[gu.userId];
      if (userData.type === "user") {
        userData.groupsToUpdate.push(gu);
      }
    } else {
      neighborsMap[gu.userId] = {
        type: "user",
        resourcesToRemove: [],
        resourcesToUpdate: [],
        groupsToRemove: [],
        groupsToUpdate: [gu],
      };
    }

    if (gu.groupId in neighborsMap) {
      const groupData = neighborsMap[gu.groupId];
      if (groupData.type === "group") {
        groupData.usersToUpdate.push(gu);
      }
    } else {
      neighborsMap[gu.groupId] = {
        type: "group",
        usersToRemove: [],
        usersToUpdate: [gu],
      };
    }

    allNodeIdsToUpdate.add(gu.userId);
    allNodeIdsToUpdate.add(gu.groupId);
    allUserIds.add(gu.userId);
    allGroupIds.add(gu.groupId);
  });

  groupUsersToRemove.forEach((gu) => {
    if (gu.userId in neighborsMap) {
      const userData = neighborsMap[gu.userId];
      if (userData.type === "user") {
        userData.groupsToRemove.push(gu);
      }
    } else {
      neighborsMap[gu.userId] = {
        type: "user",
        resourcesToRemove: [],
        resourcesToUpdate: [],
        groupsToRemove: [gu],
        groupsToUpdate: [],
      };
    }

    if (gu.groupId in neighborsMap) {
      const groupData = neighborsMap[gu.groupId];
      if (groupData.type === "group") {
        groupData.usersToRemove.push(gu);
      }
    } else {
      neighborsMap[gu.groupId] = {
        type: "group",
        usersToRemove: [gu],
        usersToUpdate: [],
      };
    }

    allNodeIdsToUpdate.add(gu.userId);
    allNodeIdsToUpdate.add(gu.groupId);
    allUserIds.add(gu.userId);
    allGroupIds.add(gu.groupId);
  });

  const groupBy =
    allUserIds.size < allGroupIds.size + allResourceRoleIds.size
      ? "user"
      : "resource,group";

  const results: JSX.Element[] = [];
  if (groupBy === "user") {
    for (let userId of Array.from(allUserIds)) {
      const changes = neighborsMap[userId];
      if (changes?.type !== "user") {
        continue;
      }
      const {
        resourcesToRemove,
        resourcesToUpdate,
        groupsToRemove,
        groupsToUpdate,
      } = changes;

      const user = userById[userId];
      if (!user) {
        continue;
      }

      const resourcesToChange = [...resourcesToRemove, ...resourcesToUpdate];
      const groupsToChange = [...groupsToRemove, ...groupsToUpdate];

      const summary = (
        <div key={userId} className={sprinkles({ marginBottom: "xs" })}>
          <Divider />
          <UserPill user={user} />
          {resourcesToChange.length > 0 ? (
            <div>
              {resourcesToRemove.length > 0 ? (
                <RevokedLabel />
              ) : (
                <ConvertLabel accessDuration={resourceNewAccessDurationMin} />
              )}
              <DataElementList>
                {resourcesToChange.map((resourceUser) => {
                  const resource = resourceById[resourceUser.resourceId];
                  if (!resource) return null;
                  return (
                    <ResourcePill
                      role={resourceUser.accessLevel}
                      resource={resource}
                    />
                  );
                })}
              </DataElementList>
            </div>
          ) : null}

          {groupsToChange.length > 0 ? (
            <div>
              {groupsToRemove.length > 0 ? (
                <RemovedLabel
                  hasOtherChanges={
                    resourcesToRemove.length + resourcesToUpdate.length > 0
                  }
                />
              ) : (
                <ConvertLabel
                  accessDuration={groupNewAccessDurationMin}
                  hasOtherChanges={
                    resourcesToRemove.length + resourcesToUpdate.length > 0
                  }
                />
              )}
              <DataElementList>
                {groupsToChange.map((groupUser) => {
                  const group = groupById[groupUser.groupId];
                  if (!group) return null;
                  return <GroupPill group={group} />;
                })}
              </DataElementList>
            </div>
          ) : null}
        </div>
      );

      results.push(summary);
    }
  } else {
    for (let resourceRolelId of Array.from(allResourceRoleIds)) {
      const changes = neighborsMap[resourceRolelId];
      if (changes.type !== "resource") {
        continue;
      }

      const usersToChange = [
        ...changes.usersToRemove,
        ...changes.usersToUpdate,
      ];
      if (usersToChange.length === 0) {
        continue;
      }

      const resourceId = usersToChange[0].resourceId;
      const role = usersToChange[0].accessLevel;

      const resource = resourceById[resourceId];
      if (!resource) {
        continue;
      }

      const summary = (
        <div
          key={resourceRolelId}
          className={sprinkles({ marginBottom: "xs" })}
        >
          <Divider />
          <DataElementList>
            {usersToChange.map((resourceUser) => {
              const user = userById[resourceUser.userId];
              if (!user) return null;
              return <UserPill user={user} />;
            })}
          </DataElementList>
          {changes.usersToRemove.length > 0 ? (
            <RevokedLabel />
          ) : (
            <ConvertLabel accessDuration={resourceNewAccessDurationMin} />
          )}
          <ResourcePill resource={resource} role={role} />
        </div>
      );

      results.push(summary);
    }

    for (let groupId of Array.from(allGroupIds)) {
      const changes = neighborsMap[groupId];
      if (changes.type !== "group") {
        continue;
      }

      const usersToChange = [
        ...changes.usersToRemove,
        ...changes.usersToUpdate,
      ];
      if (usersToChange.length === 0) {
        continue;
      }

      const group = groupById[groupId];
      if (!group) {
        continue;
      }

      const summary = (
        <div key={groupId} className={sprinkles({ marginBottom: "xs" })}>
          <Divider />
          <DataElementList>
            {usersToChange.map((groupUser) => {
              const user = userById[groupUser.userId];
              if (!user) return null;
              return <UserPill user={user} />;
            })}
          </DataElementList>
          {changes.usersToRemove.length > 0 ? (
            <RemovedLabel />
          ) : (
            <ConvertLabel accessDuration={groupNewAccessDurationMin} />
          )}
          <GroupPill group={group} />
        </div>
      );

      results.push(summary);
    }
  }

  return results;
};

const UserPill = ({ user }: { user: UserPreviewSmallFragment }) => {
  return (
    <PillV3
      key={user.id}
      keyText={user.fullName}
      pillColor="DeepOrange"
      icon={{
        type: "name",
        icon: "user",
      }}
    />
  );
};

const ResourcePill = ({
  resource,
  role: role,
}: {
  resource: ResourcePreviewLargeFragment;
  role: ResourceAccessLevel;
}) => {
  let resourceLabel = resource.name;
  if (role.accessLevelName) {
    resourceLabel += ":" + role.accessLevelName;
  }
  return (
    <PillV3
      key={resource.id}
      keyText={resourceLabel}
      pillColor="Pink"
      icon={{
        type: "name",
        icon: "cube",
      }}
    />
  );
};

const GroupPill = ({ group }: { group: GroupPreviewSmallFragment }) => {
  return (
    <PillV3
      key={group.id}
      keyText={group.name}
      pillColor="Teal"
      icon={{
        type: "name",
        icon: "users",
      }}
    />
  );
};

const RevokedLabel = () => {
  return (
    <div className={sprinkles({ marginTop: "sm", marginBottom: "sm" })}>
      will have access{" "}
      <span className={sprinkles({ fontWeight: "semibold" })}>revoked</span>{" "}
      from
    </div>
  );
};

const RemovedLabel = ({ hasOtherChanges }: { hasOtherChanges?: boolean }) => {
  return (
    <div className={sprinkles({ marginTop: "sm", marginBottom: "sm" })}>
      {hasOtherChanges ? "and " : ""}will be{" "}
      <span className={sprinkles({ fontWeight: "semibold" })}>removed</span>
      {` from the group(s)`}
    </div>
  );
};

const ConvertLabel = ({
  accessDuration,
  hasOtherChanges,
}: {
  accessDuration?: number;
  hasOtherChanges?: boolean;
}) => {
  return (
    <div className={sprinkles({ marginTop: "sm", marginBottom: "sm" })}>
      {hasOtherChanges ? "and " : ""}will have{" "}
      <span className={sprinkles({ fontWeight: "semibold" })}>
        {minutesToExpirationValue(accessDuration ?? null)}
      </span>{" "}
      of access to
    </div>
  );
};

export default ActionsSidebar;
