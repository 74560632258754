import { Maybe, ServiceType } from "api/generated/graphql";
import {
  DirectoryListingEntries,
  DirectoryListingEntryInfo,
} from "components/directory_listing/DirectoryListing";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import {
  ResourceIndividualRoleAutocompleteData,
  ResourceIndividualRoleCheckedData,
  Role,
  RoleData,
} from "components/modals/ResourceIndividualRoleModal";
import selectItemsModalStyles from "components/modals/update/SelectItemsModal.module.scss";
import { Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React, { ReactElement, useEffect, useState } from "react";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { ExpirationValue } from "views/requests/utils";

export enum SelectType {
  Add,
  Delete,
  Remove,
  Edit,
}

export type IdInfo = {
  id: string;
};

type SelectItemsDropdownModalProps = {
  title: string;
  subtitle?: string;
  sectionTitle?: string;
  selectType: SelectType;
  entryInfos: DirectoryListingEntryInfo[];
  itemName: string;
  entityType: EntityTypeDeprecated;
  serviceType: ServiceType | undefined;
  idInfosToUpdate: IdInfo[];
  setIdInfosToUpdate: (ids: IdInfo[]) => void;
  onDeleteId?: (id: IdInfo["id"]) => void;
  currentIdInfos: IdInfo[];
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  errorMessage?: Maybe<string>;
  /** Uses a custom search, like a paginated users component that hydrates its own user data. */
  customSearchComponent?: ReactElement;
  secondaryButton?: ReactElement;

  existingDirectRolesByEntityId?: Record<string, Role[]>;
  updatedRolesByEntityId?: Record<string, Role[]>;
  setUpdatedRolesByEntityId?: (newMap: Record<string, Role[]>) => void;
  availableRoles?: Role[] | undefined;

  updatedAccessDurationsByEntityId?: Record<string, ExpirationValue>;
  setUpdatedAccessDurationsByEntityId?: (
    newMap: Record<string, ExpirationValue>
  ) => void;
};

export const AddGroupUsersModal = (props: SelectItemsDropdownModalProps) => {
  const [checkedDataItems, setCheckedDataItems] = useState<
    ResourceIndividualRoleCheckedData[]
  >([]);

  const hasShowItemUserUploader = useFeatureFlag(
    FeatureFlag.ShowItemUserUploader
  );

  const infoById: Record<string, IdInfo> = {};
  props.idInfosToUpdate.forEach((info) => {
    infoById[info.id] = info;
  });

  const currentInfoById: Record<string, IdInfo> = {};
  props.currentIdInfos.forEach((info) => {
    currentInfoById[info.id] = info;
  });

  // Jank -- initialize with role data for usage with external search
  // component. This is needed to show the access expiration dropdown by
  // default.
  useEffect(() => {
    props.idInfosToUpdate.forEach((info) => {
      if (
        checkedDataItems.some(
          (checkedDataItem) => checkedDataItem.entityId === info.id
        )
      ) {
        return;
      }
      addRoleData([
        {
          entityId: info.id,
          selectType: SelectType.Add,
          rolesForResource: props.availableRoles || [],
        },
      ]);
    });
  }, [props.idInfosToUpdate, checkedDataItems, props.availableRoles]);

  const addRoleData = (selected: ResourceIndividualRoleCheckedData[]) => {
    setCheckedDataItems((roleData) => {
      return [...roleData, ...selected];
    });
  };

  const autocompleteData: ResourceIndividualRoleAutocompleteData = {
    updatedAccessDurationsByEntityId: props.updatedAccessDurationsByEntityId,
    setUpdatedAccessDurationsByEntityId:
      props.setUpdatedAccessDurationsByEntityId,
    updatedRolesByEntityId: props.updatedRolesByEntityId,
    setUpdatedRolesByEntityId: props.setUpdatedRolesByEntityId,
    existingDirectRolesByEntityId: props.existingDirectRolesByEntityId,
    serviceType: props.serviceType,
  };

  const roleData: RoleData = {
    autocompleteData: autocompleteData,
    checkedDataItems: checkedDataItems,
  };

  const requireRoles =
    (!!props.availableRoles?.length &&
      props.updatedRolesByEntityId !== undefined &&
      Object.keys(props.updatedRolesByEntityId).length === 0) ??
    false;

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={props.title}
    >
      <Modal.Body>
        {hasShowItemUserUploader ? (
          <div
            className={sprinkles({
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "md",
            })}
          >
            {props.secondaryButton}
          </div>
        ) : null}
        {props.customSearchComponent}
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
        <div className={selectItemsModalStyles.filler} />
        <div className={selectItemsModalStyles.modalFieldContainer}>
          {props.entryInfos.length > 0 ? (
            <DirectoryListingEntries
              entryInfos={props.entryInfos}
              roleData={roleData}
              onDeleteEntry={(entry) =>
                props.onDeleteId &&
                entry.entityId.entityId &&
                props.onDeleteId(entry.entityId.entityId)
              }
            />
          ) : (
            <div
              className={selectItemsModalStyles.emptyState}
            >{`Select ${pluralize(props.itemName, 2, false)} to add.`}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={`Add ${pluralize(
          props.itemName,
          props.idInfosToUpdate.length,
          props.idInfosToUpdate.length > 0
        )}`}
        primaryButtonDisabled={
          props.idInfosToUpdate.length === 0 || requireRoles
        }
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLoading={props.loading}
        secondaryButtonLabel="Close"
        onSecondaryButtonClick={props.onClose}
      />
    </Modal>
  );
};

AddGroupUsersModal.propTypes = {
  idInfosToUpdate: PropTypes.arrayOf(PropTypes.string),
  setIdInfosToUpdate: PropTypes.func,
};

export default AddGroupUsersModal;
