import {
  EntityType,
  GroupPreviewLargeFragment,
  GroupUserFragment,
  GroupUserSource,
  HrIdpStatus,
} from "api/generated/graphql";
import {
  ResourceLabel,
  TimeLabel,
  timeValueIndefinite,
} from "components/label/Label";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { Icon } from "components/ui";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { PropagationType } from "utils/useRemediations";
import GroupUserSourceLabel, {
  getGroupUserSourceSortValue,
} from "views/groups/GroupUserSourceLabel";

import { groupTypeHasRoles } from "../../utils/directory/groups";

interface GroupsUserTableRow {
  name: string;
  email: string;
  role: string;
  source: string;
  expires: string;
  status: string;
}

type GroupUsersTableProps = {
  group: GroupPreviewLargeFragment;
  users: GroupUserFragment[];
};

export const GroupUsersTable = (props: GroupUsersTableProps) => {
  const history = useHistory();

  const headers: Header<GroupsUserTableRow>[] = [
    { id: "name", label: "Name", width: 200, minWidth: 165 },
    { id: "email", label: "Email", width: 200, minWidth: 165 },
  ];

  if (groupTypeHasRoles(props.group.groupType)) {
    headers.push({
      id: "role",
      label: "Role",
      width: 200,
      minWidth: 180,
    });
  }

  headers.push(
    { id: "source", label: "Source of Access", width: 150, minWidth: 150 },
    { id: "expires", label: "Expires", width: 120, minWidth: 100 },
    { id: "status", label: "Status", width: 80, minWidth: 80 }
  );

  const rows: CellRow<GroupsUserTableRow>[] = props.users.map((groupUser) => {
    const user = groupUser.user;
    const userId = groupUser.userId;
    const name = user?.fullName || userId;

    const latestExpiringAccessPoint =
      groupUser.access?.latestExpiringAccessPoint;

    const expirationTime = latestExpiringAccessPoint?.expiration
      ? moment(new Date(latestExpiringAccessPoint.expiration))
      : null;
    const supportTicket = latestExpiringAccessPoint?.supportTicket;

    const rowId = userId;

    return {
      id: rowId,
      data: {
        name: {
          value: name,
          element: (
            <ResourceLabel
              key={userId}
              text={name}
              subText={user?.teamAttr}
              iconLarge={true}
              bold={true}
              entityType={EntityTypeDeprecated.User}
              avatar={user?.avatarUrl}
              pointerCursor={true}
              warningIcon={
                user?.hrIdpStatus !== HrIdpStatus.Active ? (
                  <Icon name="user-x" size="xxs" color="red600" />
                ) : undefined
              }
              warningTooltipText={
                user?.hrIdpStatus !== HrIdpStatus.Active
                  ? `User IDP status is "${_.startCase(
                      _.camelCase(user?.hrIdpStatus?.toString() ?? "Unknown")
                    )}"`
                  : undefined
              }
              inactive={user?.hrIdpStatus !== HrIdpStatus.Active}
            />
          ),
          clickHandler: () => {
            history.push(
              getResourceUrlNew({
                entityId: user?.id ?? null,
                entityType: EntityType.User,
              })
            );
          },
        },
        email: {
          value: groupUser.user?.email ?? "",
          element: <div>{groupUser.user?.email ?? "--"}</div>,
        },
        source: {
          value: groupUser.source,
          sortValue: getGroupUserSourceSortValue(groupUser.source),
          element: <GroupUserSourceLabel source={groupUser.source} />,
        },
        role: {
          value:
            groupUser.access?.directAccessPoint?.accessLevel?.accessLevelName ||
            "--",
          element: (
            <div>
              {groupUser.access?.directAccessPoint?.accessLevel
                ?.accessLevelName || "--"}
            </div>
          ),
        },
        expires: {
          value: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={supportTicket}
              useExpiringLabel
              isOncallAccess={groupUser.source === GroupUserSource.OnCall}
            />
          ),
        },
        status: {
          value: groupUser.userId,
          sortValue: groupUser.propagationStatus?.statusCode,
          element: (
            <PropagationStatusLabelWithModal
              propagationType={PropagationType.GroupUser}
              propagationStatus={groupUser.propagationStatus}
              isAccessReview={false}
              entityInfo={{
                user: groupUser.user,
                group: props.group,
                lastExpiringAccessPointExpiration:
                  groupUser.access?.latestExpiringAccessPoint.expiration,
              }}
            />
          ),
        },
      },
    };
  });

  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"name"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export default GroupUsersTable;
