import { Label } from "components/ui";
import { IconData } from "components/ui/utils";
import pluralize from "pluralize";
import { useState } from "react";

import * as styles from "./AccessRequestCountDisplay.css";

export type AccessRequestCountDisplayProps = {
  approvedCount: number;
  deniedCount: number;
  orgUsersCount: number;
  groupsCount: number;
  groups?: {
    key: string;
    label: string;
    icon: IconData;
  }[];
};

export const AccessRequestCountDisplay = (
  props: AccessRequestCountDisplayProps
) => {
  const [showGroups, setShowGroups] = useState(false);

  return (
    <div className={styles.resourceInfoContainer}>
      <Label
        icon={{
          type: "name",
          icon: props.approvedCount > 0 ? "check-circle" : "x-circle",
        }}
        iconColor={props.approvedCount > 0 ? "green600V3" : "red600"}
        color="gray700"
        label={`You have
          ${
            props.approvedCount > 0
              ? ` been approved for this ${props.approvedCount} ${pluralize(
                  "time",
                  props.approvedCount
                )}`
              : " never been approved for this"
          }`}
        iconSize="xs"
        truncateLength={null}
      />
      <Label
        icon={{
          type: "name",
          icon: props.deniedCount === 0 ? "check-circle" : "x-circle",
        }}
        label={`You have
        ${
          props.deniedCount > 0
            ? ` been denied access to this ${props.deniedCount} ${pluralize(
                "time",
                props.deniedCount
              )}`
            : " never been denied access to this"
        }`}
        color="gray700"
        iconColor={props.deniedCount === 0 ? "green600V3" : "red600"}
        iconSize="xs"
        truncateLength={null}
      />
      <Label
        icon={{ type: "name", icon: "users" }}
        color="gray700"
        iconSize="xs"
        label={`${props.orgUsersCount} ${pluralize(
          "person",
          props.orgUsersCount
        )} in your org use this`}
        truncateLength={null}
      />
      <div className={styles.groupsLabelContainer}>
        <Label
          icon={{ type: "name", icon: "users" }}
          color="gray700"
          iconSize="xs"
          label={`This is available in ${props.groupsCount} ${pluralize(
            "group",
            props.groupsCount
          )}`}
          truncateLength={null}
        />
        {!showGroups && props.groups && props.groups?.length !== 0 && (
          <span
            onClick={() => setShowGroups(!showGroups)}
            className={styles.toggleGroups}
          >
            Show All
          </span>
        )}
      </div>
      {showGroups && (
        <>
          <div className={styles.groupsContainer}>
            {props.groups?.map((group) => (
              <Label {...group} linkTo={`/groups/${group.key}`} />
            ))}
          </div>
          <span
            className={styles.toggleGroups}
            onClick={() => setShowGroups(!showGroups)}
          >
            Hide All
          </span>
        </>
      )}
    </div>
  );
};
