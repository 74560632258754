import {
  PropagationStatusCode,
  PushTaskCompleteFragment,
  useConnectionUsersRowQuery,
  useGroupQuery,
  usePushTaskQuery,
  useResourceQuery,
  useUserQuery,
} from "api/generated/graphql";
import AuthContext, {
  AuthContextActionType,
} from "components/auth/AuthContext";
import { useToast } from "components/toast/Toast";
import { useCallback, useContext } from "react";
import { logError } from "utils/logging";

type PollPushTaskOptions = {
  // Ids to refetch when the push task is complete. Refetching refreshes the
  // Apollo cache for an item, causing it to be updated anywhere in the UI.
  refetchOnComplete?: RefetchIds;
  // Function to be called when the task is complete.
  onComplete?: (pushTask: PushTaskCompleteFragment) => void;
  // Overrides for the messages in the toasts.
  messageOverride?: PollPushTaskMessageOverride;
};

type RefetchIds = {
  resourceId?: string;
  groupId?: string;
  connectionId?: string;
  userId?: string;
};

type PollPushTaskMessageOverride = {
  // Pass in null to disable the loading toast
  loading?: string | null;
  success?: string;
  propagation?: string;
  error?: string;
};

export type HasStatusCode = {
  statusCode: PropagationStatusCode;
};

/**
 * Returns a function `startPushTaskPoll` that, when called, polls for the status
 * of a push task until the task is complete.
 */
export const usePushTaskLoader = () => {
  const { authDispatch } = useContext(AuthContext);

  const { refetch } = usePushTaskQuery({
    // We only want to make this query when calling refetch.
    skip: true,
  });

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
    clearToast,
  } = useToast();

  const { refetch: refetchResource } = useResourceQuery({ skip: true });
  const { refetch: refetchGroup } = useGroupQuery({ skip: true });
  const { refetch: refetchConnection } = useConnectionUsersRowQuery({
    skip: true,
  });
  const { refetch: refetchUser } = useUserQuery({ skip: true });

  // Called when the push task is complete.
  const callbackOnComplete = useCallback(
    async (
      pushTask: PushTaskCompleteFragment,
      options?: PollPushTaskOptions
    ) => {
      // If requested, always refetch the item. Even if there are some errors, other
      // items may have succeeded, requiring a UI update.
      if (options?.refetchOnComplete) {
        if (options.refetchOnComplete.resourceId) {
          await refetchResource({
            input: { id: options.refetchOnComplete.resourceId },
          });
        }
        if (options.refetchOnComplete.groupId) {
          await refetchGroup({
            input: { id: options.refetchOnComplete.groupId },
          });
        }
        if (options.refetchOnComplete.connectionId) {
          await refetchConnection({
            input: { id: options.refetchOnComplete.connectionId },
          });
        }
        if (options.refetchOnComplete.userId) {
          await refetchUser({
            input: { id: options.refetchOnComplete.userId },
          });
        }
      }

      authDispatch({
        type: AuthContextActionType.AccessChanged,
        payload: {
          user: pushTask.authUser,
        },
      });

      // Show the appropriate toast
      const statuses: HasStatusCode[] = pushTask.result.propStatuses;
      const allSuccessful = statuses.every((status) => {
        return status.statusCode === PropagationStatusCode.Success;
      });
      const someManualPropagation = statuses.some(
        (status) =>
          status.statusCode === PropagationStatusCode.PendingManualPropagation
      );
      if (allSuccessful) {
        displaySuccessToast(
          options?.messageOverride?.success ||
            `Successfully propagated all access changes`
        );
      } else if (someManualPropagation) {
        displaySuccessToast(
          options?.messageOverride?.propagation ||
            `Sent notification for access to be changed`
        );
      } else {
        displayErrorToast(
          options?.messageOverride?.error ||
            `Error: some access changes failed to propagate`
        );
      }
    },
    [
      displaySuccessToast,
      displayErrorToast,
      refetchResource,
      refetchGroup,
      refetchConnection,
      refetchUser,
      authDispatch,
    ]
  );
  const pollPushTaskStatus = useCallback(
    async (
      taskId: string,
      waitInterval: number,
      totalWaited: number,
      options?: PollPushTaskOptions
    ) => {
      try {
        const result = await refetch({
          input: {
            id: taskId,
          },
        });
        if (!result) {
          return;
        }
        const { data: pushTaskData } = result;

        if (pushTaskData) {
          switch (pushTaskData?.pushTask.__typename) {
            case "PushTaskResult": {
              const pushTask = pushTaskData.pushTask.pushTask;
              switch (pushTask.__typename) {
                case "PushTaskPending": {
                  // If in progress, keep polling to check for sync task completion
                  setTimeout(() => {
                    pollPushTaskStatus(
                      taskId,
                      Math.min(2000, waitInterval * 2),
                      totalWaited + waitInterval,
                      options
                    );
                  }, waitInterval);
                  break;
                }
                case "PushTaskComplete": {
                  await callbackOnComplete(pushTask, options);
                  options?.onComplete && options.onComplete(pushTask);
                  break;
                }
              }
              break;
            }
            case "PushTaskNotFoundError":
              logError(new Error(`Error: push task not found`));
              displayErrorToast(`Error polling for propagation status`);
              break;
            default:
              logError(new Error(`Error polling for propagation status`));
              displayErrorToast(`Error polling for propagation status`);
              break;
          }
        }
      } catch (error) {
        logError(error, `Error polling for propagation status`);
        displayErrorToast(`Error polling for propagation status`);
      }
    },
    [refetch, callbackOnComplete, displayErrorToast]
  );

  const startPushTaskPoll = useCallback(
    (taskId: string, options?: PollPushTaskOptions) => {
      if (!taskId) {
        // If there is no task, we don't know whether the propagation succeeded or
        // failed - some resolvers may return no task upon error (e.g. read-only mode),
        // while others may upon success (e.g. noPush=true). Since we don't know the
        // outcome, plus these cases are rare, we'll just dismiss the loading toast and
        // let users use the new UI status to interpret the results.
        clearToast();
        return;
      }

      // This isn't very descriptive, but that's OK since the user just took a very
      // specific action and should know the context of what they just did.
      if (options?.messageOverride?.loading !== null) {
        displayLoadingToast(
          options?.messageOverride?.loading || `Propagating access changes...`
        );
      }

      setTimeout(async () => {
        await pollPushTaskStatus(taskId, 500, 250, options);
      }, 250);
    },
    [pollPushTaskStatus, displayLoadingToast, clearToast]
  );

  return startPushTaskPoll;
};
