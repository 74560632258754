import {
  ConnectionPreviewSmallFragment,
  EntityFragment,
  EntityType,
  GroupPreviewSmallFragment,
  Maybe,
  PrincipalFragment,
  ResourcePreviewSmallFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import entityViewerStyles from "components/entity_viewer/EntityViewer.module.scss";
import {
  EntityViewerTabContent,
  EntityViewerTabInfo,
  EntityViewerTabs,
  EntityViewerTabType,
} from "components/entity_viewer/EntityViewerTabs";
import { ResourceLabel } from "components/label/Label";
import { Banner, ContextMenu, Icon } from "components/ui";
import { SectionEntry } from "components/viewer/Viewer";
import viewerStyles from "components/viewer/Viewer.module.scss";
import _ from "lodash";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";

type MenuOption = {
  label: string;
  handler: () => void;
  warning?: boolean;
  disabled?: boolean;
  hide?: boolean;
};

export type ViewerContent = string | string[] | ReactElement;

type TitleInfo = {
  title: string | ReactElement;
};

export type EntityViewerProps = {
  title?: ReactElement;
  titleInfo?: TitleInfo;
  tabInfos?: EntityViewerTabInfo[];
  selectedTab?: EntityViewerTabType;
  setSelectedTab?: (tab: EntityViewerTabType) => void;
  button?: ReactElement;
  bannerMessage?: string;
};

const EntityViewer = (props: EntityViewerProps): ReactElement => {
  const location = useLocation();

  const [isScrollAtTop, setIsScrollAtTop] = useState(true);

  return (
    <div className={entityViewerStyles.entityViewer}>
      <div>
        {(props.title || props.titleInfo?.title) && (
          <EntityViewerHeader
            title={
              props.title ? (
                props.title
              ) : (
                <EntityViewerTitle title={props.titleInfo!.title} />
              )
            }
            button={props.button}
            isScrollAtTop={isScrollAtTop}
          />
        )}
        {props.bannerMessage ? (
          <div className={entityViewerStyles.banner}>
            <Banner message={props.bannerMessage} />
          </div>
        ) : null}
        {props.tabInfos && (
          <EntityViewerTabs
            tabInfos={props.tabInfos!}
            selectedTab={props.selectedTab!}
            setSelectedTab={props.setSelectedTab!}
          />
        )}
      </div>
      {props.selectedTab === location.hash.substring(1) && (
        <EntityViewerContent
          content={
            <EntityViewerTabContent
              tabInfos={props.tabInfos!}
              selectedTab={props.selectedTab!}
              setSelectedTab={props.setSelectedTab!}
            />
          }
          setIsScrollAtTop={setIsScrollAtTop}
        />
      )}
    </div>
  );
};

type EntityViewerHeaderProps = {
  title: ReactElement;
  button?: ReactElement;
  isScrollAtTop: boolean;
};

const EntityViewerHeader = (props: EntityViewerHeaderProps) => {
  return (
    <div className={entityViewerStyles.header}>
      {props.title}
      <div className={entityViewerStyles.actionsRight}>{props.button}</div>
    </div>
  );
};

export type EntityViewerTitleProps = {
  title: string | ReactElement;
  subtitle?: string | ReactElement;
  editor?: ReactElement;
  adminEditor?: ReactElement;
  modals?: ReactElement[];
};

export const EntityViewerTitle = (props: EntityViewerTitleProps) => {
  const { authState } = useContext(AuthContext);

  return (
    <div className={entityViewerStyles.actionsLeft}>
      <div className={entityViewerStyles.entityType}>
        <div className={entityViewerStyles.titleHeader}>
          <div className={entityViewerStyles.titleText}>{props.title}</div>
          <div className={entityViewerStyles.subTitleText}>
            {props.subtitle}
          </div>
        </div>
        {authState.user?.isAdmin && props.adminEditor}
        <div className={entityViewerStyles.editor}>{props.editor}</div>
        {props.modals}
      </div>
    </div>
  );
};

type EntityViewerContentProps = {
  content: ReactElement;
  setIsScrollAtTop: (isScrollAtTop: boolean) => void;
};

const EntityViewerContent = (props: EntityViewerContentProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const memoizedHandleScroll = useCallback(() => {
    if (ref.current) {
      const scrollTop = ref.current.scrollTop;

      if (scrollTop === 0) {
        props.setIsScrollAtTop(true);
      } else {
        props.setIsScrollAtTop(false);
      }
    }
  }, [props, ref]);

  useEffect(() => {
    window.addEventListener("scroll", _.debounce(memoizedHandleScroll));

    return () => {
      window.removeEventListener("scroll", () => memoizedHandleScroll);
    };
  }, [memoizedHandleScroll]);

  return (
    <div
      className={entityViewerStyles.viewerContent}
      onScroll={memoizedHandleScroll}
      ref={ref}
    >
      {props.content}
    </div>
  );
};

type EntityViewerRowProps = {
  title?: ReactElement | string;
  content: ReactElement | string;
  editor?: Maybe<ReactElement>;
  adminEditor?: ReactElement | false;
  checkbox?: ReactElement;
  modals?: ReactElement;
  isTable?: boolean;
  canEdit?: boolean;
};

export const EntityViewerRow = (props: EntityViewerRowProps) => {
  const { authState } = useContext(AuthContext);

  // TODO: remove hard-coded authState admin check
  const canEdit = props.canEdit || authState.user?.isAdmin;

  let title = props.title;
  if (props.isTable) {
    title = props.editor || (canEdit && props.adminEditor) ? "Edit" : undefined;
  }

  return (
    <div
      className={clsx({
        [entityViewerStyles.row]: !props.isTable,
        [entityViewerStyles.rowTable]: props.isTable,
      })}
    >
      <div
        className={clsx({
          [entityViewerStyles.rowContent]: true,
        })}
      >
        {title ? (
          <div
            className={clsx({
              [entityViewerStyles.rowHeader]: !props.isTable,
              [entityViewerStyles.rowHeaderTable]: props.isTable,
            })}
          >
            <div className={entityViewerStyles.editButton}>
              {title}
              {props.editor}
              {canEdit && props.adminEditor}
              {props.modals}
            </div>
            <div>{props.checkbox}</div>
          </div>
        ) : null}
        {Array.isArray(props.content)
          ? props.content.map((content, i) => (
              <div key={`rowContentSubRow_${i}`}>{content}</div>
            ))
          : props.content}
      </div>
    </div>
  );
};

type GenericEntityViewerRowProps = {
  entity:
    | ConnectionPreviewSmallFragment
    | ResourcePreviewSmallFragment
    | GroupPreviewSmallFragment
    | UserPreviewSmallFragment
    | EntityFragment
    | PrincipalFragment;
  title?: string;
};

export const GenericEntityViewerRow = (props: GenericEntityViewerRowProps) => {
  let label: ReactElement;
  switch (props.entity.__typename) {
    case "Connection":
      label = (
        <ResourceLabel
          text={props.entity.name}
          connectionType={props.entity.connectionType}
          entityTypeNew={EntityType.Connection}
        />
      );
      break;
    case "Resource":
      label = (
        <ResourceLabel
          text={props.entity.name}
          icon={props.entity.iconUrl}
          resourceType={props.entity.resourceType}
          entityTypeNew={EntityType.Resource}
        />
      );
      break;
    case "Group":
      label = (
        <ResourceLabel
          text={props.entity.name}
          groupType={props.entity.groupType}
          entityTypeNew={EntityType.Group}
        />
      );
      break;
    case "User":
      label = (
        <ResourceLabel
          text={props.entity.fullName}
          entityTypeNew={EntityType.User}
          avatar={props.entity.avatarUrl}
          entityId={
            "id" in props.entity ? props.entity.id : props.entity.userId
          }
        />
      );
      break;
    default:
      return null;
  }

  let title = props.title;
  if (!title) {
    title = props.entity.__typename;
    if (title === "Connection") {
      title = "App";
    }
  }

  return <SectionEntry title={title}>{label}</SectionEntry>;
};

type EditorProps = {
  menuOptions: MenuOption[];
  links?: ReactElement;
};

export const Editor = (props: EditorProps) => {
  const menuOptions = props.menuOptions
    .filter((opt) => !opt.hide)
    .map((opt) => ({
      label: opt.label,
      onClick: opt.handler,
      warning: opt.warning,
      disabled: opt.disabled,
    }));

  const renderButton = (onClick: (event: React.MouseEvent) => void) => {
    return (
      <div className={viewerStyles.editor}>
        {props.menuOptions.length > 0 && (
          <span onClick={onClick}>
            <Icon name="edit-2" size="sm" />
          </span>
        )}
        {props.links}
      </div>
    );
  };

  if (props.menuOptions.length === 1 && props.menuOptions[0].label === "Edit") {
    return renderButton(props.menuOptions[0].handler);
  }

  return <ContextMenu options={menuOptions} renderButton={renderButton} />;
};

export default EntityViewer;
