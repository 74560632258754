import clsx from "clsx";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";

import { ButtonV3, Checkbox, EntityIcon, Icon, Loader, Skeleton } from "..";
import { IconName } from "../icon/Icon";
import { IconData } from "../utils";
import * as styles from "./ListV3.css";

interface ListProps<Item> {
  items: Item[];
  loading?: boolean;
  // Used to determine a unique identifier for a given item.
  getItemKey: (option: Item) => string;

  // Props to render the list item
  getItemLabel: (option: Item) => string;
  getItemSublabel?: (option: Item) => string;
  getIcon?: (option: Item) => IconData | undefined;
  noItemsMessage?: string | React.ReactNode;
  getItemDisabled?: (option: Item) => boolean;
  getItemChecked?: (option: Item) => boolean;
  onItemChecked?: (option: Item, checked: boolean) => void;

  // Props to handle user interaction
  onSelectItem?: (item: Item) => void;
  getActionLabel?: (item: Item) => string;
  getActionIcon?: (item: Item) => IconName | undefined;

  // Props to handle nested items
  expandedItems?: string[];
  hasNestedItems?: (item: Item) => boolean;
  getNestedItems?: (item: Item) => Item[];
  getLoadingNestedItems?: (item: Item) => boolean;
}

const List = <Item extends {}>(props: ListProps<Item>) => {
  const renderIcon = (option: Item) => {
    if (!props.getIcon) return null;
    const iconData = props.getIcon(option);
    if (!iconData) return null;

    if (iconData.type === "name") {
      return (
        <div className={styles.icon({ iconStyle: "rounded" })}>
          <Icon name={iconData.icon} size="xs" />
        </div>
      );
    }

    if (iconData.type === "entity") {
      return (
        <div
          className={clsx(
            styles.icon({ iconStyle: "default" }),
            sprinkles({ marginRight: "sm" })
          )}
        >
          <EntityIcon type={iconData.entityType} />
        </div>
      );
    }

    if (!iconData.icon) return null;
    return (
      <div className={styles.icon({ iconStyle: "rounded" })}>
        <Icon externalIconUrl={iconData.icon} size="xs" />
      </div>
    );
  };

  const actionable = Boolean(props.onSelectItem);

  if (props.loading) {
    return (
      <>
        {_.times(10, () => (
          <Skeleton width="100%" height="52px" />
        ))}
      </>
    );
  }

  if (props.items.length === 0) {
    return (
      <div className={styles.container}>
        {props.noItemsMessage ?? "No items"}
      </div>
    );
  }

  const renderItem = (item: Item, depth: number): JSX.Element => {
    const key = props.getItemKey(item);
    const disabled = props.getItemDisabled && props.getItemDisabled(item);

    const handleClick = () => {
      if (!props.onSelectItem || disabled) {
        return;
      }
      if (props.getLoadingNestedItems && props.getLoadingNestedItems(item)) {
        return;
      }
      props.onSelectItem(item);
    };

    const hasNestedItems = props.hasNestedItems && props.hasNestedItems(item);
    const nestedItems = props.getNestedItems && props.getNestedItems(item);
    const isExpanded = props.expandedItems?.includes(key);
    let indentAmount = 20 + depth * 20;
    if (!hasNestedItems && depth > 0) {
      indentAmount += 28;
    }

    return (
      <>
        <div
          className={clsx(
            styles.item,
            actionable && !disabled ? styles.itemActionable : undefined,
            disabled ? styles.itemDisabled : undefined
          )}
          style={{
            paddingLeft: indentAmount,
          }}
          onClick={handleClick}
          key={key}
        >
          <div className={styles.leftContent}>
            {props.getItemChecked && (
              <div className={sprinkles({ marginRight: "md" })}>
                <Checkbox
                  checked={props.getItemChecked(item)}
                  onChange={(value) =>
                    props.onItemChecked && props.onItemChecked(item, value)
                  }
                />
              </div>
            )}
            {hasNestedItems && (
              <div
                className={sprinkles({
                  marginRight: "md",
                  display: "flex",
                })}
              >
                {props.getLoadingNestedItems &&
                props.getLoadingNestedItems(item) ? (
                  <Loader size="sm" />
                ) : (
                  <Icon
                    name={isExpanded ? "chevron-down" : "chevron-right"}
                    size="xs"
                  />
                )}
              </div>
            )}
            {renderIcon(item)}
            <div className={styles.label}>{props.getItemLabel(item)}</div>
            {props.getItemSublabel && (
              <div className={styles.subLabel}>
                {props.getItemSublabel(item)}
              </div>
            )}
          </div>
          {props.getActionLabel && props.getActionLabel(item) && (
            <div className={styles.actionButton}>
              <ButtonV3
                label={props.getActionLabel(item)}
                type="defaultSecondary"
                size="xs"
                leftIconName={
                  props.getActionIcon ? props.getActionIcon(item) : undefined
                }
              />
            </div>
          )}
        </div>
        {isExpanded &&
          nestedItems?.map((nestedItem) => {
            return renderItem(nestedItem, (depth ?? 0) + 1);
          })}
      </>
    );
  };

  return (
    <div className={styles.container}>
      {props.items.map((item) => renderItem(item, 0))}
    </div>
  );
};

export default List;
