import { getModifiedErrorMessage } from "api/ApiContext";
import {
  OwnerFragment,
  useOwnerQuery,
  UserPreviewSmallFragment,
  useUpdateOwnerUsersForOwnerMutation,
  useUsersQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { ColumnHeaderSkeleton } from "components/column/ColumnHeaderV3";
import FullscreenView from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import { Banner, Divider, Icon, Input } from "components/ui";
import List from "components/ui/list/ListV3";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./OwnerAddUsersView.css";

const OwnerAddUsersView = () => {
  const transitionBack = useTransitionBack();
  const { ownerId } = useParams<{ ownerId: string }>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [usersToAddByUserId, setUsersToAddByUserId] = useState<
    Record<string, UserPreviewSmallFragment>
  >({});
  const [addUsersErrorMessage, setAddUsersErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateOwnerUsersForOwnerMutation,
    { loading: addUsersLoading },
  ] = useUpdateOwnerUsersForOwnerMutation();

  const { data, error, loading } = useOwnerQuery({
    variables: { input: { id: ownerId } },
    skip: !ownerId,
  });

  let owner: OwnerFragment | undefined = undefined;
  let ownerNotFound = false;
  if (data) {
    switch (data.owner.__typename) {
      case "OwnerResult":
        owner = data.owner.owner;
        break;
      case "OwnerNotFoundError":
        ownerNotFound = true;
        break;
      default:
        logError(new Error(`failed to list owner`));
    }
  } else if (error) {
    logError(error, `failed to list owner`);
  }

  const {
    data: usersData,
    previousData: previousUsersData,
    error: usersError,
  } = useUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: { maxNumEntries: 100, searchQuery: debouncedSearchQuery },
    },
  });
  if (usersError) {
    logError(usersError, `failed to list users`);
  }

  if (loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton />
      </Column>
    );
  }
  if (ownerNotFound) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage entity="Owner" />
      </Column>
    );
  }
  if (!owner || error || usersError) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error || usersError} />
      </Column>
    );
  }
  const currentUsers = owner.ownerUsers.flatMap((user) =>
    user.user ? user.user.id : []
  );
  const allUsers =
    usersData?.users.users || previousUsersData?.users.users || [];
  const listUsers = allUsers.filter(
    (user) =>
      !currentUsers.includes(user.id) &&
      !Object.keys(usersToAddByUserId).includes(user.id)
  );

  const handleClose = () => {
    transitionBack(`/owners/${ownerId}`);
  };

  const numUsersToAdd = Object.keys(usersToAddByUserId).length;

  const handleAddUsers = async () => {
    if (!owner) return;
    try {
      const { data } = await updateOwnerUsersForOwnerMutation({
        variables: {
          input: {
            ownerId: owner.id,
            userIds: [...currentUsers, ...Object.keys(usersToAddByUserId)],
          },
        },
        refetchQueries: ["Owner"],
      });
      switch (data?.setOwnerUsers.__typename) {
        case "UpdateOwnerUsersForOwnerResult":
          displaySuccessToast("Success: owner users added");
          handleClose();
          break;
        case "CannotAddUserToSyncedOwnerError":
          setAddUsersErrorMessage(
            `Error: this owner's user list is link to a group: ${data.setOwnerUsers.group?.name}`
          );
          break;
        default:
          logError(new Error(`failed to add users to owner`));
          setAddUsersErrorMessage(`Error: failed to add users to owner`);
      }
    } catch (error) {
      logError(error, "failed to update owner");
      setAddUsersErrorMessage(
        getModifiedErrorMessage("Error: failed to add users to owner", error)
      );
    }
  };

  return (
    <FullscreenView
      title={`Add Users To Owner: ${owner.name}`}
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddUsers}
      primaryButtonLabel={`Add ${
        numUsersToAdd > 0 ? numUsersToAdd : ""
      } ${pluralize("user", numUsersToAdd)}`}
      primaryButtonDisabled={numUsersToAdd === 0}
      primaryButtonLoading={addUsersLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select users to add to the owner:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or email"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 users. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          <List
            items={listUsers}
            getItemKey={(user) => user.id}
            getItemLabel={(user) => user.fullName}
            getItemSublabel={(user) => user.email}
            getIcon={getUserAvatarIcon}
            getActionLabel={() => "Add"}
            onSelectItem={(user) => {
              const newUsersToAddByUserId = {
                ...usersToAddByUserId,
              };
              newUsersToAddByUserId[user.id] = user;
              setUsersToAddByUserId(newUsersToAddByUserId);
            }}
            getActionIcon={(user) => {
              return Object.keys(usersToAddByUserId).includes(user.id)
                ? "x"
                : "plus";
            }}
            noItemsMessage="No users found"
          />
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addUsersErrorMessage && (
          <Banner
            message={addUsersErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numUsersToAdd} {pluralize("User", numUsersToAdd)}
        </div>
        {Object.keys(usersToAddByUserId).map((userId) => {
          const user = usersToAddByUserId[userId];
          if (!user) {
            return null;
          }

          return (
            <div key={userId} className={styles.userCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={getUserAvatarIcon(user)} />
                </div>
                <div className={styles.userInfoSection}>
                  <div className={styles.userCardHeader}>{user.fullName}</div>
                  <div className={styles.userCardSubtitle}>{user.email}</div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setUsersToAddByUserId((usersToAddByUserId) => {
                        const newUsersToAddByUserId = { ...usersToAddByUserId };
                        delete newUsersToAddByUserId[userId];
                        return newUsersToAddByUserId;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default OwnerAddUsersView;
