import { TagFragment, useTagQuery } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import { ButtonV3, TabsV3 } from "components/ui";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { logError } from "utils/logging";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import TagDeleteModal from "./TagMoreInfoMenu";
import TagGroupsRow from "./viewer/rows/TagGroupsRow";
import TagResourcesRow from "./viewer/rows/TagResourcesRow";
import TagUsersRow from "./viewer/rows/TagUsersRow";

interface TagView {
  key: string;
  title: string;
  content: JSX.Element;
  count?: number;
}

const TagDetailV3 = () => {
  const location = useLocation();
  const history = useHistory();
  const { tagId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isAdmin = authState.user?.isAdmin;

  const selectedView = location.hash.slice(1) || "resources";

  const { data, error, loading } = useTagQuery({
    variables: {
      input: {
        id: tagId ?? "",
      },
    },
    skip: tagId == null,
  });
  let tag: TagFragment | null = null;
  let tagNotFound = false;

  if (data) {
    switch (data.tag.__typename) {
      case "TagResult":
        tag = data.tag.tag;
        break;
      case "TagNotFoundError":
        tagNotFound = true;
        break;
      default:
        logError(new Error(`failed to list tag`));
    }
  } else if (error) {
    logError(error, `failed to list tags`);
  }

  if (loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }
  if (tagNotFound) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage />
      </Column>
    );
  }
  if (!tag || error) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const detailsCard = (
    <ItemDetailsCard
      title={tag.key}
      subtitle={`Value: ${tag.value ?? "—"}`}
      rightActions={
        isAdmin ? (
          <ButtonV3
            label="Delete Tag"
            type="danger"
            size="sm"
            onClick={() => setShowDeleteModal(true)}
          />
        ) : undefined
      }
    />
  );

  const tagViews: TagView[] = [
    {
      key: "resources",
      content: <TagResourcesRow tag={tag} />,
      title: "Resources",
      count: tag.tagResources.length,
    },
    {
      key: "groups",
      content: <TagGroupsRow tag={tag} />,
      title: "Groups",
      count: tag.tagGroups.length,
    },
    {
      key: "users",
      content: <TagUsersRow tag={tag} />,
      title: "Users",
      count: tag.tagUsers.length,
    },
  ];
  const tabInfos = tagViews.map((view) => ({
    title: view.title,
    onClick: () => history.push({ hash: view.key }),
    isSelected: selectedView === view.key,
    badgeCount: view.count,
  }));

  const selectedViewInfo = tagViews.find((view) => view.key === selectedView);
  const content = selectedViewInfo?.content;

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={[
            {
              name: "Tags",
              to: "/tags",
            },
            {
              name: tag.key,
            },
          ]}
          includeDefaultActions
        />
        <ColumnContent>
          {detailsCard}
          <TabsV3 tabInfos={tabInfos} />
          {content}
        </ColumnContent>
      </Column>
      {showDeleteModal && (
        <TagDeleteModal
          tag={tag}
          showDeleteTagModal={showDeleteModal}
          setShowDeleteTagModal={setShowDeleteModal}
        />
      )}
    </>
  );
};

export default TagDetailV3;
