import { EntityType } from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Avatar, EntityIcon, Icon } from "components/ui";
import React from "react";
import { Box, Search } from "react-feather";

import * as styles from "./ResultItem.css";
import { KBarItem, KBarSearchResultItem } from "./utils";

const ICON_PROPS = {
  size: 18,
  strokeWidth: 1.5,
};

const getResultItemData = (
  item: KBarSearchResultItem
): {
  icon: JSX.Element;
  entityTypeName?: string;
} => {
  switch (item.entity.entityType) {
    case EntityType.Resource: {
      const resourceInfo = getResourceTypeInfo(item.resourceType);
      return {
        icon: <EntityIcon type={item.resourceType} />,
        entityTypeName: resourceInfo?.name,
      };
    }
    case EntityType.Group: {
      const groupInfo = getGroupTypeInfo(item.groupType);
      return {
        icon: <EntityIcon type={item.groupType} />,
        entityTypeName: groupInfo?.name,
      };
    }
    case EntityType.Owner: {
      return {
        icon: <Icon name="user-square" />,
        entityTypeName: "Owner",
      };
    }
    case EntityType.Connection: {
      return {
        icon: item.connection?.connectionType ? (
          <EntityIcon type={item.connection?.connectionType} />
        ) : (
          <Icon name="layers" />
        ),
        entityTypeName: "App",
      };
    }
    case EntityType.User: {
      return {
        icon: <Avatar size="normal" url={item.avatarUrl} />,
        entityTypeName: "User",
      };
    }
    case EntityType.Bundle: {
      return {
        icon: <Icon name="package" />,
        entityTypeName: "Bundle",
      };
    }
    default:
      return {
        icon: <Box {...ICON_PROPS} />,
        entityTypeName: "Unknown",
      };
  }
};

interface ResultItemProps {
  active: boolean;
  item: KBarItem;
  searchQuery: string;
}

const ResultItem: React.FC<ResultItemProps> = (props) => {
  if (props.item.type === "see-all") {
    return (
      <div className={styles.seeAllItem({ active: props.active })}>
        <div className={styles.searchIcon}>
          <Search size={18} />
        </div>
        See all results for "
        <span className={styles.searchQuery}>{props.searchQuery}</span>"
      </div>
    );
  } else if (props.item.type === "no-results") {
    return <div className={styles.noResults}>No results</div>;
  }

  const { icon, entityTypeName } = getResultItemData(props.item);

  return (
    <div className={styles.resultItem({ active: props.active })}>
      <div className={styles.resultItemLeft}>
        <div className={styles.entityIcon}>{icon}</div>
        <div className={styles.resultItemColumn}>
          <div className={styles.itemLabel}>{props.item.label}</div>
          <div className={styles.itemSubtext}>
            {props.item.annotationText || entityTypeName}
          </div>
        </div>
      </div>
      <div className={styles.appName}>{props.item.connection?.name}</div>
    </div>
  );
};

export default ResultItem;
