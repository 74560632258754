import { TooltipPlacement } from "components/label/Label";
import { EntityIcon, Icon, Label, Popover, Tooltip } from "components/ui";
import { IconData } from "components/ui/utils";

import * as styles from "./IconGroup.css";

export type IconItem = {
  key: string;
  // If names are not provided for all items, there will be no popover
  name?: string;
  icon: IconData;
  linkTo?: string;
};

type Props = {
  items: IconItem[];
  iconSize?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
  visibleItemsCount?: number;
};

export const IconGroup = ({
  items,
  visibleItemsCount = 5,
  iconSize = "lg",
}: Props) => {
  const visibleItems = items.slice(0, visibleItemsCount);
  const notVisibleItemCount = items.length - visibleItems.length;
  const hasName = (item: IconItem) => !!item.name;
  const allNamesPresent = items.some(hasName);

  return (
    <div className={styles.iconGroup}>
      {visibleItems.map((item) => {
        return (
          <div className={styles.icon({ size: iconSize })} key={item.key}>
            <Tooltip
              tooltipText={item.name}
              placement={TooltipPlacement.Top}
              arrow
            >
              {item.icon.type === "entity" ? (
                <EntityIcon
                  type={item.icon.entityType}
                  size={iconSize}
                  iconStyle={"rounded"}
                />
              ) : (
                <Icon iconStyle={"rounded"} data={item.icon} size={iconSize} />
              )}
            </Tooltip>
          </div>
        );
      })}
      {items.length > visibleItemsCount && (
        <>
          {allNamesPresent ? (
            <Popover
              content={items.map((item) => {
                return (
                  <Label
                    iconSize="xs"
                    key={item.key}
                    label={item.name ?? ""}
                    icon={item.icon}
                    linkTo={item.linkTo}
                  />
                );
              })}
            >
              <div className={styles.countCircle({ size: iconSize })}>
                +{notVisibleItemCount}
              </div>
            </Popover>
          ) : (
            <div className={styles.countCircle({ size: iconSize })}>
              +{notVisibleItemCount}
            </div>
          )}
        </>
      )}
    </div>
  );
};
