import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  OrganizationWebhookInfoFragment,
  useCreateWebhookInfoMutation,
  useDeleteWebhookInfoMutation,
  useRegenerateSigningSecretMutation,
  useUpdateWebhookInfoMutation,
  useWebhookInfoQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import samlIntegrationsStyles from "components/auth/SamlApp.module.scss";
import { SigningSecretModal } from "components/modals/SigningSecretModal";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useState } from "react";
import { logError } from "utils/logging";
import orgIntegrationsStyles from "views/settings/OrgIntegrations.module.scss";
import styles from "views/settings/Webhooks.module.scss";

import { OrgSettingsSection } from "./OrgSettings";

export type WebhooksProps = {
  title: string;
};

const Webhooks = (props: WebhooksProps) => {
  const { authState } = useContext(AuthContext);
  const [requestURL, setRequestURL] = useState("");
  const [signingSecret, setSigningSecret] = useState("");
  const [signingSecretPreview, setSigningSecretPreview] = useState("");
  const [enableWebhooks, setEnableWebhooks] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSigningSecretModal, setShowSigningSecretModal] = useState(false);
  const { displaySuccessToast, displayErrorToast } = useToast();
  const [webhookInfo, setWebhookInfo] = useState<
    Maybe<OrganizationWebhookInfoFragment>
  >(null);

  const formIsNotFilledOut = !requestURL.startsWith(`https://`);

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const formHasNotChanged = requestURL === webhookInfo?.webhookEndpointUrl;

  const needHelpURL = `https://docs.opal.dev/docs/webhooks`;

  const [
    createWebhookInfo,
    { loading: createWebhookInfoLoading },
  ] = useCreateWebhookInfoMutation();

  const [
    regenerateSigningSecret,
    { loading: regenerateSigningSecretLoading },
  ] = useRegenerateSigningSecretMutation();

  const [
    deleteWebhookInfo,
    { loading: deleteWebhookInfoLoading },
  ] = useDeleteWebhookInfoMutation();

  useWebhookInfoQuery({
    onCompleted: (data) => {
      if (data) {
        if (data.webhookInfo.webhookInfo) {
          setEnableWebhooks(data.webhookInfo.webhookInfo.enableWebhook);
          setRequestURL(data.webhookInfo.webhookInfo.webhookEndpointUrl);
          setWebhookInfo(data.webhookInfo.webhookInfo);
          if (data.webhookInfo.webhookInfo.signingSecretPreview) {
            setSigningSecretPreview(
              data.webhookInfo.webhookInfo.signingSecretPreview
            );
          }
          setFormComplete(true);
        }
      }
    },
    onError: (error) => {
      logError(error, `failed to get webhook info`);
    },
  });

  const needHelpLink = (
    <span
      onClick={() => {
        window.open(needHelpURL);
      }}
      className={styles.needHelpLink}
    >
      Need Help?
    </span>
  );

  const signingSecretModal = (
    <SigningSecretModal
      isModalOpen={showSigningSecretModal}
      signingSecret={signingSecret}
      onClose={() => {
        setShowSigningSecretModal(false);
      }}
      loading={createWebhookInfoLoading || regenerateSigningSecretLoading}
      onSubmit={() => {
        setShowSigningSecretModal(false);
      }}
    />
  );

  const confirmDeleteModal = (
    <ConfirmModal
      title={`Delete Webhooks connection`}
      message={`Are you sure you want to disconnect your Webhooks connection?`}
      isModalOpen={showConfirmModal}
      onClose={() => {
        setShowConfirmModal(false);
      }}
      onSubmit={async () => {
        try {
          const { data: deleteData } = await deleteWebhookInfo();
          switch (deleteData?.deleteWebhookInfo.__typename) {
            case "DeleteWebhookInfoResult":
              setRequestURL("");
              setSigningSecretPreview("");
              setWebhookInfo(null);
              setFormComplete(false);
              setEnableWebhooks(false);
              setShowConfirmModal(false);
              displaySuccessToast("Success: webhooks settings disconnected");
              break;
            default:
              setErrorMessage("Error: failed to disconnect webhook settings");
          }
        } catch (error) {
          logError(error, `failed to disconnect webhook settings`);
          setErrorMessage("Error: failed to disconnect webhook settings");
        }
      }}
      loading={deleteWebhookInfoLoading}
      errorMessage={errorMessage}
      submitLabel={"Disconnect"}
    />
  );

  const deleteConnectionPanel = (
    <div className={sprinkles({ marginTop: "md" })}>
      <Button
        type="error"
        leftIconName="trash-2"
        label="Delete Webhooks Connection"
        onClick={() => {
          setShowConfirmModal(true);
        }}
        disabled={!formComplete}
      />
    </div>
  );

  const enableWebhookPanel = (
    <EnableWebhooksSetting
      enableWebhooks={enableWebhooks}
      setEnableWebhooksPanel={setEnableWebhooks}
    />
  );

  const handleRegenerate = async () => {
    try {
      const { data } = await regenerateSigningSecret({
        refetchQueries: ["WebhookInfo"],
      });

      switch (data?.regenerateSigningSecret.__typename) {
        case "RegenerateSigningSecretResult":
          if (data.regenerateSigningSecret.webhookInfo.signingSecret) {
            setSigningSecret(
              data.regenerateSigningSecret.webhookInfo.signingSecret
            );
          }
          if (data.regenerateSigningSecret.webhookInfo.signingSecretPreview) {
            setSigningSecretPreview(
              data.regenerateSigningSecret.webhookInfo.signingSecretPreview
            );
          }
          setShowSigningSecretModal(true);
          break;
        default:
          displayErrorToast("Error: failed to update webhook settings");
      }
    } catch (error) {
      logError(error, "failed to update webhook settings");
      displayErrorToast(
        getModifiedErrorMessage(
          "Error: failed to update webhook settings",
          error
        )
      );
    }
  };

  const signingSecretPanel = (
    <FormGroup
      label="Signing Secret"
      infoTooltip="This is the secret used to sign requests sent to the webhook endpoint."
      rightLabelContent={
        <Button
          size="sm"
          label="Regenerate"
          onClick={handleRegenerate}
          disabled={!authState.user?.isAdmin}
        />
      }
    >
      {signingSecretPreview}
    </FormGroup>
  );

  const requestURLPanel = (
    <FormGroup
      label="Request URL"
      infoTooltip="This is the URL of the endpoint that Opal should notify."
    >
      <Input
        onChange={(value) => {
          setRequestURL(value);
        }}
        value={requestURL}
        disabled={!authState.user?.isAdmin}
      />
      {needHelpLink}
    </FormGroup>
  );

  const saveChangesPanel = (
    <div className={samlIntegrationsStyles.topPanel}>
      <div className={samlIntegrationsStyles.leftContent}>
        <div className={samlIntegrationsStyles.text}>
          <div className={samlIntegrationsStyles.header}>
            Enable webhook for access change events
          </div>
        </div>
      </div>
      <div className={orgIntegrationsStyles.rightContent}>
        {authState.user?.isAdmin && (
          <Button
            type="success"
            leftIconName="check"
            label="Save Changes"
            onClick={async () => {
              try {
                const { data } = await createWebhookInfo({
                  variables: {
                    input: {
                      webhookEndpoint: requestURL,
                    },
                  },
                  refetchQueries: ["WebhookInfo"],
                });

                switch (data?.createWebhookInfo.__typename) {
                  case "CreateWebhookInfoResult":
                    setWebhookInfo(data.createWebhookInfo.webhookInfo);
                    setRequestURL(
                      data.createWebhookInfo.webhookInfo.webhookEndpointUrl
                    );
                    if (data.createWebhookInfo.webhookInfo.signingSecret) {
                      setShowSigningSecretModal(true);
                      setSigningSecret(
                        data.createWebhookInfo.webhookInfo.signingSecret
                      );
                    }
                    if (
                      data.createWebhookInfo.webhookInfo.signingSecretPreview
                    ) {
                      setSigningSecretPreview(
                        data.createWebhookInfo.webhookInfo.signingSecretPreview
                      );
                    }
                    setFormComplete(true);
                    displaySuccessToast("Success: webhook settings updated");
                    break;
                  default:
                    displayErrorToast(
                      "Error: failed to update webhook settings"
                    );
                }
              } catch (error) {
                logError(error, "failed to update webhook settings");
                displayErrorToast(
                  getModifiedErrorMessage(
                    "Error: failed to update webhook settings",
                    error
                  )
                );
              }
            }}
            loading={createWebhookInfoLoading}
            disabled={formHasNotChanged || formIsNotFilledOut}
          />
        )}
      </div>
    </div>
  );

  const existingConnection = (
    <>
      {signingSecretPanel}
      {enableWebhookPanel}
      {authState.user?.isAdmin && deleteConnectionPanel}
      {confirmDeleteModal}
      {signingSecretModal}
    </>
  );

  return (
    <OrgSettingsSection title={props.title}>
      <div className={samlIntegrationsStyles.orgSettingContainer}>
        {saveChangesPanel}
        <div className={samlIntegrationsStyles.bottomPanel}>
          {requestURLPanel}
          {formComplete && existingConnection}
        </div>
      </div>
    </OrgSettingsSection>
  );
};

export default Webhooks;

export type EnableWebhooksSettingProps = {
  enableWebhooks: boolean;
  setEnableWebhooksPanel: (enableWebhooks: boolean) => void;
};

export const EnableWebhooksSetting = (props: EnableWebhooksSettingProps) => {
  const [updateWebhookInfo] = useUpdateWebhookInfoMutation();
  const { authState } = useContext(AuthContext);

  const { displaySuccessToast, displayErrorToast } = useToast();

  const handleToggleEnableWebhooks = async () => {
    try {
      const { data } = await updateWebhookInfo({
        variables: {
          input: {
            enableWebhook: !props.enableWebhooks,
          },
        },
        refetchQueries: ["WebhookInfo"],
      });

      switch (data?.updateWebhookInfo.__typename) {
        case "UpdateWebhookInfoResult":
          props.setEnableWebhooksPanel(
            data.updateWebhookInfo.webhookInfo.enableWebhook
          );
          displaySuccessToast("Success: webhook settings updated");
          break;
        default:
          displayErrorToast("Error: failed to update webhook settings");
      }
    } catch (error) {
      logError(error, "failed to update webhook settings");
      displayErrorToast(
        getModifiedErrorMessage(
          "Error: failed to update webhook settings",
          error
        )
      );
    }
  };

  return (
    <Switch
      checked={props.enableWebhooks}
      onChange={handleToggleEnableWebhooks}
      disabled={!authState.user?.isAdmin}
      label="Enable webhook for access state changes"
      infoTooltip="When enabled, Opal will notify a remote endpoint when access changes for users, groups, and resources."
    />
  );
};
