import {
  EntityType,
  EventFragment,
  EventType,
  Maybe,
  RequestPreviewLargeFragment,
  SortDirection,
  SubEventFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import slackLogo from "assets/logos/slack-logo.svg";
import UserLabel from "components/label/item_labels/UserLabel";
import Label, {
  ResourceLabel,
  SupportTicketLabelViaHTTP,
} from "components/label/Label";
import { ContentPill } from "components/pills/PillsV3";
import { Button, ContextMenu, Icon, Linkify } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import { ReactElement, ReactNode, useState } from "react";
import * as Icons from "react-feather";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import ViewSkeleton from "views/loading/ViewSkeleton";
import * as styles from "views/requests/RequestEventsTable.css";
import RequestsCommentsButton from "views/requests/RequestsComments";

const SORT_OPTIONS = [
  {
    label: "Newest",
    value: {
      field: "event_date",
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Oldest",
    value: {
      field: "event_date",
      direction: SortDirection.Desc,
    },
  },
];

export type RequestEventsTableProps = {
  events: EventFragment[];
  request: RequestPreviewLargeFragment;
  loading: boolean;
};

const getCommentFromSubevents = (subEvents: EventFragment["subEvents"]) => {
  const comment = subEvents.find(
    (e) => e.subEventType === EventType.RequestCommentAdded
  )?.metadata;
  if (!comment) {
    return undefined;
  }
  const parsedJson = JSON.parse(comment);
  let text: string = parsedJson["request_comment_text"];
  return text;
};

export const RequestEventsTable = (props: RequestEventsTableProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  if (props.loading) {
    return <ViewSkeleton />;
  }

  const eventOrder = sortBy.value.direction === SortDirection.Asc ? -1 : 1;
  const events = props.events.slice().sort((a, b) => {
    if (a && b) {
      if (a.createdAt > b.createdAt) {
        return eventOrder;
      } else if (a.createdAt === b.createdAt) {
        // transactions often have the same timestamp, so we want to apply
        // a "natural" ordering to the events
        if (
          a.eventType === EventType.UsersAddedToGroups ||
          a.eventType === EventType.UsersAddedToResources
        ) {
          return eventOrder;
        }
        if (
          b.eventType === EventType.UsersAddedToGroups ||
          b.eventType === EventType.UsersAddedToResources
        ) {
          return -eventOrder;
        }
        if (
          a.eventType === EventType.RequestsApproved ||
          a.eventType === EventType.RequestsDenied ||
          a.eventType === EventType.RequestsCanceled
        ) {
          return eventOrder;
        }
        if (
          b.eventType === EventType.RequestsApproved ||
          b.eventType === EventType.RequestsDenied ||
          b.eventType === EventType.RequestsCanceled
        ) {
          return -eventOrder;
        }
      } else {
        return -eventOrder;
      }
    }
    return 0;
  });

  return (
    <div
      className={sprinkles({
        marginTop: "lg",
        marginBottom: "lg",
        gap: "lg",
        flexDirection: "column",
        display: "flex",
      })}
    >
      <ContextMenu
        options={SORT_OPTIONS.map((option) => ({
          label: option.label,
          disabled: sortBy === option,
          onClick: () => {
            setSortBy(option);
            logEvent({
              name: "request_events_sort",
              properties: {
                sort: `${option.value.field} ${option.value.direction}`,
              },
            });
          },
        }))}
        renderButton={(onClick) => (
          <Button
            label={"Sort by " + _.lowerCase(sortBy.label)}
            leftIconName="sort"
            borderless
            onClick={onClick}
          />
        )}
      />
      <RequestEventRows events={events} request={props.request} />
      {!hasV3 && (
        <RequestsCommentsButton
          request={props.request}
          key={`comment-button-${props.request.id}`}
        />
      )}
    </div>
  );
};

type RequestEventRowProps = {
  request: RequestPreviewLargeFragment;
  events: EventFragment[];
};

const RequestEventRows = (props: RequestEventRowProps) => {
  let eventRows: ReactElement[] = [];

  props.events.forEach((event) => {
    const createdAtTime = moment(new Date(event.createdAt));

    let durationString = "indefinitely";
    if (props.request.durationInMinutes) {
      const expirationDuration = moment.duration(
        props.request.durationInMinutes,
        "minutes"
      );
      durationString = expirationDuration.humanize();
    }

    let actionText = null;
    let rightBlockContent = <div>{"--"}</div>;

    const actorContent = (actor?: Maybe<UserPreviewSmallFragment>) => {
      return (
        <UserLabel
          name={actor?.fullName}
          avatar={actor?.avatarUrl}
          url={getResourceUrlNew({
            entityId: actor?.id || "",
            entityType: EntityType.User,
          })}
          medium={true}
        />
      );
    };

    var requestTarget = "";
    if (props.request.targetGroupId) {
      requestTarget = " for " + props.request.targetGroup?.name;
    } else if (props.request.targetUserId !== props.request.requesterId) {
      requestTarget = " for " + props.request.targetUser?.fullName;
    }

    switch (event.eventType) {
      case EventType.RequestsCreated:
        if (!props.request.resourceProposal) {
          actionText = (
            <div>
              {`Requested access${requestTarget} for `}
              <span className={sprinkles({ fontWeight: "semibold" })}>
                {durationString}
              </span>
            </div>
          );
        } else {
          actionText = (
            <div>{`Requested for the above resource to be created`}</div>
          );
        }
        eventRows.push(
          <EventRow
            iconName="send"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );

        eventRows.push(
          ...getNotificationSubEventRows(props.request, event.subEvents)
        );
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.RequestReviewersApproved:
        actionText = <div>{"Approved the access request"}</div>;
        eventRows.push(
          <EventRow
            iconName="thumbs-up"
            iconType="success"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.RequestsAdminApproved: {
        actionText = <div>{"Admin approved the access request"}</div>;
        const comment = getCommentFromSubevents(event.subEvents);
        if (comment) {
          rightBlockContent = <div>{comment}</div>;
        }
        eventRows.push(
          <EventRow
            iconName="thumbs-up"
            iconType="success"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      }
      case EventType.RequestReviewersDenied:
        actionText = <div>{"Denied the access request"}</div>;
        eventRows.push(
          <EventRow
            iconName="thumbs-down"
            iconType="danger"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.RequestsApproved:
        actionText = <div>{`Changed the request status to approved`}</div>;
        eventRows.push(
          <EventRow
            iconName="check"
            iconType="success"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.RequestsDenied: {
        actionText = <div>{`Changed the request status to denied`}</div>;
        const comment = getCommentFromSubevents(event.subEvents);
        if (comment) {
          rightBlockContent = <div>{comment}</div>;
        }
        eventRows.push(
          <EventRow
            iconName="x"
            iconType="danger"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      }
      case EventType.RequestCommentAdded: {
        actionText = <div>{"Commented on the request"}</div>;
        const comment = getCommentFromSubevents(event.subEvents);
        if (comment) {
          rightBlockContent = <div>{comment}</div>;
        }
        eventRows.push(
          <EventRow
            iconName="message"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      }
      case EventType.RequestsCanceled:
        actionText = <div>{"Canceled their own access request"}</div>;
        eventRows.push(
          <EventRow
            iconName="x"
            iconType="danger"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getTicketSyncSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.UsersAddedToResources:
        actionText = <div>{"Was added to the requested resource"}</div>;
        eventRows.push(
          <EventRow
            iconName="box"
            /*
              in this case, the actor of the event is the approver. However, in the UI, we want to display that user that is added to the item i.e. the target user.
            */
            eventActorContent={actorContent(props.request.targetUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.UsersAddedToGroups:
        actionText = <div>{"Was added to the requested group"}</div>;
        eventRows.push(
          <EventRow
            iconName="users"
            /*
              in this case, the actor of the event is the approver. However, in the UI, we want to display that user that is added to the item i.e. the target user.
            */
            eventActorContent={actorContent(props.request.targetUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.UsersRemovedFromResources:
        actionText = <div>{"Was removed from the requested resource"}</div>;
        eventRows.push(
          <EventRow
            iconName="box"
            iconType="danger"
            /*
              in this case, the actor of the event is the approver. However, in the UI, we want to display that user that is added to the item i.e. the target user.
            */
            eventActorContent={actorContent(props.request.targetUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.GroupUsersUpdated: {
        // This event can sometimes occur as the top level event instead of UsersRemovedFromGroups
        // if the group user was removed on the remote and we pull that in during regular sync as opposed to
        // the access expiring or the user forfeiting the access
        const userRemovedFromGroupEvent:
          | SubEventFragment
          | undefined = event.subEvents.find(
          (subEvent) =>
            subEvent.subEventType === EventType.UsersRemovedFromGroups &&
            subEvent.tertiaryObjectId?.entityId === props.request.id
        );

        if (userRemovedFromGroupEvent) {
          actionText = <div>{"Was removed from the requested group"}</div>;
          eventRows.push(
            <EventRow
              iconName="users"
              iconType="danger"
              /*
                in this case, the actor of the event is the approver. However, in the UI, we want to display that user that is added to the item i.e. the target user.
              */
              eventActorContent={actorContent(props.request.targetUser)}
              eventActionText={actionText}
              createdAtTime={createdAtTime}
              rightBlockContent={rightBlockContent}
              key={event.id}
            />
          );
          eventRows.push(
            ...getPropagationSubEventRows(props.request, event.subEvents)
          );
        }

        break;
      }
      case EventType.UsersRemovedFromGroups:
        actionText = <div>{"Was removed from the requested group"}</div>;
        eventRows.push(
          <EventRow
            iconName="users"
            iconType="danger"
            /*
              in this case, the actor of the event is the approver. However, in the UI, we want to display that user that is added to the item i.e. the target user.
            */
            eventActorContent={actorContent(props.request.targetUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        break;
      case EventType.ReviewersEscalated: {
        eventRows.push(
          ...getNotificationSubEventRows(
            props.request,
            event.subEvents,
            "notified"
          )
        );
        break;
      }
      case EventType.ReviewersReminded: {
        eventRows.push(
          ...getNotificationSubEventRows(
            props.request,
            event.subEvents,
            "reminded"
          )
        );
        break;
      }
      case EventType.UserNotified:
      case EventType.UserNotNotified: {
        eventRows.push(
          ...getNotificationSubEventRows(props.request, event.subEvents)
        );
        break;
      }
      case EventType.ResourcesCreated: {
        actionText = <div>{"Resource created"}</div>;
        eventRows.push(
          <EventRow
            iconName="box"
            iconType="success"
            eventActorContent={actorContent(event.actorUser)}
            eventActionText={actionText}
            createdAtTime={createdAtTime}
            rightBlockContent={rightBlockContent}
            key={event.id}
          />
        );
        break;
      }
      case EventType.PropagationTicketUpdatedRemotely:
        actionText = <div>{"Ticket was updated remotely"}</div>;
        eventRows.push(
          ...getPropagationSubEventRows(props.request, event.subEvents)
        );
        break;
      default:
        return;
    }
  });

  return <div>{eventRows}</div>;
};

const actorContent = (event: SubEventFragment) => (
  <UserLabel
    name={event.objectName}
    avatar={event.object?.avatarURL}
    url={getResourceUrlNew({
      entityId: event.objectId.entityId,
      entityType: EntityType.User,
    })}
    medium={true}
  />
);

const getNotificationSubEventRows = (
  request: RequestPreviewLargeFragment,
  subEvents: SubEventFragment[],
  notifiedVerbText?: string
) => {
  const notifiedVerb = notifiedVerbText || "notified";
  const subEventRows: ReactElement[] = [];

  // Here we want to do two things:
  // 1. collapse all subevents related to notifications for a given user into one event
  //     (with icons for each notification method that the user was notified via)
  // 2. separate out all the subevents related to notifications for the requester.
  //     These events should be listed first in the transaction ordering.
  const requesterNotificationEvents: SubEventFragment[] = [];
  const requesterNotNotifiedEvent:
    | SubEventFragment
    | undefined = subEvents.find(
    (subEvent) =>
      subEvent.subEventType === EventType.UserNotNotified &&
      subEvent.objectId.entityId === request.requester?.id
  );
  const targetUserNotificationEvents: SubEventFragment[] = [];
  const targetUserNotNotifiedEvent:
    | SubEventFragment
    | undefined = subEvents.find(
    (subEvent) =>
      subEvent.subEventType === EventType.UserNotNotified &&
      subEvent.objectId.entityId === request.targetUserId
  );

  const notificationEventsByUserID: Record<string, SubEventFragment[]> = {};
  subEvents
    .filter((subEvent) => subEvent.subEventType === EventType.UserNotified)
    .forEach((subEvent) => {
      if (
        subEvent.objectId.entityId !== request.requesterId &&
        subEvent.objectId.entityId !== request.targetUserId
      ) {
        const notificationEvents =
          notificationEventsByUserID[subEvent.objectId.entityId];
        if (!notificationEvents) {
          notificationEventsByUserID[subEvent.objectId.entityId] = [];
        }
        notificationEventsByUserID[subEvent.objectId.entityId].push(subEvent);
      } else if (subEvent.objectId.entityId === request.requesterId) {
        requesterNotificationEvents.push(subEvent);
      } else {
        targetUserNotificationEvents.push(subEvent);
      }
    });

  if (requesterNotificationEvents.length > 0) {
    const requesterNotificationEvent = requesterNotificationEvents[0];
    subEventRows.push(
      <EventRow
        iconName="bell-ringing"
        eventActorContent={actorContent(requesterNotificationEvent)}
        eventActionText={
          <div>{`Was ${notifiedVerb} of their own request`}</div>
        }
        createdAtTime={moment(new Date(requesterNotificationEvent.createdAt))}
        rightBlockContent={
          <NotificationDeliveryMethods
            subEvents={requesterNotificationEvents}
          />
        }
        key={`subevent-requester-${requesterNotificationEvent.id}`}
      />
    );
  }

  if (requesterNotNotifiedEvent) {
    subEventRows.push(
      <EventRow
        iconName="bell-off"
        eventActorContent={actorContent(requesterNotNotifiedEvent)}
        eventActionText={
          <div>
            {`Wasn't ${notifiedVerb} of their own request because their Slack notifications were turned off`}
          </div>
        }
        createdAtTime={moment(new Date(requesterNotNotifiedEvent.createdAt))}
        key={`subevent-requester-${requesterNotNotifiedEvent.id}`}
      />
    );
  }

  if (targetUserNotificationEvents.length > 0) {
    const targetUserNotificationEvent = targetUserNotificationEvents[0];
    subEventRows.push(
      <EventRow
        iconName="bell-ringing"
        eventActorContent={actorContent(targetUserNotificationEvent)}
        eventActionText={
          <div>{`Was ${notifiedVerb} of a request made on their behalf`}</div>
        }
        createdAtTime={moment(new Date(targetUserNotificationEvent.createdAt))}
        rightBlockContent={
          <NotificationDeliveryMethods
            subEvents={targetUserNotificationEvents}
          />
        }
        key={`subevent-targeruser-${targetUserNotificationEvent.id}`}
      />
    );
  }

  if (targetUserNotNotifiedEvent) {
    subEventRows.push(
      <EventRow
        iconName="bell-off"
        eventActorContent={actorContent(targetUserNotNotifiedEvent)}
        eventActionText={
          <div>
            {`Wasn't ${notifiedVerb} of the request because their Slack notifications were turned off`}
          </div>
        }
        createdAtTime={moment(new Date(targetUserNotNotifiedEvent.createdAt))}
        key={`subevent-targeruser-${targetUserNotNotifiedEvent.id}`}
      />
    );
  }

  for (const [, notificationEvents] of Object.entries(
    notificationEventsByUserID
  )) {
    const notificationEvent = notificationEvents[0];

    subEventRows.push(
      <EventRow
        iconName="bell-ringing"
        eventActorContent={
          notificationEvent.objectId.entityType ===
          EntityType.MessageChannel ? (
            <Label text={notificationEvent.objectName} icon={slackLogo} />
          ) : (
            actorContent(notificationEvent)
          )
        }
        eventActionText={
          <div>{`Was ${notifiedVerb} to review the request`}</div>
        }
        createdAtTime={moment(new Date(notificationEvent.createdAt))}
        rightBlockContent={
          <NotificationDeliveryMethods subEvents={notificationEvents} />
        }
        key={`subevent-${notificationEvent.id}`}
      />
    );
  }

  subEvents
    .filter((subEvent) => subEvent.subEventType === EventType.UserNotNotified)
    .forEach((subEvent) => {
      if (
        subEvent.objectId.entityId !== request.requester?.id &&
        subEvent.objectId.entityId !== request.targetUserId
      ) {
        subEventRows.push(
          <EventRow
            iconName="bell-off"
            eventActorContent={actorContent(subEvent)}
            eventActionText={
              <div>
                {`Wasn't ${notifiedVerb} to review the request because their notifications were turned off`}
              </div>
            }
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
      }
    });

  return subEventRows;
};

const getPropagationSubEventRows = (
  request: RequestPreviewLargeFragment,
  subEvents: SubEventFragment[]
) => {
  const subEventRows: ReactElement[] = [];
  subEvents.forEach((subEvent) => {
    switch (subEvent.subEventType) {
      case EventType.PropagatedAddUserToGroup: {
        if (subEvent.objectId.entityId === request.requester?.id) {
          subEventRows.push(
            <EventRow
              iconName="users"
              iconType="success"
              eventActorContent={actorContent(subEvent)}
              eventActionText="Propagated access to remote group"
              createdAtTime={moment(new Date(subEvent.createdAt))}
              rightBlockContent={
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={subEvent.secondaryObjectId?.entityId}
                  text={subEvent.secondaryObjectName}
                />
              }
              key={subEvent.id}
            />
          );
        }
        break;
      }
      case EventType.PropagatedAddUserToResource: {
        if (subEvent.objectId.entityId === request.requester?.id) {
          subEventRows.push(
            <EventRow
              iconName="box"
              iconType="success"
              eventActorContent={actorContent(subEvent)}
              eventActionText="Propagated access to remote resource"
              createdAtTime={moment(new Date(subEvent.createdAt))}
              rightBlockContent={
                <ResourceLabel
                  entityTypeNew={EntityType.Resource}
                  entityId={subEvent.secondaryObjectId?.entityId}
                  text={subEvent.secondaryObjectName}
                />
              }
              key={subEvent.id}
            />
          );
        }
        break;
      }
      case EventType.PropagatedRemoveUserFromGroup: {
        if (subEvent.objectId.entityId === request.requester?.id) {
          subEventRows.push(
            <EventRow
              iconName="users"
              iconType="danger"
              eventActorContent={actorContent(subEvent)}
              eventActionText="Propagated removal from remote group"
              createdAtTime={moment(new Date(subEvent.createdAt))}
              rightBlockContent={
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={subEvent.secondaryObjectId?.entityId}
                  text={subEvent.secondaryObjectName}
                />
              }
              key={subEvent.id}
            />
          );
        }
        break;
      }
      case EventType.PropagatedRemoveUserFromResource: {
        if (subEvent.objectId.entityId === request.requester?.id) {
          subEventRows.push(
            <EventRow
              iconName="box"
              iconType="danger"
              eventActorContent={actorContent(subEvent)}
              eventActionText="Propagated removal from remote resource"
              createdAtTime={moment(new Date(subEvent.createdAt))}
              rightBlockContent={
                <ResourceLabel
                  entityTypeNew={EntityType.Resource}
                  entityId={subEvent.secondaryObjectId?.entityId}
                  text={subEvent.secondaryObjectName}
                />
              }
              key={subEvent.id}
            />
          );
        }
        break;
      }
      // PropagationManualAddUserToResource and
      // PropagationManualRemoveUserFromResource are deprecated, both on grant
      // and revocation PropagationTicketUpdatedRemotely is used instead.
      // PropagationSuccessRemoveUserFromResource is only used for custom
      // resources, which are going to be deprecated in favor of global
      // resource ticket propaagation: OPAL-10688
      case EventType.PropagationTicketCreatedRemotely:
        if (
          subEvent.tertiaryObjectId?.entityType !== EntityType.SupportTicket
        ) {
          break;
        }
        subEventRows.push(
          <EventRow
            iconName="link"
            iconType="success"
            eventActorContent={
              <SupportTicketLabelViaHTTP
                supportTicketId={subEvent.tertiaryObjectId?.entityId ?? ""}
              />
            }
            eventActionText={
              "A ticket was created to update the user's access to the resource."
            }
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
        break;
      case EventType.PropagationSuccessRemoveUserFromResource:
      case EventType.PropagationManualAddUserToResource:
      case EventType.PropagationManualRemoveUserFromResource:
      case EventType.PropagationTicketUpdatedRemotely:
        if (
          subEvent.tertiaryObjectId?.entityType !== EntityType.SupportTicket
        ) {
          break;
        }
        subEventRows.push(
          <EventRow
            iconName="link"
            iconType="success"
            eventActorContent={
              <SupportTicketLabelViaHTTP
                supportTicketId={subEvent.tertiaryObjectId?.entityId ?? ""}
              />
            }
            eventActionText={
              "Ticket was completed and the user was updated into the resource"
            }
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
        break;
      default:
        return;
    }
  });
  return subEventRows;
};

const getTicketSyncSubEventRows = (
  request: RequestPreviewLargeFragment,
  subEvents: SubEventFragment[]
) => {
  const subEventRows: ReactElement[] = [];
  subEvents.forEach((subEvent) => {
    switch (subEvent.subEventType) {
      case EventType.RequestSyncTicketCreated: {
        subEventRows.push(
          <EventRow
            iconName="link"
            iconType="success"
            eventActorContent={
              <SupportTicketLabelViaHTTP
                supportTicketId={subEvent.secondaryObjectId?.entityId ?? ""}
              />
            }
            eventActionText="A copy of this request was created in a support ticket system"
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
        break;
      }
      case EventType.RequestSyncTicketUpdated: {
        subEventRows.push(
          <EventRow
            iconName="link"
            iconType="default"
            eventActorContent={
              <SupportTicketLabelViaHTTP
                supportTicketId={subEvent.secondaryObjectId?.entityId ?? ""}
              />
            }
            eventActionText="The synced ticket was updated"
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
        break;
      }
      case EventType.RequestSyncTicketClosed: {
        subEventRows.push(
          <EventRow
            iconName="link"
            iconType="default"
            eventActorContent={
              <SupportTicketLabelViaHTTP
                supportTicketId={subEvent.secondaryObjectId?.entityId ?? ""}
              />
            }
            eventActionText="The synced ticket was closed"
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
        break;
      }
      case EventType.RequestSyncTicketCommented: {
        subEventRows.push(
          <EventRow
            iconName="link"
            iconType="default"
            eventActorContent={
              <SupportTicketLabelViaHTTP
                supportTicketId={subEvent.secondaryObjectId?.entityId ?? ""}
              />
            }
            eventActionText="The comment was added to the synced ticket"
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
        break;
      }
      case EventType.RequestSyncTicketError: {
        subEventRows.push(
          <EventRow
            iconName="link"
            iconType="danger"
            eventActorContent={
              <SupportTicketLabelViaHTTP
                supportTicketId={subEvent.secondaryObjectId?.entityId ?? ""}
              />
            }
            eventActionText="An error occurred while syncing the ticket"
            createdAtTime={moment(new Date(subEvent.createdAt))}
            key={subEvent.id}
          />
        );
        break;
      }
      default:
        break;
    }
  });
  return subEventRows;
};

type NotificationDeliveryMethodsProps = {
  subEvents: SubEventFragment[];
};

export const NotificationDeliveryMethods = (
  props: NotificationDeliveryMethodsProps
) => {
  const deliveryMethods: string[] = [];
  const labels: ReactElement[] = [];

  props.subEvents.forEach((subEvent) => {
    const metadata = subEvent.metadata;
    if (metadata) {
      const parsedJson = JSON.parse(metadata);
      let deliveryMethod: string = parsedJson["delivery_method"];
      if (deliveryMethod) {
        deliveryMethods.push(deliveryMethod);
      }
    }
  });

  deliveryMethods.sort((a, b) => {
    if (a && b) {
      return a.localeCompare(b);
    }
    return 0;
  });

  deliveryMethods.forEach((deliveryMethod) => {
    switch (deliveryMethod) {
      case "EMAIL":
        labels.push(<Label text={""} icon={<Icons.Mail strokeWidth={2} />} />);
        break;
      case "SLACK":
        labels.push(<Label text={""} icon={slackLogo} />);
        break;
    }
  });

  return <div className={sprinkles({ display: "flex" })}>{labels}</div>;
};

export const NotificationDeliveryMethodsV3 = (
  props: NotificationDeliveryMethodsProps
) => {
  const deliveryMethods: string[] = [];
  const labels: ReactElement[] = [];

  props.subEvents.forEach((subEvent) => {
    const metadata = subEvent.metadata;
    if (metadata) {
      const parsedJson = JSON.parse(metadata);
      let deliveryMethod: string = parsedJson["delivery_method"];
      if (deliveryMethod) {
        deliveryMethods.push(deliveryMethod);
      }
    }
  });

  deliveryMethods.sort((a, b) => {
    if (a && b) {
      return a.localeCompare(b);
    }
    return 0;
  });

  deliveryMethods.forEach((deliveryMethod) => {
    switch (deliveryMethod) {
      case "EMAIL":
        // Instead of using the icon props on PillV3, it's being passed in as a child
        // because it was off center otherwise.
        labels.push(
          <ContentPill>
            <Icons.Mail strokeWidth={2} />
          </ContentPill>
        );
        break;
      case "SLACK":
        // Instead of using the icon props on PillV3, it's being passed in as a child
        // because it was off center otherwise.
        labels.push(
          <ContentPill>
            <Icon
              size="sm"
              iconStyle={"rounded"}
              data={{ type: "src", icon: slackLogo }}
            />
          </ContentPill>
        );
        break;
    }
  });

  return <div className={sprinkles({ display: "flex" })}>{labels}</div>;
};

type EventRowProps = {
  eventActorContent: ReactElement;
  eventActionText: Maybe<ReactNode>;
  createdAtTime: moment.Moment;
  rightBlockContent?: ReactNode;
  iconName?: PropsFor<typeof Icon>["name"];
  iconType?: "default" | "success" | "danger";
};

const EventRow = (props: EventRowProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  if (hasV3) {
    return (
      <div
        className={sprinkles({
          display: "flex",
          gap: "lg",
          marginBottom: "lg",
        })}
      >
        <div className={styles.icon({ type: props.iconType })}>
          <Icon name={props.iconName} size="xs" />
        </div>
        <div className={sprinkles({ paddingTop: "xxs" })}>
          <div className={sprinkles({ marginBottom: "xs" })}>
            {props.eventActorContent}
          </div>
          <div className={sprinkles({ fontSize: "textXs" })}>
            {props.eventActionText}
          </div>
          <div className={sprinkles({ color: "gray500", fontSize: "textXs" })}>
            {props.createdAtTime.calendar({
              sameDay: "[" + props.createdAtTime.fromNow() + "] (LLLL)",
              lastDay: "[" + props.createdAtTime.fromNow() + "] (LLLL)",
              lastWeek: "LLLL",
              sameElse: "LLLL",
            })}
          </div>
          <div
            className={sprinkles({
              fontSize: "textXs",
              fontWeight: "medium",
            })}
          >
            <Linkify>{props.rightBlockContent}</Linkify>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        marginBottom: "lg",
      })}
    >
      <div className={styles.iconCol}>
        <div className={styles.icon({ type: props.iconType })}>
          <Icon name={props.iconName} size="xs" />
        </div>
      </div>
      <div className={styles.actorCol}>{props.eventActorContent}</div>
      <div className={styles.actionCol}>
        <div className={sprinkles({ fontSize: "bodySm" })}>
          {props.eventActionText}
          <div className={sprinkles({ color: "gray500" })}>
            {props.createdAtTime.calendar({
              sameDay: "[" + props.createdAtTime.fromNow() + "] (LLLL)",
              lastDay: "[" + props.createdAtTime.fromNow() + "] (LLLL)",
              lastWeek: "LLLL",
              sameElse: "LLLL",
            })}
          </div>
        </div>
      </div>
      <div className={styles.commentCol}>
        <Linkify>{props.rightBlockContent}</Linkify>
      </div>
    </div>
  );
};
