import { ConnectionType, useConnectionsQuery } from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import { useHistory, useLocation } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import {
  BrowseServicesList,
  ConnectionInfo,
  CONNECTIONS_LIST,
} from "views/connections/create/BrowseServices";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

const CreateAppBrowseColumn = () => {
  const location = useLocation();
  const history = useHistory();
  const hasVault = useFeatureFlag(FeatureFlag.Vault);
  const hasAD = useFeatureFlag(FeatureFlag.ActiveDirectory);
  const hasAWSLegacy = useFeatureFlag(FeatureFlag.AWSLegacy);
  const hasSnowflake = useFeatureFlag(
    FeatureFlag.EnableSnowflakeConnectionType
  );
  const hasWorkday = useFeatureFlag(FeatureFlag.EnableWorkdayConnectionType);

  const selectedCategory = decodeURI(location.hash.slice(1)) || "All";

  const { data, error, loading } = useConnectionsQuery({
    variables: {
      input: {},
    },
  });
  if (error) {
    logError(error, `failed to list connections`);
  }

  const connections = data?.connections.connections ?? [];
  if (error || (!loading && !connections)) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  let filteredServices = CONNECTIONS_LIST;
  if (selectedCategory !== "All") {
    filteredServices = filteredServices.filter(
      (app) => app.category.name === selectedCategory
    );
  }
  filteredServices = filteredServices.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  filteredServices = filterServices(filteredServices, {
    excludeVault: !hasVault,
    excludeAD: !hasAD,
    excludeAWSLegacy: !hasAWSLegacy,
    excludeSnowflake: !hasSnowflake,
    excludeWorkday: !hasWorkday,
  });

  let content;
  if (loading) {
    content = <ColumnContentSkeleton />;
  } else {
    content = (
      <BrowseServicesList
        services={filteredServices}
        connections={connections}
        selectedCategory={selectedCategory}
      />
    );
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="Add Integrations"
          icon={{ type: "name", icon: "dots-grid" }}
          breadcrumbs={[{ name: "Apps", to: "/apps" }]}
        />
        <Divider margin="md" />
        <ColumnListItem
          key="all-apps"
          label="All"
          icon={{ type: "name", icon: "home" }}
          selected={!location.hash || location.hash === "#All"}
          onClick={() => history.push({ hash: "All" })}
        />
        <ColumnListItem
          key="cloud-providers"
          label="Cloud Providers"
          icon={{ type: "name", icon: "cloud" }}
          selected={selectedCategory === "Cloud Providers"}
          onClick={() => history.push({ hash: "Cloud Providers" })}
        />
        <ColumnListItem
          key="custom"
          label="Custom"
          icon={{ type: "name", icon: "tool" }}
          selected={selectedCategory === "Custom"}
          onClick={() => history.push({ hash: "Custom" })}
        />
        <ColumnListItem
          key="databases"
          label="Databases"
          icon={{ type: "name", icon: "database" }}
          selected={selectedCategory === "Databases"}
          onClick={() => history.push({ hash: "Databases" })}
        />
        <ColumnListItem
          key="identity-group-providers"
          label="Identity & Groups Providers"
          icon={{ type: "name", icon: "user" }}
          selected={selectedCategory === "Identity & Groups Providers"}
          onClick={() =>
            history.push({
              hash: encodeURI("Identity & Groups Providers"),
            })
          }
        />
        <ColumnListItem
          key="developer-tools"
          label="Developer Tools"
          icon={{ type: "name", icon: "code" }}
          selected={selectedCategory === "Developer Tools"}
          onClick={() => history.push({ hash: "Developer Tools" })}
        />
      </Column>
      <Column isContent>{content}</Column>
    </>
  );
};

interface FilterServicesOptions {
  excludeVault: boolean;
  excludeAD: boolean;
  excludeAWSLegacy: boolean;
  excludeSnowflake: boolean;
  excludeWorkday: boolean;
}

export const filterServices = (
  apps: ConnectionInfo[],
  options: FilterServicesOptions
): ConnectionInfo[] => {
  const servicesToExclude: ConnectionType[] = [];
  if (options.excludeVault) {
    servicesToExclude.push(
      ConnectionType.Mongo,
      ConnectionType.MongoAtlas,
      ConnectionType.Mysql,
      ConnectionType.Mariadb,
      ConnectionType.Postgres
    );
  }
  if (options.excludeAD) {
    servicesToExclude.push(ConnectionType.ActiveDirectory);
  }
  if (options.excludeAWSLegacy) {
    servicesToExclude.push(ConnectionType.Aws);
  }
  if (options.excludeSnowflake) {
    servicesToExclude.push(ConnectionType.Snowflake);
  }
  if (options.excludeWorkday) {
    servicesToExclude.push(ConnectionType.Workday);
  }
  return apps.filter((app) => !servicesToExclude.includes(app.connectionType));
};

export default CreateAppBrowseColumn;
