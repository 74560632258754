import { Maybe, SearchResultEntryFragment } from "api/generated/graphql";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  useReducer,
} from "react";

export enum SearchContextActionType {
  SearchQueryChange,
}

export type SearchContextState = {
  searchResultEntries: Maybe<SearchResultEntryFragment>[];
};

export type SearchContextEvent = {
  type: SearchContextActionType;
  payload: {
    searchResultEntries?: Maybe<SearchResultEntryFragment>[] | undefined | null;
  };
};

const initialSearchContext: SearchContextState = {
  searchResultEntries: [],
};

const SearchContext = createContext<{
  searchState: SearchContextState;
  searchDispatch: Dispatch<SearchContextEvent>;
}>({
  searchState: initialSearchContext,
  searchDispatch: () => null,
});

const reducer = (
  state: SearchContextState,
  action: SearchContextEvent
): SearchContextState => {
  switch (action.type) {
    case SearchContextActionType.SearchQueryChange:
      return {
        ...state,
        searchResultEntries: action.payload.searchResultEntries!,
      };
    default:
      return state;
  }
};

export const SearchContextProvider: FunctionComponent = (
  props: PropsWithChildren<{}>
) => {
  const [searchState, searchDispatch] = useReducer(
    reducer,
    initialSearchContext
  );

  return (
    <SearchContext.Provider value={{ searchState, searchDispatch }}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchContext;
