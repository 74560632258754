export const GRAPH_WIDTH = 1200;
export const GRAPH_HEIGHT = 440;
export const GRAPH_PADDING = 40;
export const GRAPH_BUCKET_SIZE = 5;

export const Y_AXIS_WIDTH = 48;
export const X_AXIS_HEIGHT = 20;
export const X_AXIS_BAR_HEIGHT = 8;

export const POPOVER_Y_OFFSET = 80;
export const POPOVER_WIDTH = 260;
export const POPOVER_HEIGHT = 340;
export const POPOVER_PADDING = 80;

export const LOW_RISK_COLOR = "#73CE77";
export const HIGH_RISK_COLOR = "#F7766D";

export const RISK_COLOR_LOW = "rgba(80, 194, 85)"; // green500
export const RISK_COLOR_MEDIUM = "rgba(232, 214, 54)"; // yellow600
export const RISK_COLOR_HIGH = "rgba(255, 161, 23)"; // orange500
export const RISK_COLOR_CRITICAL = "rgba(245, 84, 72)"; // red500
