import {
  EntityType,
  HrIdpStatus,
  Maybe,
  ResourceAccessLevelInput,
  ResourcePreviewLargeFragment,
  ResourceUserFragment,
} from "api/generated/graphql";
import { getAccessPathsSortValue } from "components/label/AccessPointsLabel";
import {
  ResourceLabel,
  TimeLabel,
  timeValueIndefinite,
} from "components/label/Label";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { Icon } from "components/ui";
import _ from "lodash";
import moment from "moment";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { PropagationType } from "utils/useRemediations";
import {
  getResourceUserAccessPathsInfo,
  ResourceUserAccessPointsLabel,
} from "views/resources/ResourceUserAccessPointsLabel";

interface ResourceUserTableRow {
  name: string;
  email: string;
  role: string;
  accessPaths: string;
  expires: string;
  status: string;
}

type ResourceUsersTableProps = {
  resource: ResourcePreviewLargeFragment;
  users: ResourceUserFragment[];
};

export const ResourceUsersTable = (props: ResourceUsersTableProps) => {
  const history = useHistory();

  const headers: Header<ResourceUserTableRow>[] = [
    { id: "name", label: "Name", width: 200, minWidth: 160 },
    { id: "email", label: "Email", width: 240, minWidth: 160 },
    { id: "role", label: "Role", width: 200, minWidth: 180 },
    {
      id: "accessPaths",
      label: "Source of Access",
      width: 170,
      minWidth: 170,
    },
    { id: "expires", label: "Expires", width: 120, minWidth: 100 },
    { id: "status", label: "Status", width: 150, minWidth: 150 },
  ];

  const rows: CellRow<ResourceUserTableRow>[] = props.users.map(
    (resourceUser) => {
      const rowId = formatResourceUserRowId(
        resourceUser.userId,
        resourceUser.accessLevel
      );

      const name = resourceUser.user?.fullName || resourceUser.userId;
      const expirationTime = resourceUser.access?.latestExpiringAccessPoint
        ?.expiration
        ? moment(
            new Date(resourceUser.access.latestExpiringAccessPoint.expiration)
          )
        : null;
      const supportTicket =
        resourceUser.access?.latestExpiringAccessPoint?.supportTicket;

      const accessPathsInfo = getResourceUserAccessPathsInfo({
        user: resourceUser.user,
        resource: props.resource,
        access: resourceUser.access,
      });

      return {
        id: rowId,
        data: {
          name: {
            value: name,
            element: (
              <ResourceLabel
                key={resourceUser.userId}
                text={name}
                subText={resourceUser.user?.teamAttr}
                entityType={EntityTypeDeprecated.User}
                avatar={resourceUser.user?.avatarUrl}
                iconLarge={true}
                bold={true}
                pointerCursor={true}
                warningIcon={
                  resourceUser.user?.hrIdpStatus !== HrIdpStatus.Active ? (
                    <Icon name="user-x" size="xxs" color="red600" />
                  ) : undefined
                }
                warningTooltipText={
                  resourceUser.user?.hrIdpStatus !== HrIdpStatus.Active
                    ? `User IDP status is "${_.startCase(
                        _.camelCase(
                          resourceUser.user?.hrIdpStatus?.toString() ??
                            "Unknown"
                        )
                      )}"`
                    : undefined
                }
                inactive={resourceUser.user?.hrIdpStatus !== HrIdpStatus.Active}
              />
            ),
            clickHandler: () => {
              history.push(
                getResourceUrlNew({
                  entityId: resourceUser.userId,
                  entityType: EntityType.User,
                })
              );
            },
          },
          email: {
            value: resourceUser.user?.email ?? "",
            element: <div>{resourceUser.user?.email ?? "--"}</div>,
          },
          role: {
            value: resourceUser.accessLevel.accessLevelName || "",
            element: (
              <div>{resourceUser.accessLevel.accessLevelName || "--"}</div>
            ),
          },
          accessPaths: {
            value: resourceUser.userId,
            sortValue: getAccessPathsSortValue(
              accessPathsInfo.hasDirectAccess,
              accessPathsInfo.accessPointRows.length
            ),
            element: (
              <ResourceUserAccessPointsLabel
                user={resourceUser.user}
                resource={props.resource}
                access={resourceUser.access}
              />
            ),
          },
          expires: {
            value: expirationTime?.unix() || timeValueIndefinite,
            element: (
              <TimeLabel
                time={expirationTime}
                supportTicket={supportTicket}
                useExpiringLabel
              />
            ),
          },
          status: {
            value: resourceUser.userId,
            sortValue: resourceUser.propagationStatus?.statusCode,
            element: (
              <PropagationStatusLabelWithModal
                // key is required to force re-render when a resource user has multiple roles and multiple tickets
                key={
                  resourceUser.userId +
                  resourceUser.resourceId +
                  resourceUser.accessLevel.accessLevelRemoteId
                }
                propagationType={PropagationType.ResourceUser}
                propagationStatus={resourceUser.propagationStatus}
                isAccessReview={false}
                entityInfo={{
                  user: resourceUser.user,
                  resource: props.resource,
                  role: resourceUser.accessLevel,
                  lastExpiringAccessPointExpiration:
                    resourceUser.access?.latestExpiringAccessPoint.expiration,
                }}
              />
            ),
          },
        },
      };
    }
  );

  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"name"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export const formatResourceUserRowId = (
  userId: string,
  role?: Maybe<ResourceAccessLevelInput>
) => {
  return `${userId}|${role?.accessLevelName || ""}|${
    role?.accessLevelRemoteId || ""
  }`;
};

export const deconstructResourceUserRowId = (resourceUserRowId: string) => {
  return resourceUserRowId.split("|");
};

export default ResourceUsersTable;
