import {
  ConnectionType,
  GroupPreviewLargeFragment,
  GroupType,
  ResourcePreviewLargeFragment,
  ResourceType,
  ServiceType,
} from "api/generated/graphql";
import {
  getGroupTypeInfo,
  groupTypeInfoByType,
} from "components/label/GroupTypeLabel";
import {
  getResourceTypeInfo,
  resourceTypeInfoByType,
} from "components/label/ResourceTypeLabel";

export const serviceTypesByConnectionType: Record<
  ConnectionType,
  ServiceType[]
> = {
  [ConnectionType.ActiveDirectory]: [ServiceType.ActiveDirectory],
  [ConnectionType.Aws]: [
    ServiceType.AwsIam,
    ServiceType.Kubernetes,
    ServiceType.Mysql,
    ServiceType.Postgres,
    ServiceType.Ssh,
  ],
  [ConnectionType.AwsSso]: [
    ServiceType.AwsSso,
    ServiceType.AwsIam,
    ServiceType.Kubernetes,
    ServiceType.Mysql,
    ServiceType.Postgres,
    ServiceType.Ssh,
  ],
  [ConnectionType.Custom]: [ServiceType.Custom],
  [ConnectionType.CustomConnector]: [ServiceType.Custom],
  [ConnectionType.Duo]: [ServiceType.Duo],
  [ConnectionType.Gcp]: [
    ServiceType.GcpIam,
    ServiceType.GcpStorage,
    ServiceType.GcpBigQuery,
    ServiceType.Postgres,
    ServiceType.Mysql,
    ServiceType.Ssh,
  ],
  [ConnectionType.GitHub]: [ServiceType.GitHub],
  [ConnectionType.GitLab]: [ServiceType.GitLab],
  [ConnectionType.GoogleGroups]: [ServiceType.GoogleGroups],
  [ConnectionType.GoogleWorkspace]: [ServiceType.GoogleWorkspace],
  [ConnectionType.Ldap]: [ServiceType.Ldap],
  [ConnectionType.Mongo]: [ServiceType.Mongo],
  [ConnectionType.MongoAtlas]: [ServiceType.MongoAtlas],
  [ConnectionType.OktaDirectory]: [ServiceType.OktaDirectory],
  [ConnectionType.Opal]: [ServiceType.Opal],
  [ConnectionType.Pagerduty]: [ServiceType.Pagerduty],
  [ConnectionType.Tailscale]: [ServiceType.Tailscale],
  [ConnectionType.Salesforce]: [ServiceType.Salesforce],
  [ConnectionType.Workday]: [ServiceType.Workday],
  [ConnectionType.Mysql]: [ServiceType.Mysql],
  [ConnectionType.Mariadb]: [ServiceType.Mariadb],
  [ConnectionType.Postgres]: [ServiceType.Postgres],
  [ConnectionType.Teleport]: [ServiceType.Teleport],
  [ConnectionType.AzureAd]: [ServiceType.AzureAd],
  [ConnectionType.Snowflake]: [ServiceType.Snowflake],
};

export const resourceTypesByConnectionType: Record<
  ConnectionType,
  ResourceType[]
> = {
  [ConnectionType.ActiveDirectory]: [],
  [ConnectionType.Aws]: [
    ResourceType.AwsIamRole,
    ResourceType.AwsRdsMysqlInstance,
    ResourceType.AwsRdsMysqlCluster,
    ResourceType.AwsRdsPostgresInstance,
    ResourceType.AwsRdsPostgresCluster,
    ResourceType.AwsEc2Instance,
    ResourceType.AwsEksCluster,
  ],
  [ConnectionType.AwsSso]: [
    ResourceType.AwsAccount,
    ResourceType.AwsSsoPermissionSet,
    ResourceType.AwsIamRole,
    ResourceType.AwsRdsMysqlInstance,
    ResourceType.AwsRdsMysqlCluster,
    ResourceType.AwsRdsPostgresInstance,
    ResourceType.AwsRdsPostgresCluster,
    ResourceType.AwsEc2Instance,
    ResourceType.AwsEksCluster,
  ],
  [ConnectionType.Custom]: [ResourceType.Custom],
  [ConnectionType.CustomConnector]: [ResourceType.CustomConnector],
  [ConnectionType.Duo]: [],
  [ConnectionType.Gcp]: [
    ResourceType.GcpOrganization,
    ResourceType.GcpFolder,
    ResourceType.GcpProject,
    ResourceType.GcpBucket,
    ResourceType.GcpBigQueryDataset,
    ResourceType.GcpBigQueryTable,
    ResourceType.GcpCloudSqlMysqlInstance,
    ResourceType.GcpCloudSqlPostgresInstance,
    ResourceType.GcpGkeCluster,
    ResourceType.GcpComputeInstance,
    ResourceType.GcpServiceAccount,
  ],
  [ConnectionType.GitHub]: [ResourceType.GitHubRepo],
  [ConnectionType.GitLab]: [ResourceType.GitLabProject],
  [ConnectionType.GoogleGroups]: [],
  [ConnectionType.GoogleWorkspace]: [ResourceType.GoogleWorkspaceRole],
  [ConnectionType.Ldap]: [],
  [ConnectionType.Mongo]: [ResourceType.MongoInstance],
  [ConnectionType.MongoAtlas]: [ResourceType.MongoAtlasInstance],
  [ConnectionType.OktaDirectory]: [ResourceType.OktaApp, ResourceType.OktaRole],
  [ConnectionType.Opal]: [ResourceType.OpalRole],
  [ConnectionType.Pagerduty]: [ResourceType.PagerdutyRole],
  [ConnectionType.Salesforce]: [
    ResourceType.SalesforcePermissionSet,
    ResourceType.SalesforceProfile,
    ResourceType.SalesforceRole,
  ],
  [ConnectionType.Tailscale]: [ResourceType.TailscaleSsh],
  [ConnectionType.Workday]: [],
  [ConnectionType.Mysql]: [ResourceType.MysqlInstance],
  [ConnectionType.Mariadb]: [ResourceType.MariadbInstance],
  [ConnectionType.Postgres]: [ResourceType.PostgresInstance],
  [ConnectionType.Teleport]: [ResourceType.TeleportRole],
  [ConnectionType.AzureAd]: [
    ResourceType.AzureManagementGroup,
    ResourceType.AzureSubscription,
    ResourceType.AzureResourceGroup,
    ResourceType.AzureVirtualMachine,
  ],
  [ConnectionType.Snowflake]: [
    ResourceType.SnowflakeDatabase,
    ResourceType.SnowflakeSchema,
    ResourceType.SnowflakeTable,
  ],
};

export const groupTypeByConnectionType: Record<
  ConnectionType,
  GroupType[] | undefined
> = {
  [ConnectionType.ActiveDirectory]: [GroupType.ActiveDirectoryGroup],
  [ConnectionType.Aws]: undefined,
  [ConnectionType.AwsSso]: [GroupType.AwsSsoGroup],
  [ConnectionType.Custom]: undefined,
  [ConnectionType.CustomConnector]: [GroupType.ConnectorGroup],
  [ConnectionType.Duo]: [GroupType.DuoGroup],
  [ConnectionType.Gcp]: undefined,
  [ConnectionType.GitHub]: [GroupType.GitHubTeam],
  [ConnectionType.GitLab]: [GroupType.GitLabGroup],
  [ConnectionType.GoogleGroups]: [
    GroupType.GoogleGroupsGroup,
    GroupType.GoogleGroupsGkeGroup,
  ],
  [ConnectionType.GoogleWorkspace]: undefined,
  [ConnectionType.Ldap]: [GroupType.LdapGroup],
  [ConnectionType.Mongo]: undefined,
  [ConnectionType.MongoAtlas]: undefined,
  [ConnectionType.OktaDirectory]: [GroupType.OktaGroup],
  [ConnectionType.Opal]: [GroupType.OpalGroup],
  [ConnectionType.Pagerduty]: undefined,
  [ConnectionType.Salesforce]: undefined,
  [ConnectionType.Tailscale]: undefined,
  [ConnectionType.Workday]: undefined,
  [ConnectionType.Mysql]: undefined,
  [ConnectionType.Mariadb]: undefined,
  [ConnectionType.Postgres]: undefined,
  [ConnectionType.Teleport]: undefined,
  [ConnectionType.AzureAd]: [
    GroupType.AzureAdMicrosoft_365Group,
    GroupType.AzureAdSecurityGroup,
  ],
  [ConnectionType.Snowflake]: [GroupType.SnowflakeRole],
};

export const resourceTypeByServiceTypeByConnectionType: Record<
  ConnectionType,
  Record<string, ResourceType[]>
> = {
  [ConnectionType.ActiveDirectory]: {},
  [ConnectionType.Aws]: {
    [ServiceType.AwsIam]: [ResourceType.AwsIamRole],
    [ServiceType.Mysql]: [
      ResourceType.AwsRdsMysqlInstance,
      ResourceType.AwsRdsMysqlCluster,
    ],
    [ServiceType.Postgres]: [
      ResourceType.AwsRdsPostgresInstance,
      ResourceType.AwsRdsPostgresCluster,
    ],
    [ServiceType.Ssh]: [ResourceType.AwsEc2Instance],
    [ServiceType.Kubernetes]: [ResourceType.AwsEksCluster],
  },
  [ConnectionType.AwsSso]: {
    [ServiceType.AwsSso]: [
      ResourceType.AwsAccount,
      ResourceType.AwsSsoPermissionSet,
    ],
    [ServiceType.AwsIam]: [ResourceType.AwsIamRole],
    [ServiceType.Mysql]: [
      ResourceType.AwsRdsMysqlInstance,
      ResourceType.AwsRdsMysqlCluster,
    ],
    [ServiceType.Postgres]: [
      ResourceType.AwsRdsPostgresInstance,
      ResourceType.AwsRdsPostgresCluster,
    ],
    [ServiceType.Ssh]: [ResourceType.AwsEc2Instance],
    [ServiceType.Kubernetes]: [ResourceType.AwsEksCluster],
  },
  [ConnectionType.Custom]: {
    [ServiceType.Custom]: [ResourceType.Custom],
  },
  [ConnectionType.CustomConnector]: {
    [ServiceType.CustomConnector]: [ResourceType.CustomConnector],
  },
  [ConnectionType.Duo]: {},
  [ConnectionType.Gcp]: {
    [ServiceType.GcpIam]: [
      ResourceType.GcpOrganization,
      ResourceType.GcpFolder,
      ResourceType.GcpProject,
      ResourceType.GcpServiceAccount,
    ],
    [ServiceType.GcpStorage]: [ResourceType.GcpBucket],
    [ServiceType.GcpBigQuery]: [
      ResourceType.GcpBigQueryDataset,
      ResourceType.GcpBigQueryTable,
    ],
    [ServiceType.Mysql]: [ResourceType.GcpCloudSqlMysqlInstance],
    [ServiceType.Postgres]: [ResourceType.GcpCloudSqlPostgresInstance],
    [ServiceType.Kubernetes]: [ResourceType.GcpGkeCluster],
    [ServiceType.Ssh]: [ResourceType.GcpComputeInstance],
  },
  [ConnectionType.GitHub]: {
    [ServiceType.GitHub]: [ResourceType.GitHubRepo],
  },
  [ConnectionType.GitLab]: {
    [ServiceType.GitLab]: [ResourceType.GitLabProject],
  },
  [ConnectionType.GoogleGroups]: {},
  [ConnectionType.GoogleWorkspace]: {
    [ServiceType.GoogleWorkspace]: [ResourceType.GoogleWorkspaceRole],
  },
  [ConnectionType.Ldap]: {},
  [ConnectionType.Mongo]: {
    [ServiceType.Mongo]: [ResourceType.MongoInstance],
  },
  [ConnectionType.MongoAtlas]: {
    [ServiceType.MongoAtlas]: [ResourceType.MongoAtlasInstance],
  },
  [ConnectionType.OktaDirectory]: {
    [ServiceType.OktaDirectory]: [ResourceType.OktaApp, ResourceType.OktaRole],
  },
  [ConnectionType.Opal]: {
    [ServiceType.Opal]: [ResourceType.OpalRole],
  },
  [ConnectionType.Pagerduty]: {
    [ServiceType.Pagerduty]: [ResourceType.PagerdutyRole],
  },
  [ConnectionType.Salesforce]: {
    [ServiceType.Salesforce]: [
      ResourceType.SalesforcePermissionSet,
      ResourceType.SalesforceProfile,
      ResourceType.SalesforceRole,
    ],
  },
  [ConnectionType.Tailscale]: {
    [ServiceType.Tailscale]: [ResourceType.TailscaleSsh],
  },
  [ConnectionType.Workday]: {},
  [ConnectionType.Mysql]: {
    [ServiceType.Mysql]: [ResourceType.MysqlInstance],
  },
  [ConnectionType.Mariadb]: {
    [ServiceType.Mariadb]: [ResourceType.MariadbInstance],
  },
  [ConnectionType.Postgres]: {
    [ServiceType.Postgres]: [ResourceType.PostgresInstance],
  },
  [ConnectionType.Teleport]: {
    [ServiceType.Teleport]: [ResourceType.TeleportRole],
  },
  [ConnectionType.AzureAd]: {
    [ServiceType.AzureAd]: [
      ResourceType.AzureResourceGroup,
      ResourceType.AzureVirtualMachine,
    ],
  },
  [ConnectionType.Snowflake]: {
    [ServiceType.Snowflake]: [
      ResourceType.SnowflakeDatabase,
      ResourceType.SnowflakeSchema,
      ResourceType.SnowflakeTable,
    ],
  },
};

export const groupTypeByServiceTypeByConnectionType: Record<
  ConnectionType,
  Record<string, GroupType[]>
> = {
  [ConnectionType.ActiveDirectory]: {
    [ServiceType.ActiveDirectory]: [GroupType.ActiveDirectoryGroup],
  },
  [ConnectionType.Aws]: {
    [ServiceType.AwsIam]: [],
  },
  [ConnectionType.AwsSso]: {
    [ServiceType.AwsSso]: [GroupType.AwsSsoGroup],
  },
  [ConnectionType.Custom]: {},
  [ConnectionType.CustomConnector]: {
    [ServiceType.CustomConnector]: [GroupType.ConnectorGroup],
  },
  [ConnectionType.Duo]: {
    [ServiceType.Duo]: [GroupType.DuoGroup],
  },
  [ConnectionType.Gcp]: {},
  [ConnectionType.GitHub]: {
    [ServiceType.GitHub]: [GroupType.GitHubTeam],
  },
  [ConnectionType.GitLab]: {
    [ServiceType.GitLab]: [GroupType.GitLabGroup],
  },
  [ConnectionType.GoogleGroups]: {
    [ServiceType.GoogleGroups]: [
      GroupType.GoogleGroupsGroup,
      GroupType.GoogleGroupsGkeGroup,
    ],
  },
  [ConnectionType.GoogleWorkspace]: {},
  [ConnectionType.Ldap]: {
    [ServiceType.Ldap]: [GroupType.LdapGroup],
  },
  [ConnectionType.Mongo]: {},
  [ConnectionType.MongoAtlas]: {},
  [ConnectionType.OktaDirectory]: {
    [ServiceType.OktaDirectory]: [GroupType.OktaGroup],
  },
  [ConnectionType.Opal]: {
    [ServiceType.Opal]: [GroupType.OpalGroup],
  },
  [ConnectionType.Pagerduty]: {},
  [ConnectionType.Salesforce]: {},
  [ConnectionType.Workday]: {},
  [ConnectionType.Mysql]: {},
  [ConnectionType.Mariadb]: {},
  [ConnectionType.Postgres]: {},
  [ConnectionType.Tailscale]: {},
  [ConnectionType.Teleport]: {},
  [ConnectionType.AzureAd]: {
    [ConnectionType.AzureAd]: [
      GroupType.AzureAdMicrosoft_365Group,
      GroupType.AzureAdSecurityGroup,
    ],
  },
  [ConnectionType.Snowflake]: {
    [ServiceType.Snowflake]: [GroupType.SnowflakeRole],
  },
};

export type ItemType = GroupType | ResourceType;

export const getServiceTypeFromResourceType = (
  resourceType: ResourceType,
  connectionType?: ConnectionType
): ServiceType => {
  for (const [thisConnectionType, resourceTypesByServiceType] of Object.entries(
    resourceTypeByServiceTypeByConnectionType
  )) {
    if (Boolean(connectionType) && connectionType !== thisConnectionType) {
      continue;
    }
    for (const [serviceType, resourceTypes] of Object.entries(
      resourceTypesByServiceType
    )) {
      for (const thisResourceType of resourceTypes) {
        if (resourceType === thisResourceType) {
          return serviceType as ServiceType;
        }
      }
    }
  }

  return ServiceType.ActiveDirectory;
};

export const getServiceTypeFromGroupType = (
  groupType: GroupType
): ServiceType => {
  let serviceTypeForGroupType: ServiceType = ServiceType.ActiveDirectory;
  Object.values(groupTypeByServiceTypeByConnectionType).forEach(
    (groupTypeByServiceType) => {
      Object.keys(groupTypeByServiceType).forEach((serviceType) => {
        for (const groupTypeForServiceType of groupTypeByServiceType[
          serviceType
        ]) {
          if (groupType === groupTypeForServiceType) {
            serviceTypeForGroupType = serviceType as ServiceType;
          }
        }
      });
    }
  );
  return serviceTypeForGroupType;
};

export const getItemTypesFromConnectionType = (
  connectionType: ConnectionType
): ItemType[] => {
  let itemTypes: ItemType[] = [];
  Object.values(groupTypeByServiceTypeByConnectionType[connectionType]).forEach(
    (groupTypes) => {
      itemTypes = itemTypes.concat(groupTypes);
    }
  );
  Object.values(
    resourceTypeByServiceTypeByConnectionType[connectionType]
  ).forEach((resourceTypes) => {
    itemTypes = itemTypes.concat(resourceTypes);
  });
  return itemTypes.sort();
};

export const getItemIcon = (
  item: ResourcePreviewLargeFragment | GroupPreviewLargeFragment
) => {
  switch (item.__typename) {
    case "Resource":
      return item.iconUrl || getResourceTypeInfo(item.resourceType)?.icon;
    case "Group":
      return getGroupTypeInfo(item.groupType)?.icon;
  }
};

export const getItemTypeName = (itemType: ItemType) => {
  if (itemType in groupTypeInfoByType) {
    return groupTypeInfoByType[itemType as GroupType].name;
  }

  if (itemType in resourceTypeInfoByType) {
    return resourceTypeInfoByType[itemType as ResourceType].name;
  }

  return "";
};
