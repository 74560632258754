import {
  OidcProviderType,
  useCreateOrUpdateOidcProviderMutation,
  useOidcProviderQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import { FormGroup, Input, Modal } from "components/ui";
import React, { useContext, useState } from "react";
import { logError, logWarning } from "utils/logging";
import { isValidHttpsUrl } from "utils/url";
import orgSettingsStyles from "views/settings/OrgSettings.module.scss";

export type OidcMultiStepModalProps = {
  oidcProviderType: OidcProviderType;
};

export const OidcMultiStepModal = (props: OidcMultiStepModalProps) => {
  let [showModal, setShowModal] = useState(false);

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [issuerUrl, setIssuerUrl] = useState("https://");
  useOidcProviderQuery({
    variables: {
      input: {
        oidcProviderType: OidcProviderType.AwsSession,
      },
    },
    onCompleted: (data) => {
      switch (data?.oidcProvider.__typename) {
        case "OidcProviderResult": {
          setClientId(data.oidcProvider.oidcProvider.clientId);
          setIssuerUrl(data.oidcProvider.oidcProvider.issuerUrl);
          break;
        }
        case "OidcProviderNotFoundError":
          // Do nothing, keep defaults
          break;
      }
    },
    onError: (error) => {
      logWarning(error, "failed to fetch OIDC provider");
    },
  });

  const { authState } = useContext(AuthContext);
  const settingsPanel = (
    <div>
      <div>
        <div className={orgSettingsStyles.switchesHeader}>
          <div className={orgSettingsStyles.switches}>
            <button
              disabled={!authState.user?.isAdmin}
              onClick={() => {
                setShowModal(true);
              }}
              className={orgSettingsStyles.orgSettingOpenModalButton}
            >
              {"Configure"}
            </button>
          </div>
          <div className={orgSettingsStyles.label}>OIDC Provider Settings</div>
          <MoreInfo
            tooltipText={
              "When set up, apps can use OIDC for user authentication."
            }
          />
        </div>
      </div>
    </div>
  );

  const [
    createOrUpdateOidcProvider,
    { loading },
  ] = useCreateOrUpdateOidcProviderMutation();
  // Select IDP Modal

  const providerFieldUnset =
    clientId === "" || clientSecret === "" || !isValidHttpsUrl(issuerUrl);
  const { displaySuccessToast, displayErrorToast } = useToast();
  const modal = (
    <Modal
      isOpen={true}
      onClose={() => {
        setShowModal(false);
      }}
      title="Set up OpenID Connect Provider"
    >
      <Modal.Body>
        <FormGroup label="Client ID:">
          <Input
            value={clientId}
            onChange={(value) => setClientId(value)}
            type="text"
          />
        </FormGroup>
        <FormGroup label="Client Secret:">
          <Input
            value={clientSecret}
            onChange={(value) => setClientSecret(value)}
            type="text"
          />
        </FormGroup>
        <FormGroup label="Issuer URL:">
          <Input
            value={issuerUrl}
            onChange={(value) => {
              setIssuerUrl(value);
            }}
            type="text"
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonDisabled={providerFieldUnset}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={async () => {
          try {
            const { data } = await createOrUpdateOidcProvider({
              variables: {
                input: {
                  clientId: clientId,
                  clientSecret: clientSecret,
                  issuerUrl: issuerUrl,
                  oidcProviderType: props.oidcProviderType,
                },
              },
            });

            switch (data?.createOrUpdateOidcProvider.__typename) {
              case "CreateOrUpdateOidcProviderResult":
                setShowModal(false);
                displaySuccessToast("OIDC Provider configuration updated.");
                break;
              case "OidcIssuerNotValidError":
                logError(new Error(data.createOrUpdateOidcProvider.message));
                displayErrorToast(data.createOrUpdateOidcProvider.message);
                break;
            }
          } catch (error) {
            displayErrorToast("OIDC provider setup failed.");
            logError(error, "OIDC provider setup failed");
          }
        }}
      ></Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {settingsPanel}
      {showModal && modal}
    </div>
  );
};
