import * as Sentry from "@sentry/react";
import {
  GeneralSettingType,
  IntegrationType,
  useAccessReviewsNoStatsQuery,
  useIdpConnectionQuery,
  useOrganizationSettingsQuery,
  useThirdPartyIntegrationsQuery,
} from "api/generated/graphql";
import AuthCodeCallbackAuthed from "components/auth/AuthCodeCallbackAuthed";
import AuthContext from "components/auth/AuthContext";
import GitHubCallback from "components/auth/GitHubCallback";
import GitLabCallback from "components/auth/GitLabCallback";
import GitLabConnectionCallback from "components/auth/GitLabConnectionCallback";
import OidcCallback from "components/auth/OidcCallback";
import SignOut from "components/auth/SignOut";
import SlackCallback from "components/auth/SlackCallback";
import SlackMfa from "components/auth/SlackMfa";
import SlackMfaCallback from "components/auth/SlackMfaCallback";
import SlackMfaOidcPresigned from "components/auth/SlackMfaOidcPresigned";
import FullscreenLayout from "components/layout/FullscreenLayout";
import LayoutV2 from "components/layout/Layout";
import LayoutV3 from "components/layout/LayoutV3";
import useLeftSidebarSectionInfos from "components/layout/left_sidebar/useLeftSidebarRoutes";
import useLeftSidebarSectionInfosV3 from "components/layout/left_sidebar/useLeftSidebarRoutesV3";
import { NavigationContextProvider } from "components/layout/NavigationContextProvider";
import SpotlightSearchProvider from "components/spotlight_search/SpotlightSearchProvider";
import { VirtualTableContextProvider } from "components/tables/material_table/VirtualTableContextProvider";
import LogRocket from "logrocket";
import { ReactElement, useContext, useEffect } from "react";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import { setUserProperties, useUserProperties } from "utils/analytics";
import { initDatadogRUMTracer } from "utils/datadog/tracer";
import { FeatureFlag, useFeatureFlag, useIdentify } from "utils/feature_flags";
import { logError } from "utils/logging";
import AccessRequestView from "views/access_request/AccessRequestView";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewCreateV3 from "views/access_reviews/AccessReviewCreateV3";
import AccessReviewScheduleEditorV3 from "views/access_reviews/AccessReviewScheduleEditorV3";
import AppContext from "views/app/AppContext";
import { NotFoundPage } from "views/error/ErrorCodePage";
import Homepage from "views/homepage/Homepage";
import { Search } from "views/search/Search";
import { SearchContextProvider } from "views/search/SearchContext";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";

import AccessReviewPerformView from "./access_reviews/AccessReviewPerform";
import AppAddIntegrationView from "./apps/AppAddIntegrationView";
import AppEditView from "./apps/AppEditView";
import AppImportItemsView from "./apps/AppImportItemsView";
import AppsBulkEditView from "./apps/AppsBulkEditView";
import { AppsContextProvider } from "./apps/AppsContext";
import GroupAddMemberGroupsView from "./apps/GroupAddMemberGroupsView";
import GroupAddResourcesView from "./apps/GroupAddResourcesView";
import GroupAddUsersView from "./apps/GroupAddUsersView";
import GroupEditView from "./apps/GroupEditView";
import ResourceAddGroupsView from "./apps/ResourceAddGroupsView";
import ResourceAddPrincipalsView from "./apps/ResourceAddPrincipalsView";
import ResourceAddUsersView from "./apps/ResourceAddUsersView";
import ResourceEditView from "./apps/ResourceEditView";
import UserAddResourcesView from "./apps/UserAddResourcesView";
import BundleCreateView from "./bundles/BundleCreateView";
import BundleEditView from "./bundles/BundleEditView";
import CreateConnection from "./connections/CreateConnection";
import OwnerAddUsersView from "./owners/owner_viewer/OwnerAddUsersView";
import Setup from "./setup/Setup";
import { SetupContextProvider } from "./setup/SetupContext";
import CreateConfigTemplateV3 from "./templates/CreateConfigTemplateV3";
import EditConfigTemplateView from "./templates/EditConfigTemplate";
import UserSettings from "./user_settings/UserSettings";

const getFullscreenRoutes = ({ isV3 = false }: { isV3: boolean }) => {
  if (!isV3) {
    return ["/setup"];
  }
  const EDIT_ROUTES = [
    "/apps/:connectionId/edit",
    "/apps/okta/:connectionId/edit",
    "/apps/bulk-edit",
    "/apps/:connectionId/account/:accountId/edit",
    "/resources/:resourceId/edit",
    "/groups/:groupId/edit",
    "/access-reviews/t/:scheduleId/edit",
    "/access-reviews/t/new",
    "/access-reviews/new",
    "/access-reviews/:accessReviewId/r/:accessReviewResourceId",
    "/access-reviews/:accessReviewId/g/:accessReviewGroupId",
    "/access-reviews/:accessReviewId/c/:accessReviewConnectionId",
    "/templates/configurations/:configurationTemplateId/edit",
    "/bundles/:bundleId/edit",
  ];
  const ADD_ROUTES = [
    "/apps/add-app",
    "/apps/create/:serviceID",
    "/apps/:connectionId/import",
    "/resources/:resourceId/add-principals",
    "/resources/:resourceId/add-users",
    "/groups/:groupId/add-users",
    "/owners/:ownerId/add-users",
    "/groups/:groupId/add-resources",
    "/groups/:groupId/add-groups",
    "/users/:userId/add-resources",
    "/resources/:resourceId/add-groups",
    "/templates/configurations/create",
    "/bundles/create",
    "/request-access",
  ];
  return ["/setup", ...EDIT_ROUTES, ...ADD_ROUTES];
};

const AuthenticatedViews = () => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { orgState, orgDispatch } = useContext(OrgContext);

  // Identify user for LaunchDarkly feature flags.
  useIdentify(authState, appState);
  // Identify user for Amplitude product analytics tracking.
  const userProperties = useUserProperties();
  useEffect(() => {
    setUserProperties(userProperties);
  }, [userProperties]);

  const leftSidebarSectionInfos = useLeftSidebarSectionInfos();
  const leftSidebarSectionInfosV3 = useLeftSidebarSectionInfosV3();
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEndUserExp = useFeatureFlag(FeatureFlag.EndUserExperience);
  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  const leftSidebarRoutes: ReactElement[] = [];

  if (hasV3Nav) {
    leftSidebarSectionInfosV3.forEach((sectionInfo) => {
      if ("options" in sectionInfo) {
        sectionInfo.options.forEach((option) => {
          leftSidebarRoutes.push(
            <Route
              key={`${option.route}-${option.title}`}
              path={
                option.extraRoutes
                  ? [...option.extraRoutes, option.route]
                  : option.route
              }
              component={option.component}
            />
          );
        });
      } else {
        leftSidebarRoutes.push(
          <Route
            key={`${sectionInfo.route}-${sectionInfo.title}`}
            path={
              sectionInfo.extraRoutes
                ? [...sectionInfo.extraRoutes, sectionInfo.route]
                : sectionInfo.route
            }
            component={sectionInfo.component}
          />
        );
      }
    });
  } else {
    leftSidebarSectionInfos.forEach((sectionInfo) => {
      sectionInfo.options.forEach((option) => {
        leftSidebarRoutes.push(
          <Route
            key={`${option.route}-${option.title}`}
            path={
              option.extraRoutes
                ? [...option.extraRoutes, option.route]
                : option.route
            }
            component={option.component}
          />
        );
      });
    });
  }

  if (appState.isRemoteLoggingEnabled) {
    Sentry.init({
      dsn:
        "https://6e6b86c3c574446ba8a7a446186b4532@o581619.ingest.sentry.io/5736543",
      integrations: [new Sentry.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      release: appState.version,
      environment: appState.environment,
      attachStacktrace: true,
    });

    Sentry.setUser({ id: authState.user!.user.id });
    Sentry.setTag("userId", authState.user!.user.id);
    Sentry.setTag("userName", authState.user!.user.fullName);
    Sentry.setTag("orgId", authState.user!.user.organization.id);
    Sentry.setTag("orgName", authState.user!.user.organization.name);

    var isLogRocketEnabled =
      orgState.orgSettings &&
      !orgState.orgSettings.generalSettings.includes(
        GeneralSettingType.LogRocketDisabled
      );
    if (
      (!appState.isOnPrem || window.location.hostname === "us.opal.dev") &&
      isLogRocketEnabled
    ) {
      LogRocket.identify(authState.user!.user.id, {
        name: authState.user!.user.fullName,
        email: authState.user!.user.email,
      });

      LogRocket.init("owtokk/opal", {
        release: `v${appState.version}`,
        network: {
          isEnabled: true,
          requestSanitizer: (request) => {
            request.body = undefined;
            if (
              "authorization" in request.headers &&
              request.headers["authorization"]
            ) {
              request.headers["authorization"] = "";
            }
            return request;
          },
          responseSanitizer: (response) => {
            response.body = undefined;
            return response;
          },
        },
      });
    }
  }

  // Initialize Datadog RUM tracing
  const datadogRUMEnabled = useFeatureFlag(FeatureFlag.DatadogRumTracing);
  useEffect(() => {
    if (!datadogRUMEnabled || !authState.user || !appState.environment) {
      return;
    }
    initDatadogRUMTracer(appState.environment, {
      id: authState.user!.user.id,
      orgId: authState.user!.user.organization.id,
      orgName: authState.user!.user.organization.name,
    });
  }, [datadogRUMEnabled, appState.environment, authState.user]);

  useOrganizationSettingsQuery({
    onCompleted: (data) => {
      switch (data.organizationSettings.__typename) {
        case "OrganizationSettingsResult": {
          const orgSettings = data.organizationSettings.settings;
          orgDispatch({
            type: OrgContextActionType.OrgSettings,
            payload: {
              orgSettings: orgSettings,
            },
          });
          break;
        }
        default:
          logError(new Error(`failed to initialize org settings`));
      }
    },
    onError: (error) => {
      logError(error, `failed to initialize org settings with error`);
    },
  });

  useAccessReviewsNoStatsQuery({
    variables: {
      input: {
        ongoingOnly: true,
      },
    },
    onCompleted: (data) => {
      switch (data.accessReviews.__typename) {
        case "AccessReviewsResult": {
          data.accessReviews.accessReviews.forEach((accessReview) => {
            accessReviewState.ongoingAccessReviewIdSet.add(accessReview.id);
          });
          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewUpdate,
            payload: {
              ongoingAccessReviewIdSet:
                accessReviewState.ongoingAccessReviewIdSet,
            },
          });
          break;
        }
        default:
          logError(new Error(`failed to initialize access reviews state`));
      }
    },
    onError: (error) => {
      logError(error, `failed to initialize access reviews state with error`);
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewUpdate,
        payload: {
          ongoingAccessReviewIdSet: new Set(),
        },
      });
    },
  });

  useThirdPartyIntegrationsQuery({
    variables: {
      input: {
        integrationType: IntegrationType.Org,
      },
    },
    onCompleted: (data) => {
      switch (data.thirdPartyIntegrations.__typename) {
        case "ThirdPartyIntegrationsResult": {
          const orgIntegrations =
            data.thirdPartyIntegrations.thirdPartyIntegrations;
          orgDispatch({
            type: OrgContextActionType.OrgThirdPartyIntegrations,
            payload: {
              orgThirdPartyIntegrations: orgIntegrations,
            },
          });
          break;
        }
        default:
          logError(new Error(`failed to initialize third party integrations`));
      }
    },
    onError: (error) => {
      logError(
        error,
        `failed to initialize third party integrations with error`
      );
    },
  });

  useIdpConnectionQuery({
    onCompleted: (data) => {
      switch (data.idpConnection.__typename) {
        case "IdpConnectionResult": {
          const idpConnection = data.idpConnection.idpConnection;
          orgDispatch({
            type: OrgContextActionType.OrgIdpConnection,
            payload: {
              orgIdpConnection: idpConnection,
            },
          });
          break;
        }
        case "IdpConnectionNotFoundError":
          break;
        default:
          logError(new Error(`failed to initialize idp connection`));
      }
    },
    onError: (error) => {
      logError(error, `failed to initialize idp connection with error`);
    },
  });

  const Layout = hasV3Nav ? LayoutV3 : LayoutV2;

  let contents = (
    <Switch>
      <Route exact path="/sign-in">
        <Redirect to={"/"} />
      </Route>
      <Route exact path="/" component={Homepage} />,
      <Route exact path="/sign-out" component={SignOut} />
      <Route exact path="/callback" component={AuthCodeCallbackAuthed} />
      <Route exact path="/callback/slack" component={SlackCallback} />
      <Route exact path="/callback/github" component={GitHubCallback} />
      <Route exact path="/callback/gitlab" component={GitLabCallback} />
      <Route
        exact
        path="/callback/gitlab-connection"
        component={GitLabConnectionCallback}
      />
      <Route exact path="/callback/oidc" component={OidcCallback} />
      <Route exact path="/callback/slackmfa" component={SlackMfaCallback} />
      <Route exact path="/slackmfa" component={SlackMfa} />
      <Route exact path="/slackmfa/oidc" component={SlackMfaOidcPresigned} />
      <Route path="/browse">
        <Redirect to="/apps" />
      </Route>
      <Route path={getFullscreenRoutes({ isV3: hasV3Nav })}>
        <FullscreenLayout>
          <Switch>
            <Route path="/setup" component={Setup} />
            {hasV3Nav && (
              <>
                {hasNHIs ? (
                  <Route
                    path="/resources/:resourceId/add-principals"
                    component={ResourceAddPrincipalsView}
                  />
                ) : (
                  <Route
                    path="/resources/:resourceId/add-principals"
                    component={NotFoundPage}
                  />
                )}
                <Route
                  path="/apps/:connectionId/edit"
                  component={AppEditView}
                />
                <Route path="/apps/add-app" component={AppAddIntegrationView} />
                <Route
                  path="/apps/create/:serviceID"
                  component={CreateConnection}
                />
                <Route
                  path="/apps/:connectionId/import"
                  component={AppImportItemsView}
                />
                <Route path="/apps/bulk-edit" component={AppsBulkEditView} />
                <Route
                  path="/resources/:resourceId/edit"
                  component={ResourceEditView}
                />
                <Route path="/groups/:groupId/edit" component={GroupEditView} />
                <Route
                  path="/resources/:resourceId/add-users"
                  component={ResourceAddUsersView}
                />
                <Route
                  path="/groups/:groupId/add-users"
                  component={GroupAddUsersView}
                />
                <Route
                  path="/groups/:groupId/add-resources"
                  component={GroupAddResourcesView}
                />
                <Route
                  path="/groups/:groupId/add-groups"
                  component={GroupAddMemberGroupsView}
                />
                <Route
                  path="/users/:userId/add-resources"
                  component={UserAddResourcesView}
                />
                <Route
                  path="/resources/:resourceId/add-groups"
                  component={ResourceAddGroupsView}
                />
                <Route
                  path="/owners/:ownerId/add-users"
                  component={OwnerAddUsersView}
                />
                <Route
                  path="/access-reviews/t/:scheduleId/edit"
                  component={AccessReviewScheduleEditorV3}
                />
                <Route
                  path="/access-reviews/t/new"
                  component={AccessReviewScheduleEditorV3}
                />
                <Route
                  path="/access-reviews/new"
                  component={AccessReviewCreateV3}
                />
                <Route
                  path={[
                    "/access-reviews/:accessReviewId/r/:accessReviewResourceId",
                    "/access-reviews/:accessReviewId/g/:accessReviewGroupId",
                    "/access-reviews/:accessReviewId/c/:accessReviewConnectionId",
                  ]}
                  component={AccessReviewPerformView}
                />
                <Route
                  path="/templates/configurations/create"
                  component={CreateConfigTemplateV3}
                />
                <Route
                  path="/templates/configurations/:configurationTemplateId/edit"
                  component={EditConfigTemplateView}
                />
                <Route
                  path="/bundles/:bundleId/edit"
                  component={BundleEditView}
                />
                <Route path="/bundles/create" component={BundleCreateView} />
                {hasEndUserExp && (
                  <Route path="/request-access" component={AccessRequestView} />
                )}
              </>
            )}
          </Switch>
        </FullscreenLayout>
      </Route>
      <Route path="/">
        <Layout>
          <Switch>
            <Route exact path="/user/settings" component={UserSettings} />
            <Route path="/search" component={Search} />
            {leftSidebarRoutes}
            <Route path="/home" component={Homepage} />
            <Route path="/" component={NotFoundPage} />
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );

  [
    SearchContextProvider,
    VirtualTableContextProvider,
    NavigationContextProvider,
    SpotlightSearchProvider,
    AppsContextProvider,
    SetupContextProvider,
  ].forEach((Provider) => {
    contents = <Provider>{contents}</Provider>;
  });

  return contents;
};

export default AuthenticatedViews;
