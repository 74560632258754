import AuthContext from "components/auth/AuthContext";
import NotificationIcon from "components/notifications/notificationIcon";
import { useSpotlightSearch } from "components/spotlight_search/utils";
import {
  BreadcrumbsV3,
  ButtonV3,
  EntityIcon,
  Icon,
  Skeleton,
  Tooltip,
} from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import useLogEvent from "utils/analytics";
import { ItemDetailsCardSkeleton } from "views/common/ItemDetailsCard";

import * as styles from "./ColumnHeaderV3.css";

export interface ActionIcon {
  iconName?: IconName;
  label?: string;
  sublabel?: string;
  onClick: () => void;
  adminOnly: boolean;
  tooltipText?: string;
  type?: "warning" | "danger" | "default";
  disabled?: boolean;
  pairWithPrior?: boolean;
}

export interface Props {
  icon?: IconData;
  title?: string;
  leftActions?: JSX.Element;
  actionIcons?: ActionIcon[];
  breadcrumbs?: PropsFor<typeof BreadcrumbsV3>["breadcrumbInfos"];
  demoBadge?: boolean;
  preserveQuery?: boolean;
  includeDefaultActions?: boolean;
  onClose?: () => void;
}

const ColumnHeader: React.FC<Props> = (props) => {
  const { authState } = useContext(AuthContext);
  const { setShowSpotlightSearch } = useSpotlightSearch();
  const logEvent = useLogEvent();

  const renderCloseButton = () => {
    if (!props.onClose) return null;
    return <ButtonV3 leftIconName="x" size="lg" onClick={props.onClose} />;
  };

  const renderIcon = () => {
    if (!props.icon) return null;
    if (props.icon.type === "name") {
      return (
        <div className={styles.icon}>
          <Icon name={props.icon.icon} size="md" iconStyle={props.icon.style} />
        </div>
      );
    }
    if (props.icon.type === "src") {
      return (
        <div className={styles.icon}>
          <Icon data={props.icon} size="md" iconStyle={props.icon.style} />
        </div>
      );
    }
    if (props.icon.type === "entity") {
      return (
        <div className={styles.icon}>
          <EntityIcon type={props.icon.entityType} size="md" />
        </div>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (props.title) {
      return (
        <div className={styles.title} title={props.title}>
          {props.title}
        </div>
      );
    }
    if (props.breadcrumbs) {
      return (
        <div className={styles.footer}>
          {props.breadcrumbs && (
            <BreadcrumbsV3 breadcrumbInfos={props.breadcrumbs} />
          )}
        </div>
      );
    }
  };

  const renderActionIcons = () => {
    var actionIcons = props.actionIcons?.filter((action) => {
      return !action.adminOnly || authState.user?.isAdmin;
    });
    if (props.includeDefaultActions) {
      const searchAction: ActionIcon = {
        iconName: "search",
        onClick: () => {
          logEvent({
            name: "k_bar_search_open",
            properties: {
              entryPoint: "click",
            },
          });
          setShowSpotlightSearch(true);
        },
        adminOnly: false,
      };
      const notificationsAction: ActionIcon = {
        iconName: "bell-notification",
        onClick: () => {},
        adminOnly: false,
      };
      actionIcons = actionIcons
        ? [searchAction, notificationsAction, ...actionIcons]
        : [searchAction, notificationsAction];
    }
    return actionIcons && actionIcons.length
      ? actionIcons.map((action, index) => {
          if (action.iconName === "bell-notification") {
            return <NotificationIcon />;
          }
          return action.tooltipText ? (
            <Tooltip key={index} tooltipText={action.tooltipText}>
              <ButtonV3
                leftIconName={action.iconName}
                label={action.label}
                sublabel={action.sublabel}
                size="lg"
                type={action.type}
                onClick={action.onClick}
                disabled={action.disabled}
              />
            </Tooltip>
          ) : (
            <ButtonV3
              key={index}
              leftIconName={action.iconName}
              label={action.label}
              sublabel={action.sublabel}
              size="lg"
              type={action.type}
              onClick={action.onClick}
              disabled={action.disabled}
            />
          );
        })
      : null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.topCard}>
        <div className={styles.titleRow}>
          {renderIcon()}
          {renderTitle()}
          {props.leftActions}
        </div>
        <div>
          {/* TODO Make Demo Badge Better */}
          {props.demoBadge && <div className={styles.demoBadge}>Demo only</div>}
        </div>
        <div className={styles.iconRow}>
          <div className={styles.actions}>
            {renderActionIcons()}
            {renderCloseButton()}
          </div>
        </div>
      </div>
    </div>
  );
};

interface SkeletonProps {
  includeCard?: boolean;
}

export const ColumnHeaderSkeleton = (props: SkeletonProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.topCard}>
        <Skeleton width="200px" height="52px" />
      </div>
      <div className={sprinkles({ margin: "lg" })}>
        {props.includeCard && <ItemDetailsCardSkeleton />}
      </div>
    </div>
  );
};

export default ColumnHeader;
