import {
  ConnectionType,
  GroupBindingGroupPreviewFragment,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { Label } from "components/ui";
import sprinkles from "css/sprinkles.css";

const GroupBindingGroupLabel = ({
  group,
}: {
  group?: GroupBindingGroupPreviewFragment;
}) => {
  let connectionLabel = null;
  if (group?.connection?.connectionType === ConnectionType.CustomConnector) {
    connectionLabel = (
      <>
        <ConnectionLabel
          connectionType={group.connection.connectionType}
          text={group.connection.name}
          connectionId={group.connection.id}
        />
        {"/"}
      </>
    );
  }
  return (
    <span
      className={sprinkles({
        display: "flex",
        flexDirection: "row",
        gap: "xs",
        alignItems: "center",
      })}
    >
      {connectionLabel}
      <Label
        linkTo={`/groups/${group?.id}`}
        label={group?.name ?? "Deleted group"}
        icon={
          connectionLabel
            ? undefined
            : {
                type: "src",
                icon: getGroupTypeInfo(group?.groupType)?.icon,
              }
        }
      />
    </span>
  );
};

export default GroupBindingGroupLabel;
