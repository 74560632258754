import { openInNewTab } from "api/common/common";
import {
  EntityType,
  GeneralSettingType,
  HrIdpStatus,
  UserOverviewFragment,
  UserTagFragment,
  useUserDetailQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import { PillV3 } from "components/pills/PillsV3";
import { ButtonV3, ContextMenu, TabsV3 } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { IconName } from "components/ui/icon/Icon";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";

import AuthContext from "../../components/auth/AuthContext";
import { UsersConfigFormV3 } from "../../components/forms/UsersConfigFormV3";
import { makeConfigForUser } from "../../components/forms/utils";
import { AppsContext } from "../apps/AppsContext";
import BulkImportColumnV2 from "../apps/BulkImportColumnV2";
import OrgContext from "../settings/OrgContext";
import UserDeleteModal from "./UserDeleteModal";
import UserDirectReportsTable from "./UserDirectReportsTable";
import UserEditModal from "./UserEditModal";
import UserResetMFAModal from "./UserResetMFAModal";
import UserResourcesTableV3 from "./UserResourcesTableV3";
import { formatHrIdpStatus, getUserAvatarIcon } from "./utils";
import UserGroupsRow from "./viewer/rows/UserGroupsRow";

interface UserView {
  key: string;
  title: string;
  iconName: IconName;
  content: JSX.Element;
  count?: number;
}

const UserDetailColumn = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useParams<Record<string, string>>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetMFAModal, setShowResetMFAModal] = useState(false);
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const { orgState } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const hasResetUserMFA = useFeatureFlag(FeatureFlag.OpalMFAAllowUserMFAReset);
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const { selectedUnmanagedItems } = useContext(AppsContext);

  const isAdmin = authState.user?.isAdmin || false;
  const canEditManager =
    (authState.user?.isAdmin &&
      !(orgState.isIdpEnabled || orgState.isHrEnabled)) ??
    false;

  const { data, error, loading } = useUserDetailQuery({
    variables: {
      id: userId!,
    },
    skip: !userId,
  });

  let user: UserOverviewFragment | null = null;
  let userAttributes: UserTagFragment[] | null = null;
  if (data) {
    switch (data.user.__typename) {
      case "UserResult":
        user = data.user.user;
        break;
      case "UserNotFoundError":
        break;
      default:
        logError(new Error(`failed to list users`));
    }
    switch (data.userAttributes.__typename) {
      case "UserTagsResult":
        userAttributes = data.userAttributes.userTags;
        break;
      default:
        logError(new Error(`failed to list users`));
    }
  } else if (error) {
    logError(error, `failed to fetch user detail ${userId}`);
  }

  if (loading) {
    return (
      <>
        <Column isContent maxWidth={hasV3Nav ? "none" : "lg"}>
          <ColumnHeaderSkeleton includeCard />
        </Column>
      </>
    );
  }

  if (!user) {
    return (
      <Column isContent>
        <NotFoundPage />
      </Column>
    );
  }

  const config = makeConfigForUser(user, userAttributes, canEditManager);

  if (selectedUnmanagedItems.length === 1) {
    return <BulkImportColumnV2 />;
  }

  const userViews: UserView[] = [
    {
      key: "resources",
      title: "Resources",
      iconName: "cube",
      count: user.numResources,
      content: <UserResourcesTableV3 user={user} canEdit={isAdmin} />,
    },
    {
      key: "groups",
      title: "Groups",
      iconName: "users",
      count: user.numGroups,
      content: <UserGroupsRow userId={user.id} />,
    },
    {
      key: "events",
      title: "Events",
      iconName: "events",
      content: (
        <EventsTableV3Component
          eventFilter={{
            objects: {
              objectId: user.id,
            },
          }}
          route={{
            pathname: `/users/${user.id}`,
            hash: "#events",
          }}
        />
      ),
    },
    {
      key: "overview",
      title: "Details",
      iconName: "list",
      content: (
        <UsersConfigFormV3
          mode={"view"}
          config={config}
          setShowEditModal={setShowUserEditModal}
        />
      ),
    },
  ];

  if (user.directReports.length) {
    userViews.splice(2, 0, {
      key: "direct-reports",
      title: "Direct Reports",
      iconName: "users",
      count: user.directReports.length,
      content: <UserDirectReportsTable user={user} />,
    });
  }
  const selectedView = location.hash.slice(1) || userViews[0].key;

  const selectedViewInfo = userViews.find((view) => view.key === selectedView);
  const content = selectedViewInfo?.content;

  const buttonSize = "sm";
  const overviewButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <ButtonV3
        label="Explore"
        type="mainSecondary"
        onClick={() => {
          const hash = makeURLForEntityViz(userId, EntityType.User);
          openInNewTab("/insights" + hash);
        }}
        leftIconName="department"
        size={buttonSize}
      />
      <ButtonV3
        label="Edit"
        type="main"
        onClick={() => setShowUserEditModal(true)}
        leftIconName="edit"
        size={buttonSize}
      />
    </div>
  );

  const tabInfos = userViews.map((view) => ({
    title: view.title,
    onClick: () => history.push({ hash: view.key }),
    isSelected: selectedView === view.key,
  }));

  const orgUsesAuth0MFA =
    // RequireOpalMfaForLogins indicates that an org uses Auth0 for logins.
    orgState.orgSettings?.generalSettings.some(
      (setting) => setting === GeneralSettingType.RequireOpalMfaForLogins
    ) ||
    // The presence of either of these settings indicates that an org does not
    // use Auth0 MFA for Opal actions.
    !orgState.orgSettings?.generalSettings.some(
      (setting) =>
        setting === GeneralSettingType.UseOktaMfaForGatingOpalActions ||
        setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
    ) ||
    false;

  let extraMenuOptions: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "Remove from Opal",
      onClick: () => setShowDeleteModal(true),
      type: "danger",
    },
  ];
  if (orgUsesAuth0MFA && hasResetUserMFA) {
    extraMenuOptions.push({
      label: "Reset MFA for User",
      onClick: () => setShowResetMFAModal(true),
      type: "warning",
    });
  }

  const cardBodyFields = {
    Manager: (
      <PillV3
        pillColor="DeepOrange"
        icon={{
          type: "src",
          icon: user.manager?.avatarUrl || defaultAvatarURL,
        }}
        keyText={user.manager?.fullName}
      />
    ),
    Team: <PillV3 pillColor="Teal" keyText={user.teamAttr} />,
    Title: <PillV3 pillColor="Teal" keyText={user.position} />,
    ["HRIS/IDP Status"]: (
      <PillV3
        pillColor={
          user.hrIdpStatus == HrIdpStatus.Active ? "LightGreen" : "Red"
        }
        icon={
          user.hrIdpStatus == HrIdpStatus.Active
            ? { type: "name", icon: "user-check" }
            : { type: "name", icon: "user-x" }
        }
        keyText={user.hrIdpStatus && formatHrIdpStatus(user.hrIdpStatus)}
      />
    ),
  };

  return (
    <>
      {content ? (
        <Column isContent maxWidth={hasV3Nav ? "none" : "lg"}>
          <ColumnHeader
            breadcrumbs={
              hasV3Nav
                ? [
                    { name: "Users", to: "/users" },
                    { name: user.fullName, to: "" },
                  ]
                : undefined
            }
            includeDefaultActions
          />
          <ColumnContent>
            <ItemDetailsCard
              icon={getUserAvatarIcon({ avatarUrl: user.avatarUrl })}
              title={user.fullName}
              subtitle={user.email}
              rightActions={isAdmin ? overviewButtons : undefined}
              extraMenuOptions={isAdmin ? extraMenuOptions : undefined}
              bodyFields={cardBodyFields}
            />
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "center",
              })}
            >
              <TabsV3 tabInfos={tabInfos} />
            </div>
            {content}
          </ColumnContent>
        </Column>
      ) : null}
      {showDeleteModal ? (
        <UserDeleteModal
          user={user}
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
        />
      ) : null}
      {showResetMFAModal ? (
        <UserResetMFAModal
          user={user}
          showModal={showResetMFAModal}
          setShowModal={setShowResetMFAModal}
        />
      ) : null}
      {showUserEditModal ? (
        <UserEditModal
          user={user}
          userAttributes={userAttributes}
          canEditManager={canEditManager}
          showModal={showUserEditModal}
          setShowModal={setShowUserEditModal}
        />
      ) : null}
    </>
  );
};

export default UserDetailColumn;
