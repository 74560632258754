import { Popper } from "@material-ui/core";
import { Button, EntityIcon, Icon, Label } from "components/ui";
import { IconData } from "components/ui/utils";
import React from "react";
import { useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as stylesV2 from "./ContextMenu.css";
import * as stylesV3 from "./ContextMenuV3.css";

interface MenuDivider {
  type: "divider";
  hide?: boolean;
}

interface MenuOption {
  label: string;
  sublabel?: string;
  onClick: React.MouseEventHandler;
  type?: "warning" | "danger" | "success";
  disabled?: boolean;
  hide?: boolean;
  icon?: IconData;
  renderOption?: () => JSX.Element;
}

interface Props {
  options: (MenuOption | MenuDivider)[];
  renderButton?: (onClick: React.MouseEventHandler) => JSX.Element;
  offsetLeft?: number;
  rightAligned?: boolean;
  block?: boolean;
  horizontalDots?: boolean;
  padding?: "md" | "lg";
}

const ContextMenu = (props: Props) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const styles = hasV3Nav ? stylesV3 : stylesV2;

  const visibleMenuOptions = props.options.filter((option) => !option.hide);

  const renderMenuOption = (
    menuOption: MenuOption | MenuDivider,
    index: number
  ) => {
    if (menuOption.type === "divider") {
      return <hr key={`${index}-divider`} className={styles.divider} />;
    }
    if (menuOption.renderOption) {
      return (
        <React.Fragment key={`${index}-${menuOption.label}`}>
          {menuOption.renderOption()}
        </React.Fragment>
      );
    }

    return (
      <li
        key={`${index}-${menuOption.label}`}
        className={styles.menuItem({
          disabled: menuOption.disabled,
          type: menuOption.type,
          padding: props.padding,
        })}
        onClick={
          menuOption.disabled
            ? undefined
            : (event) => {
                event.stopPropagation();
                menuOption.onClick(event);
                setShowMenu(false);
              }
        }
      >
        {menuOption.icon ? (
          <span className={styles.iconContainer}>
            {menuOption.icon.type === "entity" ? (
              <EntityIcon type={menuOption.icon.entityType} size="sm" />
            ) : (
              <Icon data={menuOption.icon} size="xs" />
            )}
          </span>
        ) : null}
        <div>
          <Label label={menuOption.label} oneLine />
          {menuOption.sublabel ? (
            <div className={styles.sublabel({ type: menuOption.type })}>
              {menuOption.sublabel}
            </div>
          ) : null}
        </div>
      </li>
    );
  };

  const content = (
    <ul className={styles.menuContainer}>
      {visibleMenuOptions.map(renderMenuOption)}
    </ul>
  );

  const button = props.renderButton ? (
    props.renderButton((event) => {
      event.stopPropagation();
      setShowMenu((prev) => !prev);
    })
  ) : (
    <Button
      leftIconName={props.horizontalDots ? "more-horizontal" : "more-vertical"}
      borderless
      onClick={(event) => {
        event.stopPropagation();
        setShowMenu((prev) => !prev);
      }}
      round
    />
  );

  const placement = props.rightAligned ? "bottom-end" : "bottom-start";

  return (
    <>
      <div
        className={styles.buttonContainer({ block: Boolean(props.block) })}
        ref={buttonRef}
      >
        {button}
      </div>
      <Popper
        placement={placement}
        open={showMenu}
        anchorEl={buttonRef.current}
        className={styles.overlayContainer}
      >
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (
              e.target instanceof Element &&
              buttonRef.current?.contains(e.target) === true
            ) {
              // Do not handle this if it is a click on our button
              return;
            }
            setShowMenu(false);
          }}
        >
          <div
            className={styles.overlay}
            style={{ marginLeft: props.offsetLeft ?? 0 }}
          >
            {content}
          </div>
        </OutsideClickHandler>
      </Popper>
    </>
  );
};

export default ContextMenu;
