import { openInNewTab } from "api/common/common";
import {
  EntityType,
  GroupFragment,
  ResourcePreviewSmallFragment,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useGroupQuery,
  useResourcePreviewQuery,
  useSyncStatusQuery,
  Visibility,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import ResourcesConfigFormV3 from "components/forms/ResourcesConfigFormV3";
import { makeConfigForGroup } from "components/forms/utils";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import SyncStatusModal from "components/label/SyncStatusModal";
import RequestModal from "components/modals/RequestModal";
import { PillV3 } from "components/pills/PillsV3";
import useSyncActionIcon, {
  getSyncLabel,
} from "components/sync/useSyncActionIcon";
import { useToast } from "components/toast/Toast";
import { ButtonV3, TabsV3 } from "components/ui";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionExport,
  AuthorizedActionManage,
  AuthorizedActionManageUser,
  hasBasicPermissions,
} from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { usePageTitle } from "utils/hooks";
import { logError } from "utils/logging";
import { useQuery, useURLSearchParam } from "utils/router/hooks";
import { useHandleRedirectToEndUserExp } from "views/apps/enduser_exp/utils";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";
import GroupBindingDeleteModal from "views/group_bindings/modals/GroupBindingDeleteModal";
import GroupBindingEditModal from "views/group_bindings/modals/GroupBindingEditModal";
import GroupBindingManualLinkModal from "views/group_bindings/modals/GroupBindingManualLinkModal";
import GroupBindingRequestRedirectModal from "views/group_bindings/modals/GroupBindingRequestRedirectModal";
import GroupDeleteModal from "views/groups/GroupDeleteModal";
import GroupLeadersDetailsView from "views/groups/GroupLeadersDetailsView";
import GroupResourcesTableV3 from "views/groups/GroupResourcesTableV3";
import GroupUsersTableV3 from "views/groups/GroupUsersTableV3";
import MemberGroupsTableV3 from "views/groups/MemberGroupsTableV3";

import { OKTA_APP_ID_URL_KEY } from "./AppsContext";
import { GroupActionButtons } from "./GroupActionButtons";

interface GroupView {
  key: string;
  title: string;
  content: JSX.Element;
  count?: number;
}

const GroupDetailView = () => {
  const history = useHistory();
  const logEvent = useLogEvent();
  const { authState } = useContext(AuthContext);
  const { groupId } = useParams<Record<string, string>>();
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  const redirectToEndUserExp = useHandleRedirectToEndUserExp();
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const canUseGroupProjects = useFeatureFlag(FeatureFlag.GroupProjects);
  const searchParams = useQuery();

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showEditGroupBindingModal, setShowEditGroupBindingModal] = useState(
    false
  );
  const [showLinkGroupModal, setShowLinkGroupModal] = useState(false);
  const [showUnlinkGroupModal, setShowUnlinkGroupModal] = useState(false);
  const [showRequestRedirectModal, setShowRequestRedirectModal] = useState(
    false
  );
  const [showUnmanagedResources, setShowUnmanagedResources] = useState(false);
  const [forceRequestModal, setForceRequestModal] = useURLSearchParam(
    "request"
  );

  // These are set from the resources view of an imported Okta app (i.e., a non-native integration).
  // When the user navigates to this view from the resources view of an imported Okta app, we want to
  // ensure the breadcrumbs and other UI elements reference the imported Okta app to help the user
  // better understand the context of what they are viewing.
  const oktaAppId = searchParams.get(OKTA_APP_ID_URL_KEY);
  const getSearchParameters = () => {
    return oktaAppId ? searchParams : undefined;
  };

  const {
    data: groupData,
    error: groupError,
    loading: groupLoading,
    refetch,
  } = useGroupQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        id: groupId,
      },
    },
  });

  let group: GroupFragment | undefined;
  let groupNotFound = true;
  if (groupData) {
    switch (groupData.group.__typename) {
      case "GroupResult":
        group = groupData.group.group;
        groupNotFound = false;
        break;
      case "GroupNotFoundError":
        break;
      default:
        logError(new Error(`failed to get group`));
    }
  } else if (groupError) {
    logError(groupError, `failed to get group`);
  }

  // Redirect users to the correct path when they're browsing a resource but
  // they need to be shown the end user UX.
  useEffect(() => {
    if (!group?.id || !group?.connectionId) {
      return;
    }

    if (oktaAppId) {
      redirectToEndUserExp(oktaAppId, group.id, EntityType.Group);
    } else {
      redirectToEndUserExp(group.connectionId, group.id, EntityType.Group);
    }
    // don't include redirectToEndUserExp as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group?.id, group?.connectionId, oktaAppId]);

  usePageTitle(group?.name);

  // Fetch the original Okta app details when the group is navigated to via an Okta app screen
  const {
    data: resourceData,
    error: resourceError,
    loading: resourceLoading,
  } = useResourcePreviewQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        id: oktaAppId,
      },
    },
    skip: !oktaAppId,
  });

  let oktaApp: ResourcePreviewSmallFragment | undefined;
  if (resourceData) {
    switch (resourceData.resource.__typename) {
      case "ResourceResult":
        oktaApp = resourceData.resource.resource;
        break;
      case "ResourceNotFoundError":
        break;
      default:
        logError(new Error(`failed to get resource`));
    }
  } else if (resourceError) {
    logError(resourceError, `failed to get resource`);
  }

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsSingleGroup,
        groupId,
      },
    },
    skip: groupNotFound,
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "GroupNotFoundError":
        logError(syncData.syncStatus.message);
        break;
    }
  }
  let syncStatus: string;
  if (syncError) {
    syncStatus = "Unable to get status";
  } else if (syncLoading) {
    syncStatus = "Loading sync status";
  } else {
    syncStatus = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const syncActionIcon = useSyncActionIcon({
    syncType: SyncType.PullConnectionsSingleGroup,
    group: group ?? undefined,
    queriesToRefetch: ["Group", "GroupAccessLevels"],
    loadingEntity: (groupLoading && !groupData) || groupNotFound,
    label: "Sync group",
    adminOnly: false,
  });

  const isSourceGroup =
    group && group.groupBinding
      ? group.groupBinding?.sourceGroupId === group.id
      : true;

  useEffect(() => {
    if (forceRequestModal) {
      if (!isSourceGroup) {
        setShowRequestModal(false);
        setShowRequestRedirectModal(true);
        return;
      }
      if (group?.isRequestable) {
        setShowRequestModal(true);
        return;
      }
    }
    setShowRequestRedirectModal(false);
    setShowRequestModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRequestModal, isSourceGroup, group?.isRequestable]);

  if ((groupLoading && !groupData) || (resourceLoading && !resourceData)) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }
  if (groupNotFound) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage entity="Group" />
      </Column>
    );
  }
  if (!group || groupError) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={groupError} />
      </Column>
    );
  }

  const handleRequestExport = () => {
    if (!group) return;
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "group",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/groups/users?groupID=" + group.id,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!group) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + group.name.replace(" ", "_") + "_Users.csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded users in group`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const handleRequestExportDebugInfo = () => {
    if (!group) return;
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/groups/${group.id}/debug`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!group) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + group.name.replace(" ", "_") + "_Debug.json"
        );
        link.click();
        displaySuccessToast(`Success: downloaded group debug info`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const canManage = group.authorizedActions?.includes(AuthorizedActionManage);
  const canManageUser =
    group.authorizedActions?.includes(AuthorizedActionManageUser) ||
    group.authorizedActions?.includes(AuthorizedActionManage);
  const isAdmin = authState.user?.isAdmin || authState.user?.isReadOnlyAdmin;
  const adminTabsVisible =
    group.authorizedActions?.includes(AuthorizedActionManage) ||
    group.authorizedActions?.includes(AuthorizedActionExport);

  const resourcesCount = new Set(
    (showUnmanagedResources
      ? group.groupResources
      : group.groupResources.filter((resource) => resource.resource?.isManaged)
    ).map((resource) => resource.resourceId)
  ).size;
  const groupsCount = new Set(
    (showUnmanagedResources
      ? group.containingGroups
      : group.containingGroups.filter(
          (group) => group.containingGroup?.isManaged
        )
    ).map((group) => group.containingGroupId)
  ).size;

  const views: GroupView[] = [
    {
      key: "resources",
      title: "Resources",
      content: (
        <GroupResourcesTableV3
          group={group}
          showUnmanagedResources={showUnmanagedResources}
          setShowUnmanagedResources={setShowUnmanagedResources}
        />
      ),
      count: resourcesCount + groupsCount,
    },
  ];
  if (canManageUser) {
    views.push({
      key: "users",
      title: "User Access",
      content: <GroupUsersTableV3 group={group} />,
      count: group.groupUsers.length,
    });
  }
  if (canUseGroupProjects) {
    views.push({
      key: "groups",
      title: "Group Access",
      content: <MemberGroupsTableV3 group={group} />,
      count: group.memberGroups.length,
    });
  }
  if (adminTabsVisible) {
    views.push({
      key: "events",
      title: "Events",
      content: (
        <EventsTableV3Component
          eventFilter={{
            objects: {
              objectId: group.id,
            },
          }}
          route={{
            pathname: `/groups/${group.id}`,
            hash: "#events",
            search: getSearchParameters()?.toString(),
          }}
        />
      ),
    });
  }
  views.push({
    key: "details",
    title: "Details",
    content: (
      <ResourcesConfigFormV3
        mode="view"
        config={makeConfigForGroup(group, group.configTemplate ?? undefined)}
        onChange={() => {}}
        isViewingAsNonAdmin={!adminTabsVisible}
      />
    ),
  });

  const selectedView = location.hash.slice(1) || views[0].key;

  const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = views.map((view) => ({
    title: view.title,
    onClick: () =>
      history.push({
        hash: view.key,
        search: getSearchParameters()?.toString(),
      }),
    isSelected: selectedView === view.key,
    badgeCount: view.count,
  }));

  const actionIcons: PropsFor<typeof ColumnHeader>["actionIcons"] = [];
  if (!hasBasicPermissions(authState.user) || canManage) {
    if (canManage && syncActionIcon) {
      actionIcons.push(syncActionIcon);
    }
    actionIcons.push({
      label: "View Sync Details",
      sublabel: syncStatus,
      onClick: () => {
        logEvent({
          name: "apps_view_sync_details",
          properties: {
            syncType: SyncType.PullConnectionsSingleConnection,
          },
        });
        setShowSyncModal(true);
      },
      adminOnly: false,
    });
  }

  const preMenuLayout = (
    <>
      {canUseGroupProjects && (
        <GroupLeadersDetailsView groupLeaderUsers={group.groupLeaders} />
      )}
    </>
  );
  const headerButtonSize = "sm";
  const rightActions = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      {isAdmin && (
        <ButtonV3
          type="mainSecondary"
          label="Explore"
          leftIconName="department"
          onClick={() => {
            const hash = makeURLForEntityViz(groupId, EntityType.Group);
            openInNewTab("/insights" + hash);
          }}
          size={headerButtonSize}
        />
      )}
      {canManage && (
        <ButtonV3
          type="mainSecondary"
          label="Edit"
          leftIconName="edit"
          onClick={() => history.push(`/groups/${groupId}/edit`)}
          size={headerButtonSize}
        />
      )}
      <GroupActionButtons
        group={group}
        selectedGroupViewKey="overview"
        onNavigate={(viewKey) => {
          if (viewKey === "request") {
            if (!isSourceGroup) {
              setShowRequestRedirectModal(true);
              return;
            }
            setForceRequestModal("true");
          }
        }}
        buttonSize={headerButtonSize}
      />
    </div>
  );

  const menuOptions: PropsFor<typeof ItemDetailsCard>["extraMenuOptions"] = [];
  if (group.authorizedActions?.includes(AuthorizedActionExport)) {
    menuOptions.push(
      {
        label: "Export group users",
        icon: { type: "name", icon: "users-right" },
        onClick: handleRequestExport,
      },
      {
        label: "Export debug info",
        icon: { type: "name", icon: "tool" },
        onClick: handleRequestExportDebugInfo,
      }
    );
  }
  if (group.authorizedActions?.includes(AuthorizedActionManage)) {
    if (hasGroupBindings) {
      if (group.groupBinding) {
        menuOptions.push(
          {
            label: "Edit group link",
            onClick: () => setShowEditGroupBindingModal(true),
            icon: { type: "name", icon: "edit-3" },
          },
          {
            label: "Unlink",
            onClick: () => {
              setShowUnlinkGroupModal(true);
            },
            icon: { type: "name", icon: "link-broken" },
            type: "danger",
          }
        );
      } else {
        menuOptions.push({
          label: "Link to group",
          onClick: () => setShowLinkGroupModal(true),
          icon: { type: "name", icon: "link" },
        });
      }
    }
    menuOptions.push({
      label: "Remove from Opal",
      onClick: () => {
        setShowDeleteGroupModal(true);
      },
      icon: { type: "name", icon: "trash" },
      type: "danger",
    });
  }

  const breadcrumbs: PropsFor<typeof ColumnHeader>["breadcrumbs"] = [
    { name: "Catalog", to: "/apps" },
    { name: "Apps", to: "/apps" },
  ];

  if (!oktaApp && group.connection) {
    breadcrumbs.push({
      name: group.connection.name,
      to: `/apps/${group.connection.id}`,
    });
  }
  if (oktaApp) {
    breadcrumbs.push({
      name: oktaApp.name,
      to: `/resources/${oktaAppId}`,
    });
  }
  breadcrumbs.push({
    name: group.name,
  });

  const footerFields: Record<string, string> = {
    Admin: group.adminOwner?.name || "--",
    Visibility: group.visibility === Visibility.Global ? "Global" : "Limited",
    ["Configuration Template"]: group.configTemplate?.name || "No Template",
    ["MFA to Approve"]: group.requireMfaToApprove ? "Required" : "Not Required",
  };

  const selectedViewInfo = views.find((view) => view.key === selectedView);
  const content = selectedViewInfo?.content;
  const pillText = oktaApp
    ? `${oktaApp.name} Access Via ${getGroupTypeInfo(group.groupType)?.name}`
    : getGroupTypeInfo(group.groupType)?.name;

  const titleAccessory = group.groupBinding ? (
    <div
      className={sprinkles({
        display: "flex",
        gap: "sm",
        alignItems: "center",
      })}
    >
      <PillV3
        pillColor="Teal"
        icon={{ type: "entity", entityType: group.groupType }}
        keyText={pillText}
      />
      <GroupBindingDetailPopover
        groupId={group.id}
        groupBinding={group.groupBinding}
      />
    </div>
  ) : (
    <PillV3
      pillColor="Teal"
      icon={{ type: "entity", entityType: group.groupType }}
      keyText={pillText}
    />
  );

  return (
    <>
      {showSyncModal && (
        <SyncStatusModal
          syncType={SyncType.PullConnectionsSingleGroup}
          entity={group}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      )}
      {showRequestModal && (
        <RequestModal
          entity={group}
          isGlobalImpersonationResource={false}
          isImpersonationResource={false}
          entityType={EntityType.Group}
          isModalOpen
          onClose={() => {
            // Persist existing params
            setForceRequestModal(null);
            setShowRequestModal(false);
          }}
        />
      )}
      {showDeleteGroupModal && (
        <GroupDeleteModal
          group={group}
          showModal={showDeleteGroupModal}
          setShowModal={setShowDeleteGroupModal}
        />
      )}
      {showLinkGroupModal && (
        <GroupBindingManualLinkModal
          isOpen={showLinkGroupModal}
          onModalClose={() => {
            setShowLinkGroupModal(false);
            refetch();
          }}
          groupId={group.id}
        />
      )}
      {showEditGroupBindingModal && (
        <GroupBindingEditModal
          isOpen={showEditGroupBindingModal}
          onModalClose={() => {
            setShowEditGroupBindingModal(false);
            refetch();
          }}
          groupBindingIds={[group.groupBinding?.id ?? ""]}
        />
      )}
      {showUnlinkGroupModal && (
        <GroupBindingDeleteModal
          isOpen={showUnlinkGroupModal}
          onModalClose={() => {
            setShowUnlinkGroupModal(false);
            refetch();
          }}
          groupBindingIds={[group.groupBinding?.id ?? ""]}
        />
      )}
      {showRequestRedirectModal && (
        <GroupBindingRequestRedirectModal
          isOpen={showRequestRedirectModal}
          onModalClose={() => {
            setShowRequestRedirectModal(false);
          }}
          groupId={group.id}
          groupBindingId={group.groupBinding?.id ?? ""}
        />
      )}
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={breadcrumbs}
          actionIcons={actionIcons}
          includeDefaultActions
        />
        <ColumnContent>
          <ItemDetailsCard
            icon={
              oktaApp?.iconUrl
                ? { type: "src", icon: oktaApp.iconUrl }
                : {
                    type: "entity",
                    entityType:
                      group.connection?.connectionType || group.groupType,
                  }
            }
            title={group.name}
            titleAccessory={titleAccessory}
            subtitle={group.description || "--"}
            preMenuLayout={preMenuLayout}
            rightActions={rightActions}
            extraMenuOptions={menuOptions}
            footerFields={footerFields}
            tags={group.tags.map((tag) => ({
              id: tag.tagId,
              key: tag.tag?.key || "",
              value: tag.tag?.value || "",
            }))}
            messageChannels={group.auditMessageChannels?.map(
              (channel) => channel.name
            )}
          />
          <TabsV3 tabInfos={tabInfos} />
          {content}
        </ColumnContent>
      </Column>
    </>
  );
};

export default GroupDetailView;
