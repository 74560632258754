import { getModifiedErrorMessage, refetchQueries } from "api/ApiContext";
import {
  ErrorEntity,
  Maybe,
  useDeleteGroupsMutation,
  useDeleteResourcesMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Modal } from "components/ui";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";

type BulkDeleteModalProps = {
  onCancel: () => void;
  onComplete: () => void;
  resourceIds: string[];
  groupIds: string[];
};

export const BulkDeleteModal = (props: BulkDeleteModalProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const history = useHistory();

  const numChecked = props.resourceIds.length + props.groupIds.length;

  const [errorMessageDelete, setErrorMessageDelete] = useState<Maybe<string>>(
    null
  );
  const [errorEntities, setErrorEntities] = useState<ErrorEntity[]>([]);

  const [
    deleteResourcesMutation,
    { loading: deleteResourcesLoading },
  ] = useDeleteResourcesMutation({
    refetchQueries: ["ConnectionOverview", "ResourcesHome", "ItemsListSection"],
  });

  const [
    deleteGroupsMutation,
    { loading: deleteGroupsLoading },
  ] = useDeleteGroupsMutation({
    refetchQueries: ["ConnectionOverview", "GroupsHome", "ItemsListSection"],
  });

  const { displaySuccessToast } = useToast();

  const resetModal = () => {
    setErrorMessageDelete(null);
    setErrorEntities([]);
  };

  const handleSubmit = async () => {
    let hasError = false;
    // TODO: combine these 2 mutations into 1 mutation and simplify logic.
    if (props.resourceIds.length > 0) {
      hasError = true;
      try {
        const { data } = await deleteResourcesMutation({
          variables: {
            input: {
              resourceIds: props.resourceIds,
            },
          },
        });
        switch (data?.deleteResources.__typename) {
          case "DeleteResourcesResult":
            hasError = false;
            break;
          case "ResourceNotFoundError":
            setErrorMessageDelete(data.deleteResources.message);
            break;
          case "OpalResourceImmutableError":
            setErrorMessageDelete(data.deleteResources.message);
            break;
          default:
            logError(new Error(`failed to delete resources`));
            setErrorMessageDelete(`Error: failed to delete resources`);
        }
      } catch (error) {
        logError(error, "failed to delete resources");
        setErrorMessageDelete(
          getModifiedErrorMessage("Error: failed to delete resources", error)
        );
      }
    }
    if (props.groupIds.length > 0) {
      hasError = true;
      try {
        const { data } = await deleteGroupsMutation({
          variables: {
            input: {
              ids: props.groupIds,
            },
          },
        });
        switch (data?.deleteGroups.__typename) {
          case "DeleteGroupsResult":
            hasError = false;
            break;
          case "GroupNotFoundError":
          case "GroupHasChildrenError":
            logWarning(new Error(data.deleteGroups.message));
            setErrorMessageDelete(data.deleteGroups.message);
            break;
          default:
            logError(new Error(`failed to delete groups`));
            setErrorMessageDelete(`Error: failed to delete groups"`);
        }
      } catch (e) {
        logError(e, `failed to delete groups`);
        setErrorMessageDelete(`Error: failed to delete groups`);
      }
    }
    if (!hasError) {
      resetModal();
      if (hasV3) {
        props.onCancel();
        refetchQueries({ include: ["ConnectionPreview", "AppResources"] });
      } else {
        history.push("/apps");
      }
      props.onComplete();
      displaySuccessToast(
        `Success: ${numChecked} ${pluralize("item", numChecked)} deleted`
      );
    }
  };

  return (
    <Modal
      title={`Remove ${numChecked} ${pluralize("item", numChecked)} from Opal`}
      isOpen
      onClose={() => {
        resetModal();
        props.onCancel();
      }}
    >
      <Modal.Body>
        <p>
          <Banner
            message={`If your items have any expiring or on-call access provisioned through Opal,
            this access will become permanent on the end system once you remove the items.`}
            type="warning"
          />
        </p>
        <p>
          Are you sure you want to remove {numChecked}{" "}
          {pluralize("item", numChecked)} from Opal? This cannot be undone.
        </p>
        <p>Any children of this item will also be removed.</p>
        <p>
          Removing an item from Opal only deletes its configuration in Opal and
          does not cause access to be removed from the end system. This includes
          access provisioned through Opal.
        </p>
        {errorMessageDelete && (
          <ModalErrorMessage
            errorMessage={errorMessageDelete}
            errorEntities={errorEntities}
          />
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Remove"
        primaryButtonLoading={deleteResourcesLoading || deleteGroupsLoading}
        primaryButtonType="error"
      />
    </Modal>
  );
};
