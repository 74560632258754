import {
  UserPreviewSmallFragment,
  UserProductRole,
  useUsersQuery,
} from "api/generated/graphql";
import UploadCSVButton, {
  CSVColumns,
} from "components/buttons/UploadCSVButton";
import { UserInfo } from "components/modals/InviteTeammatesModal";
import pluralize from "pluralize";
import React from "react";
import { logError } from "utils/logging";

type UserUploadData = UserPreviewSmallFragment & {
  productRole: UserProductRole;
};

export type GroupUsersUploadCSVButtonProps = {
  setUserIds?: (usersIds: string[]) => void;
  onLoadUsers?: (users: UserUploadData[]) => void;
  setErrorMessage: (errorMessage: string) => void;
  groupUserIdsWithDirectAccess: string[];
};

/**
 * Button for uploading group users as a CSV, and populating the CSV data into data structures
 * as per `setUserIds` and `setUserInfosToAdd`.
 */
export const GroupUsersUploadCSVButton = (
  props: GroupUsersUploadCSVButtonProps
) => {
  const {
    setUserIds,
    onLoadUsers,
    setErrorMessage,
    groupUserIdsWithDirectAccess,
  } = props;

  const { refetch } = useUsersQuery({ skip: true, fetchPolicy: "no-cache" });

  const onUpload = async (uploadedUserInfos: UserInfo[]) => {
    const userEmails = uploadedUserInfos.map((userInfo) => userInfo.email);
    const { data, error } = await refetch({ input: { userEmails } });
    if (error) {
      logError(error, `failed to fetch users`);
      setErrorMessage(`Error: failed to fetch users`);
      return;
    }
    const uploadedUsers = data.users.users;

    if (setUserIds) {
      const newUserIds = uploadedUsers.map((user) => user.id);
      setUserIds(newUserIds);
    }

    const roleByEmail: Record<string, UserProductRole> = {};
    uploadedUserInfos.forEach((userInfo) => {
      roleByEmail[userInfo.email] = userInfo.productRole
        ? userInfo.productRole
        : UserProductRole.Member;
    });

    const duplicates: string[] = [];
    const newUsers: UserUploadData[] = [];
    uploadedUsers.forEach((user) => {
      const isDuplicate = groupUserIdsWithDirectAccess.includes(user.id);
      if (isDuplicate) {
        duplicates.push(user.email);
        return;
      }

      newUsers.push({
        ...user,
        productRole: roleByEmail[user.email] || UserProductRole.Member,
      });
    });

    if (onLoadUsers) {
      if (duplicates.length > 0) {
        const dupList = duplicates.join(", ");
        setErrorMessage(
          `Skipped ${duplicates.length} ${pluralize(
            "user",
            duplicates.length
          )} because they are already in the group: ${dupList}`
        );
      }
      onLoadUsers(newUsers);
    }
  };

  return (
    <UploadCSVButton
      onChangeUserInfos={onUpload}
      onChangeErrorMessage={props.setErrorMessage}
      requiredColumns={[CSVColumns.Email]}
      trackName="group_users"
    />
  );
};
