import { useGroupBindingsCountsQuery } from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { Divider, Tabs, TabsV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { usePageTitle } from "utils/hooks";
import GroupBindingsFilters from "views/group_bindings/filters/GroupBindingsFilters";
import GroupBindingsTable from "views/group_bindings/GroupBindingsTable";
import GroupBindingSuggestionsTable from "views/group_bindings/GroupBindingSuggestionsTable";

const GroupBindings = () => {
  const history = useHistory();
  const { data } = useGroupBindingsCountsQuery({
    fetchPolicy: "cache-and-network",
  });
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  usePageTitle("Linked Groups");

  const currentTab = () => {
    if (location.hash.includes("#suggestions")) {
      return "suggestions";
    }
    return "all";
  };

  const bindingsCount = data?.groupBindings?.totalNumItems;
  const suggestionsCount = data?.groupBindingSuggestions?.totalNumItems;
  return (
    <ColumnContainer>
      <Column isContent maxWidth="none">
        {hasV3Nav ? (
          <>
            <ColumnHeaderV3
              title="Linked Groups"
              icon={{ type: "name", icon: "link" }}
              includeDefaultActions
            />
            <div
              className={sprinkles({ alignSelf: "flex-start", width: "100%" })}
            >
              <TabsV3
                tabInfos={[
                  {
                    title: "All Linked Groups",
                    isSelected: currentTab() === "all",
                    badgeCount: bindingsCount,
                    onClick: () => history.push({ hash: "#all" }),
                  },
                  {
                    title: "Suggestions",
                    isSelected: currentTab() === "suggestions",
                    badgeCount: suggestionsCount,
                    onClick: () => history.push({ hash: "#suggestions" }),
                  },
                ]}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              })}
            >
              <Tabs
                round
                tabInfos={[
                  {
                    title: "All Linked Groups",
                    badgeCount: bindingsCount,
                    onClick: () => history.push({ hash: "#all" }),
                    isSelected: currentTab() === "all",
                  },
                  {
                    title: "Suggestions",
                    badgeCount: suggestionsCount,
                    onClick: () => history.push({ hash: "#suggestions" }),
                    isSelected: currentTab() === "suggestions",
                  },
                ]}
              />
            </div>
            <Divider />
          </>
        )}
        {currentTab() === "suggestions" ? (
          <>
            <GroupBindingsFilters />
            <GroupBindingSuggestionsTable />
          </>
        ) : (
          <>
            <GroupBindingsFilters />
            <GroupBindingsTable />
          </>
        )}
      </Column>
    </ColumnContainer>
  );
};

export default GroupBindings;
