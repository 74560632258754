import * as React from "react";

import * as styles from "./ColumnContent.css";

const ColumnContentNoOverflow = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{}>
>((props, ref) => (
  <div className={styles.containerNoOverflow} ref={ref}>
    {props.children}
  </div>
));

export default ColumnContentNoOverflow;
