import {
  AccessReviewReviewerFragment,
  EventWithSubEventInfoFragment,
  useAccessReviewEventsQuery,
  useAccessReviewReviewersQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Input, ProgressBar, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { filterSearchResults } from "utils/search/filter";
import useDebounce from "utils/search/useDebounce";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { ForbiddenPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";
import { dropNothings } from "views/utils";

import AccessReviewEventsCell from "./AccessReviewEventsCell";
import * as styles from "./AccessReviewReviewersV3.css";

interface AccessReviewReviewerRow {
  id: string;
  user: string;
  usersReviewed: number;
  groupResourcesReviewed: number;
  nhisReviewed: number;
  numEvents: number;
  data: {
    user?: {
      avatarUrl?: string;
    };
    totalUsersReviewed: number;
    totalGroupResourcesReviewed: number;
    totalNHIsReviewed: number;
    events?: EventWithSubEventInfoFragment[];
    eventsLoading?: boolean;
  };
}

type Props = {
  accessReviewId: string;
};

const AccessReviewReviewersV3 = ({ accessReviewId }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const { authState } = useContext(AuthContext);
  const isMember = hasBasicPermissions(authState.user);

  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  const ACCESS_REVIEW_REVIEWER_COLUMNS: Header<AccessReviewReviewerRow>[] = dropNothings(
    [
      {
        id: "user",
        label: "User",
        sortable: true,
        customCellRenderer: (row) => {
          return <div>{row.user}</div>;
        },
      },
      {
        id: "usersReviewed",
        label: "User Reviews",
        sortable: true,
        customCellRenderer: (row) => {
          const percent =
            (row.usersReviewed / row.data.totalUsersReviewed) * 100;
          if (row.data.totalUsersReviewed === 0) {
            return (
              <div
                className={sprinkles({
                  color: "gray700",
                })}
              >
                No user reviews assigned
              </div>
            );
          }
          return (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "md",
              })}
            >
              <div className={styles.progressBar}>
                <ProgressBar percentProgress={percent} />
              </div>
              <div className={styles.itemsReviewedLabel}>
                {`${row.usersReviewed}/${row.data.totalUsersReviewed} Users`}
              </div>
            </div>
          );
        },
      },
      {
        id: "groupResourcesReviewed",
        label: "Group Reviews",
        sortable: true,
        customCellRenderer: (row) => {
          const percent =
            (row.groupResourcesReviewed /
              row.data.totalGroupResourcesReviewed) *
            100;
          if (row.data.totalGroupResourcesReviewed === 0) {
            return (
              <div
                className={sprinkles({
                  color: "gray700",
                })}
              >
                No group reviews assigned
              </div>
            );
          }
          return (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "md",
              })}
            >
              <div className={styles.progressBar}>
                <ProgressBar percentProgress={percent} />
              </div>
              <div className={styles.itemsReviewedLabel}>
                {`${row.groupResourcesReviewed}/${row.data.totalGroupResourcesReviewed} Groups`}
              </div>
            </div>
          );
        },
      },
      hasNHIs
        ? {
            id: "nhisReviewed",
            label: "NHI Reviews",
            sortable: true,
            customCellRenderer: (row) => {
              const percent =
                (row.nhisReviewed / row.data.totalNHIsReviewed) * 100;
              if (row.data.totalNHIsReviewed === 0) {
                return (
                  <div
                    className={sprinkles({
                      color: "gray700",
                    })}
                  >
                    No NHI reviews assigned
                  </div>
                );
              }
              return (
                <div
                  className={sprinkles({
                    display: "flex",
                    alignItems: "center",
                    gap: "md",
                  })}
                >
                  <div className={styles.progressBar}>
                    <ProgressBar percentProgress={percent} />
                  </div>
                  <div className={styles.itemsReviewedLabel}>
                    {`${row.nhisReviewed}/${row.data.totalNHIsReviewed} NHIs`}
                  </div>
                </div>
              );
            },
          }
        : undefined,
      {
        id: "numEvents",
        label: "Events",
        sortable: true,
        customCellRenderer: (row) => {
          if (row.data.eventsLoading) {
            return <Skeleton variant="text" width="80px" />;
          }
          return <AccessReviewEventsCell events={row.data.events || []} />;
        },
      },
    ]
  );

  const { data, error, loading } = useAccessReviewReviewersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      accessReviewId,
    },
  });

  let reviewers: AccessReviewReviewerFragment[] = [];
  let hasError = false;
  if (data) {
    switch (data.accessReview.__typename) {
      case "AccessReviewResult":
        reviewers = data.accessReview.accessReview.reviewers || [];
        break;
      default:
        hasError = true;
        logWarning(new Error("error: access review schedule not found"));
    }
  } else if (error) {
    hasError = true;
    logError(error, `failed to retrieve access review schedule`);
  }

  const {
    data: eventsData,
    error: eventsError,
    loading: eventsLoading,
  } = useAccessReviewEventsQuery({
    variables: {
      input: {
        filter: {
          objects: {
            objectId: accessReviewId,
          },
        },
      },
    },
  });

  if (isMember) {
    return <ForbiddenPage />;
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (hasError) {
    return <UnexpectedErrorPage error={error} />;
  }

  if (eventsError) {
    // Log oncall error but don't fail the whole page
    logError(eventsError, `failed to retrieve access review events`);
  }

  const getEventsForObjectId = (objectId: string) => {
    if (eventsData?.events.__typename !== "EventsResult") {
      return [];
    }
    return eventsData.events.events.filter((event) => {
      return event.subEvents.some((subEvent) => {
        return [
          subEvent.objectId.entityId,
          subEvent.secondaryObjectId?.entityId,
          subEvent.tertiaryObjectId?.entityId,
          subEvent.object4Id?.entityId,
        ].includes(objectId);
      });
    });
  };

  reviewers = filterSearchResults(reviewers, debouncedSearchQuery, (reviewer) =>
    reviewer.user ? [reviewer.user.fullName, reviewer.user.email] : []
  );

  const rows = reviewers.map<AccessReviewReviewerRow>((reviewer) => {
    const events = getEventsForObjectId(reviewer.userId);

    const row: AccessReviewReviewerRow = {
      id: reviewer.userId,
      user: reviewer.user?.fullName || "",
      usersReviewed: reviewer.completedUsersCount,
      groupResourcesReviewed: reviewer.completedGroupResourcesCount,
      nhisReviewed: reviewer.completedNHIsCount,
      numEvents: events.length,
      data: {
        user: reviewer.user ?? undefined,
        totalUsersReviewed: reviewer.totalUsersCount,
        totalGroupResourcesReviewed: reviewer.totalGroupResourcesCount,
        totalNHIsReviewed: reviewer.totalNHIsCount,
        events,
        eventsLoading,
      },
    };

    return row;
  });

  return (
    <div
      className={sprinkles({
        height: "100%",
      })}
    >
      <div className={styles.searchInput}>
        <Input
          value={searchQuery}
          onChange={(value) => setSearchQuery(value)}
          placeholder="Filter"
          leftIconName="search"
        />
      </div>
      <TableHeader entityName="Reviewer" totalNumRows={rows.length} />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        loadingRows={loading}
        getRowId={(ru) => ru.id}
        columns={ACCESS_REVIEW_REVIEWER_COLUMNS}
        defaultSortBy="user"
        defaultSortDirection="ASC"
      />
    </div>
  );
};

export default AccessReviewReviewersV3;
