import {
  EntityType,
  HrIdpStatus,
  UserOverviewFragment,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { Icon } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import React from "react";

import * as styles from "./UserDirectReportsTable.css";

interface DirectReportRow {
  userId: string;
  fullName?: string;
  userAvatarUrl?: string;
  email: string;
  team: string;
  title: string;
  idpStatus?: HrIdpStatus;
}

const DIRECT_REPORT_COLUMNS: Header<DirectReportRow>[] = [
  {
    id: "fullName",
    label: "User",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div className={styles.userCellContainer}>
          <ResourceLabel
            text={row.fullName}
            avatar={row.userAvatarUrl}
            pointerCursor={true}
            entityId={row.userId}
            entityTypeNew={EntityType.User}
          />
        </div>
      );
    },
    width: 130,
  },
  {
    id: "email",
    label: "Email",
    customCellRenderer: (row) => {
      return <div className={styles.emailCell}>{row.email}</div>;
    },
    width: 130,
  },
  {
    id: "team",
    label: "Team",
    sortable: true,
  },
  {
    id: "title",
    label: "Title",
    sortable: true,
  },
  {
    id: "idpStatus",
    label: "",
    width: 32,
    customCellRenderer: (row) => {
      let iconName: PropsFor<typeof Icon>["name"] = "user";
      let color: PropsFor<typeof Icon>["color"] = "gray400";
      switch (row.idpStatus) {
        case HrIdpStatus.Active:
          iconName = "user-check";
          color = "gray400";
          break;
        default:
          iconName = "user-x";
          color = "red600";
          break;
      }
      return <Icon name={iconName} size="xs" color={color} />;
    },
  },
];

type UserDirectReportsTableProps = {
  user: UserOverviewFragment;
};

export const UserDirectReportsTable = (props: UserDirectReportsTableProps) => {
  const rows: DirectReportRow[] = props.user.directReports.map((user) => {
    const row = {
      userId: user.id,
      fullName: user.fullName,
      userAvatarUrl: user.avatarUrl,
      email: user.email ?? "--",
      team: user.teamAttr ?? "--",
      title: user.position ?? "--",
      idpStatus: user.hrIdpStatus ?? undefined,
    };
    return row;
  });

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: "md",
      })}
    >
      <TableHeader
        entityName={"Direct Report"}
        totalNumRows={props.user.directReports.length}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.userId}
        columns={DIRECT_REPORT_COLUMNS}
        defaultSortBy="fullName"
      />
    </div>
  );
};

export default UserDirectReportsTable;
