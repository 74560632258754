import { TimePeriod } from "api/generated/graphql";
import {
  GeneralSettingType,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import NotificationPeriod from "components/data_elements/NotificationPeriod";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  DataElementList,
  FormGroup,
  Input,
  Modal,
  Select,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useState } from "react";
import { logError } from "utils/logging";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import styles from "views/settings/OrgSettings.module.scss";

export type AccessExpiringNotificationSettingProps = {
  currentSettings: GeneralSettingType[];
  currentSchedule: number[];
};

export const AccessExpiringNotificationSetting = ({
  currentSettings,
  currentSchedule,
}: AccessExpiringNotificationSettingProps) => {
  const MAX_NOTIFICATION_COUNT = 10;

  type NotificationState = {
    timePeriod: TimePeriod;
    value: number;
  };
  const [notifState, setNotifState] = useState<NotificationState>({
    timePeriod: TimePeriod.Day,
    value: 1,
  });

  const { authState } = useContext(AuthContext);
  const { orgDispatch } = useContext(OrgContext);
  const { displaySuccessToast, displayErrorToast } = useToast();

  const [showModal, setShowModal] = useState(false);
  // The schedule is an array of integers representing the # of seconds prior to expiry to notify the user.
  const validSchedule = currentSchedule.filter((scheduleEntry) => {
    const d = moment.duration(scheduleEntry, "seconds");
    return d.isValid() && d.asHours() >= 1; // Check it's a valid number as is at least one hour
  });
  validSchedule.sort((a, b) => a - b);

  // NOTE: this manages the component's state diff and will be empty until the modal opens.
  // Use the value of validSchedule to render in the current settings state.
  const [notifSchedule, setNotifSchedule] = useState<number[]>([]);

  const [
    updateOrgSettings,
    { loading },
  ] = useUpdateOrganizationSettingsMutation();

  const modalReset = () => {
    setShowModal(false);
    setNotifState({ timePeriod: TimePeriod.Day, value: 1 });
    setNotifSchedule([]);
  };

  const modalInit = () => {
    setNotifSchedule(validSchedule);
    setShowModal(true);
  };

  const saveNotification = async () => {
    try {
      const { data } = await updateOrgSettings({
        variables: {
          input: {
            settings: currentSettings,
            accessExpiringNotifications: notifSchedule,
          },
        },
      });

      switch (data?.updateOrganizationSettings.__typename) {
        case "UpdateOrganizationSettingsResult":
          orgDispatch({
            type: OrgContextActionType.OrgSettings,
            payload: {
              orgSettings: data.updateOrganizationSettings.settings,
            },
          });

          modalReset();
          displaySuccessToast("Success: settings updated");
          break;
        case "AccessExpiringNotificationsInvalidError":
          logError(new Error(data.updateOrganizationSettings.message));
          displayErrorToast(data.updateOrganizationSettings.message);
          break;
        default:
          logError(new Error(`failed to update org settings`));
          displayErrorToast("Error: failed to update org settings");
      }
    } catch (error) {
      logError(error, "failed to update org settings");
    }
  };

  /**
   * Converts a notification state object to its representation in seconds.
   * @param notifState A NotificationState object representing when to send a notification.
   * @returns The number of seconds prior to expiry upon which to send a notification.
   */
  const notifStateToDuration = (notifState: NotificationState): number => {
    switch (notifState.timePeriod) {
      case TimePeriod.Day: {
        return moment.duration(notifState.value, "days").asSeconds();
      }
      case TimePeriod.Hour: {
        return moment.duration(notifState.value, "hours").asSeconds();
      }
      default: {
        return 0;
      }
    }
  };

  /**
   * Converts a # of seconds to a human readable duration representation.
   * @param scheduleEntry A number of seconds.
   * @returns A human readable translation of the encoded string.
   */
  const durationToReadableStr = (scheduleEntry: number): string => {
    const duration = moment.duration(scheduleEntry, "seconds");

    // We can't use plain .humanize() here as it has fuzzy precision that can come off as confusing, see
    // https://github.com/moment/moment/issues/348 for more details, and also defaults to bumping up to
    // a higher order time unit (e.g. 32 days => .days() gives back 1 as it's representing it as 1 month and 1 day).
    // Long-term solution is to stop using moment as it went into legacy mode ~ late 2020 and instead
    // pick a new lib like dayjs or just the native APIs.

    // Instead, we're going to walk up from the smallest to largest unit to build a string ourselves. This is
    // slightly rough in the edge case where someone inputs 25+ hours, as we convert that out of the format
    // they inputted it in (25 hours => 1 day and 1 hour) which forces a little more cognitive load on the user
    // but it seems a minor enough edge case not to worry about.

    const hours = duration.asHours(); // the as prefixed functions force moment to return only in those units
    const days = duration.asDays();

    // Render as X days {and Y hours} before, where the bracketed value only shows up if there's a # of hours
    // that's not an exact multiple of 24
    if (hours >= 24) {
      var humanDuration = "";
      const daysPlural = days >= 2 ? "s" : "";

      const adjustedHours = duration.hours();
      if (adjustedHours != 0) {
        const hoursPlural = adjustedHours > 1 ? "s" : "";
        const floorDays = Math.floor(days);
        humanDuration += `${floorDays} day${daysPlural} and ${adjustedHours} hour${hoursPlural}`;
      } else {
        humanDuration += `${days} day${daysPlural}`;
      }

      return humanDuration + " before";
    } else {
      // Render as X hours before
      const pluralization = hours > 1 ? "s" : "";
      return `${hours} hour${pluralization} before`;
    }
  };

  const updateNotification = (timeVal: number, timePeriod: TimePeriod) => {
    if (!isNaN(timeVal)) {
      const maxTimeVal = timePeriod == TimePeriod.Day ? 365 : 24 * 365;
      timeVal = timeVal > maxTimeVal ? maxTimeVal : timeVal;
      setNotifState({ value: timeVal, timePeriod: timePeriod });
    }
  };

  const addNotification = () => {
    const newSchedule = [...notifSchedule, notifStateToDuration(notifState)];
    newSchedule.sort((a, b) => a - b); // sort in asc order
    setNotifSchedule(newSchedule);
  };

  const removeNotification = (notif: number) => {
    setNotifSchedule(
      notifSchedule.filter((n) => {
        return n != notif;
      })
    );
  };

  const settingsPanel = (
    <div>
      <div>
        <div className={styles.switchesHeader}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={styles.switches}>
                <button
                  disabled={!authState.user?.isAdmin}
                  onClick={modalInit}
                  className={styles.orgSettingOpenModalButton}
                >
                  Edit
                </button>
              </div>

              <div className={styles.label}>
                Access expiration Notifications
              </div>
              <MoreInfo
                tooltipText={
                  "Configure when users are notified that their access to a resource will be expiring."
                }
              />
            </div>
            <div style={{ display: "flex", marginTop: "12px" }}>
              <DataElementList>
                {validSchedule.map((scheduleEntry: number) => {
                  return (
                    <NotificationPeriod
                      key={`aens-${scheduleEntry}`}
                      label={durationToReadableStr(scheduleEntry)}
                    />
                  );
                })}
              </DataElementList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const modal = (
    <Modal
      isOpen={showModal}
      title="Access expiration notifications"
      onClose={modalReset}
      fullWidth={true}
      maxWidth="sm"
    >
      <Modal.Body>
        <div className={sprinkles({ marginBottom: "lg" })}>
          Notifications will be sent to users before their access to a group or
          resource expires
        </div>
        <div className={sprinkles({ marginBottom: "md" })}>
          <FormGroup label="Add notification period">
            <div className={sprinkles({ display: "flex", gap: "sm" })}>
              <div style={{ width: "30%", marginRight: "2%" }}>
                <Input
                  type="number"
                  value={notifState.value}
                  onChange={(val?: number) => {
                    if (val) {
                      updateNotification(val, notifState.timePeriod);
                    }
                  }}
                  min={1}
                  max={
                    notifState.timePeriod === TimePeriod.Day ? 365 : 365 * 24
                  }
                  autoFocus
                />
              </div>
              <div style={{ width: "50%", marginRight: "2%" }}>
                <Select
                  options={[TimePeriod.Hour, TimePeriod.Day]}
                  onChange={(timePeriod?: TimePeriod) => {
                    if (timePeriod) {
                      updateNotification(notifState.value, timePeriod);
                    }
                  }}
                  getOptionLabel={(option: string) =>
                    option.toLowerCase() + "s before expiration"
                  }
                  value={notifState.timePeriod}
                />
              </div>
              <div style={{ width: "16%" }}>
                <Button
                  type="primary"
                  borderless
                  leftIconName="plus"
                  onClick={addNotification}
                  size="lg"
                  disabled={
                    notifState.value === undefined ||
                    notifSchedule.includes(notifStateToDuration(notifState)) ||
                    notifSchedule.length >= MAX_NOTIFICATION_COUNT
                  }
                />
              </div>
            </div>
          </FormGroup>
        </div>
        <div className={sprinkles({ marginBottom: "md" })}>
          <DataElementList>
            {notifSchedule.map((scheduleEntry) => (
              <NotificationPeriod
                key={`aen-${scheduleEntry}`}
                label={durationToReadableStr(scheduleEntry)}
                handleDelete={() => removeNotification(scheduleEntry)}
              />
            ))}
          </DataElementList>
        </div>
        {notifSchedule.length == 0 && (
          <Banner
            type="warning"
            message={
              "You will not be notified that your access to a resource is expiring, as no notification periods have been set."
            }
            isGlobal={false}
            marginBottom="sm"
          />
        )}
        {notifSchedule.length == MAX_NOTIFICATION_COUNT && (
          <Banner
            type="warning"
            message={`You can have at most ${MAX_NOTIFICATION_COUNT} time periods defined for sending access expiration notifications.`}
            isGlobal={false}
            marginBottom="sm"
          />
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        primaryButtonDisabled={_.isEqual(validSchedule, notifSchedule)}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={saveNotification}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={modalReset}
      ></Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {settingsPanel}
      {modal}
    </div>
  );
};

export default AccessExpiringNotificationSetting;
