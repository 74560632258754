import {
  EntityType,
  ResourceGroupFragment,
  ResourcePreviewLargeFragment,
} from "api/generated/graphql";
import { getAccessPathsSortValue } from "components/label/AccessPointsLabel";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { IndexRange, SortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import { PropagationType } from "utils/useRemediations";
import {
  getGroupResourceAccessPathsInfo,
  GroupResourceAccessPointsLabel,
} from "views/resources/GroupResourceAccessPointsLabel";

import { AppsContext } from "../apps/AppsContext";

interface ContainingGroupsTableRow {
  name: string;
  role: string;
  accessPaths: string;
  connection: string;
  status: string;
}

type ResourceContainingGroupsTableProps = {
  resource: ResourcePreviewLargeFragment;
  resourceGroups: ResourceGroupFragment[];
  denseFormatting?: boolean;
  loadMoreRows?: (params: IndexRange) => Promise<void>;
  allRowsLoaded: boolean;
  urlOverride?: EntityType;
  showUnmanagedGroups: boolean;
};

export const ResourceContainingGroupsTable = (
  props: ResourceContainingGroupsTableProps
) => {
  const history = useHistory();

  const { toggleUnmanagedItem } = useContext(AppsContext);

  const headers: Header<ContainingGroupsTableRow>[] = [
    { id: "name", label: "Name", width: 305, minWidth: 180 },
    { id: "role", label: "Role", width: 200, minWidth: 160 },
    {
      id: "accessPaths",
      label: "Source of Access",
      width: 170,
      minWidth: 170,
    },
    { id: "connection", label: "App", width: 200, minWidth: 180 },
    { id: "status", label: "Status", width: 80, minWidth: 80 },
  ];

  const rows: CellRow<ContainingGroupsTableRow>[] = [];
  for (const resourceGroup of props.resourceGroups) {
    const group = resourceGroup.group;
    const groupId = resourceGroup.groupId;

    const name = group?.name || groupId || "";
    const connection = group?.connection;
    const connectionId = group?.connectionId;

    if (!props.showUnmanagedGroups && !group?.isManaged) {
      continue;
    }

    const accessPathsInfo = getGroupResourceAccessPathsInfo({
      group: resourceGroup.group,
      resource: props.resource,
      access: resourceGroup.access,
    });
    rows.push({
      id: groupId,
      data: {
        name: {
          value: name,
          element: (
            <GroupTableCellResourceLabel
              groupName={name}
              groupType={group?.groupType}
              isOnCall={group?.isOnCallSynced}
              isManaged={group?.isManaged}
            />
          ),
          sortValue: name?.toLowerCase(),
          clickHandler: () => {
            if (group && group.isManaged) {
              history.push(
                getResourceUrlNew({
                  entityId: groupId,
                  entityType: EntityType.Group,
                })
              );
            } else {
              if (group) {
                toggleUnmanagedItem(group);
              }
            }
          },
        },
        role: {
          value: resourceGroup.accessLevel?.accessLevelName || "",
          element: (
            <div>{resourceGroup.accessLevel?.accessLevelName || "--"}</div>
          ),
        },
        accessPaths: {
          value: resourceGroup.groupId,
          sortValue: getAccessPathsSortValue(
            accessPathsInfo.hasDirectAccess,
            accessPathsInfo.accessPointRows.length
          ),
          element: (
            <GroupResourceAccessPointsLabel
              accessPathsInfo={accessPathsInfo}
              group={resourceGroup.group}
              resource={props.resource}
              role={resourceGroup.accessLevel}
              access={resourceGroup.access}
            />
          ),
        },
        connection: {
          sortValue: connection?.name || "",
          value: connectionId || "",
          element: connection ? (
            <ConnectionLabel
              text={connection?.name}
              connectionId={connection?.id}
              connectionType={connection?.connectionType}
              pointerCursor={true}
            />
          ) : (
            <>{`--`}</>
          ),
        },
        status: {
          value: groupId,
          sortValue: resourceGroup.propagationStatus?.statusCode,
          element: (
            <PropagationStatusLabelWithModal
              propagationType={PropagationType.GroupResource}
              propagationStatus={resourceGroup.propagationStatus}
              isAccessReview={false}
              entityInfo={{
                group: resourceGroup.group,
                resource: props.resource,
                role: resourceGroup.accessLevel,
              }}
            />
          ),
        },
      },
    });
  }

  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"name"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded={props.allRowsLoaded}
      loadMoreRows={props.loadMoreRows}
    />
  );
};

export default ResourceContainingGroupsTable;
