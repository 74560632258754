import { EntityType, Visibility } from "api/generated/graphql";
import { BundleConfig } from "components/forms/common";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import FullscreenView from "components/layout/FullscreenView";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { Banner, Divider, FormGroup, Input } from "components/ui";

interface Props {
  config: Partial<BundleConfig>;
  onChange: (config: Partial<BundleConfig>) => void;
  errors: string[];
  isImport?: boolean;
}

const BundleEditForm = (props: Props) => {
  const { config } = props;

  const handleChange = (key: keyof BundleConfig) => (
    val: BundleConfig[keyof BundleConfig]
  ) => {
    props.onChange({
      ...config,
      [key]: val,
    });
  };

  return (
    <>
      <FullscreenView.Sidebar>
        <FormGroup label="Name">
          <Input
            value={config.name}
            onChange={handleChange("name")}
            placeholder="Enter name"
          />
        </FormGroup>
        <FormGroup label="Description">
          <Input
            value={config.description || ""}
            onChange={handleChange("description")}
            placeholder="Enter description"
            type="textarea"
          />
        </FormGroup>
        <FormGroup label="Admin">
          <OwnerDropdown
            selectedOwnerId={config.adminOwner?.id}
            onSelectOwner={(owner?) => {
              // Works around some type issues, since BundleConfig.adminOwner must be defined.
              // This effectively ignores updates setting adminOwner to null
              const handler = handleChange("adminOwner");
              if (!owner) {
                return;
              } else {
                handler(owner);
              }
            }}
          />
        </FormGroup>
      </FullscreenView.Sidebar>
      <FullscreenView.Content>
        {props.errors.map((error) => (
          <Banner message={error} type="error" marginBottom="md" />
        ))}
        <div>
          <FormGroup label="Who can view and request this item">
            <VisibilityRow
              mode="edit"
              entityType={EntityType.Bundle}
              visibility={config.visibility}
              visibilityGroups={config.visibilityGroupsIds ?? []}
              onChangeVisibilityAndGroups={(val: {
                visibility?: Visibility;
                groupIds?: string[];
                setDoNotChange?: boolean;
              }) => {
                const { visibility, groupIds, setDoNotChange } = val;
                if (setDoNotChange) {
                  props.onChange({
                    ...props.config,
                    visibility: undefined,
                    visibilityGroupsIds: undefined,
                  });
                  return;
                }
                const visDict = visibility ? { visibility } : {};
                const visGroupsDict = groupIds
                  ? { visibilityGroupsIds: groupIds }
                  : {};
                props.onChange({
                  ...props.config,
                  ...visDict,
                  ...visGroupsDict,
                });
              }}
              contentOnly
              isV3
            />
          </FormGroup>
        </div>
        <Divider margin="lg" />
        <FormGroup label="Request configuration">
          <div>
            Request configurations for bundles are managed on the resource
            level. To view or change the request configurations for resources
            within this bundle, you will need to view each resource
            individually.
          </div>
        </FormGroup>
      </FullscreenView.Content>
    </>
  );
};

export default BundleEditForm;
