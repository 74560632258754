import {
  createStyles,
  Input,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";

export const MUI_OVERRIDE_COLORS = {
  primaryBlue: "#3D6ECC",
  primaryLightBlue: "#d8e2f4",
  primaryGray: "#656565",
  primaryLightGray: "#808080",
};

export const CustomTooltip = withStyles(() =>
  createStyles({
    tooltip: {
      padding: "8px 12px 8px 12px",
      background: "rgba(0, 0, 0, 0.8)",
      borderRadius: "8px",
      fontStyle: "Normal",
    },
    arrow: {
      color: "rgba(0, 0, 0, 0.8)",
    },
  })
)(Tooltip);

export const CustomTooltipTypography = withStyles(() =>
  createStyles({
    subtitle1: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.2,
    },
  })
)(Typography);

// Used to render Select items in our theme
export const CustomInputDark = withStyles(() =>
  createStyles({
    root: {
      color: "white",
    },
    input: {
      height: "28px",
      fontWeight: 600,
      padding: "12px",
      width: "500px",
      fontSize: "16px",
    },
    focused: {},
    underline: {
      borderBottom: "1px solid white",
      "&:after": {
        borderBottom: "3px solid white",
      },
    },
    error: {
      "&$error": {
        borderBottom: "1px solid #EA7A90",
        color: "#EA7A90",
      },
      "&$error:after": {
        borderBottom: "1px solid #EA7A90",
        color: "#EA7A90",
      },
    },
  })
)(Input);
