import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupGroupInput,
  ConnectionType,
  GroupAccessLevel,
  GroupDropdownPreviewFragment,
  GroupPreviewWithGroupsFragment,
  GroupType,
  useAddGroupGroupsMutation,
  useGroupAddGroupsQuery,
  useGroupQuery,
  usePaginatedGroupDropdownLazyQuery,
  useSearchGroupsQuery,
} from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Select,
} from "components/ui";
import List from "components/ui/list/ListV3";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import { useToast } from "../../components/toast/Toast";
import { groupTypeHasRoles } from "../../utils/directory/groups";
import * as styles from "./GroupAddMemberGroups.css";

const PAGE_SIZE = 100;

interface ListItem {
  id: string;
  icon: IconData;
  name: string;
  connectionType?: ConnectionType;
  groupType?: GroupType;
}

const GroupAddMemberGroupsView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { groupId } = useParams<{ groupId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [groupById, setGroupById] = useState<{
    [groupId: string]: GroupDropdownPreviewFragment;
  }>({});
  const [groupsByConnectionId, setGroupsByConnectionId] = useState<{
    [connectionId: string]: GroupDropdownPreviewFragment[];
  }>({});
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, GroupAccessLevel[]>
  >({});
  const [addError, setAddError] = useState("");

  const { displaySuccessToast } = useToast();

  const [getGroups] = usePaginatedGroupDropdownLazyQuery();
  const { data, loading, error } = useGroupAddGroupsQuery({
    variables: {
      groupId,
    },
  });

  const {
    data: searchGroupsData,
    loading: searchGroupsLoading,
    error: searchGroupsError,
  } = useSearchGroupsQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },

    skip: debouncedSearchQuery === "",
  });
  const [addGroupGroups, { loading: addLoading }] = useAddGroupGroupsMutation();
  const { refetch: refetchGroup } = useGroupQuery({ skip: true });

  let group: GroupPreviewWithGroupsFragment | undefined;
  if (data?.group.__typename === "GroupResult") {
    group = data.group.group;
  }
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numGroups)
  );
  const allRoles = group?.accessLevels ?? [];

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!group?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!group || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const groupHasRoles = groupTypeHasRoles(group.groupType);
  const directRolesByGroupId: Record<string, GroupAccessLevel[]> = {};

  group.memberGroups.forEach((groupGroup) => {
    // Update this when access is implemented
    // if (!groupGroup.access.directAccessPoint) {
    //   return;
    // }
    if (!directRolesByGroupId[groupGroup.memberGroupId]) {
      directRolesByGroupId[groupGroup.memberGroupId] = [];
    }
    directRolesByGroupId[groupGroup.memberGroupId] = _.uniqBy(
      [
        ...directRolesByGroupId[groupGroup.memberGroupId],
        groupGroup.accessLevel,
      ],
      "accessLevelRemoteId"
    );
  });

  const handleClose = () => {
    transitionBack(`/groups/${groupId}#groups`);
  };

  const handleFetchGroups = async (connectionId: string) => {
    try {
      const { data } = await getGroups({
        variables: {
          input: {
            connectionIds: [connectionId],
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      setGroupsByConnectionId((resourcesByConnectionId) => {
        return {
          ...resourcesByConnectionId,
          [connectionId]:
            data?.groups.groups.filter((g) => g.id !== group?.id) ?? [],
        };
      });

      setGroupById((groupById) => {
        return {
          ...groupById,
          ...data?.groups.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {} as typeof groupById),
        };
      });
    } catch (err) {
      logError(err, "Failed to fetch groups for connection " + connectionId);
    }
  };

  const title = (
    <>
      Add Groups:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <EntityIcon type={group.groupType} size="lg" />
      </div>
      {group.name}
    </>
  );

  const numGroupsToAdd = Object.keys(roleByGroupIdToAdd).length;

  const disabledGroupIds = new Set();
  group.memberGroups.forEach((groupGroup) => {
    const numDirectRoles =
      directRolesByGroupId[groupGroup.memberGroupId].length ?? 0;
    if (numDirectRoles != 0) {
      disabledGroupIds.add(groupGroup.memberGroupId);
    }
  });

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_resource_to_groups",
      properties: {
        numGroupsAddedTo: Object.entries(roleByGroupIdToAdd).length,
      },
    });
    try {
      const groupGroupsToAdd: AddGroupGroupInput[] = [];
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        if (roles.length === 0) {
          // If role requires a role, but none are selected,
          // show an error.
          if (groupHasRoles) {
            setAddError("Please select at least one role for each group.");
            return;
          } else {
            // If group does not require roles,
            // add an empty role to add the group directly.
            groupGroupsToAdd.push({
              memberGroupId: groupId,
              containingGroupId: group?.id!,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
            });
          }
        }

        roles.forEach((role) => {
          groupGroupsToAdd.push({
            memberGroupId: groupId,
            containingGroupId: group?.id!,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
          });
        });
      }

      const { data } = await addGroupGroups({
        variables: {
          input: {
            groupGroups: groupGroupsToAdd,
          },
        },
      });
      switch (data?.addGroupGroups.__typename) {
        case "AddGroupGroupsResult":
          if (data.addGroupGroups.taskId) {
            startPushTaskPoll(data.addGroupGroups.taskId, {
              refetchOnComplete: { groupId: groupId },
              onComplete() {
                // Refetch group to update group user propagations, this is
                // sort of a magic number (2s), we should keep track of all the
                // generated pushTaskIDs from the subtasks that are spawned
                // when propagating nested group users
                setTimeout(() => {
                  refetchGroup({
                    input: { id: groupId },
                  });
                }, 2000);
              },
            });
          } else {
            displaySuccessToast(
              `Successfully added ${pluralize(
                "group",
                groupGroupsToAdd.length,
                true
              )}`
            );
          }
          handleClose();
          break;
        case "GroupNotFoundError":
        case "GroupGroupAlreadyExists":
          setAddError(data.addGroupGroups.message);
          break;
        case "GroupNestingNotAllowedError": {
          const fromGroupName =
            data.addGroupGroups.fromGroup?.name ?? "Unknown group";
          const toGroupName =
            data.addGroupGroups.toGroup?.name ?? "Unknown group";
          setAddError(
            `Error: Group nesting not allowed. Cannot add ${fromGroupName} group to ${toGroupName} group,` +
              ` as it would create a circular dependency. Contact your admin for assistance.`
          );
          break;
        }
        default:
          logError(new Error(`failed to add groups to group`));
          setAddError("Error: failed to add groups to group");
      }
    } catch (error) {
      logError(error, "failed to add groups to group");
      setAddError(
        getModifiedErrorMessage("Error: failed to add groups to group", error)
      );
    }
  };

  const renderConnectionsList = () => {
    const listItems: ListItem[] = connections.map((connection) => {
      return {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
    });

    return (
      <List
        items={listItems}
        getItemKey={(item) => item.id}
        getItemLabel={(item) => item.name}
        getItemSublabel={(item) => {
          if (disabledGroupIds.has(item.id)) {
            return "Already in group";
          }
          return "";
        }}
        getItemDisabled={(item) => {
          return disabledGroupIds.has(item.id);
        }}
        getIcon={(item) => item.icon}
        getActionLabel={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "Remove" : "Add";
          }
          return "";
        }}
        getActionIcon={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "x" : "plus";
          }
        }}
        onSelectItem={(item) => {
          if (item.connectionType) {
            setExpandedItems((expandedItems) => {
              if (expandedItems.includes(item.id)) {
                return expandedItems.filter((id) => id !== item.id);
              } else {
                return [...expandedItems, item.id];
              }
            });
            if (item.connectionType && !groupsByConnectionId[item.id]) {
              handleFetchGroups(item.id);
            }
          } else {
            setRoleByGroupIdToAdd((prev) => {
              const newRoles = { ...prev };
              if (item.id in newRoles) {
                delete newRoles[item.id];
                return newRoles;
              } else {
                return {
                  ...prev,
                  [item.id]: [],
                };
              }
            });
          }
        }}
        hasNestedItems={(item) => Boolean(item.connectionType)}
        getNestedItems={(item) => {
          const groups = groupsByConnectionId[item.id];
          return groups?.map((group) => {
            const iconData: IconData = {
              type: "entity",
              entityType: group.groupType,
            };
            return {
              id: group.id,
              icon: iconData,
              name: group.name,
              groupType: group.groupType,
            };
          });
        }}
        expandedItems={expandedItems}
      />
    );
  };

  const renderSearchList = () => {
    if (searchGroupsError) {
      return <ModalErrorMessage errorMessage={searchGroupsError.message} />;
    }
    return (
      <List
        items={
          searchGroupsData?.groups.groups.filter((g) => g.id !== group?.id) ??
          []
        }
        loading={loading || searchGroupsLoading}
        getItemKey={(item) => item.id}
        getItemLabel={(item) => item.name}
        getIcon={(item) => {
          return {
            type: "entity",
            entityType: item.groupType,
          };
        }}
        getItemSublabel={(item) => {
          if (disabledGroupIds.has(item.id)) {
            return "Already in group";
          }
          return "";
        }}
        getItemDisabled={(item) => {
          return disabledGroupIds.has(item.id);
        }}
        noItemsMessage="No search results"
        onSelectItem={(item) => {
          setRoleByGroupIdToAdd((prev) => {
            const newRoles = { ...prev };
            if (item.id in newRoles) {
              delete newRoles[item.id];
              return newRoles;
            } else {
              return {
                ...prev,
                [item.id]: [],
              };
            }
          });
          setGroupById((groupById) => {
            return {
              ...groupById,
              [item.id]: item,
            };
          });
        }}
        getActionLabel={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "Remove" : "Add";
          }
          return "";
        }}
        getActionIcon={(item) => {
          if (item.groupType) {
            return roleByGroupIdToAdd[item.id] ? "x" : "plus";
          }
        }}
      />
    );
  };

  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numGroupsToAdd === 0}
      primaryButtonLabel={`Add ${
        numGroupsToAdd ? numGroupsToAdd : ""
      } ${pluralize("group", numGroupsToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select groups to add the group to:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 groups in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numGroupsToAdd} {pluralize("Group", numGroupsToAdd)}
        </div>
        {Object.keys(roleByGroupIdToAdd).map((groupId) => {
          const group = groupById[groupId];
          if (!group || !group.connection) {
            return null;
          }
          const existingRoles = directRolesByGroupId[groupId] || [];
          const rolesToAdd = roleByGroupIdToAdd[groupId] || [];
          const roleOptions = allRoles.filter((role) => {
            return ![...existingRoles, ...rolesToAdd].some(
              (existingRole) =>
                existingRole.accessLevelRemoteId === role.accessLevelRemoteId
            );
          });

          return (
            <div key={group.id} className={styles.resourceCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                })}
              >
                <div
                  className={sprinkles({
                    flexShrink: 0,
                  })}
                >
                  <EntityIcon
                    type={group.connection.connectionType}
                    iconStyle="rounded"
                  />
                </div>
                <div className={styles.resourceInfoSection}>
                  <div className={styles.resourceCardHeader}>{group.name}</div>
                  <div className={styles.resourceCardSubtitle}>
                    {group.connection.name}
                  </div>
                  <div className={styles.resourceCardType}>
                    <EntityIcon type={group.groupType} includeBrand={false} />
                    {groupTypeInfoByType[group.groupType].name}
                  </div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setRoleByGroupIdToAdd((prev) => {
                        const newRoles = { ...prev };
                        delete newRoles[group.id];
                        return newRoles;
                      });
                    }}
                  />
                </div>
              </div>
              {groupHasRoles && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    options={roleOptions}
                    loading={loading}
                    placeholder="Select role"
                    getOptionLabel={(role) => role.accessLevelName}
                    onChange={(role) => {
                      if (role) {
                        setRoleByGroupIdToAdd((prev) => {
                          const newRoles = { ...prev };
                          newRoles[groupId] = [
                            ...(newRoles[groupId] || []),
                            role,
                          ];
                          return newRoles;
                        });
                      }
                    }}
                    selectOnly
                  />
                  {rolesToAdd.map((role) => {
                    return (
                      <div
                        key={role.accessLevelRemoteId}
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            setRoleByGroupIdToAdd((prev) => {
                              const newRoles = { ...prev };
                              newRoles[groupId] = newRoles[groupId].filter(
                                (existingRole) =>
                                  existingRole.accessLevelRemoteId !==
                                  role.accessLevelRemoteId
                              );
                              return newRoles;
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default GroupAddMemberGroupsView;
