import {
  ConnectionUserPropagationStatusFragment,
  GroupPropagationStatusFragment,
  GroupResourcePropagationStatusFragment,
  Maybe,
  PropagationStatusFragment,
  ResourcePropagationStatusFragment,
  ResourceUserPropagationMetadataFragment,
  SupportTicketPreviewFragment,
} from "api/generated/graphql";
import { PropagationStatusLabel } from "components/propagation/PropagationStatusLabel";
import PropagationStatusModal from "components/propagation/PropagationStatusModal";
import React, { useEffect, useState } from "react";
import { PropagatedEntityInfo, PropagationType } from "utils/useRemediations";

export type PropagationStatus =
  | ResourcePropagationStatusFragment
  | GroupPropagationStatusFragment
  | GroupResourcePropagationStatusFragment
  | ConnectionUserPropagationStatusFragment
  | PropagationStatusFragment;

export type PropagationMetadata = ResourceUserPropagationMetadataFragment;

type PropagationStatusLabelWithModalProps = {
  propagationType: PropagationType;
  propagationStatus: Maybe<PropagationStatus> | undefined;
  isAccessReview: boolean;
  entityInfo: PropagatedEntityInfo;
};

export const PropagationStatusLabelWithModal = (
  props: PropagationStatusLabelWithModalProps
) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // The relevant entity has been deleted or changed, so we no longer want the modal to be open.
    setShowModal(false);
  }, [
    props.entityInfo.user?.id,
    props.entityInfo.resource?.id,
    props.entityInfo.group?.id,
  ]);

  let propagationTickets: SupportTicketPreviewFragment[] | undefined;
  switch (props.propagationStatus?.__typename) {
    case "ResourcePropagationStatus":
      if (props.propagationStatus.metadata?.ticket) {
        propagationTickets = [props.propagationStatus.metadata?.ticket];
      }
      break;
    case "GroupPropagationStatus":
      propagationTickets =
        props.propagationStatus.ticketPropagation?.resourceTickets
          .filter((resourceTicket) => resourceTicket.ticket ?? false)
          // we already filter out the nulls above, but in the TS version we
          // use we need to do this to make the type checker
          .map(
            (resourceTicket) =>
              resourceTicket.ticket as SupportTicketPreviewFragment
          ) ?? undefined;
  }

  return (
    <>
      <div
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        <PropagationStatusLabel
          propagationStatusCode={props.propagationStatus?.statusCode}
          propagationTicket={
            propagationTickets && propagationTickets?.length > 0
              ? propagationTickets[0]
              : undefined
          }
          taskType={props.propagationStatus?.taskType}
          pointerCursor
        />
      </div>
      {showModal && (
        <PropagationStatusModal
          propagationStatusInfos={[
            {
              propagationType: props.propagationType,
              propagationStatus: props.propagationStatus,
              isAccessReview: props.isAccessReview,
              entityInfo: props.entityInfo,
              propagationTickets: propagationTickets,
            },
          ]}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

export default PropagationStatusLabelWithModal;
