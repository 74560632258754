import { EntityType, EventStreamFragment } from "api/generated/graphql";
import { EventStreamConnection } from "components/event_streaming/EventStreamConnection";
import TableHeader from "components/ui/table/TableHeader";
import React from "react";

import * as styles from "./EventStreamConnections.css";

type EventStreamConnectionsProps = {
  eventStreams: EventStreamFragment[];
};

export const EventStreamConnections = (props: EventStreamConnectionsProps) => {
  const eventStreams = props.eventStreams;
  const content = eventStreams.map((eventStream) => {
    return <EventStreamConnection eventStream={eventStream} />;
  });

  return (
    <>
      <div className={styles.container}>
        <TableHeader
          entityType={EntityType.EventStreamConnection}
          totalNumRows={eventStreams.length}
          defaultRightActions={[
            {
              label: "Event Streaming Connection",
              type: "main",
              onClick: () => alert("DF-188"),
              iconName: "plus",
              disabledTooltip:
                eventStreams.length >= 3
                  ? "You can only have 3 Event Streaming Connections"
                  : undefined,
            },
          ]}
        />
        <div className={styles.contentContainer}>{content}</div>
      </div>
    </>
  );
};
