import clsx from "clsx";
import { brandIconSet } from "components/ui/entityicon/brandIconSet";
import { brandSquareIconSet } from "components/ui/entityicon/brandSquareIconSet";
import { customIconSet } from "components/ui/icon/customIconSet";
import { featherIconSet } from "components/ui/icon/featherIconSet";
import { proIconSet } from "components/ui/icon/proIconSet";
import ImgWithFallback from "components/ui/image/ImgWithFallback";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { colorVars } from "css/vars.css";
import React, { MouseEventHandler } from "react";

import * as styles from "./Icon.css";

export type IconSize =
  | "xxs"
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "lgr"
  | "xl"
  | "xxl"
  | "xxxl"
  | "entitySm"
  | "entityMd"
  | "entityLg";
type IconStyle = "default" | "rounded";

interface IconProps {
  name?: IconName;
  /** externalIconUrl should only be used if you are unable to import the URL at compile time.
   *  If you know the URL at compile time, you should instead add the URL to customIconSet. */
  externalIconUrl?: string;
  data?: IconData;
  brandIcon?: keyof typeof brandIconSet;
  /** default = md */
  size?: IconSize;
  iconStyle?: IconStyle;
  onClick?: MouseEventHandler;
  color?: colorVars;
}

export type IconName =
  | keyof typeof featherIconSet
  | keyof typeof customIconSet
  | keyof typeof proIconSet
  | keyof typeof brandSquareIconSet;

const Icon: React.FC<IconProps> = (props) => {
  let style = props.iconStyle;
  if (props.data && "style" in props.data && props.data.style) {
    style = props.data.style;
  }
  const iconStyle = clsx(
    styles.icon({
      size: props.size,
      style,
      clickable: props.onClick !== undefined,
    }),
    sprinkles({ color: props.color })
  );

  const getIcon = (iconData?: IconData) => {
    if (!iconData) return null;

    if (iconData.type === "src") {
      return (
        <ImgWithFallback
          className={iconStyle}
          src={iconData.icon}
          fallbackSrc={iconData.fallbackIcon}
          alt=""
          aria-hidden={true}
          onClick={props.onClick}
        />
      );
    }

    if (iconData.type === "name" && iconData.icon) {
      if (iconData.icon in brandSquareIconSet) {
        const IconContent =
          brandSquareIconSet[iconData.icon as keyof typeof brandSquareIconSet];
        return <IconContent className={iconStyle} onClick={props.onClick} />;
      }
      if (iconData.icon in proIconSet) {
        const IconContent =
          proIconSet[iconData.icon as keyof typeof proIconSet];
        return <IconContent className={iconStyle} onClick={props.onClick} />;
      }
      if (iconData.icon in featherIconSet) {
        const IconContent =
          featherIconSet[iconData.icon as keyof typeof featherIconSet];
        return <IconContent className={iconStyle} onClick={props.onClick} />;
      }

      if (iconData.icon in customIconSet) {
        return (
          <img
            className={iconStyle}
            src={customIconSet[iconData.icon as keyof typeof customIconSet]}
            alt=""
            aria-hidden={true}
            onClick={props.onClick}
          />
        );
      }
    }

    return null;
  };

  const getBrandIcon = (iconName?: keyof typeof brandIconSet) => {
    if (!iconName) return null;
    const IconContent = brandIconSet[iconName];
    return <IconContent className={iconStyle} onClick={props.onClick} />;
  };

  const getIconData = (): IconData | undefined => {
    if (props.data) return props.data;
    if (props.name) {
      return {
        type: "name",
        icon: props.name,
      };
    }
    if (props.externalIconUrl) {
      return {
        type: "src",
        icon: props.externalIconUrl,
      };
    }
    return undefined;
  };

  const primaryIcon = getIcon(getIconData());
  if (!primaryIcon) {
    return null;
  }

  const brandIcon = getBrandIcon(props.brandIcon);
  if (brandIcon) {
    return (
      <div
        className={clsx(
          styles.containerWithSubIcon({
            size: props.size,
            clickable: props.onClick !== undefined,
          }),
          sprinkles({ color: props.color })
        )}
      >
        <div className={styles.primaryIcon}>{primaryIcon}</div>
        <div className={styles.brandIcon}>{brandIcon}</div>
      </div>
    );
  }

  return primaryIcon;
};

export default Icon;
