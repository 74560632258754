import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewLargeFragment,
  IdpConnectionType,
  Maybe,
  useCreateIdpConnectionMutation,
} from "api/generated/graphql";
import googleLogo from "assets/logos/google-logo.svg";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import styles from "views/idp/create/CreateConnection.module.scss";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";

const CreateGoogle = () => {
  return (
    <div className={styles.container}>
      <CreateGoogleForm />
    </div>
  );
};

interface CreateGoogleState {
  connection: ConnectionPreviewLargeFragment;
}

const CreateGoogleForm = () => {
  const { orgDispatch } = useContext(OrgContext);
  const history = useHistory();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const location = useLocation<CreateGoogleState>();

  if (!location.state?.connection.id) {
    history.push("/settings");
  }

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [customerId, setCustomerId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [
    createIdpConnectionMutation,
    { loading },
  ] = useCreateIdpConnectionMutation();

  const fieldUnset = customerId === "" || userEmail === "";

  const onSubmit = async () => {
    try {
      const { data } = await createIdpConnectionMutation({
        variables: {
          input: {
            idpConnectionType: IdpConnectionType.Google,
            existingConnectionId: location.state?.connection.id,
          },
        },
        refetchQueries: ["IdpConnection"],
      });
      switch (data?.createIdpConnection.__typename) {
        case "CreateIdpConnectionResult":
          displaySuccessToast(`Success: Google IDP connection created`);

          history.push("/settings");
          orgDispatch({
            type: OrgContextActionType.OrgIdpConnection,
            payload: {
              orgIdpConnection: data.createIdpConnection.idpConnection,
            },
          });
          break;
        case "IdpConnectionExistsError":
        case "UserFacingError":
          logWarning(new Error(data.createIdpConnection.message));
          setErrorMessage(data.createIdpConnection.message);
          break;
        default:
          logError(new Error(`failed to create Google IDP connection`));
          setErrorMessage(`Error: failed to create Google IDP connection`);
      }
    } catch (error) {
      logError(error, "failed to create Google IDP connection");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to create Google IDP connection",
          error
        )
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={googleLogo}
      title={"Use Google as your Identity Provider"}
      backUrl={"/settings/idp/browse"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={""}
          subtitle={"Enter your service account information"}
          isLast
        >
          <>
            <p>
              Please enter the following information about your Google service
              account. For more details, please see our{" "}
              <a
                href="https://docs.opal.dev/docs/google-identity-provider"
                rel="noopener noreferrer"
                target="_blank"
              >
                help docs here
              </a>
              .
            </p>
            <FormGroup label="Customer ID:">
              <Input
                onChange={setCustomerId}
                placeholder="The customer ID corresponding to the Google Workspace."
                value={customerId}
              />
            </FormGroup>
            <FormGroup label="Google Workspace admin email:">
              <Input
                onChange={setUserEmail}
                placeholder="The email of the Google Workspace user to run queries on behalf of."
                value={userEmail}
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div className={styles.stepButtons}>
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Connect"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateGoogle;
