import {
  EntityType,
  GroupResourceAccessFragment,
  Maybe,
  ResourceAccessLevel,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import AccessPointsLabel, {
  GroupForAccessLabel,
  ResourceForAccessLabel,
} from "components/label/AccessPointsLabel";
import EventLabel from "components/label/item_labels/EventLabel";
import { ResourceLabel } from "components/label/Label";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import sprinkles from "css/sprinkles.css";
import React from "react";

interface GroupResourceAccessPointRow {
  event: string;
  accessPath: string;
}

type GroupResourceAccessPointsLabelProps = {
  accessPathsInfo: GroupResourceAccessPathsInfo;
  group: Maybe<GroupForAccessLabel> | undefined;
  access: GroupResourceAccessFragment;
  resource: Maybe<ResourceForAccessLabel> | undefined;
  role?: ResourceAccessLevel;
};

const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "800px";

export const GroupResourceAccessPointsLabel = (
  props: GroupResourceAccessPointsLabelProps
) => {
  const headers: Header<GroupResourceAccessPointRow>[] = [
    { id: "event", label: "Event" },
    { id: "accessPath", label: "Access Path" },
  ];

  const numAccessPoints = props.accessPathsInfo.accessPointRows.length;
  if (numAccessPoints === 0) {
    return <div>{"--"}</div>;
  }

  return (
    <AccessPointsLabel
      subject={props.group}
      object={props.resource}
      roleName={props.role?.accessLevelName}
      hasDirectAccess={props.accessPathsInfo.hasDirectAccess}
      numAccessPoints={numAccessPoints}
      numInheritedAccessPoints={props.accessPathsInfo.numInheritedAccessPoints}
      numGroupAccessPoints={0}
      accessPathContent={
        <div className={sprinkles({ padding: "sm" })}>
          <MaterialTable<GroupResourceAccessPointRow>
            headers={headers}
            rows={props.accessPathsInfo.accessPointRows}
            denseFormatting={true}
            tableContainerStyles={{ maxHeight: 300 }}
          />
        </div>
      }
    />
  );
};

export type GroupResourceAccessPathsInfo = {
  accessPointRows: CellRow<GroupResourceAccessPointRow>[];
  numInheritedAccessPoints: number;
  hasDirectAccess: boolean;
};

type GroupResourceAccessPathsInfoParams = {
  group: Maybe<GroupForAccessLabel> | undefined;
  access: Maybe<GroupResourceAccessFragment> | undefined;
  resource: Maybe<ResourceForAccessLabel> | undefined;
};

export const getGroupResourceAccessPathsInfo = (
  params: GroupResourceAccessPathsInfoParams
) => {
  const accessPointRows: CellRow<GroupResourceAccessPointRow>[] = [];
  const directGroupAccess = params.access?.directAccessPoint;

  if (directGroupAccess) {
    accessPointRows.push({
      id: directGroupAccess.resourceId,
      data: {
        event: {
          value: directGroupAccess.eventId,
          element: <EventLabel eventId={directGroupAccess.eventId} />,
        },
        accessPath: {
          value: `${directGroupAccess.resourceId}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={params.group?.id}
                  text={params.group?.name}
                  groupType={params.group?.groupType}
                  maxChars={24}
                />,
                <ResourceLabel
                  entityTypeNew={EntityType.Resource}
                  entityId={params.resource?.id}
                  text={params.resource?.name}
                  resourceType={params.resource?.resourceType}
                  maxChars={24}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  }

  params.access?.inheritedAccessPoints.forEach((inheritedUserAccess) => {
    const id = inheritedUserAccess.resourceId || "unknown-inherited";
    accessPointRows.push({
      id: id,
      data: {
        event: {
          value: inheritedUserAccess.eventId,
          element: <EventLabel eventId={inheritedUserAccess.eventId} />,
        },
        accessPath: {
          value: `${id}-access-path`,
          element:
            // Special-case legacy inherited access
            inheritedUserAccess.resourceId == params.access?.resourceId ? (
              <AccessPathLabel
                accessLabels={[
                  <ResourceLabel
                    entityTypeNew={EntityType.Group}
                    entityId={params.group?.id}
                    text={params.group?.name}
                    groupType={params.group?.groupType}
                    maxChars={24}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    text={"Unknown resource"}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={params.resource?.id}
                    text={params.resource?.name}
                    resourceType={params.resource?.resourceType}
                    maxChars={24}
                  />,
                ]}
                maxWidth={accessPathColMaxWidth}
              />
            ) : (
              <AccessPathLabel
                accessLabels={[
                  <ResourceLabel
                    entityTypeNew={EntityType.Group}
                    entityId={params.group?.id}
                    text={params.group?.name}
                    groupType={params.group?.groupType}
                    maxChars={24}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={id}
                    text={inheritedUserAccess.resourceName}
                    resourceType={inheritedUserAccess.resourceType}
                    maxChars={24}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={params.resource?.id}
                    text={params.resource?.name}
                    resourceType={params.resource?.resourceType}
                    maxChars={24}
                  />,
                ]}
                maxWidth={accessPathColMaxWidth}
              />
            ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  });

  return {
    accessPointRows,
    hasDirectAccess: !!directGroupAccess,
    numInheritedAccessPoints: params.access?.inheritedAccessPoints.length || 0,
  };
};
