import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GeneralSettingType,
  Maybe,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { formatDuration } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { MoreInfo } from "components/more_info/MoreInfo";
import { CustomDurationPicker } from "components/requests/CustomDurationPicker";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useContext, useState } from "react";
import { logError } from "utils/logging";
import AdminDurationButtonText from "views/settings/AdminDurationButton";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import styles from "views/settings/OrgSettings.module.scss";

export type SessionLifetimeSettingProps = {
  labelText: string;
  tooltipText: string;
  currentSettings: GeneralSettingType[];
  authSessionExpirationInMinutes?: Maybe<number>;
};

export const SessionLifetimeSetting = (props: SessionLifetimeSettingProps) => {
  const { orgDispatch } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const [
    authSessionExpirationInMinutes,
    setAuthSessionExpirationInMinutes,
  ] = useState<number | null>(null);

  const [
    updateOrgSettings,
    { loading },
  ] = useUpdateOrganizationSettingsMutation();
  const { displaySuccessToast, displayErrorToast } = useToast();

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const currentAuthSessionExpirationInMinutes =
    props.authSessionExpirationInMinutes || 0;

  const dropdownModal = (
    <Modal
      title="Set login session lifetime"
      isOpen={showModal}
      onClose={() => {
        setAuthSessionExpirationInMinutes(null);
        modalReset();
      }}
    >
      <Modal.Body>
        <p>The maximum allowed is 30 days.</p>
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
        <CustomDurationPicker
          durationInMinutes={currentAuthSessionExpirationInMinutes ?? 0}
          setDurationInMinutes={(updated) =>
            setAuthSessionExpirationInMinutes(updated)
          }
        />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        primaryButtonDisabled={
          !authSessionExpirationInMinutes ||
          props.authSessionExpirationInMinutes ===
            authSessionExpirationInMinutes
        }
        primaryButtonLoading={loading}
        onPrimaryButtonClick={async () => {
          try {
            const { data } = await updateOrgSettings({
              variables: {
                input: {
                  settings: props.currentSettings,
                  authSessionExpirationInMinutes: authSessionExpirationInMinutes,
                },
              },
            });

            switch (data?.updateOrganizationSettings.__typename) {
              case "UpdateOrganizationSettingsResult":
                orgDispatch({
                  type: OrgContextActionType.OrgSettings,
                  payload: {
                    orgSettings: data.updateOrganizationSettings.settings,
                  },
                });

                modalReset();
                displaySuccessToast("Success: settings updated");
                break;
              case "AuthSessionExpirationInvalidError":
                logError(new Error(`invalid login session expiration input`));
                displayErrorToast(
                  "Error: invalid login session expiration input"
                );
                break;
              default:
                logError(new Error(`failed to update org settings`));
                displayErrorToast("Error: failed to update org settings");
            }
          } catch (error) {
            logError(error, "failed to update org settings");
            displayErrorToast(
              getModifiedErrorMessage(
                "Error: failed to update org settings",
                error
              )
            );
          }
        }}
      />
    </Modal>
  );

  return (
    <div className={styles.switchesHeader}>
      <div className={styles.switches}>
        {dropdownModal}
        <button
          onClick={() => setShowModal(true)}
          className={styles.orgSettingOpenModalButton}
          disabled={!authState.user?.isAdmin}
        >
          <div className={sprinkles({ display: "flex" })}>
            <AdminDurationButtonText
              text={formatDuration(
                currentAuthSessionExpirationInMinutes
                  ? moment.duration(props.authSessionExpirationInMinutes, "m")
                  : null
              )}
            />
          </div>
        </button>
      </div>
      <div className={styles.label}>{props.labelText}</div>
      <MoreInfo tooltipText={props.tooltipText} />
    </div>
  );
};

export default SessionLifetimeSetting;
