import { Maybe, UserProductRole } from "api/generated/graphql";
import { DisplayNameByProductRole } from "components/modals/InviteTeammatesModal";
import { Select } from "components/ui";
import React from "react";

type ProductRoleDropdownProps = {
  productRole: Maybe<UserProductRole>;
  setProductRole: (productRole: UserProductRole) => void;
};

export const ProductRoleDropdown = (props: ProductRoleDropdownProps) => {
  return (
    <Select
      placeholder={""}
      onChange={(newRole) => {
        switch (newRole?.value) {
          case UserProductRole.Member:
            props.setProductRole(UserProductRole.Member);
            break;
          case UserProductRole.Admin:
            props.setProductRole(UserProductRole.Admin);
            break;
        }
      }}
      getOptionLabel={(option) => option.label}
      value={
        props.productRole
          ? {
              label: DisplayNameByProductRole[props.productRole],
              value: props.productRole,
            }
          : {
              label: DisplayNameByProductRole[UserProductRole.Member],
              value: "",
            }
      }
      options={Object.values(UserProductRole).map((productRole) => {
        return {
          label: DisplayNameByProductRole[productRole],
          value: productRole,
        };
      })}
    />
  );
};
