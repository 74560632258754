import {
  GroupDropdownPreviewFragment,
  UserDropdownPreviewFragment,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import * as React from "react";
import {
  EntityWithAdminOwner,
  userIsAdminOfEntities,
} from "utils/auth/entities";

import { PaginatedUserOrGroupDropdown } from "../../components/dropdown/PaginatedUserOrGroupDropdown";
import { UserOrGroupDropdown } from "../../components/dropdown/UserOrGroupDropdown";
import { FeatureFlag, useFeatureFlag } from "../../utils/feature_flags";

interface Props {
  id?: string;
  entities: EntityWithAdminOwner[];
  targetUserOrGroup: UserDropdownPreviewFragment | GroupDropdownPreviewFragment;
  onChange: (
    newTarget: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => void;
}
const RequestForUserOrGroupDropdown: React.FC<Props> = (props) => {
  const { authState } = React.useContext(AuthContext);
  const hasGroupProjects = useFeatureFlag(FeatureFlag.GroupProjects);

  if (!authState.user?.user) {
    return null;
  }

  // If user is an Opal admin, global requester, or admin owner for all requested entities,
  // allow them to request on behalf of any user.
  if (
    authState.user?.isAdmin ||
    authState.user?.isGlobalRequester ||
    userIsAdminOfEntities(authState.user.user.id, props.entities)
  ) {
    return (
      <PaginatedUserOrGroupDropdown
        id={props.id}
        value={props.targetUserOrGroup}
        onChange={(newTarget) => newTarget && props.onChange(newTarget)}
        clearable={false}
        includeTargetGroups={hasGroupProjects}
      />
    );
  }

  // Otherwise, they can only request on behalf of direct reports.
  const directReports = authState.user.user.directReports;
  return (
    <UserOrGroupDropdown
      id={props.id}
      allUsers={[authState.user.user, ...directReports]}
      onChange={(newUser) => newUser && props.onChange(newUser)}
      value={props.targetUserOrGroup}
      clearable={false}
      includeTargetGroups={hasGroupProjects}
    />
  );
};

export default RequestForUserOrGroupDropdown;
