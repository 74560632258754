import {
  ConnectionType,
  EntityType,
  GroupType,
  ResourceType,
} from "api/generated/graphql";
import { useKBar, VisualState } from "kbar";

export function useSpotlightSearch() {
  const { searchQuery } = useKBar((state) => {
    return {
      searchQuery: state.searchQuery,
    };
  });
  const { query } = useKBar();
  const setShowSpotlightSearch = (showSpotlightSearch: boolean) => {
    if (showSpotlightSearch) {
      query.setVisualState(VisualState.animatingIn);
    } else {
      query.setVisualState(VisualState.animatingOut);
    }
  };
  return {
    setShowSpotlightSearch,
    searchQuery,
  };
}

interface BaseKBarItem {
  command: {
    perform: () => void;
  };
}

interface KBarSeeAllItem extends BaseKBarItem {
  type: "see-all";
}

export interface KBarSearchResultItem extends BaseKBarItem {
  type: "result";
  label: string;
  entity: {
    entityId: string;
    entityType: EntityType;
  };
  connection?: {
    name: string;
    connectionType: ConnectionType;
  };
  groupType: GroupType;
  resourceType: ResourceType;
  avatarUrl?: string;
  annotationText?: string;
}

interface KBarNoResults extends BaseKBarItem {
  type: "no-results";
}

export type KBarItem = KBarSearchResultItem | KBarSeeAllItem | KBarNoResults;
