import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GroupType,
  Maybe,
  RemoveGroupResourceInput,
  ResourceAccessLevel,
  ResourceFragment,
  ResourceGroupFragment,
  ResourceType,
  useRemoveGroupResourcesMutation,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { ResourceLabel } from "components/label/Label";
import ResourceRoleSelectModal from "components/modals/ResourceRoleSelectModal";
import { SelectType } from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import _ from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { isSnowflakeResource } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import ResourceContainingGroupsTable from "views/groups/ResourceContainingGroupsTable";
import ResourceAddGroupsModal from "views/resources/ResourceAddGroupsModal";

import { Role } from "../../../../components/modals/ResourceIndividualRoleModal";
import { Checkbox } from "../../../../components/ui";

type ResourceGroupsRowProps = {
  resource: ResourceFragment;
  showUnmanagedGroups: boolean;
  setShowUnmanagedGroups: (checked: boolean) => void;
};

export const ResourceGroupsRow = (props: ResourceGroupsRowProps) => {
  const history = useHistory();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const canEdit = props.resource?.authorizedActions?.includes(
    AuthorizedActionManage
  );

  const search = useLocation().search;
  const roleRemoteId = new URLSearchParams(search).get("role");

  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();

  const [
    removeGroupResources,
    { loading: removeLoading },
  ] = useRemoveGroupResourcesMutation();

  const [removeErrorMessage, setRemoveErrorMessage] = useState<Maybe<string>>(
    null
  );
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [roleByGroupIdToRemove, setRoleByGroupIdToRemove] = useState<
    Record<string, Role[]>
  >({});

  const [showGroupsAddModal, setShowGroupsAddModal] = useState(false);
  const [showGroupsRemoveModal, setShowGroupsRemoveModal] = useState(false);

  const filterGroups = (
    groups: ResourceGroupFragment[],
    roleRemoteId: Maybe<string>
  ) => {
    if (!roleRemoteId) {
      return groups;
    }
    return groups.filter(
      (group) => roleRemoteId === group.accessLevel.accessLevelRemoteId
    );
  };

  const groupsTable = (
    <ResourceContainingGroupsTable
      resource={props.resource}
      resourceGroups={filterGroups(
        props.resource.containingGroups,
        roleRemoteId
      )}
      denseFormatting={true}
      allRowsLoaded
      showUnmanagedGroups={props.showUnmanagedGroups}
    />
  );

  const editor = (
    <ConditionalEditor
      isAdmin={canEdit}
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            if (hasV3) {
              history.push(`/resources/${props.resource.id}/add-groups`);
            } else {
              setShowGroupsAddModal(true);
            }
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowGroupsRemoveModal(true);
          },
        },
      ]}
      additionalConditions={[
        {
          canEdit: () => !isSnowflakeResource(props.resource.resourceType),
          tooltipText:
            "Snowflake resources cannot be managed in Opal. Please make these changes in Snowflake.",
        },
      ]}
      disabledInReadOnlyMode
    />
  );

  const directGroupRolesByGroupId: Record<string, ResourceAccessLevel[]> = {};
  props.resource.containingGroups.forEach((groupResource) => {
    if (!directGroupRolesByGroupId[groupResource.groupId]) {
      directGroupRolesByGroupId[groupResource.groupId] = [];
    }

    if (groupResource.access.directAccessPoint) {
      directGroupRolesByGroupId[groupResource.groupId] = _.uniqBy(
        [
          ...directGroupRolesByGroupId[groupResource.groupId],
          groupResource.accessLevel,
        ],
        "accessLevelRemoteId"
      );
    }
  });

  const addModalReset = () => {
    setShowGroupsAddModal(false);
  };

  const groupsAddModal = (
    <ResourceAddGroupsModal
      resource={props.resource}
      onClose={addModalReset}
      existingDirectRolesByGroupId={directGroupRolesByGroupId}
    />
  );

  const removeModalReset = () => {
    setShowGroupsRemoveModal(false);
    setRemoveErrorMessage(null);
    setRoleByGroupIdToRemove({});
    setSearchQuery("");
  };

  const groupsRemoveModal = (
    <ResourceRoleSelectModal
      title={"Remove from groups"}
      selectType={SelectType.Remove}
      itemName={"group"}
      entryInfos={_.uniqBy(props.resource.containingGroups, "groupId")
        .filter(
          (group) =>
            !!group.access.directAccessPoint &&
            group.group?.name
              .toLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) &&
            group.group.isManaged
        )
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.group?.name || "";
            const bSortString = b.group?.name || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((resourceGroup) => {
          const group = resourceGroup.group;
          const groupId = group?.id || resourceGroup.groupId;
          const canManageGroup = resourceGroup.group?.authorizedActions?.includes(
            AuthorizedActionManage
          );

          let disabled = false;
          let tooltipText;
          if (
            resourceGroup.group?.groupType === GroupType.OktaGroup &&
            props.resource.resourceType === ResourceType.OktaRole
          ) {
            disabled = true;
            tooltipText =
              "Okta Role and Group relationships cannot be managed in Opal. Please make these changes in Okta.";
          } else if (!canManageGroup) {
            disabled = true;
            tooltipText =
              "You can only remove resources which you administer. Please ask your Opal admin to remove it.";
          }

          return {
            entityId: { entityId: groupId, entityType: EntityType.Group },
            label: (
              <ResourceLabel
                entityTypeNew={EntityType.Group}
                text={group?.name || groupId}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
            disabled: disabled,
            tooltipText,
          };
        })}
      isModalOpen={showGroupsRemoveModal}
      onClose={removeModalReset}
      onSubmit={async () => {
        logEvent({
          name: "apps_remove_resource_from_groups",
          properties: {
            numGroupsRemovedFrom: Object.entries(roleByGroupIdToRemove).length,
          },
        });

        const groupResourcesToRemove: RemoveGroupResourceInput[] = [];
        for (const [groupIdToRemove, roles] of Object.entries(
          roleByGroupIdToRemove
        )) {
          roles.forEach((accessLevel) => {
            groupResourcesToRemove.push({
              groupId: groupIdToRemove,
              resourceId: props.resource.id,
              accessLevel: {
                accessLevelName: accessLevel.accessLevelName,
                accessLevelRemoteId: accessLevel.accessLevelRemoteId,
              },
            });
          });
        }

        try {
          const { data } = await removeGroupResources({
            variables: {
              input: {
                groupResources: groupResourcesToRemove,
              },
            },
          });
          switch (data?.removeGroupResources.__typename) {
            case "RemoveGroupResourcesResult":
              startPushTaskPoll(data.removeGroupResources.taskId, {
                refetchOnComplete: {
                  resourceId: props.resource.id,
                },
              });
              removeModalReset();
              break;
            case "UserFacingError":
              setRemoveErrorMessage(data.removeGroupResources.message);
              break;
            default:
              logError(new Error(`failed to remove resource from groups`));
              setRemoveErrorMessage(
                `Error: failed to remove resource from groups`
              );
          }
        } catch (error) {
          logError(error, "failed to remove resource from groups");
          setRemoveErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to remove resource from groups",
              error
            )
          );
        }
      }}
      loading={removeLoading}
      errorMessage={removeErrorMessage}
      submitDisabled={Object.keys(roleByGroupIdToRemove).length === 0}
      resource={props.resource}
      existingDirectRolesByEntityId={directGroupRolesByGroupId}
      updatedRolesByEntityId={roleByGroupIdToRemove}
      setUpdatedRolesByEntityId={setRoleByGroupIdToRemove}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );

  const showUnmanagedGroupsCheckbox = (
    <Checkbox
      size="sm"
      label="Show unmanaged groups"
      checked={props.showUnmanagedGroups}
      onChange={(value) => {
        props.setShowUnmanagedGroups(value);
      }}
    />
  );

  return (
    <EntityViewerRow
      title={"Groups"}
      content={
        <EmptyStateContentWrapper
          content={groupsTable}
          entityType={EntityType.Group}
          title={`No containing groups`}
          subtitle={`Add this resource to other groups to populate the table`}
          buttonTitle={`Add to group`}
          isEmpty={props.resource.containingGroups.length === 0}
          onClickHandler={() => {
            setShowGroupsAddModal(true);
          }}
        />
      }
      editor={editor}
      checkbox={showUnmanagedGroupsCheckbox}
      modals={
        <>
          {showGroupsAddModal && groupsAddModal}
          {groupsRemoveModal && groupsRemoveModal}
        </>
      }
      isTable={true}
      canEdit={canEdit}
    />
  );
};

export default ResourceGroupsRow;
