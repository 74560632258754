import { UserProductRole } from "api/generated/graphql";
import { UserInfo } from "components/modals/InviteTeammatesModal";
import { Button, FileUpload } from "components/ui";
import _ from "lodash";
import React from "react";
import useLogEvent from "utils/analytics";
import { checkRequiredColumns, parseCSV } from "utils/csv";

export type UploadCSVButtonProps = {
  onChangeUserInfos: (userInfos: UserInfo[]) => void;
  onChangeErrorMessage?: (errorMessage: string) => void;
  requiredColumns: CSVColumns[];
  trackName?: string;
};

export enum CSVColumns {
  Email = "email",
  Name = "name",
  Role = "role",
  AccessLevel = "accessLevel",
}

export const UploadCSVButton = (props: UploadCSVButtonProps) => {
  const logEvent = useLogEvent();

  const handleUpload = (file: File) => {
    if (props.trackName) {
      logEvent({
        name: "csv_upload_start",
        properties: { entryPoint: props.trackName },
      });
    }

    let userInfos: UserInfo[] = [];
    if (file) {
      parseCSV(file)
        .then((rows) => {
          if (rows.length === 0) {
            if (props.onChangeErrorMessage) {
              props.onChangeErrorMessage(
                "No rows detected. Empty or missing header?"
              );
            }
            return;
          }
          rows.forEach((row) => {
            checkRequiredColumns(row, props.requiredColumns);
            userInfos.push({
              name: row["name"],
              email: row["email"],
              // "role" is used when inviting a teammate as Opal Admin
              // or when adding a user as Group Admin
              productRole: row["role"] ? parseProductRole(row["role"]) : null,
              role: row["accessLevel"],
            });
          });
          userInfos = _.uniqBy(userInfos, "email");
          props.onChangeUserInfos(userInfos);
        })
        .catch((error) => {
          if (props.onChangeErrorMessage) {
            props.onChangeErrorMessage(
              `failed to parse CSV file because ${error}`
            );
          }
        });
    }

    if (props.trackName) {
      logEvent({
        name: "csv_upload_complete",
        properties: { entryPoint: props.trackName },
      });
    }
  };

  return (
    <FileUpload
      renderButton={(onClick) => (
        <Button label="Upload CSV" borderless onClick={onClick} />
      )}
      handleUpload={handleUpload}
      accept={[".csv"]}
    />
  );
};

const parseProductRole = (productRole: string) => {
  if (typeof productRole === "string") {
    productRole = productRole.toUpperCase();
  }
  switch (productRole) {
    case UserProductRole.Member:
      return UserProductRole.Member;
    case UserProductRole.Admin:
      return UserProductRole.Admin;
    default:
      return UserProductRole.Member;
  }
};

export default UploadCSVButton;
