import {
  TagPreviewSmallFragment,
  useConfigTemplateTagsQuery,
  useUpdateConfigTemplateTagsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Icon, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useState } from "react";
import { useContext } from "react";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import ConfirmDeleteTemplateMappingModal from "./ConfirmDeleteTemplateMappingModal";
import CreateTemplateMappingModal from "./CreateTemplateMappingModal";
import ReorderTemplateMappingModal from "./ReorderTemplateMappingModal";

interface TemplateMappingRow {
  id: string;
  priority: string;
  tag?: string | null;
  template?: string;
  manage: {
    delete?: () => void;
    edit?: () => void;
  };
}

const CONFIGURATION_TEMPLATE_COLUMNS: Header<TemplateMappingRow>[] = [
  {
    id: "priority",
    label: "Priority",
    sortable: true,
    width: 64,
  },
  {
    id: "tag",
    label: "Tag",
    sortable: false,
  },
  {
    id: "template",
    label: "Template",
    sortable: false,
  },
  {
    id: "manage",
    label: "",
    sortable: false,
    customCellRenderer: (row) => (
      <div
        className={sprinkles({
          display: "flex",
          gap: "xs",
          alignItems: "center",
        })}
      >
        <div
          className={sprinkles({
            width: "100%",
          })}
        >
          <Icon
            name="trash"
            size="xs"
            color="red600"
            onClick={row.manage.delete}
          />
        </div>
        <ButtonV3 leftIconName="edit" size="xs" onClick={row.manage.edit} />
      </div>
    ),
    width: 0,
  },
];

export type ConfigTemplateTag = {
  id: string;
  priority: number;
  tag?: TagPreviewSmallFragment | undefined;
  configTemplate?:
    | {
        id: string;
        name: string;
      }
    | null
    | undefined;
};

const TemplateMappingsView = () => {
  const { authState } = useContext(AuthContext);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(
    false
  );
  const [mappingToDelete, setMappingToDelete] = useState<ConfigTemplateTag>();
  const [mappingToEdit, setMappingToEdit] = useState<ConfigTemplateTag>();
  const isAdmin = authState.user?.isAdmin;
  const { clearToast, displaySuccessToast } = useToast();
  const [
    showCreateTemplateMappingModal,
    setShowCreateTemplateMappingModal,
  ] = useState(false);
  const [
    showReorderTemplateMappingModal,
    setShowReorderTemplateMappingModal,
  ] = useState(false);
  const [showEditMappingModal, setShowEditMappingModal] = useState(false);

  const [configTemplateTags, setConfigTemplateTags] = useState<
    ConfigTemplateTag[]
  >([]);

  const { loading, error } = useConfigTemplateTagsQuery({
    onCompleted: (data) => {
      const mappings = data.configTemplateTags.configTemplateTags.slice();
      const orderedMappings = mappings.sort((a, b) => a.priority - b.priority);
      setConfigTemplateTags(orderedMappings);
    },
  });

  const reorderPriorities = (configs: ConfigTemplateTag[]) => {
    return configs.map((config, i) => ({
      ...config,
      priority: i + 1,
    }));
  };

  const [
    updateConfigTemplateTags,
    { error: mutationError },
  ] = useUpdateConfigTemplateTagsMutation();

  const updateMappings = async (newConfigTemplateTags: ConfigTemplateTag[]) => {
    const filteredMappings = reorderPriorities(
      newConfigTemplateTags.filter(
        (configTemplateTag) =>
          // skip mappings that are not complete
          configTemplateTag.tag && configTemplateTag.configTemplate
      )
    );
    clearToast();
    await updateConfigTemplateTags({
      variables: {
        input: {
          configTemplateTags: filteredMappings.map((configTemplateTag) => ({
            configTemplateId: configTemplateTag.configTemplate!.id,
            tagId: configTemplateTag.tag!.id,
            priority: configTemplateTag.priority,
          })),
        },
      },
      refetchQueries: ["ConfigTemplateTags"],
    });
    displaySuccessToast("Saved");
  };

  const deleteMapping = async () => {
    if (!mappingToDelete) {
      return;
    }

    setShowConfirmDeleteModal(false);
    setMappingToDelete(undefined);
    const newMappings = reorderPriorities(
      configTemplateTags.filter((ctt) => ctt.id !== mappingToDelete.id)
    );
    setConfigTemplateTags(newMappings);
    updateMappings(newMappings);
  };

  if (error || mutationError) {
    return <UnexpectedErrorPage error={error || mutationError} />;
  }

  const rows: TemplateMappingRow[] = configTemplateTags.map((tt) => {
    return {
      id: tt.id,
      priority: tt.priority.toString(),
      tag: `${tt.tag?.key}:${tt.tag?.value}`,
      template: tt.configTemplate?.name || "N/A",
      manage: {
        delete: () => {
          setMappingToDelete(tt);
          setShowConfirmDeleteModal(true);
        },
        edit: () => {
          setMappingToEdit(tt);
          setShowEditMappingModal(true);
        },
      },
    };
  });

  return (
    <>
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: "md",
        })}
      >
        <span
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
          })}
        >
          {loading ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            `${pluralize("Template Mapping", rows.length, true)}`
          )}
        </span>
        {isAdmin && (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
            })}
          >
            <ButtonV3
              label="Mapping"
              type="main"
              leftIconName="plus"
              onClick={() => {
                setShowCreateTemplateMappingModal(true);
              }}
              size="sm"
            />
            <ButtonV3
              label="Reorder"
              type="main"
              leftIconName="dots-grid"
              onClick={() => {
                setShowReorderTemplateMappingModal(true);
              }}
              size="sm"
              disabled={rows.length === 0}
              disabledTooltip="No Template Mappings"
            />
          </div>
        )}
      </div>

      {loading ? (
        <ColumnListItemsSkeleton />
      ) : (
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(ru) => ru.id}
          columns={CONFIGURATION_TEMPLATE_COLUMNS}
        />
      )}
      {showCreateTemplateMappingModal && (
        <CreateTemplateMappingModal
          onClose={() => setShowCreateTemplateMappingModal(false)}
          configTemplateTags={configTemplateTags}
          setConfigTemplateTags={setConfigTemplateTags}
          updateMappings={updateMappings}
        />
      )}
      {showReorderTemplateMappingModal && (
        <ReorderTemplateMappingModal
          onClose={() => setShowReorderTemplateMappingModal(false)}
          isAdmin={isAdmin}
          configTemplateTags={configTemplateTags}
          setConfigTemplateTags={setConfigTemplateTags}
          reorderPriorities={reorderPriorities}
          updateMappings={updateMappings}
        />
      )}
      {showConfirmDeleteModal && (
        <ConfirmDeleteTemplateMappingModal
          mappingToDelete={mappingToDelete}
          deleteMappings={deleteMapping}
          setShowConfirmDeleteModal={setShowConfirmDeleteModal}
        />
      )}
      {showEditMappingModal && (
        <CreateTemplateMappingModal
          mappingToEdit={mappingToEdit}
          onClose={() => setShowEditMappingModal(false)}
          configTemplateTags={configTemplateTags}
          setConfigTemplateTags={setConfigTemplateTags}
          updateMappings={updateMappings}
        />
      )}
    </>
  );
};
export default TemplateMappingsView;
