import {
  AuthFlowType,
  IdpConnectionFragment,
  IntegrationType,
  Maybe,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
} from "api/generated/graphql";
import mergeLogo from "assets/logos/merge-logo.svg";
import clsx from "clsx";
import { AppTile } from "components/integrations/AppTile";
import {
  getLogoByIdpConnectionType,
  getNameByIdpConnectionType,
} from "components/label/Label";
import IdpAttributesModal from "components/modals/update/IdpAttributesModal";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import React, { useState } from "react";
import integrationsStyles from "views/settings/OrgIntegrations.module.scss";
import { IdpAuthorizer } from "views/settings/third_party_authorizer/IdpAuthorizer";
import { ThirdPartyLinkFlowAuthorizer } from "views/settings/third_party_authorizer/ThirdPartyLinkFlowAuthorizer";

export type IdpHrIntegrationAppProps = {
  idpConnection: Maybe<IdpConnectionFragment>;
  hrConnection: ThirdPartyIntegrationFragment | undefined;
};

export const IdpHrIntegrationApp = (props: IdpHrIntegrationAppProps) => {
  const { idpConnection, hrConnection } = props;

  const [showModal, setShowModal] = useState(false);
  const [
    openThirdPartyProvider,
    setOpenThirdPartyProvider,
  ] = useState<ThirdPartyProvider | null>(null);

  const idpConnected = !!idpConnection;
  const hrConnected = !!hrConnection;
  const isConnected = idpConnected || hrConnected;

  let connectionStatusText = isConnected ? "Connected" : "No connection";
  if (isConnected) {
    connectionStatusText = `${connectionStatusText}`;
  }

  let authorizer;
  if (hrConnected) {
    authorizer = (
      <ThirdPartyLinkFlowAuthorizer
        integrationType={IntegrationType.Org}
        integrationInfo={{
          name: "HR Information System",
          logo: mergeLogo,
          thirdPartyProvider: ThirdPartyProvider.Merge,
          authFlowType: AuthFlowType.Link,
        }}
        isConnected={hrConnected}
        openThirdPartyProvider={openThirdPartyProvider}
        setOpenThirdPartyProvider={setOpenThirdPartyProvider}
      />
    );
  } else {
    authorizer = (
      <IdpAuthorizer idpConnection={idpConnection} isConnected={idpConnected} />
    );
  }

  return (
    <div>
      <div className={integrationsStyles.appInfo}>
        <div className={integrationsStyles.leftContent}>
          <AppTile
            logo={
              hrConnected
                ? mergeLogo
                : getLogoByIdpConnectionType(idpConnection?.idpConnectionType)
            }
          />
          <div className={integrationsStyles.text}>
            <div className={integrationsStyles.appName}>
              {getNameByIdpConnectionType(idpConnection?.idpConnectionType) ||
                (hrConnected
                  ? "HR Information System"
                  : "Identity Provider / HR Information System")}
            </div>
            <div className={sprinkles({ display: "flex" })}>
              <div className={integrationsStyles.subtitle}>
                <div
                  className={clsx({
                    [integrationsStyles.subtitleStatus]: true,
                    [integrationsStyles.statusDisconnected]: !isConnected,
                    [integrationsStyles.statusConnected]: isConnected,
                  })}
                />
                <div className={integrationsStyles.subtitleText}>
                  {connectionStatusText}
                </div>
              </div>
              {idpConnected && (
                <div className={integrationsStyles.attributesLink}>
                  <div
                    className={integrationsStyles.subtitleText}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {`Importing ${pluralize(
                      "custom user attribute",
                      idpConnection?.userAttributeImportMappings.length || 0,
                      true
                    )}`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={integrationsStyles.rightContent}>{authorizer}</div>
      </div>
      {idpConnection && (
        <IdpAttributesModal
          idpConnection={idpConnection}
          isModalOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
