import {
  ActiveRequestConfigurationFragment,
  EntityType,
  GeneralSettingType,
  OidcProviderType,
  RequestConfigurationGroupInput,
  RequestConfigurationResourceInput,
  RequestCustomMetadataInput,
  RequestDefaultsFragment,
  RequestedGroupInput,
  RequestedResourceInput,
  RequestMessageFragment,
  RequestMessageLevel,
  RequestTemplateCustomFieldType,
  RequestTemplateFragment,
  ResourceType,
  useAppPreviewQuery,
  useBulkActiveGroupRequestConfigsQuery,
  useBulkActiveResourceRequestConfigsQuery,
  useCreateRequestMutation,
  useInitOidcAuthFlowMutation,
  useRequestDefaultsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import PaginatedAppDropdown from "components/dropdown/PaginatedAppDropdown";
import PaginatedEntityDropdown from "components/dropdown/PaginatedEntityDropdown";
import FullscreenView from "components/layout/FullscreenView";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import { useUnsavedChangesModal } from "components/modals/update/UnsavedChangesModal";
import AccessRequestDurationPicker from "components/requests/AccessRequestDurationPicker";
import AccessRequestTicketPicker from "components/requests/AccessRequestTicketPicker";
import {
  AccessRequestGroupCard,
  AccessRequestOktaAppCard,
  AccessRequestResourceCard,
} from "components/requests/request_cards/AccessRequestEntityCard";
import RequestCustomFieldRow from "components/requests/RequestCustomFieldRow";
import { Banner, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { generateState } from "utils/auth/auth";
import { usePageTitle } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { MfaCustomParams } from "utils/mfa/mfa";
import { setOidcData } from "utils/oidc/oidc";
import {
  AccessRequestEntity,
  AccessRequestState,
  AccessRequestStateType,
  Role,
  useAccessRequestStateReducer,
} from "views/access_request/AccessRequestContext";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import RequestForUserOrGroupDropdown from "views/requests/RequestForUserOrGroupDropdown";
import RequestModalMfaFiller from "views/requests/RequestModalMfaFiller";
import OrgContext from "views/settings/OrgContext";

import * as styles from "./AccessRequestView.css";

// Things that are missing:
// - Showing current access for the selected entities and roles
// - Showing error messages when one or more selected entities requires a remote user to be linked (eg. Github)
// - Highlight if a resource is not requestable by the target user
// - Proper validation before submitting the request
// - Map error to the correct field when submitting the request
// - Cap the amount of requestable entities to 20 before the user adds a 21st entity or starts the request
const RequestAccessView = () => {
  const history = useHistory();
  const [config, dispatch] = useAccessRequestStateReducer();
  const { orgState } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);
  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();
  // Don't store this in the access request state because it's only used for
  // MFA and should never be serialized
  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);
  const useOktaMfa = orgState.orgSettings?.generalSettings.some(
    (setting) => setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
  );
  const useOidcMfa = orgState.orgSettings?.generalSettings.some(
    (setting) => setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
  );

  const {
    handleClose,
    maybeRenderUnsavedChangesModal,
  } = useUnsavedChangesModal(`/apps`);

  usePageTitle("Request access");

  const [
    createRequest,
    { loading: createRequestLoading },
  ] = useCreateRequestMutation({
    refetchQueries: ["Requests", "RequestsPendingStat", "RequestStats"],
  });

  const { data, loading, error } = useAppPreviewQuery({
    variables: {
      appId: config.appId ?? "",
    },
    skip: !config.appId,
    onCompleted: (data) => {
      if (data.app.__typename !== "App") return;
      switch (data.app?.app.__typename) {
        case "ConnectionApp":
          dispatch({
            type: AccessRequestStateType.App,
            value: {
              appId: data.app?.app.connectionId,
              appType: data.app?.app.connectionType,
            },
          });
          break;
        case "OktaResourceApp":
          dispatch({
            type: AccessRequestStateType.App,
            value: {
              appId: data.app?.app.resourceId,
              appType: ResourceType.OktaApp,
            },
          });
          break;
      }
    },
  });

  const { resourceInputs, groupInputs } = useMemo(
    () =>
      getConfigQueryInputs(
        config.selectedEntities,
        config.selectedRolesByEntityId,
        config.selectedEntityByEntityId
      ),
    [
      config.selectedEntities,
      config.selectedRolesByEntityId,
      config.selectedEntityByEntityId,
    ]
  );

  const {
    data: requestDefaultsData,
    loading: requestDefaultsLoading,
    previousData: requestDefaultsPreviousData,
  } = useRequestDefaultsQuery({
    variables: {
      input: {
        targetUserId: config.targetUserId ?? config.requesterUserId,
        requestedResources: resourceInputs,
        requestedGroups: groupInputs,
      },
    },
  });

  useBulkActiveResourceRequestConfigsQuery({
    skip: !config.appId && !resourceInputs.length,
    variables: {
      targetUserId: config.targetUserId ?? config.requesterUserId,
      resourceInputs: resourceInputs,
    },
    onCompleted: (data) => {
      dispatch({
        type: AccessRequestStateType.RequestConfigurations,
        value: data.activeResourceRequestConfigurations.map((config) => {
          return {
            entityId: config.resourceId,
            roleRemoteId: config.accessLevelRemoteId,
            requestConfiguration: config.requestConfiguration,
          };
        }),
      });
    },
  });

  useBulkActiveGroupRequestConfigsQuery({
    skip: !config.appId && !groupInputs.length,
    variables: {
      targetUserId: config.targetUserId ?? config.requesterUserId,
      groupInputs: groupInputs,
    },
    onCompleted: (data) => {
      dispatch({
        type: AccessRequestStateType.RequestConfigurations,
        value: data.activeGroupRequestConfigurations.map((config) => {
          return {
            entityId: config.groupId,
            roleRemoteId: config.accessLevelRemoteId,
            requestConfiguration: config.requestConfiguration,
          };
        }),
      });
    },
  });

  const app = data?.app.__typename === "App" ? data.app : undefined;
  const appNotFound = data?.app.__typename === "AppNotFoundError";
  const requestDefaults =
    requestDefaultsData?.requestDefaults ??
    requestDefaultsPreviousData?.requestDefaults;
  const showEntityDropdown =
    config.appId &&
    config.appType !== ResourceType.OktaApp &&
    !loading &&
    !config.appNotRequestable &&
    !config.hideAppDropdown;
  const showFullRequestView =
    !config.appNotRequestable || config.selectedEntities.length > 0;

  const allActiveRequestConfig = config.selectedEntities.flatMap((entity) => {
    const targetEntity = config.selectedEntityByEntityId[entity.id] ?? entity;
    return (
      config.requestConfigsByEntityId[targetEntity.id]?.map(
        (requestConfig) => requestConfig.requestConfiguration
      ) ?? []
    );
  });
  const requestTemplates = getUniqueRequestTemplates(allActiveRequestConfig);

  const isSupportTicketRequired =
    requestDefaults?.requireSupportTicket ?? false;
  const isReasonRequired = !requestDefaults?.reasonOptional ?? true;

  const showNotRequestableBundleEntityWarningBanner =
    config.notRequestableBundleEntityCount &&
    config.notRequestableBundleEntityCount > 0;

  const messagesByEntityId: Record<string, RequestMessageFragment[]> =
    requestDefaults?.messages.reduce((acc, message) => {
      if (!message.entityId) return acc;
      if (!acc[message.entityId]) {
        acc[message.entityId] = [];
      }
      acc[message.entityId].push(message);
      return acc;
    }, {} as Record<string, RequestMessageFragment[]>) ?? {};

  // Update the default duration when the org settings change -- org settings
  // won't be available when we create the context for the first time, so we
  // need to use an effect to retroactively update the default duration, in
  // conjunction with the suggested duration from the request configuration. We
  // could simply load the suggested value from the request configuration in
  // the onCompleted callback, but it wouldn't consider the global maxima from
  // the org settings.
  useEffect(() => {
    dispatch({
      type: AccessRequestStateType.DefaultDuration,
      value: requestDefaults?.defaultDurationInMinutes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore dispatch from the list of deps
  }, [requestDefaults?.defaultDurationInMinutes]);

  if (error) {
    // TODO: display errors a little more nicely
    return <UnexpectedErrorPage error={error} />;
  }

  const handleCreateRequest = async (state: AccessRequestState) => {
    try {
      if (!state.targetUserId && !state.targetGroupId) {
        return;
      }

      if (state.durationInMinutes && state.durationInMinutes <= 0) {
        dispatch({
          type: AccessRequestStateType.ValidationError,
          value: "Error: duration can't be negative or zero",
        });
        return;
      }

      const {
        requestedResources,
        requestedGroups,
        customMetadataInput,
      } = getRequestInputs(
        state.selectedEntities,
        state.selectedRolesByEntityId,
        state.selectedEntityByEntityId,
        state.requestConfigsByEntityId,
        state.customMetadata
      );
      const { data } = await createRequest({
        variables: {
          input: {
            targetUserId: state.targetUserId ?? null,
            targetGroupId: state.targetGroupId ?? null,
            requestedResources: requestedResources,
            requestedGroups: requestedGroups,
            reason: state.reason ?? "",
            durationInMinutes: state.durationInMinutes,
            supportTicket: state.supportTicket && {
              remoteId: state.supportTicket.remoteId,
              identifier: state.supportTicket.identifier,
              url: state.supportTicket.url,
              thirdPartyProvider: state.supportTicket.thirdPartyProvider,
            },
            customMetadata: customMetadataInput,
          },
        },
      });

      switch (data?.createRequest.__typename) {
        case "CreateRequestResult": {
          // TODO: show success dialog instead
          history.replace(`/requests/sent/${data.createRequest.request.id}`);
          break;
        }
        // TODO: handle error when ticket is missing
        case "RequestDurationTooLargeError":
        case "RequestRequiresUserAuthTokenForConnectionError":
        case "NoManagerSetForRequestingUserError":
        case "ItemCannotBeRequestedError":
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value: data.createRequest.message,
          });
          break;
        case "BulkRequestTooLargeError":
          logWarning(
            new Error(
              `Bulk request too large: requested ${config.selectedEntities.length} items`
            )
          );
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value: data.createRequest.message,
          });
          break;
        case "NoReviewersSetForOwnerError":
        case "NoReviewersSetForResourceError":
        case "NoReviewersSetForGroupError":
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value: "This request has no approvers set.",
          });
          break;
        case "MfaInvalidError": {
          const redirectUrl = `/request-access`;
          const mfaParams: MfaCustomParams = { requestState: config };

          if (useOidcMfa) {
            try {
              const state = generateState();
              const { data } = await initOidcAuthFlow({
                variables: {
                  input: {
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                  },
                },
              });
              switch (data?.initOidcAuthFlow.__typename) {
                case "InitOidcAuthFlowResult":
                  setOidcData({
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                    postAuthPath: redirectUrl,
                    mfaParams: mfaParams,
                  });
                  window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                  break;
                case "OidcProviderNotFoundError":
                  dispatch({
                    type: AccessRequestStateType.ValidationError,
                    value:
                      "Error: Failed to trigger MFA. Please contact your admin.",
                  });
                  break;
                default:
                  dispatch({
                    type: AccessRequestStateType.ValidationError,
                    value:
                      "Error: Failed to trigger MFA. Please try again or contact support if the issue persists.",
                  });
              }
            } catch (e) {
              logError(e, "Error: could not complete MFA");
              dispatch({
                type: AccessRequestStateType.ValidationError,
                value:
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists.",
              });
            }
          } else if (useOktaMfa) {
            setIsIdpMfaModalOpen(true);
          } else {
            authState.authClient?.mfaFlow(redirectUrl, mfaParams);
          }
          break;
        }
        case "UserCannotRequestAccessForTargetGroupError":
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value:
              "Error: You do not have permission to request access on behalf of this group.",
          });
          break;
        case "TargetUserHasNestedAccessError":
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value:
              "Error: You do not have permission to request access for this user.",
          });
          break;
        case "RequestReasonMissingError":
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value: "Error: reason is required",
          });
          break;
        case "RequestFieldValueMissingError":
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value: data.createRequest.message,
          });
          break;
        default:
          logError(new Error(`failed to create request: unhandled error case`));
          dispatch({
            type: AccessRequestStateType.ValidationError,
            value: "Error: failed to create request",
          });
      }
    } catch (error) {
      logError(error, `failed to create request`);
      dispatch({
        type: AccessRequestStateType.ValidationError,
        value: "Error: failed to create request",
      });
    }
  };

  return (
    <FullscreenView
      title={"Request access"}
      onCancel={() => handleClose(config.hasChanges)}
      primaryButtonLabel={"Request"}
      primaryButtonLoading={createRequestLoading}
      // TODO: add proper validation
      primaryButtonDisabled={
        createRequestLoading || !requestCanBeSubmitted(config, requestDefaults)
      }
      onPrimaryButtonClick={() => handleCreateRequest(config)}
    >
      <FullscreenView.Content fullWidth center>
        <div className={styles.container}>
          {config.errors.map((error) => (
            <Banner
              type="error"
              message={error}
              marginBottom="md"
              key={error}
            />
          ))}
          {appNotFound && (
            <Banner
              type="error"
              marginBottom="md"
              message="App not found. Please select a valid app from the dropdown below."
            />
          )}
          <FormGroup label="App" required>
            <PaginatedAppDropdown
              id="app-dropdown"
              value={app}
              onChange={(app) => {
                switch (app?.app.__typename) {
                  case "ConnectionApp":
                    dispatch({
                      type: AccessRequestStateType.App,
                      value: {
                        appId: app?.app.connectionId,
                        appType: app?.app.connectionType,
                        isUserAction: true,
                      },
                    });
                    break;
                  case "OktaResourceApp":
                    dispatch({
                      type: AccessRequestStateType.App,
                      value: {
                        appId: app?.app.resourceId,
                        appType: ResourceType.OktaApp,
                        isUserAction: true,
                      },
                    });
                    break;
                }
              }}
            />
          </FormGroup>
          {showNotRequestableBundleEntityWarningBanner && (
            <Banner
              type="warning"
              marginBottom="lg"
              message={`You do not have permission to request ${
                config.notRequestableBundleEntityCount ?? 0
              } ${pluralize(
                "item",
                config.notRequestableBundleEntityCount
              )} in this bundle, which is why they are not listed below`}
            />
          )}
          {config.appNotRequestable && (
            <Banner
              type="warning"
              marginBottom="lg"
              message={
                <>
                  <span className={sprinkles({ fontWeight: "medium" })}>
                    You do not have permission to view any items in this app
                  </span>
                  <br />
                  Contact your administrator if you think this is an error.
                </>
              }
            />
          )}
          {showEntityDropdown && config.appId && (
            <FormGroup label="What do you need access to?" required>
              <PaginatedEntityDropdown
                id="entity-dropdown"
                key={config.appId}
                connectionId={config.appId}
                selectedIds={config.selectedEntities.map((entity) => entity.id)}
                onSelect={(options) => {
                  dispatch({
                    type: AccessRequestStateType.AddEntitiesToRequest,
                    value: options,
                  });
                }}
                onRemove={(options) => {
                  dispatch({
                    type: AccessRequestStateType.RemoveEntitiesFromRequest,
                    value: options,
                  });
                }}
                onError={(error) => {
                  switch (error) {
                    case "app-not-found":
                    case "items-not-found":
                      dispatch({
                        type: AccessRequestStateType.AppNotRequestable,
                        value: { isNotRequestable: true },
                      });
                  }
                }}
                includeOnlyRequestable
              />
            </FormGroup>
          )}
          {showFullRequestView && (
            <>
              {config.selectedEntities.map((entity) => {
                switch (entity.type) {
                  case EntityType.Group:
                    return (
                      <AccessRequestGroupCard
                        key={entity.id}
                        {...entity}
                        targetUserId={
                          config.targetUserId ?? config.requesterUserId
                        }
                        onRemoveEntity={(entity) =>
                          dispatch({
                            type:
                              AccessRequestStateType.RemoveEntitiesFromRequest,
                            value: [entity],
                          })
                        }
                        selectedRoles={
                          config.selectedRolesByEntityId[entity.id]
                        }
                        setSelectedRoles={(roles, isUserAction) =>
                          dispatch({
                            type: AccessRequestStateType.SetRolesForEntity,
                            value: { ...entity, roles, isUserAction },
                          })
                        }
                        setIsRoleRequired={(isRoleRequired) =>
                          dispatch({
                            type:
                              AccessRequestStateType.SetIsRoleRequiredForEntity,
                            value: { ...entity, isRoleRequired },
                          })
                        }
                        messages={messagesByEntityId[entity.id]}
                      />
                    );
                  case EntityType.Resource:
                    return (
                      <AccessRequestResourceCard
                        key={entity.id}
                        {...entity}
                        targetUserId={
                          config.targetUserId ?? config.requesterUserId
                        }
                        onRemoveEntity={(entity) =>
                          dispatch({
                            type:
                              AccessRequestStateType.RemoveEntitiesFromRequest,
                            value: [entity],
                          })
                        }
                        selectedRoles={
                          config.selectedRolesByEntityId[entity.id]
                        }
                        setSelectedRoles={(roles, isUserAction) =>
                          dispatch({
                            type: AccessRequestStateType.SetRolesForEntity,
                            value: { ...entity, roles, isUserAction },
                          })
                        }
                        setIsRoleRequired={(isRoleRequired) =>
                          dispatch({
                            type:
                              AccessRequestStateType.SetIsRoleRequiredForEntity,
                            value: { ...entity, isRoleRequired },
                          })
                        }
                        messages={messagesByEntityId[entity.id]}
                      />
                    );
                  case ResourceType.OktaApp:
                    return (
                      <AccessRequestOktaAppCard
                        key={entity.id}
                        {...entity}
                        selectedRoleEntity={
                          config.selectedEntityByEntityId[entity.id]
                        }
                        onSelectRoleEntity={(role, isUserAction) => {
                          if (role) {
                            dispatch({
                              type: AccessRequestStateType.SetEntityForEntity,
                              value: {
                                ...entity,
                                entity: role,
                                isUserAction: isUserAction,
                              },
                            });
                          }
                        }}
                        onRemoveEntity={(entity) =>
                          dispatch({
                            type:
                              AccessRequestStateType.RemoveEntitiesFromRequest,
                            value: [entity],
                          })
                        }
                        onError={() => {
                          if (entity.id === config.appId) {
                            dispatch({
                              type: AccessRequestStateType.AppNotRequestable,
                              value: { isNotRequestable: true },
                            });
                          }
                          dispatch({
                            type:
                              AccessRequestStateType.RemoveEntitiesFromRequest,
                            value: [entity],
                          });
                        }}
                        messages={messagesByEntityId[entity.id]}
                      />
                    );
                }
              })}
              <FormGroup label="Who needs access?" required>
                {config.target ? (
                  <RequestForUserOrGroupDropdown
                    id="target-dropdown"
                    entities={[]}
                    targetUserOrGroup={config.target}
                    onChange={(newTarget) => {
                      switch (newTarget?.__typename) {
                        case "User":
                          dispatch({
                            type: AccessRequestStateType.Target,
                            value: { targetUser: newTarget },
                          });
                          break;
                        case "Group":
                          dispatch({
                            type: AccessRequestStateType.Target,
                            value: { targetGroup: newTarget },
                          });
                      }
                    }}
                  />
                ) : null}
              </FormGroup>
              <FormGroup
                label="Why do you need access?"
                required={isReasonRequired}
              >
                <Input
                  type="textarea"
                  rows={4}
                  placeholder={"I need access to this because..."}
                  value={config.reason}
                  onChange={(value) =>
                    dispatch({ type: AccessRequestStateType.Reason, value })
                  }
                  data-test-id="request-reason"
                />
              </FormGroup>
              {requestTemplates.flatMap((requestTemplate) => {
                return requestTemplate.customFields?.map((field) => {
                  return (
                    <RequestCustomFieldRow
                      key={requestTemplate.id + field.name}
                      customField={field}
                      metadataValue={
                        config.customMetadata[field.name]?.metadataValue
                      }
                      onChange={(val) =>
                        dispatch({
                          type: AccessRequestStateType.CustomMetadata,
                          value: {
                            fieldName: field.name,
                            fieldValue: {
                              fieldName: field.name,
                              fieldType: field.type,
                              metadataValue: val,
                            },
                          },
                        })
                      }
                    />
                  );
                });
              })}
              <AccessRequestDurationPicker
                loading={requestDefaultsLoading}
                durationInMinutes={config.durationInMinutes}
                defaultDurationInMinutes={
                  requestDefaults?.defaultDurationInMinutes ?? 60
                }
                durationOptions={requestDefaults?.durationOptions ?? []}
                maxDurationInMinutes={
                  requestDefaults?.maxDurationInMinutes ?? undefined
                }
                recommendedDurationInMinutes={
                  requestDefaults?.recommendedDurationInMinutes ?? undefined
                }
                setDurationInMinutes={(duration) =>
                  dispatch({
                    type: AccessRequestStateType.Duration,
                    value: duration,
                  })
                }
              />
              <AccessRequestTicketPicker
                required={isSupportTicketRequired}
                supportTicket={config.supportTicket}
                onChange={(ticket) =>
                  dispatch({
                    type: AccessRequestStateType.SupportTicket,
                    value: ticket,
                  })
                }
              />
            </>
          )}
        </div>
        {maybeRenderUnsavedChangesModal()}
        {isIdpMfaModalOpen && (
          <IdpMfaModal
            onClose={() => setIsIdpMfaModalOpen(false)}
            onMfaSuccess={() => handleCreateRequest(config)}
          />
        )}
        <RequestModalMfaFiller
          callback={(requestModalParams: MfaCustomParams) => {
            if (!requestModalParams.requestState) {
              logError(new Error("MFA request state is missing"));
              return;
            }
            // Replace state with the one we saved in the MFA modal
            // In case click request fails, we have the form filled out
            dispatch({
              type: AccessRequestStateType.RestoreFromMfaCustomParams,
              value: requestModalParams,
            });
            handleCreateRequest(requestModalParams.requestState);
          }}
        />
      </FullscreenView.Content>
    </FullscreenView>
  );
};

// TODO: move this operation to request defaults, so given the selection of
// user/group + request entities, we just get all the fields that we need to
// render, rather than going through so many hoops to get the unique templates
function getUniqueRequestTemplates(
  allActiveRequestConfig: ActiveRequestConfigurationFragment[]
) {
  return _.uniqBy(
    allActiveRequestConfig.reduce((acc, requestConfig) => {
      if (!requestConfig.requestTemplate) return acc;
      return [...acc, requestConfig.requestTemplate];
    }, [] as RequestTemplateFragment[]),
    "id"
  );
}

function getConfigQueryInputs(
  selectedEntities: AccessRequestEntity[],
  selectedRolesByEntityId: Partial<Record<string, Role[]>>,
  selectedEntityByEntityId: Partial<Record<string, AccessRequestEntity>>
): {
  resourceInputs: RequestConfigurationResourceInput[];
  groupInputs: RequestConfigurationGroupInput[];
} {
  const resourceInputs: RequestConfigurationResourceInput[] = [];
  const groupInputs: RequestConfigurationGroupInput[] = [];

  selectedEntities.forEach((entity) => {
    switch (entity.type) {
      case EntityType.Resource:
        if (selectedRolesByEntityId[entity.id]) {
          selectedRolesByEntityId[entity.id]?.forEach((role) => {
            resourceInputs.push({
              resourceId: entity.id,
              accessLevelRemoteId: role.accessLevelRemoteId,
            });
          });
          break;
        }

        resourceInputs.push({
          resourceId: entity.id,
          accessLevelRemoteId: null,
        });
        break;
      case EntityType.Group:
        if (selectedRolesByEntityId[entity.id]) {
          selectedRolesByEntityId[entity.id]?.forEach((role) => {
            groupInputs.push({
              groupId: entity.id,
              accessLevelRemoteId: role.accessLevelRemoteId,
            });
          });
          break;
        }
        groupInputs.push({
          groupId: entity.id,
          accessLevelRemoteId: null,
        });
        break;
      case ResourceType.OktaApp: {
        const selectedEntity = selectedEntityByEntityId[entity.id];
        if (selectedEntity?.type === EntityType.Resource) {
          resourceInputs.push({
            resourceId: selectedEntity.id,
          });
        } else if (selectedEntity?.type === EntityType.Group) {
          groupInputs.push({
            groupId: selectedEntity.id,
          });
        }
        break;
      }
    }
  });

  return { resourceInputs, groupInputs };
}

function getRequestInputs(
  selectedEntities: AccessRequestEntity[],
  selectedRolesByEntityId: AccessRequestState["selectedRolesByEntityId"],
  selectedEntityByEntityId: AccessRequestState["selectedEntityByEntityId"],
  requestConfigsByEntityId: AccessRequestState["requestConfigsByEntityId"],
  customMetadata: AccessRequestState["customMetadata"]
) {
  const requestedResources: RequestedResourceInput[] = [];
  const requestedGroups: RequestedGroupInput[] = [];

  for (const entity of selectedEntities) {
    const selectedRoles = selectedRolesByEntityId[entity.id];

    switch (entity.type) {
      case EntityType.Resource: {
        if (!selectedRoles || selectedRoles.length === 0) {
          requestedResources.push({
            resourceId: entity.id,
            accessLevel: { accessLevelName: "", accessLevelRemoteId: "" },
          });
          continue;
        }
        for (const role of selectedRoles) {
          requestedResources.push({
            resourceId: entity.id,
            accessLevel: {
              accessLevelName: role?.accessLevelName || "",
              accessLevelRemoteId: role?.accessLevelRemoteId || "",
            },
          });
        }
        break;
      }
      case EntityType.Group: {
        requestedGroups.push({
          groupId: entity.id,
          accessLevel:
            selectedRoles && selectedRoles.length > 0 ? selectedRoles[0] : null,
        });
        break;
      }
      case ResourceType.OktaApp: {
        const selectedEntity = selectedEntityByEntityId[entity.id];
        if (selectedEntity?.type === EntityType.Resource) {
          requestedResources.push({
            resourceId: selectedEntity.id,
            accessLevel: { accessLevelName: "", accessLevelRemoteId: "" },
          });
        } else if (selectedEntity?.type === EntityType.Group) {
          requestedGroups.push({
            groupId: selectedEntity.id,
          });
        }
        break;
      }
    }
  }

  const activeRequestConfigurations = selectedEntities.flatMap(
    (entity) =>
      requestConfigsByEntityId[entity.id]?.map(
        (requestConfig) => requestConfig.requestConfiguration
      ) ?? []
  );
  const requestTemplates = getUniqueRequestTemplates(
    activeRequestConfigurations
  );

  let customMetadataInput: RequestCustomMetadataInput[] = [];
  requestTemplates.forEach((template) => {
    const customFields = template?.customFields ?? [];
    customFields.forEach((field) => {
      if (customMetadata[field.name]) {
        customMetadataInput.push(customMetadata[field.name]);
      } else {
        customMetadataInput.push({
          fieldName: field.name,
          fieldType: field.type,
          metadataValue: {},
        });
      }
    });
  });

  return {
    requestedResources,
    requestedGroups,
    customMetadataInput,
  };
}

function requestCanBeSubmitted(
  config: AccessRequestState,
  requestDefaults?: RequestDefaultsFragment
): boolean {
  if (config.selectedEntities.length === 0) {
    return false;
  }

  for (const entity of config.selectedEntities) {
    if (
      config.isRoleRequiredByEntityId[entity.id] &&
      !config.selectedRolesByEntityId[entity.id]
    ) {
      return false;
    } else if (
      entity.type === ResourceType.OktaApp &&
      !config.selectedEntityByEntityId[entity.id]
    ) {
      return false;
    }
  }

  if (!requestDefaults?.reasonOptional && !config.reason) {
    return false;
  }

  if (requestDefaults?.requireSupportTicket && !config.supportTicket) {
    return false;
  }

  const activeRequestConfigurations = config.selectedEntities.flatMap(
    (entity) =>
      config.requestConfigsByEntityId[entity.id]?.map(
        (requestConfig) => requestConfig.requestConfiguration
      ) ?? []
  );
  const requestTemplates = getUniqueRequestTemplates(
    activeRequestConfigurations
  );

  for (const template of requestTemplates) {
    for (const field of template.customFields ?? []) {
      if (!field.required) {
        continue;
      }
      const metadata = config.customMetadata[field.name];
      if (metadata == null || metadata.metadataValue == null) {
        return false;
      }
      switch (field.type) {
        case RequestTemplateCustomFieldType.ShortText:
          if (!metadata.metadataValue.shortTextValue?.value) {
            return false;
          }
          break;
        case RequestTemplateCustomFieldType.LongText:
          if (!metadata.metadataValue.longTextValue?.value) {
            return false;
          }
          break;
        case RequestTemplateCustomFieldType.Boolean:
          if (!metadata.metadataValue.booleanValue?.value) {
            return false;
          }
          break;
        case RequestTemplateCustomFieldType.MultiChoice:
          if (!metadata.metadataValue.multiChoiceValue?.value) {
            return false;
          }
      }
    }
  }

  for (const message of requestDefaults?.messages ?? []) {
    if (message.level === RequestMessageLevel.Error) return false;
  }

  return true;
}
export default RequestAccessView;
