import {
  EntityType,
  FiltersInput,
  ResourcePreviewLargeFragment,
  SortDirection,
  useFilteredResourcesLargeQuery,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { useToast } from "components/toast/Toast";
import React, { useEffect, useState } from "react";
import { AccessReviewItemPreviewModal } from "views/access_reviews/settings/scope/AccessReviewItemPreviewModal";

interface AccessReviewResourcesPreviewModalProps {
  onClose: () => void;
  filters: FiltersInput;
}

export const AccessReviewResourcesPreviewModal: React.FC<AccessReviewResourcesPreviewModalProps> = (
  props
) => {
  const { onClose } = props;
  const [sort, setSort] = useState(SortDirection.Asc);
  const queryInput = { filters: props.filters, sort };
  const { data, error, loading, fetchMore } = useFilteredResourcesLargeQuery({
    variables: {
      input: queryInput,
    },
  });
  const { displayErrorToast } = useToast();
  useEffect(() => {
    if (error) {
      displayErrorToast("An error occurred while loading the resources list.");
      onClose();
    }
  }, [onClose, error, displayErrorToast]);

  const loadMoreRows = async (cursor: string) => {
    await fetchMore({
      variables: {
        input: { ...queryInput, cursor },
      },
    });
  };

  const markedResources: ResourcePreviewLargeFragment[] =
    data?.filteredResources?.resources ?? [];

  const items = markedResources.map((r) => {
    return {
      id: r.id,
      value: r.name,
      rendered: (
        <ResourceLabel
          text={r.name}
          subText={getResourceTypeInfo(r.resourceType)?.name || "--"}
          entityId={r.id}
          entityTypeNew={EntityType.Resource}
          resourceType={r.resourceType}
          icon={r.iconUrl}
          bold={true}
          iconLarge={true}
          target="_blank"
        />
      ),
      connection: r.connection ?? undefined,
      admin: r.adminOwner ?? undefined,
    };
  });

  return (
    <AccessReviewItemPreviewModal
      itemType="resource"
      onClose={onClose}
      items={items}
      loading={loading}
      cursor={data?.filteredResources?.cursor ?? undefined}
      onSortDirectionChange={setSort}
      loadMoreRows={loadMoreRows}
    />
  );
};
