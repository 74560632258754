import { Route, useRouteMatch } from "react-router";

import Recommendations from "./Recommendations";

const RecommendationsView = () => {
  const match = useRouteMatch();

  return <Route exact path={`${match.path}`} component={Recommendations} />;
};

export default RecommendationsView;
