import {
  EntityType,
  useRequestTemplatesViewQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { ButtonV3, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { getResourceUrlNew } from "utils/common";
import { useTransitionTo } from "utils/router/hooks";

import CreateRequestTemplateModal from "./CreateRequestTemplateModal";

interface RequestTemplateRow {
  id: string;
  name: string;
  numCustomFields: number;
}

const REQUEST_TEMPLATE_COLUMNS: Header<RequestTemplateRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
  },
  {
    id: "numCustomFields",
    label: "Custom Fields",
    sortable: false,
  },
];

const RequestTemplatesView = () => {
  const transitionTo = useTransitionTo();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const { data, loading } = useRequestTemplatesViewQuery({
    fetchPolicy: "cache-and-network",
  });

  const templates = data?.requestTemplatesWithFields.requestTemplates ?? [];

  const rows: RequestTemplateRow[] = templates.map((template) => {
    return {
      id: template.id,
      name: template.name,
      numCustomFields: template.customFields?.length ?? 0,
    };
  });

  return (
    <>
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: "md",
        })}
      >
        <span
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
          })}
        >
          {loading && !data ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            `${pluralize(
              "Custom Access Request",
              data?.requestTemplatesWithFields.requestTemplates.length,
              true
            )}`
          )}
        </span>
        {isAdmin && (
          <ButtonV3
            label="Custom Access Request"
            type="main"
            leftIconName="plus"
            onClick={() => {
              setShowCreateModal(true);
            }}
            size="sm"
          />
        )}
      </div>

      {loading && !data ? (
        <ColumnListItemsSkeleton />
      ) : (
        <Table
          rows={rows}
          totalNumRows={
            data?.requestTemplatesWithFields.requestTemplates.length ?? 0
          }
          getRowId={(ru) => ru.id}
          columns={REQUEST_TEMPLATE_COLUMNS}
          onRowClick={(row, event) => {
            transitionTo(
              {
                pathname: getResourceUrlNew({
                  entityId: row.id,
                  entityType: EntityType.RequestTemplate,
                }),
              },
              event
            );
          }}
        />
      )}
      {showCreateModal ? (
        <CreateRequestTemplateModal
          templateType="request"
          onClose={() => setShowCreateModal(false)}
        />
      ) : null}
    </>
  );
};

export default RequestTemplatesView;
