import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

enum SettingsCategory {
  Integrations,
  Advanced,
  IdpAndHr,
  Authentication,
  EventStreaming,
  AccessRequests,
  AWSSettings,
  Api,
  Webhooks,
  DangerZone,
}

interface SettingsCategoryInfo {
  category: SettingsCategory;
  name: string;
  anchor: string;
  hidden?: boolean;
}

interface SettingsSection {
  categories: SettingsCategoryInfo[];
}

const SettingsColumn = () => {
  const history = useHistory();
  const location = useLocation();

  const currentCategory = location.hash.slice(1);

  const handleCategoryClick = (categoryInfo: SettingsCategoryInfo) => {
    history.push(`/settings#${categoryInfo.anchor}`);
  };
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEventStreaming = useFeatureFlag(FeatureFlag.EventStreaming);
  const canUseEventStreaming = hasV3 && hasEventStreaming;

  const settingsSections: SettingsSection[] = [
    {
      categories: [
        {
          category: SettingsCategory.IdpAndHr,
          name: "IDP & HR Integrations",
          anchor: "idp-and-hr",
        },
        {
          category: SettingsCategory.Integrations,
          name: "Productivity Integrations",
          anchor: "productivity-integrations",
        },
        {
          category: SettingsCategory.Authentication,
          name: "Authentication",
          anchor: "authentication",
        },
        {
          category: SettingsCategory.EventStreaming,
          name: "Event Streaming",
          anchor: "event-streaming",
          hidden: !canUseEventStreaming,
        },
      ],
    },
    {
      categories: [
        {
          category: SettingsCategory.AccessRequests,
          name: "Access Requests",
          anchor: "access-requests",
        },
        {
          category: SettingsCategory.AWSSettings,
          name: "AWS Settings",
          anchor: "aws-settings",
        },
        {
          category: SettingsCategory.Advanced,
          name: "Advanced",
          anchor: "advanced",
        },
        {
          category: SettingsCategory.Api,
          name: "API Access Tokens",
          anchor: "api",
        },
        {
          category: SettingsCategory.Webhooks,
          name: "Webhooks",
          anchor: "webhooks",
        },
      ],
    },
    {
      categories: [
        {
          category: SettingsCategory.DangerZone,
          name: "Danger Zone",
          anchor: "danger-zone",
        },
      ],
    },
  ];

  return (
    <Column>
      <ColumnHeader
        title="Settings"
        subtitle="Manage integrations and organization settings"
        icon={{ icon: "settings", type: "name" }}
      />
      <Divider />
      {settingsSections.map((section, i) => {
        return (
          <React.Fragment key={i}>
            {section.categories
              .filter((c) => c.hidden !== true)
              .map((categoryInfo) => {
                let selected = categoryInfo.anchor === currentCategory;
                if (
                  categoryInfo.category === SettingsCategory.IdpAndHr &&
                  location.pathname.includes("/idp")
                ) {
                  selected = true;
                }
                return (
                  <ColumnListItem
                    key={categoryInfo.category}
                    label={categoryInfo.name}
                    onClick={() => handleCategoryClick(categoryInfo)}
                    selected={selected}
                  />
                );
              })}
            {i !== settingsSections.length - 1 ? <Divider /> : null}
          </React.Fragment>
        );
      })}
    </Column>
  );
};

export default SettingsColumn;
