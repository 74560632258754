import {
  EntityType,
  UserPreviewSmallFragment,
  UserResourceFragment,
} from "api/generated/graphql";
import { getAccessPathsSortValue } from "components/label/AccessPointsLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import {
  ResourceLabel,
  TimeLabel,
  timeValueIndefinite,
} from "components/label/Label";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { getResourceSublabel } from "utils/resources";
import { PropagationType } from "utils/useRemediations";
import {
  getResourceUserAccessPathsInfo,
  ResourceUserAccessPointsLabel,
} from "views/resources/ResourceUserAccessPointsLabel";

import { AppsContext } from "../apps/AppsContext";

interface UserResourceTableRow {
  name: string;
  role: string;
  connection: string;
  accessPaths: string;
  expires: string;
  status: string;
}

type UserResourcesTableProps = {
  user: UserPreviewSmallFragment;
  userResources: UserResourceFragment[];
  showUnmanagedResources: boolean;
};

export const UserResourcesTable = (props: UserResourcesTableProps) => {
  const history = useHistory();

  const { toggleUnmanagedItem } = useContext(AppsContext);

  const headers: Header<UserResourceTableRow>[] = [
    { id: "name", label: "Resource", width: 280, minWidth: 230 },
    { id: "role", label: "Role", width: 200, minWidth: 180 },
    { id: "connection", label: "App", width: 200, minWidth: 180 },
    { id: "accessPaths", label: "Source of Access", width: 150, minWidth: 150 },
    { id: "expires", label: "Expires", width: 120, minWidth: 100 },
    { id: "status", label: "Status", width: 150, minWidth: 150 },
  ];

  const rows: CellRow<UserResourceTableRow>[] = [];
  for (const userResource of props.userResources) {
    if (!props.showUnmanagedResources && !userResource.resource?.isManaged) {
      continue;
    }

    const name = userResource.resource?.name || userResource.resourceId;
    const subText = userResource?.resource
      ? getResourceSublabel(userResource.resource)
      : "--";

    const connection = userResource.resource?.connection;
    const connectionId = connection?.id;

    const expirationTime = userResource.access?.latestExpiringAccessPoint
      ?.expiration
      ? moment(
          new Date(userResource.access.latestExpiringAccessPoint.expiration)
        )
      : null;
    const supportTicket =
      userResource.access?.latestExpiringAccessPoint?.supportTicket;

    const accessPathsInfo = getResourceUserAccessPathsInfo({
      user: props.user,
      resource: userResource.resource,
      access: userResource.access,
    });

    rows.push({
      id: userResource.resourceId,
      data: {
        name: {
          value: name,
          element: (
            <ResourceLabel
              text={name}
              subText={subText}
              entityType={EntityTypeDeprecated.Resource}
              bold={true}
              icon={userResource?.resource?.iconUrl}
              resourceType={userResource?.resource?.resourceType}
              iconLarge={true}
              pointerCursor={true}
              maxChars="auto"
              inactive={
                userResource.resource?.isManaged !== undefined &&
                !userResource.resource?.isManaged
              }
              tooltipText={
                userResource.resource?.isManaged !== undefined &&
                !userResource.resource?.isManaged
                  ? "This resource is currently not managed in Opal"
                  : null
              }
            />
          ),
          clickHandler: () => {
            if (userResource.resource?.isManaged) {
              history.push(
                getResourceUrlNew({
                  entityId: userResource.resourceId,
                  entityType: EntityType.Resource,
                })
              );
            } else {
              userResource.resource &&
                toggleUnmanagedItem(userResource.resource);
            }
          },
        },
        role: {
          value: userResource.accessLevel?.accessLevelName || "",
          element: (
            <div>{userResource.accessLevel?.accessLevelName || "--"}</div>
          ),
        },
        connection: {
          sortValue: connection?.name || "",
          value: connectionId || "",
          element: (
            <ConnectionLabel
              text={connection?.name}
              connectionId={connectionId}
              connectionType={connection?.connectionType}
            />
          ),
        },
        accessPaths: {
          value: userResource.userId,
          sortValue: getAccessPathsSortValue(
            accessPathsInfo.hasDirectAccess,
            accessPathsInfo.accessPointRows.length
          ),
          element: (
            <ResourceUserAccessPointsLabel
              user={props.user}
              resource={userResource.resource}
              access={userResource.access}
            />
          ),
        },
        expires: {
          value: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={supportTicket}
              useExpiringLabel
            />
          ),
        },
        status: {
          value: userResource.userId,
          sortValue: userResource.propagationStatus?.statusCode,
          element: (
            <PropagationStatusLabelWithModal
              propagationType={PropagationType.ResourceUser}
              propagationStatus={userResource.propagationStatus}
              isAccessReview={false}
              entityInfo={{
                user: props.user,
                resource: userResource.resource,
                role: userResource.accessLevel,
                lastExpiringAccessPointExpiration:
                  userResource.access?.latestExpiringAccessPoint.expiration,
              }}
            />
          ),
        },
      },
    });
  }

  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"name"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export default UserResourcesTable;
