import React from "react";
import SetupBar from "views/setup/SetupBar";

import ImpersonationBanner from "./ImpersonationBanner";
import * as styles from "./LayoutV3.css";
import LeftSidebar from "./left_sidebar/LeftSidebarV3";

const LayoutV3: React.FC<{}> = (props) => {
  return (
    <div className={styles.fullPageContainer}>
      <ImpersonationBanner />
      <div className={styles.layoutContainer}>
        <LeftSidebar />
        <div className={styles.rightContainer}>
          <SetupBar />
          <div className={styles.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default LayoutV3;
