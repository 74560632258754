import {
  ConnectionPreviewLargeFragment,
  GroupType,
  ResourcePreviewLargeFragment,
  ResourceType,
  useConnectionsQuery,
  useGroupsHomeQuery,
  useResourcesHomeQuery,
} from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import PaginatedResourceDropdown from "components/dropdown/PaginatedResourceDropdown";
import ResourceSearchDropdown from "components/dropdown/ResourceSearchDropdown";
import {
  DataElement,
  DataElementList,
  EntityIcon,
  FormGroup,
  Modal,
  Select,
  Skeleton,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { principalResourceTypes } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

interface Props {
  entityIds: string[];
  onChangeEntityIds?: (entityIds: string[]) => void;
}

type Entity =
  | ResourcePreviewLargeFragment
  | { id: string; name: string; groupType: GroupType }
  | ConnectionPreviewLargeFragment;
type EntityType = PropsFor<typeof EntityIcon>["type"];

const EntitiesIndividualFilter: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showNHIsModal, setShowNHIsModal] = useState(false);
  const [modalEntityIds, setModalEntityIds] = useState<string[]>([]);

  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  const {
    data: resourcesData,
    loading: resourcesLoading,
  } = useResourcesHomeQuery({
    variables: {
      input: {
        resourceIds: [...props.entityIds, ...modalEntityIds],
      },
    },
  });

  const { data: groupsData, loading: groupsLoading } = useGroupsHomeQuery({
    variables: {
      input: {
        groupIds: [...props.entityIds, ...modalEntityIds],
      },
    },
  });

  const {
    data: connectionsData,
    loading: connectionsLoading,
  } = useConnectionsQuery({
    variables: {
      input: {},
    },
  });

  const loading = resourcesLoading || groupsLoading || connectionsLoading;

  const resourceById: {
    [resourceId: string]: ResourcePreviewLargeFragment;
  } = {};
  resourcesData?.resources.resources.forEach(
    (resource) => (resourceById[resource.id] = resource)
  );
  const groupById: {
    [groupId: string]: { id: string; name: string; groupType: GroupType };
  } = {};
  groupsData?.groups.groups.forEach((group) => (groupById[group.id] = group));
  const connectionById: {
    [connectionId: string]: ConnectionPreviewLargeFragment;
  } = {};
  connectionsData?.connections.connections.forEach(
    (connection) => (connectionById[connection.id] = connection)
  );
  const unselectedConnections =
    connectionsData?.connections.connections.filter(
      (connection) => !modalEntityIds.includes(connection.id)
    ) ?? [];

  const handleCancel = () => {
    setShowNHIsModal(false);
    setShowModal(false);
  };

  const handleSave = () => {
    if (props.onChangeEntityIds) {
      props.onChangeEntityIds([...modalEntityIds]);
    }
    setShowNHIsModal(false);
    setShowModal(false);
  };

  const getEntity = (entityId: string): Entity | undefined => {
    if (resourceById[entityId]) {
      return resourceById[entityId];
    }

    if (groupById[entityId]) {
      return groupById[entityId];
    }

    if (connectionById[entityId]) {
      return connectionById[entityId];
    }
  };

  const getEntityType = (entity: Entity): EntityType | undefined => {
    if ("groupType" in entity) {
      return entity.groupType;
    }
    if ("resourceType" in entity) {
      return entity.resourceType;
    }
    if ("connectionType" in entity) {
      return entity.connectionType;
    }
  };

  if (!props.onChangeEntityIds) {
    if (props.entityIds.length === 0) {
      return null;
    }
    return (
      <FormGroup
        label="Specific Resources"
        fontWeight="normal"
        fontSize="textMd"
      >
        <DataElementList>
          {loading ? (
            <Skeleton />
          ) : (
            props.entityIds.map((entityId) => {
              const entity = getEntity(entityId);
              if (!entity) {
                return null;
              }
              const entityType = getEntityType(entity);
              if (!entityType) {
                return null;
              }
              return (
                <DataElement
                  key={entity.id}
                  label={entity.name}
                  color="pink"
                  leftIcon={{ data: { entityType, type: "entity" } }}
                />
              );
            })
          )}
        </DataElementList>
      </FormGroup>
    );
  }

  const nonHumanIdentities = hasNHIs
    ? modalEntityIds.flatMap((entityId) => {
        const entity = getEntity(entityId);
        if (!entity) {
          return [];
        }
        const entityType = getEntityType(entity);
        if (!entityType) {
          return [];
        }
        if (!principalResourceTypes.includes(entityType as ResourceType)) {
          return [];
        }
        return entity as ResourcePreviewLargeFragment;
      })
    : [];

  return (
    <>
      <FormGroup label="Specific entities">
        <div className={sprinkles({ marginBottom: "sm" })}>
          Add specific items to the access review
        </div>
        <DataElementList>
          {loading ? (
            <Skeleton />
          ) : (
            props.entityIds.map((entityId) => {
              const entity = getEntity(entityId);
              if (!entity) {
                return null;
              }
              const entityType = getEntityType(entity);
              if (!entityType) {
                return null;
              }
              return (
                <DataElement
                  key={entity.id}
                  label={entity.name}
                  color="pink"
                  leftIcon={{ data: { entityType, type: "entity" } }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      props.onChangeEntityIds &&
                        props.onChangeEntityIds(
                          props.entityIds.filter((id) => id !== entityId)
                        );
                    },
                  }}
                />
              );
            })
          )}
        </DataElementList>
        <div className={sprinkles({ marginTop: "sm" })}>
          <DataElementList>
            <DataElement
              label="Add entity"
              color="blue"
              leftIcon={{
                name: "plus",
              }}
              onClick={() => {
                setShowModal(true);
                setModalEntityIds(props.entityIds);
              }}
            />
            {hasNHIs && (
              <DataElement
                label="Add non-human identity"
                color="blue"
                leftIcon={{
                  name: "plus",
                }}
                onClick={() => {
                  setShowNHIsModal(true);
                  setModalEntityIds(props.entityIds);
                }}
              />
            )}
          </DataElementList>
        </div>
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Include specific entities"
      >
        <Modal.Body>
          <p>
            This access review will include the following resources, groups, and
            apps. If no items are selected, the access review will only include
            items matching the other provided scopes.
          </p>
          <FormGroup label="Resources">
            <ResourceSearchDropdown
              style="search"
              selectedResourceIds={modalEntityIds}
              onSelect={(data) => {
                if (data.actionType === "select-option") {
                  setModalEntityIds([
                    ...modalEntityIds,
                    ...data.resources.map((r) => r.id),
                  ]);
                }
              }}
            />
          </FormGroup>
          <FormGroup label="Groups">
            <GroupSearchDropdown
              style="search"
              selectedGroupIds={modalEntityIds}
              onSelect={(data) => {
                if (data.actionType === "select-option") {
                  setModalEntityIds([
                    ...modalEntityIds,
                    ...data.groups.map((r) => r.id),
                  ]);
                }
              }}
            />
          </FormGroup>
          <FormGroup label="Connections">
            <Select
              options={unselectedConnections}
              selectOnly
              getOptionLabel={(option) => option.name}
              getIcon={(connection) => ({
                type: "entity",
                entityType: connection.connectionType,
              })}
              onChange={(connection) => {
                if (connection) {
                  setModalEntityIds((prev) => [...prev, connection.id]);
                }
              }}
            />
          </FormGroup>
          {modalEntityIds.length > 0 && (
            <div className={sprinkles({ marginTop: "lg" })}>
              {loading ? (
                <Skeleton />
              ) : (
                <DataElementList>
                  {modalEntityIds.map((entityId) => {
                    const entity = getEntity(entityId);
                    if (!entity) {
                      return null;
                    }
                    const entityType = getEntityType(entity);
                    if (!entityType) {
                      return null;
                    }
                    return (
                      <DataElement
                        key={entity.id}
                        label={entity.name}
                        color="pink"
                        leftIcon={{
                          data: { entityType, type: "entity" },
                        }}
                        rightIcon={{
                          name: "x",
                          onClick: () => {
                            setModalEntityIds(
                              modalEntityIds.filter((id) => id !== entityId)
                            );
                          },
                        }}
                      />
                    );
                  })}
                </DataElementList>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
      <Modal
        isOpen={hasNHIs && showNHIsModal}
        onClose={handleCancel}
        title="Include specific non-human identities"
      >
        <Modal.Body>
          <p>
            This access review will include the following non-human identities
            in addition to any other selected entities:
          </p>
          <PaginatedResourceDropdown
            selectOnly
            selectedResourceIDs={modalEntityIds}
            placeholder="Add non-human identities"
            resourceTypes={principalResourceTypes}
            onChange={(resource) => {
              if (resource) {
                setModalEntityIds([...modalEntityIds, resource.id]);
              }
            }}
          />
          {nonHumanIdentities.length > 0 && (
            <div className={sprinkles({ marginTop: "lg" })}>
              {loading ? (
                <Skeleton />
              ) : (
                <DataElementList>
                  {nonHumanIdentities.map((entity) => {
                    return (
                      <DataElement
                        key={entity.id}
                        label={entity.name}
                        color="pink"
                        leftIcon={{
                          data: {
                            entityType: entity.resourceType,
                            type: "entity",
                          },
                        }}
                        rightIcon={{
                          name: "x",
                          onClick: () => {
                            setModalEntityIds(
                              modalEntityIds.filter((id) => id !== entity.id)
                            );
                          },
                        }}
                      />
                    );
                  })}
                </DataElementList>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default EntitiesIndividualFilter;
