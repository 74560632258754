import clsx from "clsx";
import { Icon, Loader } from "components/ui";
import sprinkles from "css/sprinkles.css";

import * as styles from "./Metrics.css";

interface Props {
  title: string;
  subtitle: string;
  units?: string;
  loading: boolean;
  error: boolean;
  noData?: boolean;
}

const MetricsGraphCard: React.FC<Props> = (props) => {
  const { error, loading, title, subtitle, units, noData } = props;

  let content;
  if (error) {
    content = (
      <div
        className={sprinkles({
          color: "gray500",
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        <Icon name="alert-triangle" size="xs" />
        Failed to load data. Try refreshing the page.
      </div>
    );
  } else if (loading) {
    content = <Loader opalLogo label="Loading data..." />;
  } else if (noData) {
    content = (
      <div className={styles.noDataLabel}>
        <Icon name="help-circle" size="xs" />
        No data available for the selected date range and filters.
      </div>
    );
  } else {
    content = props.children;
  }

  return (
    <div className={clsx(styles.metricsTile, styles.graphMetricTile)}>
      <div className={styles.title}>
        {title}{" "}
        {units ? (
          <span
            className={sprinkles({
              fontSize: "bodySm",
              color: "gray500",
              fontWeight: "normal",
            })}
          >
            ({units})
          </span>
        ) : null}
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.graphContainer}>{content}</div>
    </div>
  );
};

export const MetricsGraphRow: React.FC = (props) => {
  return (
    <div
      className={sprinkles({
        padding: "md",
        gap: "lg",
        display: "flex",
      })}
    >
      {props.children}
    </div>
  );
};

export default MetricsGraphCard;
