import { refetchQueries } from "api/ApiContext";
import {
  AppType,
  EntityType,
  GroupBulkSuggestionPreviewFragment,
  RecommendationsEntityKey,
  RecommendationsEntityType,
  RecommendationsSubscoreType,
  ResourceBulkSuggestionPreviewFragment,
  ResourceType,
  TopBulkSuggestionsQuery,
  useDismissRecommendationsSubscoresMutation,
  useLogEntityRemediationMutation,
  useRescoreEntityMutation,
  useTopBulkSuggestionsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { BreadcrumbsV3, ButtonV3, Icon, PopoverV3 } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _, { lowerCase } from "lodash";
import pluralize from "pluralize";
import { useContext } from "react";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { useGetResourceBreadcrumbs } from "utils/hooks";
import {
  isEmptyFilter,
  RecommendationsFilter,
} from "views/recommendations/RecommendationFilters";
import ThreatPill, {
  outsideAccessRiskFactorText,
  perpetualAccessRiskFactorText,
  unusedAccessRiskFactorText,
} from "views/recommendations/ThreatPill";

import EntityRemediationModal from "./resolutions/EntityRemediationModal";
import { ResourceEntityIcon } from "./resolutions/RemediationColumns";
import * as popover_styles from "./ThreatPill.css";
import * as styles from "./ThreatResolutionNew.css";

type BulkSuggestionRow = {
  id: string;
  entity:
    | ResourceBulkSuggestionPreviewFragment
    | GroupBulkSuggestionPreviewFragment;
  type: RecommendationsSubscoreType;
  totalCount: number;
  targetCount: number;
};

interface ThreatResolutionNewProps {
  filter: RecommendationsFilter;
  clearFilter: Dispatch<SetStateAction<RecommendationsFilter>>;
}

export interface RemediationMetadata {
  entityKey: RecommendationsEntityKey;
  jitCount: number;
  jitCountFromSuggestions: number;
  revokeCount: number;
  revokeCountFromSuggestions: number;
  remediationType: RecommendationsSubscoreType;
  totalSuggestionsCount: number;
}

export const getSuggestionRowId = (row: BulkSuggestionRow) =>
  row.type + ":" + row.id;

const ThreatResolutionNew = ({
  filter,
  clearFilter,
}: ThreatResolutionNewProps) => {
  const [displayedSuggestionsData, setDisplayedSuggestionData] = useState<
    TopBulkSuggestionsQuery | undefined
  >(undefined);
  const [rowDisplayCount, setRowDisplayCount] = useState(5);
  const loadMore = () => {
    setRowDisplayCount(rowDisplayCount + 4);
  };
  const [remediationMetadata, setRemediationMetadata] = useState<
    Map<string, RemediationMetadata>
  >(new Map());
  const [remediatedEntityId, setRemediatedEntityId] = useState<string>();
  const [rowsBeingRemediated, setRowsBeingRemediated] = useState<Set<string>>(
    new Set()
  );

  const addRemediatedEntityId = (entityId: string | undefined) => {
    setRemediatedEntityId(entityId);
  };
  const [runLogEntityRemediationMutation] = useLogEntityRemediationMutation();
  const addRemediationMetadata = (row: BulkSuggestionRow) => {
    return (metadata: RemediationMetadata) =>
      setRemediationMetadata(
        new Map(remediationMetadata.set(getSuggestionRowId(row), metadata))
      );
  };

  const removeRemediationMetadata = (rowId: string) => {
    setRemediationMetadata(
      new Map(Array.from(remediationMetadata).filter(([key]) => key !== rowId))
    );
  };
  const [runRescoreMutation] = useRescoreEntityMutation();

  const debouncedRemoveRow = _.debounce(
    async (rowId, rowItem: RemediationMetadata) => {
      removeRemediationMetadata(rowId);

      await runLogEntityRemediationMutation({
        variables: {
          input: {
            entityKey: rowItem.entityKey,
            jitCount: rowItem.jitCount,
            jitCountFromSuggestions: rowItem.jitCountFromSuggestions,
            revokeCount: rowItem.revokeCount,
            revokeCountFromSuggestions: rowItem.revokeCountFromSuggestions,
            remediationType: rowItem.remediationType,
            totalSuggestionsCount: rowItem.totalSuggestionsCount,
          },
        },
      });

      await runRescoreMutation({
        variables: {
          input: rowItem.entityKey,
        },
      });

      refetchQueries({ include: ["TopBulkSuggestions", "RiskScoreGraphData"] });

      const updatedSuggestions = displayedSuggestionsData
        ? _.cloneDeep(displayedSuggestionsData)
        : undefined;
      if (updatedSuggestions?.topBulkSuggestions?.suggestions) {
        updatedSuggestions.topBulkSuggestions.suggestions = updatedSuggestions.topBulkSuggestions.suggestions.filter(
          // @ts-ignore, we don't have a fragment for groups yet so id is nullable
          (suggestion) => getSuggestionRowId(suggestion) !== rowId
        );
      }
      setDisplayedSuggestionData(updatedSuggestions);
      addRemediatedEntityId(undefined); // resetting the remediatedEntityId
      setRowsBeingRemediated((prevRows) => {
        // resetting the rows being processed
        const newProcessingRows = new Set(prevRows);
        newProcessingRows.delete(rowId);
        return newProcessingRows;
      });
    },
    5000
  );

  useEffect(() => {
    if (remediationMetadata.size > 0) {
      remediationMetadata.forEach((rowItem, rowId) => {
        if (!rowsBeingRemediated.has(rowId)) {
          // Add row to remediating state
          setRowsBeingRemediated((prev) => new Set(prev).add(rowId));
          debouncedRemoveRow(rowId, rowItem);
        }
      });
    }
  });

  const appIdInput = useMemo(() => {
    if (!filter.appFilter) {
      return undefined;
    }

    switch (filter.appFilter?.app.__typename) {
      case "ConnectionApp":
        return {
          id: filter.appFilter.app.connectionId,
          type: AppType.Native,
        };
      case "OktaResourceApp":
        return {
          id: filter.appFilter.app.resourceId,
          type: AppType.Okta,
        };
      default:
        return undefined;
    }
  }, [filter.appFilter]);

  const tagIdInput = useMemo(() => {
    if (!filter.tagFilter) {
      return undefined;
    }
    return {
      id: filter.tagFilter.id,
    };
  }, [filter.tagFilter]);

  const AllSuggestionTypes = useMemo(
    () => [
      RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess,
      RecommendationsSubscoreType.SuggestionNotUsingAccess,
      RecommendationsSubscoreType.SuggestionUnusedAccess,
      RecommendationsSubscoreType.SuggestionUnmanagedAccess,
    ],
    []
  );

  const getSuggestionTypesFromRiskFactor = (
    riskFactor: string | undefined
  ): RecommendationsSubscoreType[] => {
    let riskTypes: RecommendationsSubscoreType[] = [];
    if (riskFactor === perpetualAccessRiskFactorText) {
      riskTypes.push(
        RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess
      );
    } else if (riskFactor === unusedAccessRiskFactorText) {
      riskTypes.push(
        RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess,
        RecommendationsSubscoreType.SuggestionNotUsingAccess,
        RecommendationsSubscoreType.SuggestionUnusedAccess
      );
    } else if (riskFactor == outsideAccessRiskFactorText) {
      riskTypes.push(RecommendationsSubscoreType.SuggestionUnmanagedAccess);
    } else {
      throw new Error(`Unexpected risk factor: ${riskFactor}`);
    }
    return riskTypes;
  };

  const {
    data: suggestionsData,
    loading: suggestionsLoading,
    refetch,
  } = useTopBulkSuggestionsQuery({
    variables: {
      appId: appIdInput,
      minRiskScore: filter.riskScoreFilter?.minScore,
      maxRiskScore: filter.riskScoreFilter?.maxScore,
      tagID: tagIdInput,
      riskTypes: filter.riskFactor
        ? getSuggestionTypesFromRiskFactor(filter.riskFactor)
        : AllSuggestionTypes,
      groupType: filter.groupTypeFilter,
      resourceType: filter.resourceTypeFilter,
    },
  });

  useEffect(() => {
    refetch({
      appId: appIdInput,
      minRiskScore: filter.riskScoreFilter?.minScore,
      maxRiskScore: filter.riskScoreFilter?.maxScore,
      riskTypes: filter.riskFactor
        ? getSuggestionTypesFromRiskFactor(filter.riskFactor)
        : AllSuggestionTypes,
      groupType: filter.groupTypeFilter,
      resourceType: filter.resourceTypeFilter,
    });
  }, [
    refetch,
    appIdInput,
    filter.riskScoreFilter?.minScore,
    filter.riskScoreFilter?.maxScore,
    filter.riskFactor,
    AllSuggestionTypes,
    filter.groupTypeFilter,
    filter.resourceTypeFilter,
  ]);

  useEffect(() => {
    if (!suggestionsLoading) {
      setDisplayedSuggestionData(suggestionsData);
    }
  }, [suggestionsLoading, suggestionsData]);

  const allRows = (
    displayedSuggestionsData?.topBulkSuggestions.suggestions || []
  )
    .filter((suggestion) => Boolean(suggestion))
    .map((suggestion) => {
      switch (suggestion.entity.__typename) {
        case "Resource":
          return {
            id: suggestion.entity.resourceId,
            entity: suggestion.entity,
            type: suggestion.type,
            totalCount: suggestion.totalUserCount,
            targetCount: suggestion.targetUserCount,
          };
        case "Group":
          return {
            id: suggestion.entity.groupId,
            entity: suggestion.entity,
            type: suggestion.type,
            totalCount: suggestion.totalUserCount,
            targetCount: suggestion.targetUserCount,
          };
      }
    }) as BulkSuggestionRow[];

  const columns: Header<BulkSuggestionRow>[] = [
    {
      id: "id",
      label: "",
      customCellRenderer: (row: BulkSuggestionRow) => {
        return (
          <RecommendationBulkSuggestionRow
            entity={row.entity}
            type={row.type}
            totalCount={row.totalCount}
            targetCount={row.targetCount}
            addRemediationMetadata={addRemediationMetadata(row)}
            remediationMetadata={remediationMetadata.get(
              getSuggestionRowId(row)
            )}
            addRemediatedEntityId={addRemediatedEntityId}
            remediatedEntityId={remediatedEntityId}
          />
        );
      },
    },
  ];

  const numSuggestions =
    displayedSuggestionsData?.topBulkSuggestions.suggestions.length;

  const DataSection = () => {
    if (!isEmptyFilter(filter) && allRows.length == 0) {
      return (
        <div
          className={sprinkles({
            fontFamily: "body",
            fontSize: "textXs",
          })}
        >
          No resources match your search criteria.{" "}
          <span
            className={sprinkles({
              color: "blue600V3",
              cursor: "pointer",
            })}
            onClick={() => clearFilter({})}
          >
            Clear your filters
          </span>{" "}
          to continue remediating.
        </div>
      );
    }

    return (
      <>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
          })}
        >
          <Table
            rows={allRows.slice(0, rowDisplayCount)}
            columns={columns}
            loadingRows={suggestionsLoading}
            totalNumRows={numSuggestions ?? 0}
            getRowId={(row) => getSuggestionRowId(row)}
            emptyState={{
              title: "No suggestions to remediate",
            }}
            getRowHighlightColor={(row) => {
              const hasMetadata = remediationMetadata.has(
                getSuggestionRowId(row)
              );
              if (hasMetadata) {
                return "green";
              }
            }}
            getRowHighlighted={(row) => {
              const hasMetadata = remediationMetadata.has(
                getSuggestionRowId(row)
              );
              return hasMetadata;
            }}
            hideHeader
          />
        </div>
        <div>
          {allRows.length > rowDisplayCount && (
            <ButtonV3
              type="default"
              size="sm"
              outline
              label="Load More"
              onClick={() => {
                loadMore();
              }}
            />
          )}
        </div>
      </>
    );
  };
  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        gap: "xs",
      })}
    >
      <div className={styles.header}>Most Urgent Threats</div>
      <div>
        <DataSection />
      </div>
    </div>
  );
};

const RecommendationBulkSuggestionRow = ({
  entity,
  type,
  totalCount,
  targetCount,
  addRemediationMetadata,
  remediationMetadata,
  addRemediatedEntityId,
  remediatedEntityId,
}: {
  entity:
    | ResourceBulkSuggestionPreviewFragment
    | GroupBulkSuggestionPreviewFragment;
  type: RecommendationsSubscoreType;
  totalCount: number;
  targetCount: number;
  addRemediationMetadata: (metadata: RemediationMetadata) => void;
  remediationMetadata?: RemediationMetadata;
  addRemediatedEntityId: (entityId: string) => void;
  remediatedEntityId?: string;
}) => {
  const isOktaApp =
    entity.__typename === "Resource" &&
    entity.resourceType == ResourceType.OktaApp;

  const [modalOpen, setModalOpen] = useState(false);

  const [dismiss] = useDismissRecommendationsSubscoresMutation({
    refetchQueries: ["TopBulkSuggestions", "RiskScoreGraphData"],
  });
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const suggestionTypeContent = () => {
    const entityTypeText = isOktaApp ? "app" : lowerCase(entity.__typename);

    const renderContent = (
      message: string,
      pillTypes: RecommendationsSubscoreType[]
    ) => (
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        {pillTypes.map((subscoreType) => (
          <ThreatPill key={subscoreType} type={subscoreType} />
        ))}
        <span
          className={sprinkles({
            fontSize: "textSm",
            color: "black",
          })}
        >
          {message}
        </span>
      </div>
    );

    switch (type) {
      case RecommendationsSubscoreType.SuggestionPerpetualAndUnusedAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} directly assigned ${pluralize(
            "user",
            totalCount
          )} ${pluralize(
            "have",
            targetCount
          )} not used this ${entityTypeText} in 30 days.`,
          [
            RecommendationsSubscoreType.PerpetualAccess,
            RecommendationsSubscoreType.UnusedAccess,
          ]
        );

      case RecommendationsSubscoreType.SuggestionUnmanagedAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} directly assigned ${pluralize(
            "user",
            totalCount
          )} ${pluralize(
            "have",
            targetCount
          )} externally provisioned access to this ${entityTypeText}.`,
          [RecommendationsSubscoreType.UnmanagedAccess]
        );

      case RecommendationsSubscoreType.SuggestionNotUsingAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} ${pluralize(
            "resource",
            totalCount
          )} ${pluralize(
            "is",
            targetCount
          )} unused by all members of this ${entityTypeText}.`,
          [RecommendationsSubscoreType.UnusedAccess]
        );

      case RecommendationsSubscoreType.SuggestionUnusedAccess:
        return renderContent(
          `${targetCount} out of ${totalCount} directly assigned ${pluralize(
            "user",
            totalCount
          )} ${pluralize(
            "have",
            targetCount
          )} not used this ${entityTypeText} in 30 days.`,
          [RecommendationsSubscoreType.UnusedAccess]
        );

      default:
        return <></>;
    }
  };

  const entityType = (() => {
    switch (entity.__typename) {
      case "Resource":
        return EntityType.Resource;
      case "Group":
        return EntityType.Group;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const entityId = (() => {
    switch (entity.__typename) {
      case "Resource":
        return entity.resourceId;
      case "Group":
        return entity.groupId;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const entityTypeName = (() => {
    switch (entity.__typename) {
      case "Resource":
        return resourceTypeInfoByType[entity.resourceType].fullName;
      case "Group":
        return groupTypeInfoByType[entity.groupType].name;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const recommendationsEntityType = (() => {
    switch (entity.__typename) {
      case "Resource":
        return RecommendationsEntityType.Resource;
      case "Group":
        return RecommendationsEntityType.Group;
      default:
        throw new Error(`Unexpected entity type: ${entity.__typename}`);
    }
  })();

  const {
    data: breadcrumbs,
    error: breadcrumbsError,
    loading: breadcrumbsLoading,
  } = useGetResourceBreadcrumbs(
    {
      ...entity,
      id: entityId,
    },
    false
  );
  if (breadcrumbsError) {
    throw new Error(`Unexpected error generating breadcrumbs`);
  }
  return (
    <div
      key={entityId}
      className={sprinkles({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "sm",
        fontFamily: "body",
      })}
    >
      <div
        className={sprinkles({
          display: "block",
        })}
      >
        <div
          className={sprinkles({
            display: "flex",
            gap: "xs",
            alignItems: "center",
            paddingBottom: "sm",
          })}
        >
          <ResourceEntityIcon
            isOktaApp={isOktaApp}
            iconUrl={entity.iconUrl}
            connectionType={entity.connection?.connectionType}
            iconSize={"sm"}
          />

          <span className={sprinkles({ color: "gray600" })}>
            {!breadcrumbsLoading && (
              <BreadcrumbsV3 breadcrumbInfos={breadcrumbs} linkColor={"grey"} />
            )}
          </span>
        </div>

        <div
          className={sprinkles({
            display: "flex",
            gap: "xs",
            paddingBottom: "sm",
          })}
        >
          <Link
            className={sprinkles({
              fontWeight: "bold",
              fontSize: "textLg",
            })}
            to={getResourceUrlNew(
              { entityId: entityId, entityType: entityType },
              entityType
            )}
          >
            {entity.name}
          </Link>
          <span
            className={sprinkles({
              fontSize: "textLg",
            })}
          >
            {`(${entityTypeName})`}
          </span>
        </div>
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "sm",
          })}
        >
          {remediationMetadata ? (
            <span
              className={sprinkles({
                fontSize: "textSm",
                color: "green500V3",
              })}
            >
              <Icon name="check-circle" size="sm" color="green500V3" />
              {` Threat Resolved: ${remediationMetadata.jitCount} ${pluralize(
                "grant",
                remediationMetadata.jitCount
              )} converted to JIT${
                remediationMetadata.revokeCount !== 0
                  ? `, ${remediationMetadata.revokeCount} revoked ${pluralize(
                      "grant",
                      remediationMetadata.revokeCount
                    )}`
                  : ""
              }`}
            </span>
          ) : (
            suggestionTypeContent()
          )}
        </div>
      </div>
      {!remediationMetadata && (
        <PopoverV3
          content={
            <div className={popover_styles.tooltip}>
              {"Please wait, entity is currently being remediated"}
            </div>
          }
          position={"left"}
          enabled={entityId == remediatedEntityId}
        >
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <PopoverV3
              content={
                <div className={popover_styles.tooltip}>
                  {"You must be an super-admin to dismiss remediations"}
                </div>
              }
              position={"left"}
              enabled={!isAdmin}
            >
              <ButtonV3
                size="sm"
                outline
                type="mainBorderless"
                label="Dismiss"
                disabled={entityId == remediatedEntityId || !isAdmin}
                onClick={() =>
                  dismiss({
                    variables: {
                      input: {
                        entityKeys: [
                          {
                            entityId: entityId,
                            entityType: recommendationsEntityType,
                          },
                        ],
                        type: type,
                      },
                    },
                  })
                }
              />
            </PopoverV3>

            <ButtonV3
              size="sm"
              outline
              type="main"
              label="Review"
              disabled={entityId == remediatedEntityId}
              onClick={() => {
                setModalOpen(true);
              }}
            />
            {modalOpen && (
              <EntityRemediationModal
                entityKeys={[
                  {
                    entityId: entityId,
                    entityType: recommendationsEntityType,
                  },
                ]}
                onClose={() => setModalOpen(false)}
                onRemediationComplete={addRemediationMetadata}
                suggestionTypes={[type]}
                entityRemediated={addRemediatedEntityId}
                isOktaApp={isOktaApp}
                onDismissal={(dismissedEntityId, dismissedEntityType) => {
                  dismiss({
                    variables: {
                      input: {
                        entityKeys: [
                          {
                            entityId: dismissedEntityId,
                            entityType: dismissedEntityType,
                          },
                        ],
                        type: type,
                      },
                    },
                  });
                }}
              />
            )}
          </div>
        </PopoverV3>
      )}
    </div>
  );
};

export default ThreatResolutionNew;
