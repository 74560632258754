import {
  GroupDropdownPreviewFragment,
  GroupType,
  IdpConnectionType,
  ResourceDropdownPreviewFragment,
  ResourceType,
  usePaginatedGroupDropdownQuery,
  usePaginatedResourceDropdownQuery,
  useSetupStateQuery,
} from "api/generated/graphql";
import {
  Checkbox,
  DataElement,
  DataElementList,
  Icon,
  Select,
} from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import { getAppIcon } from "views/apps/utils";
import ViewSkeleton from "views/loading/ViewSkeleton";

import { SetupContext } from "../SetupContext";
import * as styles from "./IdpStep.css";

const ImportSelectData = () => {
  const { data: setupData } = useSetupStateQuery();

  const connectionType = setupData?.setupState.state.idpConnectionType;
  const connectionId = setupData?.setupState.state.connectionId;

  if (!connectionId || !connectionType) {
    return null;
  }

  let content;
  switch (connectionType) {
    case IdpConnectionType.Okta:
      content = <Okta connectionId={connectionId} />;
      break;
    case IdpConnectionType.Google:
      content = <Google connectionId={connectionId} />;
      break;
    case IdpConnectionType.AzureAd:
      content = <Azure connectionId={connectionId} />;
      break;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>Import selected data</div>
      <p className={styles.subtitle}>
        Select data to import into Opal. Choose this option if you're testing
        Opal, or doing a targeted/slow roll out to your org. You'll be able to
        select and import more data in the future.
      </p>
      {content}
    </div>
  );
};

interface AppProps {
  connectionId: string;
}

const Okta = (props: AppProps) => {
  return (
    <div className={sprinkles({ flexGrow: 1, overflowY: "auto" })}>
      <div
        className={sprinkles({ fontWeight: "semibold", marginBottom: "lg" })}
      >
        Groups and roles
      </div>
      <div className={styles.searchContainer}>
        <Search
          connectionId={props.connectionId}
          connectionType={IdpConnectionType.Okta}
        />
      </div>
      <div
        className={sprinkles({
          fontWeight: "semibold",
          marginBottom: "lg",
          marginTop: "lg",
        })}
      >
        Apps
      </div>
      <div className={styles.callout}>
        Looking for cloud SaaS apps? You can add those later under Settings.
      </div>
      <OktaApps connectionId={props.connectionId} />
    </div>
  );
};

const Google = (props: AppProps) => {
  return (
    <div className={sprinkles({ flexGrow: 1, overflowY: "auto" })}>
      <div
        className={sprinkles({ fontWeight: "semibold", marginBottom: "lg" })}
      >
        Roles
      </div>
      <div className={styles.searchContainer}>
        <Search
          connectionId={props.connectionId}
          connectionType={IdpConnectionType.Google}
        />
      </div>
    </div>
  );
};

const Azure = (props: AppProps) => {
  return (
    <div className={sprinkles({ flexGrow: 1, overflowY: "auto" })}>
      <div
        className={sprinkles({ fontWeight: "semibold", marginBottom: "lg" })}
      >
        Groups
      </div>
      <div className={styles.searchContainer}>
        <Search
          connectionId={props.connectionId}
          connectionType={IdpConnectionType.AzureAd}
        />
      </div>
    </div>
  );
};

interface SearchProps {
  connectionId: string;
  connectionType: IdpConnectionType;
}

const PAGE_SIZE = 100;

type EntitySelectorPage = "home" | "groups" | "resources";

interface NavigationStackItem {
  page: EntitySelectorPage;
  pageTitle: string;
}

const Search = (props: SearchProps) => {
  const { itemsToImport = [], setImportItems } = useContext(SetupContext);
  const [navigationStack, setNavigationStack] = useState<NavigationStackItem[]>(
    [{ page: "home", pageTitle: "Home" }]
  );
  const [searchQuery, setSearchQuery] = useState("");

  const itemsToImportIds = itemsToImport.map((item) => item.id);

  const currentNavigationItem = navigationStack[navigationStack.length - 1];
  const page = currentNavigationItem.page;
  const pageTitle = currentNavigationItem.pageTitle;

  let placeholder = "Search for groups";
  let resourceType: ResourceType | undefined;
  let groupType: GroupType | undefined;
  let resourceIcon: IconName | undefined;
  let resourceLabel = "";
  const topLevelBrowseOptions: {
    label: string;
    value: EntitySelectorPage;
    icon: IconData;
  }[] = [];

  switch (props.connectionType) {
    case IdpConnectionType.Okta:
      placeholder = "Search Okta for groups or roles";
      topLevelBrowseOptions.push(
        {
          label: "Groups",
          value: "groups",
          icon: { type: "name", icon: "users" },
        },
        {
          label: "Roles",
          value: "resources",
          icon: { type: "name", icon: "role" },
        }
      );
      resourceType = ResourceType.OktaRole;
      groupType = GroupType.OktaGroup;
      resourceIcon = "role";
      resourceLabel = "role";
      break;
    case IdpConnectionType.Google:
      placeholder = "Search Google for roles";
      topLevelBrowseOptions.push({
        label: "Roles",
        value: "resources",
        icon: { type: "name", icon: "role" },
      });
      resourceType = ResourceType.GoogleWorkspaceRole;
      resourceIcon = "role";
      resourceLabel = "role";
      break;
    case IdpConnectionType.AzureAd:
      placeholder = "Search Azure for groups";
      topLevelBrowseOptions.push({
        label: "Groups",
        value: "groups",
        icon: { type: "name", icon: "users" },
      });
      groupType = GroupType.ActiveDirectoryGroup;
      break;
  }

  const {
    data: groupsData,
    error: groupsError,
    fetchMore: groupsFetchMore,
    loading: groupsLoading,
  } = usePaginatedGroupDropdownQuery({
    variables: {
      input: {
        searchQuery,
        connectionIds: [props.connectionId],
        unmanagedOnly: true,
        maxNumEntries: PAGE_SIZE,
      },
    },
    skip: page !== "groups",
  });
  if (groupsError) {
    logError(groupsError, "failed to list groups");
  }

  let groupsCursor: string | null | undefined;
  let groups: GroupDropdownPreviewFragment[] = [];
  switch (groupsData?.groups.__typename) {
    case "GroupsResult":
      groups = groupsData.groups.groups;
      groupsCursor = groupsData.groups.cursor;
      break;
    default:
      break;
  }

  const {
    data: resourcesData,
    error: resourcesError,
    fetchMore: resourcesFetchMore,
    loading: resourcesLoading,
  } = usePaginatedResourceDropdownQuery({
    variables: {
      input: {
        searchQuery,
        connectionIds: [props.connectionId],
        resourceTypes: resourceType ? [resourceType] : undefined,
        unmanagedOnly: true,
        maxNumEntries: PAGE_SIZE,
      },
    },
    skip: page !== "resources",
  });
  if (resourcesError) {
    logError(resourcesError, "failed to list resources");
  }

  let resourcesCursor: string | null | undefined;
  let resources: ResourceDropdownPreviewFragment[] = [];
  switch (resourcesData?.resources.__typename) {
    case "ResourcesResult":
      resources = resourcesData.resources.resources;
      resourcesCursor = resourcesData.resources.cursor;
      break;
    default:
      break;
  }

  const popNavigationStack = () => {
    if (navigationStack.length === 1) {
      return;
    }
    const newStack = navigationStack.slice();
    newStack.pop();
    setNavigationStack(newStack);
  };

  const pushNavigationStack = (newPage: NavigationStackItem) => {
    setNavigationStack([...navigationStack, newPage]);
  };

  const sharedProps = {
    placeholder,
    alwaysShowPlaceholder: true,
    disableBuiltInFiltering: true,
    onInputChange: setSearchQuery,
  };

  let search;
  if (page === "groups") {
    search = (
      <Select<GroupDropdownPreviewFragment>
        {...sharedProps}
        multiple
        placeholderIcon={{ type: "name", icon: "search" }}
        value={groups.filter((group) => itemsToImportIds.includes(group.id))}
        listboxHeader={{
          title: pageTitle,
          onGoBack: popNavigationStack,
        }}
        options={groups}
        onSelectValue={(option, reason) => {
          if (!option) {
            return;
          }
          if (reason === "select-option") {
            setImportItems([
              ...itemsToImport,
              {
                id: option.id,
                name: option.name,
                groupType,
              },
            ]);
          } else if (reason === "remove-option") {
            setImportItems(
              itemsToImport.filter((item) => item.id !== option.id)
            );
          }
        }}
        getOptionLabel={(option) => option.name}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.groupType,
        })}
        onScrollToBottom={async () => {
          if (groupsCursor) {
            await groupsFetchMore({
              variables: {
                input: {
                  searchQuery,
                  connectionIds: [props.connectionId],
                  unmanagedOnly: true,
                  cursor: groupsCursor,
                  maxNumEntries: PAGE_SIZE,
                },
              },
            });
          }
        }}
        loading={groupsLoading}
      />
    );
  } else if (page === "resources") {
    search = (
      <Select<ResourceDropdownPreviewFragment>
        {...sharedProps}
        multiple
        placeholderIcon={{ type: "name", icon: "search" }}
        value={resources.filter((resource) =>
          itemsToImportIds.includes(resource.id)
        )}
        listboxHeader={{
          title: pageTitle,
          onGoBack: popNavigationStack,
        }}
        options={resources}
        onSelectValue={(option, reason) => {
          if (!option) {
            return;
          }
          if (reason === "select-option") {
            setImportItems([
              ...itemsToImport,
              {
                id: option.id,
                name: option.name,
                resourceType,
              },
            ]);
          } else if (reason === "remove-option") {
            setImportItems(
              itemsToImport.filter((item) => item.id !== option.id)
            );
          }
        }}
        getOptionLabel={(option) => option.name}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.resourceType,
        })}
        onScrollToBottom={async () => {
          if (resourcesCursor) {
            await resourcesFetchMore({
              variables: {
                input: {
                  searchQuery,
                  connectionIds: [props.connectionId],
                  resourceTypes: resourceType ? [resourceType] : undefined,
                  unmanagedOnly: true,
                  cursor: resourcesCursor,
                  maxNumEntries: PAGE_SIZE,
                },
              },
            });
          }
        }}
        loading={resourcesLoading}
      />
    );
  } else {
    search = (
      <Select
        {...sharedProps}
        options={topLevelBrowseOptions}
        getOptionLabel={(option) => option.label}
        getIcon={(option) => option.icon}
        onChange={() => {}}
        optionHasDrilldown={() => true}
        placeholderIcon={{ type: "name", icon: "search" }}
        onDrilldown={(option) =>
          pushNavigationStack({
            pageTitle: option.label,
            page: option.value,
          })
        }
      />
    );
  }

  const selectedGroups = itemsToImport.filter((item) =>
    Boolean(item.groupType)
  );
  const selectedResources = itemsToImport.filter(
    (item) => item.resourceType === resourceType
  );

  return (
    <>
      <div className={styles.search}>{search}</div>
      <div
        className={sprinkles({
          marginTop: "md",
          display: "flex",
          gap: "lg",
        })}
      >
        {props.connectionType !== IdpConnectionType.Google && (
          <div className={styles.searchResultsSection}>
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "sm",
                marginLeft: "md",
              })}
            >
              <Icon name="users" size="sm" /> {selectedGroups.length}{" "}
              {pluralize("group", selectedGroups.length)}
            </div>
            <div className={sprinkles({ marginTop: "md" })}>
              <DataElementList>
                {selectedGroups.map((group) => (
                  <DataElement
                    color="turquoise"
                    label={group.name}
                    leftIcon={{
                      name: "users",
                    }}
                    size="sm"
                    onClick={() =>
                      setImportItems(
                        itemsToImport.filter((item) => item.id !== group.id)
                      )
                    }
                  />
                ))}
              </DataElementList>
            </div>
          </div>
        )}
        {resourceType && (
          <div className={styles.searchResultsSection}>
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "sm",
                marginLeft: "sm",
              })}
            >
              <Icon name={resourceIcon} size="sm" /> {selectedResources.length}{" "}
              {pluralize(resourceLabel, selectedResources.length)}
            </div>
            <div className={sprinkles({ marginTop: "md" })}>
              <DataElementList>
                {selectedResources.map((resource) => (
                  <DataElement
                    color="orange"
                    label={resource.name}
                    leftIcon={{
                      name: resourceIcon,
                    }}
                    size="sm"
                    onClick={() =>
                      setImportItems(
                        itemsToImport.filter((item) => item.id !== resource.id)
                      )
                    }
                  />
                ))}
              </DataElementList>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

interface OktaAppsProps {
  connectionId: string;
}

const OktaApps = (props: OktaAppsProps) => {
  const { itemsToImport = [], setImportItems } = useContext(SetupContext);
  const itemsToImportIds = itemsToImport.map((item) => item.id);

  const {
    data: resourcesData,
    error: resourcesError,
    loading,
  } = usePaginatedResourceDropdownQuery({
    variables: {
      input: {
        connectionIds: [props.connectionId],
        resourceTypes: [ResourceType.OktaApp],
        unmanagedOnly: true,
        maxNumEntries: 10000, // TODO: paginate
      },
    },
  });
  if (resourcesError) {
    logError(resourcesError, "failed to list resources");
  }

  let resources: ResourceDropdownPreviewFragment[] = [];
  switch (resourcesData?.resources.__typename) {
    case "ResourcesResult":
      resources = resourcesData.resources.resources;
      break;
    default:
      break;
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  return (
    <div className={styles.oktaAppsContainer}>
      {resources.map((resource) => {
        const selected = itemsToImportIds.includes(resource.id);
        const handleSelect = () => {
          if (selected) {
            setImportItems(
              itemsToImport.filter((item) => item.id !== resource.id)
            );
          } else {
            setImportItems([
              ...itemsToImport,
              {
                id: resource.id,
                name: resource.name,
                resourceType: ResourceType.OktaApp,
              },
            ]);
          }
        };
        return (
          <div
            className={styles.tile({
              selected,
            })}
            onClick={handleSelect}
          >
            <div className={styles.checkbox}>
              <Checkbox checked={selected} size="sm" onChange={handleSelect} />
            </div>
            <div className={styles.logoContainer}>
              <Icon
                data={getAppIcon({
                  __typename: "OktaResourceApp",
                  iconUrl: resource.iconUrl,
                })}
                size="lg"
              />
            </div>
            <div
              className={sprinkles({
                fontSize: "bodyLg",
                fontWeight: "medium",
              })}
            >
              {resource.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ImportSelectData;
