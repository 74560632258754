import { openInNewTab } from "api/common/common";
import {
  ConnectionType,
  EntityType,
  Maybe,
  ResourceDetailFragment,
  ResourceType,
  ServiceType,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useBulkUpdateMutation,
  useRecordResourceViewMutation,
  useResourceDetailColumnQuery,
  useSyncStatusQuery,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { FormMode, ResourceConfig } from "components/forms/common";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import {
  getResourceConfigChangedFields,
  makeConfigForResource,
  makeUpdateInputForResource,
  validateResourceConfig,
} from "components/forms/utils";
import SyncStatusModal from "components/label/SyncStatusModal";
import useSyncMenuItem, { getSyncLabel } from "components/sync/useSyncMenuItem";
import { useToast } from "components/toast/Toast";
import { Banner, Button, Divider, Link, Tabs } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionExport,
  AuthorizedActionManage,
} from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { isSessionableType } from "utils/directory/connections";
import {
  isSnowflakeResource,
  resourceTypeCanBeAccessed,
  serviceTypeHasCustomRoles,
} from "utils/directory/resources";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import { useTransitionTo } from "utils/router/hooks";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";
import ResourceDeleteModal from "views/resources/ResourceDeleteModal";
import { ResourceRenameModal } from "views/resources/ResourceRenameModal";
import ResourceCustomRolesRow from "views/resources/viewer/rows/ResourceCustomRolesRow";
import ResourceEventsRow from "views/resources/viewer/rows/ResourceEventsRow";
import ResourceGroupsRow from "views/resources/viewer/rows/ResourceGroupsRow";
import ResourceUsageRow from "views/resources/viewer/rows/ResourceUsageRow";
import ResourceUsersRow from "views/resources/viewer/rows/ResourceUsersRow";
import ResourceVaultSessionsRow from "views/resources/viewer/rows/ResourceVaultSessionsRow";
import OrgContext from "views/settings/OrgContext";

import AppsContentColumn from "./AppsContentColumn";
import {
  ACCESS_OPTION_URL_KEY,
  ACCOUNT_ID_URL_KEY,
  APP_ID_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
  OKTA_APP_ID_URL_KEY,
} from "./AppsContext";
import BulkImportColumnV2 from "./BulkImportColumnV2";
import { ResourceActionButtons } from "./ResourceActionButtons";
import RequestColumn from "./ResourceRequestColumn";
import { ResourceViewKey } from "./utils";

interface ResourceView {
  key: ResourceViewKey;
  title: string;
  content: React.ReactNode;
  icon: IconName;
  count?: number;
}

const ResourceDetailColumn = () => {
  let {
    resourceId: resourceIdFromUrl,
    accountId: accountIdFromUrl,
    resourceView: selectedResourceViewKey,
    appId: appIdFromUrl,
    resourceId: leafResourceId,
    oktaAppId,
  } = useParams<Record<string, string>>();
  const resourceId = oktaAppId || resourceIdFromUrl || accountIdFromUrl;

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showRenameResourceModal, setShowRenameResourceModal] = useState(false);
  const [showDeleteResourceModal, setShowDeleteResourceModal] = useState(false);
  const [showUnmanagedGroups, setShowUnmanagedGroups] = useState(false);
  const [mode, setMode] = useState<FormMode>("view");
  const [config, setConfig] = useState<Partial<ResourceConfig>>({});
  const [initialConfig, setInitialConfig] = useState<Partial<ResourceConfig>>();
  const [errors, setErrors] = useState<string[]>([]);

  const transitionTo = useTransitionTo({
    preserveQueries: [
      ACCESS_OPTION_URL_KEY,
      APP_ID_URL_KEY,
      ITEM_TYPE_URL_KEY,
      OKTA_APP_ID_URL_KEY,
      ACCOUNT_ID_URL_KEY,
    ],
  });
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const logEvent = useLogEvent();
  const history = useHistory();

  const { selectedUnmanagedItems, bulkMode, isSelectMode } = useContext(
    AppsContext
  );
  useReadUINotification(resourceId);

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const [recordResourceView] = useRecordResourceViewMutation();
  useMountEffect(() => {
    if (!authState.impersonatingUser) {
      try {
        recordResourceView({
          variables: {
            input: {
              resourceId: resourceId,
            },
          },
        });
      } catch (err) {
        logError(err, "failed to record resource view");
      }
    }
  });

  // Default open Overview for leaf resources (no child resources)
  if (
    !selectedResourceViewKey &&
    leafResourceId &&
    leafResourceId === resourceId
  ) {
    selectedResourceViewKey = "overview";
  }

  const {
    data: resourceData,
    error: resourceError,
    loading: resourceLoading,
    refetch: refetchResource,
  } = useResourceDetailColumnQuery({
    variables: {
      id: resourceId,
    },
  });

  let resource: Maybe<ResourceDetailFragment> = null;
  let resourceNotFound = true;
  if (resourceData) {
    switch (resourceData.resource.__typename) {
      case "ResourceResult":
        resource = resourceData.resource.resource;
        resourceNotFound = false;
        break;
      case "ResourceNotFoundError":
        break;
      default:
        logError(new Error(`failed to list resources`));
    }
  } else if (resourceError) {
    logError(resourceError, `failed to list resources`);
  }

  const syncMenuItem = useSyncMenuItem({
    syncType:
      resource?.resourceType === ResourceType.Custom
        ? SyncType.PullPropagationTickets
        : SyncType.PullConnectionsSingleResource,
    resource: resource ?? undefined,
    queriesToRefetch: [
      "ResourceDetailColumn",
      "ResourceAccessLevels",
      "ResourceCustomAccessLevels",
      "ResourceTags",
    ],
    loadingEntity: resourceLoading || resourceNotFound,
  });

  // Redirect any legacy URLs of the scheme resources/:parentResourceId to apps
  // Redirect any legacy okta resource IDs to apps
  useEffect(() => {
    if (resource) {
      const resourceType = resource.resourceType;
      const searchParams = new URLSearchParams(window.location.search);
      if (resourceType == ResourceType.AwsAccount && !accountIdFromUrl) {
        history.replace({
          pathname: `/apps/${resource.connectionId}/account/${resource.id}`,
          search: searchParams.toString(),
        });
      }
      if (resourceType === ResourceType.OktaApp && !oktaAppId) {
        history.replace({
          pathname: `/apps/okta/${resource.id}/overview`,
          search: searchParams.toString(),
        });
      }
    }
  }, [transitionTo, resource, accountIdFromUrl, oktaAppId, history]);

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsSingleResource,
        resourceId,
      },
    },
    skip: resourceNotFound,
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "ResourceNotFoundError":
        // we should not get this error if the resource doesn't exist as we're
        // skipping this query if resourceNotFound is true
        logError(syncData.syncStatus.message);
        break;
    }
  }

  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully updated, now syncing...",
    queriesToRefetch: ["ResourceDetailColumn"],
  });

  const [bulkUpdate, { loading: updateLoading }] = useBulkUpdateMutation({
    refetchQueries: ["ResourceDetailColumn"],
  });

  useEffect(() => {
    if (!resource) return;
    const resourceConfig = makeConfigForResource(
      resource,
      resource.configTemplate ?? undefined
    );

    // Something in ResourcesConfigFormV2 is causing this parent component to refetch the resource
    // which causes this effect to get rerun, resetting configV2 to the initial config.
    // TODO: figure out why this is happening and fix it, but for now this is a workaround.
    // It's possible it might not be an issue once we add a resolver for requestConfigs.
    if (
      Object.keys(config).length === 0 ||
      config.entityId !== resourceId ||
      config.configurationTemplate !== resource.configTemplate
    ) {
      setConfig(resourceConfig);
    }
    setInitialConfig(resourceConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  if (bulkMode === "request") {
    return null;
  }

  if (resourceLoading) {
    return (
      <AppsContentColumn>
        <ColumnContentSkeleton />
      </AppsContentColumn>
    );
  }

  if (resourceNotFound) {
    return (
      <Column isContent>
        <NotFoundPage entity="Resource" />
      </Column>
    );
  }

  if (resourceError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={resourceError} />
      </Column>
    );
  }

  if (selectedUnmanagedItems.length === 1) {
    return <BulkImportColumnV2 />;
  }

  if (!resource) {
    return null;
  }

  const canBeAccessed = resourceTypeCanBeAccessed(resource.resourceType);
  const resourceAdminTabsVisible =
    resource.authorizedActions?.includes(AuthorizedActionManage) ||
    resource.authorizedActions?.includes(AuthorizedActionExport);
  const connectionType = resource.connection?.connectionType;
  const canManage = resource.authorizedActions?.includes(
    AuthorizedActionManage
  );

  const isUsersTabVisible =
    resourceAdminTabsVisible &&
    (canBeAccessed || isSnowflakeResource(resource.resourceType));
  const isGroupsTabVisible =
    canBeAccessed || isSnowflakeResource(resource.resourceType);
  const isEventsTabVisible = resourceAdminTabsVisible;
  const isUsageTabVisible =
    resource.serviceType === ServiceType.Ssh &&
    (connectionType === ConnectionType.Aws ||
      connectionType === ConnectionType.AwsSso) &&
    resourceAdminTabsVisible;

  const isRoleTabVisible =
    serviceTypeHasCustomRoles(
      resource.serviceType,
      connectionType,
      resource.resourceType
    ) && resourceAdminTabsVisible;

  const isSessionTabVisible =
    connectionType &&
    isSessionableType(connectionType, resource.remoteId) &&
    resourceAdminTabsVisible &&
    canBeAccessed;

  const isViewingAsNonAdmin =
    mode === "view" &&
    !resource.authorizedActions?.includes(AuthorizedActionManage) &&
    !resource.authorizedActions?.includes(AuthorizedActionExport);

  const resourceViews: ResourceView[] = [
    {
      key: "overview",
      title: "Overview",
      content: (
        <>
          {resource.configTemplate ? (
            <div className={sprinkles({ padding: "md" })}>
              <Banner
                type={mode === "edit" ? "warning" : "info"}
                message={
                  <>
                    This resource is configured via the{" "}
                    <span className={sprinkles({ fontWeight: "semibold" })}>
                      {authState.user?.isAdmin ? (
                        <Link
                          url={getResourceUrlNew({
                            entityId: resource.configTemplate.id,
                            entityType: EntityType.ConfigurationTemplate,
                          })}
                        >
                          {resource.configTemplate.name}
                        </Link>
                      ) : (
                        resource.configTemplate.name
                      )}
                    </span>{" "}
                    template.
                  </>
                }
              ></Banner>
            </div>
          ) : null}
          <ResourcesConfigForm
            mode={mode}
            config={config}
            onChange={setConfig}
            isViewingAsNonAdmin={isViewingAsNonAdmin}
          />
        </>
      ),
      icon: "list",
    },
    {
      key: "containing-groups",
      title: "Containing Groups",
      content: isGroupsTabVisible ? (
        <ResourceGroupsRow
          resource={resource}
          showUnmanagedGroups={showUnmanagedGroups}
          setShowUnmanagedGroups={setShowUnmanagedGroups}
        />
      ) : undefined,
      icon: "users",
      count: showUnmanagedGroups
        ? resource.containingGroups.length
        : resource.containingGroups.filter((group) => group.group?.isManaged)
            .length,
    },
    {
      key: "users",
      title: "Users",
      content: isUsersTabVisible ? (
        <ResourceUsersRow resource={resource} />
      ) : undefined,
      icon: "user",
      // Dedup users by ID, since users may have multiple roles
      count: new Set(resource.resourceUsers.map((u) => u.userId)).size,
    },
    {
      key: "events",
      title: "Events",
      content: isEventsTabVisible ? (
        <ResourceEventsRow resource={resource} />
      ) : undefined,
      icon: "events",
    },
    {
      key: "usage",
      title: "Usage",
      content: isUsageTabVisible ? (
        <ResourceUsageRow resource={resource} />
      ) : undefined,
      icon: "monitor",
    },
    {
      key: "roles",
      title: "Roles",
      content: isRoleTabVisible ? (
        <ResourceCustomRolesRow resource={resource} />
      ) : undefined,
      icon: "key",
    },
    {
      key: "sessions",
      title: "Sessions",
      content: isSessionTabVisible ? (
        <ResourceVaultSessionsRow resource={resource} />
      ) : undefined,
      icon: "terminal",
    },
  ];

  const handleNavigateView = (resourceViewKey: ResourceViewKey) => {
    let pathname = `/resources/${resourceId}/${resourceViewKey}`;
    if (resource?.resourceType === ResourceType.AwsAccount) {
      pathname = `/apps/${appIdFromUrl}/account/${resourceId}/${resourceViewKey}`;
    } else if (oktaAppId) {
      pathname = `/apps/okta/${oktaAppId}/${resourceViewKey}`;
    }

    const searchParams = new URLSearchParams();
    if (resourceViewKey === "request" && oktaAppId) {
      searchParams.set(OKTA_APP_ID_URL_KEY, oktaAppId);
    }

    transitionTo({
      pathname,
      search: searchParams.toString(),
    });
  };

  const handleRequestExport = () => {
    if (!resource) return;
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "resource",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/resources/users?resourceID=" + resource.id,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!resource) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + resource.name.replace(" ", "_") + "_Users.csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded users for resource`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const handleRequestExportDebugInfo = () => {
    if (!resource) return;
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/resources/${resource.id}/debug`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!resource) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + resource.name.replace(" ", "_") + "_Debug.json"
        );
        link.click();
        displaySuccessToast(`Success: downloaded resource debug info`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const getMenuOptions = () => {
    if (!resource) return { hasSyncErrors: false, menuOptions: [] };

    let sublabel: string;
    if (syncError) {
      sublabel = "Unable to get status";
    } else if (syncLoading) {
      sublabel = "Loading sync status";
    } else {
      sublabel = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
    }

    const hasSyncErrors = authState.user?.isAdmin && syncErrors.length > 0;
    const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
    if ((authState.user?.isAdmin || canManage) && syncMenuItem) {
      menuOptions.push({
        label: hasSyncErrors ? "View sync errors" : "View sync details",
        sublabel: sublabel,
        onClick: () => {
          logEvent({
            name: "apps_view_sync_details",
            properties: {
              syncType: SyncType.PullConnectionsSingleResource,
            },
          });
          setShowSyncModal(true);
        },
        icon: hasSyncErrors
          ? { type: "name", icon: "alert-circle" }
          : { type: "name", icon: "info" },
        type: hasSyncErrors ? "warning" : undefined,
      });
      menuOptions.push(syncMenuItem);
    }

    if (
      menuOptions.length > 0 &&
      ((resource.authorizedActions?.includes(AuthorizedActionExport) &&
        resource.resourceUsers.length > 0) ||
        resource.authorizedActions?.includes(AuthorizedActionManage))
    ) {
      menuOptions.push({ type: "divider" });
    }

    if (resource.authorizedActions?.includes(AuthorizedActionExport)) {
      menuOptions.push(
        {
          label: "Export resource users",
          icon: { type: "name", icon: "users-right" },
          onClick: handleRequestExport,
        },
        {
          label: "Export debug info",
          icon: { type: "name", icon: "tool" },
          onClick: handleRequestExportDebugInfo,
        }
      );
      if (resource.authorizedActions?.includes(AuthorizedActionManage)) {
        menuOptions.push({ type: "divider" });
      }
    }

    if (resource.authorizedActions?.includes(AuthorizedActionManage)) {
      menuOptions.push({
        label: "Rename",
        onClick: () => {
          setShowRenameResourceModal(true);
        },
        icon: { type: "name", icon: "edit-3" },
      });
      if (resource.connection?.connectionType != ConnectionType.Opal) {
        menuOptions.push({
          label: "Remove from Opal",
          onClick: () => {
            setShowDeleteResourceModal(true);
          },
          type: "danger",
          icon: { type: "name", icon: "trash" },
        });
      }
    }

    return { hasSyncErrors, menuOptions };
  };

  const handleSave = async () => {
    if (!resource || !initialConfig) return;

    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    logEvent({
      name: "apps_item_edit_click",
      properties: {
        itemType: resource.resourceType,
      },
    });

    try {
      setErrors([]);
      const changedConfig = getResourceConfigChangedFields(
        initialConfig,
        config
      );
      const inputFields = makeUpdateInputForResource(changedConfig, resource);
      if (
        initialConfig.childrenDefaultConfigTemplate?.id &&
        !config.childrenDefaultConfigTemplate?.id
      ) {
        inputFields.childrenDefaultConfigTemplateId = {};
      }
      if (
        initialConfig.configurationTemplate?.id &&
        !config.configurationTemplate?.id
      ) {
        inputFields.configurationId = {};
      }

      const input = inputFields;
      const { data } = await bulkUpdate({
        variables: {
          input: {
            resourceIds: [resourceId],
            ...input,
          },
        },
      });

      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          setMode("view");
          setErrors([]);
          if (data.bulkUpdateItems.syncTask?.id) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          } else {
            displaySuccessToast(`Successfully updated resource`);
          }
          break;
        case "TagNotFoundError":
          setErrors(["Failed to get tag data"]);
          break;
        case "InvalidUpdateResourceVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "ResourceMaxDurationTooLargeError":
          setErrors([
            "Resource max access duration cannot exceed org-wide max duration",
          ]);
          break;
        case "CannotUpdateConfigurationTemplateError":
          setErrors([
            "This item is linked to a configuration template. To change settings controlled by templates, you must first unlink the template.",
          ]);
          break;
        default:
          logError("Failed to update resource");
          setErrors(["Failed to update resource."]);
      }
    } catch (err) {
      logError(err, "Failed to update resource");
      setErrors(["Failed to update resource."]);
    }
  };

  const getDisplayedResourceView = () => {
    if (!selectedResourceViewKey || !resource) return null;

    if (leafResourceId && leafResourceId !== resource.id) return null;

    if (selectedResourceViewKey === "request") {
      return (
        <RequestColumn resource={resource} onNavigate={handleNavigateView} />
      );
    }

    const viewKey = selectedResourceViewKey;
    const resourceViewToDisplay = resourceViews.find(
      (resourceView) => resourceView.key === viewKey
    );

    if (!resourceViewToDisplay) return null;

    const hasChanges = !_.isEqual(config, initialConfig);

    let headerButtons;
    if (viewKey === "overview") {
      headerButtons =
        mode === "view" ? (
          <Button
            label="Edit"
            leftIconName="edit"
            borderless
            onClick={() => setMode("edit")}
            size="md"
            disabled={isSelectMode}
            disabledTooltip="Exit bulk select mode to edit"
          />
        ) : (
          <div className={sprinkles({ display: "flex", gap: "sm" })}>
            <Button
              label="Cancel"
              onClick={() => {
                setMode("view");
                setErrors([]);
                if (initialConfig) {
                  setConfig(initialConfig);
                }
              }}
              disabled={updateLoading}
              borderless
              size="md"
            />
            <Button
              label={updateLoading ? "Saving..." : "Save"}
              leftIconName="check"
              type="primary"
              onClick={handleSave}
              disabled={updateLoading || !hasChanges}
              size="md"
            />
          </div>
        );
    }

    const { hasSyncErrors, menuOptions } = getMenuOptions();

    return (
      <AppsContentColumn>
        <ColumnHeader
          title={resource.name}
          icon={{ entityType: resource.resourceType, type: "entity" }}
          menuError={hasSyncErrors}
          menuOptions={menuOptions}
          onClickInsights={() => {
            if (!resource) return;
            const hash = makeURLForEntityViz(resource.id, EntityType.Resource);
            openInNewTab("/insights" + hash);
          }}
          rightActions={
            <ResourceActionButtons
              resource={resource}
              onNavigate={handleNavigateView}
              mode={mode}
              editButtons={
                resource.authorizedActions?.includes(AuthorizedActionManage)
                  ? headerButtons
                  : undefined
              }
              refetchResource={refetchResource}
            />
          }
        />
        <Divider margin="md" />

        {mode === "view" ? (
          <div
            className={sprinkles({ display: "flex", justifyContent: "center" })}
          >
            <Tabs
              tabInfos={resourceViews
                .filter((view) => view.content)
                .map((view) => ({
                  title: view.title,
                  onClick: () => {
                    if (!resource) return;
                    handleNavigateView(view.key);
                    logEvent({
                      name: "apps_item_tab_click",
                      properties: {
                        itemType: resource.resourceType,
                        tab: view.key,
                      },
                    });
                  },
                  isSelected:
                    viewKey === view.key &&
                    (leafResourceId ? leafResourceId === resourceId : true),
                  badgeCount: view.count,
                  icon: view.icon,
                }))}
              round
            />
          </div>
        ) : null}

        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}

        <ColumnContent>{resourceViewToDisplay.content}</ColumnContent>
      </AppsContentColumn>
    );
  };

  return (
    <>
      {showSyncModal ? (
        <SyncStatusModal
          syncType={SyncType.PullConnectionsSingleResource}
          entity={resource}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      ) : null}
      {getDisplayedResourceView()}

      {showRenameResourceModal && (
        <ResourceRenameModal
          resource={resource}
          showModal={showRenameResourceModal}
          setShowModal={setShowRenameResourceModal}
        />
      )}
      {showDeleteResourceModal && (
        <ResourceDeleteModal
          resource={resource}
          showModal={showDeleteResourceModal}
          setShowModal={setShowDeleteResourceModal}
        />
      )}
    </>
  );
};

export default ResourceDetailColumn;
