import {
  BundleItemsSortByField,
  EntityType,
  GroupAccessLevel,
  ResourceAccessLevel,
  ResourceType,
} from "api/generated/graphql";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { IconItem } from "components/ui/icon_group/IconGroup";
import { IconData } from "components/ui/utils";
import _ from "lodash";
import moment from "moment";
import { useHistory, useLocation } from "react-router";
import { UserExperience, useUserHasUserExperience } from "utils/auth/auth";
import { useReplaceTo } from "utils/router/hooks";
import {
  AccessRequestEntity,
  AccessRequestState,
  Role,
  useAccessRequestTransition,
} from "views/access_request/AccessRequestContext";

import { AppItemRow, BundleItemRow, ItemAccessInfo } from "./types";

export function getAppIcon(row: AppItemRow | BundleItemRow): IconData {
  return {
    type: "entity",
    entityType: "type" in row ? row.type : row[BundleItemsSortByField.Type],
    onlyBrandIcon: true,
  };
}

type Item = Pick<AppItemRow, "entityId" | "entityType" | "roles">;
export function formatRequestDataForItems(
  items: Item[] | Item,
  oktaAppId?: string
): Partial<AccessRequestState> {
  if (!Array.isArray(items)) {
    items = [items];
  }
  return {
    selectedEntities: items.map((item) => ({
      id: oktaAppId ? oktaAppId : item.entityId,
      type: oktaAppId
        ? ResourceType.OktaApp
        : (item.entityType as EntityType.Group | EntityType.Resource),
    })),
    selectedRolesByEntityId: items.reduce((acc, item) => {
      const entityId = item.entityId;
      if (!entityId || !item.roles) {
        return acc;
      }
      acc[entityId] = _.uniqWith(
        [...(acc[entityId] ?? []), ...item.roles],
        (a, b) => a.accessLevelRemoteId === b.accessLevelRemoteId
      );
      return acc;
    }, {} as Partial<Record<string, Role[]>>),
    selectedEntityByEntityId: oktaAppId
      ? items.reduce((acc, item) => {
          const entityId = item.entityId;
          if (!entityId) {
            return acc;
          }
          acc[oktaAppId] = {
            type: EntityType.Group,
            id: entityId,
          };
          return acc;
        }, {} as Partial<Record<string, AccessRequestEntity>>)
      : {},
  };
}

type CurrentUserGroupAccess = {
  groupUser?: {
    accessLevel?: GroupAccessLevel | null;
    access?: {
      latestExpiringAccessPoint: {
        expiration?: string | null;
      };
    } | null;
  } | null;
};

function getSoonestGroupUserExpiration(
  currentUserAccess: CurrentUserGroupAccess
): moment.Moment | null {
  return currentUserAccess.groupUser?.access?.latestExpiringAccessPoint
    .expiration
    ? moment(
        currentUserAccess.groupUser.access.latestExpiringAccessPoint.expiration
      )
    : null;
}

export function getGroupUserAccessInfo(
  currentUserAccess: CurrentUserGroupAccess | undefined
): ItemAccessInfo {
  if (!currentUserAccess) {
    return {
      hasAccess: false,
      soonestExpiration: null,
      roles: [],
      expirationByRoleId: {},
    };
  }

  // Groups can only one role at a time
  const role = currentUserAccess.groupUser?.accessLevel;
  const expiration = getSoonestGroupUserExpiration(currentUserAccess);

  return {
    hasAccess: !!currentUserAccess.groupUser,
    soonestExpiration: getSoonestGroupUserExpiration(currentUserAccess),
    roles: role ? [role] : [],
    expirationByRoleId: role ? { [role.accessLevelRemoteId]: expiration } : {},
  };
}

type CurrentUserResourceAccess = {
  resourceUsers: {
    accessLevel: ResourceAccessLevel;
    access?: {
      latestExpiringAccessPoint: {
        expiration?: string | null;
      };
    } | null;
  }[];
};

export function getResourceUserAccessInfo(
  currentUserAccess: CurrentUserResourceAccess | undefined
): ItemAccessInfo {
  if (!currentUserAccess) {
    return {
      hasAccess: false,
      soonestExpiration: null,
      roles: [],
      expirationByRoleId: {},
    };
  }

  const roles: ResourceAccessLevel[] = [];
  const expirationByRoleId: Record<string, moment.Moment> = {};
  let soonestExpiration: moment.Moment | null = null;
  // For every access point (resource users) we want to find the soonest
  // expiration, and map any role with their expiration
  for (const ru of currentUserAccess.resourceUsers) {
    roles.push(ru.accessLevel);
    if (ru.access?.latestExpiringAccessPoint.expiration) {
      const expiration = moment(
        ru.access?.latestExpiringAccessPoint.expiration
      );
      expirationByRoleId[ru.accessLevel.accessLevelRemoteId] = expiration;

      if (!soonestExpiration || expiration.isBefore(soonestExpiration)) {
        soonestExpiration = expiration;
      }
    }
  }

  return {
    hasAccess: roles.length > 0,
    soonestExpiration,
    roles,
    expirationByRoleId,
  };
}

type OwnerUserInfoMicro = {
  user?:
    | {
        id: string;
        fullName: string;
        avatarUrl?: string;
      }
    | undefined
    | null;
};

export function getOwnerUserIconList(
  ownerUsers?: OwnerUserInfoMicro[],
  includeLink: boolean = false
): IconItem[] {
  if (!ownerUsers) {
    return [];
  }
  const userIconList = ownerUsers
    .map((owner) => {
      if (owner.user) {
        return {
          key: owner.user.id,
          name: owner.user.fullName,
          linkTo: includeLink ? `/users/${owner.user.id}` : undefined,
          icon: {
            type: "src",
            icon: owner.user.avatarUrl || defaultAvatarURL,
          },
        };
      }
      // return null when owner.user is not truthy
      return null;
    })
    // filter out null values
    .filter((item) => item !== null) as IconItem[];

  return userIconList;
}

export const useHandleRedirectToEndUserExp = () => {
  const replaceTo = useReplaceTo({ preserveAllQueries: true });
  const showEndUserExp =
    useUserHasUserExperience() === UserExperience.EndUserUX;

  const redirectToEndUserExp = (
    appId: string,
    entityId?: string,
    entityType?: EntityType.Resource | EntityType.Group
  ) => {
    if (!showEndUserExp) {
      return;
    }
    switch (entityType) {
      case EntityType.Resource:
        replaceTo({
          pathname: `/apps/${appId}`,
          search: `?resourceId=${entityId}`,
        });
        break;
      case EntityType.Group:
        replaceTo({
          pathname: `/apps/${appId}`,
          search: `?groupId=${entityId}`,
        });
        break;
      default:
        replaceTo({
          pathname: `/apps/${appId}`,
        });
        break;
    }
  };

  return redirectToEndUserExp;
};

export const useHandleRedirectFromAdminCatalog = () => {
  const transitionToAccessRequest = useAccessRequestTransition();
  const location = useLocation();
  const history = useHistory();

  const redirectToRequestPage = (
    resourceId: string | null,
    groupId: string | null,
    appId: string | undefined,
    isOktaApp: boolean
  ) => {
    if (!location.search || !appId) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    const request = searchParams.get("request");

    if (request) {
      // Remove request query param so that if the user navigates back to the
      // catalog, they don't get redirected to the request page again
      searchParams.delete("request");
      history.replace({
        search: searchParams.toString(),
      });

      let item: Item | undefined;
      if (resourceId) {
        item = {
          entityId: resourceId,
          entityType: EntityType.Resource,
        };
      } else if (groupId) {
        item = {
          entityId: groupId,
          entityType: EntityType.Group,
        };
      }

      if (!item) {
        return transitionToAccessRequest({
          appId,
        });
      }

      return transitionToAccessRequest({
        ...formatRequestDataForItems(item, isOktaApp ? appId : undefined),
        appId,
      });
    }
  };

  return redirectToRequestPage;
};
