import {
  AppDropdownPreviewFragment,
  GroupType,
  ResourceType,
  TagPreviewLargeFragment,
} from "api/generated/graphql";
import EntityTypeDropdown from "components/dropdown/EntityTypeDropdown";
import PaginatedAppDropdown from "components/dropdown/PaginatedAppDropdown";
import TagDropdown from "components/dropdown/TagDropdown";
import { ButtonV3, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { colorVars } from "css/vars.css";
import React, { Dispatch, SetStateAction } from "react";
import {
  outsideAccessIcon,
  outsideAccessRiskFactorText,
  permanentAccessIcon,
  perpetualAccessRiskFactorText,
  unusedAccessIcon,
  unusedAccessRiskFactorText,
} from "views/recommendations/ThreatPill";

export interface RecommendationsFilter {
  appFilter?: AppDropdownPreviewFragment;
  riskScoreFilter?: RiskScoreRange;
  tagFilter?: TagPreviewLargeFragment;
  riskFactor?: string;
  resourceTypeFilter?: ResourceType;
  groupTypeFilter?: GroupType;
}

export interface RiskScoreRange {
  minScore: number;
  maxScore: number;
}

export const AllFilterableRiskFactors: string[] = [
  perpetualAccessRiskFactorText,
  unusedAccessRiskFactorText,
  outsideAccessRiskFactorText,
];

export const allRiskScoreRanges: RiskScoreRange[] = [
  {
    minScore: 0,
    maxScore: 25,
  },
  {
    minScore: 26,
    maxScore: 50,
  },
  {
    minScore: 51,
    maxScore: 75,
  },
  {
    minScore: 76,
    maxScore: 100,
  },
];

export const isEmptyFilter = (filter: RecommendationsFilter): boolean => {
  return (
    !filter.riskScoreFilter &&
    !filter.appFilter &&
    !filter.tagFilter &&
    !filter.riskFactor &&
    !(filter.resourceTypeFilter || filter.groupTypeFilter)
  );
};
interface RecommendationFilterSectionProps {
  filter: RecommendationsFilter;
  setFilter: Dispatch<SetStateAction<RecommendationsFilter>>;
}

const RecommendationFilterSection = ({
  filter,
  setFilter,
}: RecommendationFilterSectionProps) => {
  const handleSetAppFilter = (
    appFilter: AppDropdownPreviewFragment | undefined
  ): void => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      appFilter: appFilter,
    }));
  };

  const handleSetRiskScoreFilter = (
    riskScoreRange: RiskScoreRange | undefined
  ): void => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      riskScoreFilter: riskScoreRange,
    }));
  };

  const handleSetTagFilter = (
    tag: TagPreviewLargeFragment | undefined
  ): void => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      tagFilter: tag,
    }));
  };

  const handleSetRiskFactorFilter = (riskFactor: string | undefined): void => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      riskFactor: riskFactor,
    }));
  };

  const handleSetEntityTypeFilter = (
    resourceType?: ResourceType | undefined,
    groupType?: GroupType | undefined
  ): void => {
    if (resourceType) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        resourceTypeFilter: resourceType,
      }));
    } else if (groupType) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        groupTypeFilter: groupType,
      }));
    }
  };

  const getRiskScoreOptionLabel = (range: RiskScoreRange): string => {
    let labelPrefix: string;
    if (range.maxScore <= 25) {
      labelPrefix = "Low";
    } else if (range.maxScore <= 50) {
      labelPrefix = "Medium";
    } else if (range.maxScore <= 75) {
      labelPrefix = "High";
    } else {
      labelPrefix = "Critical";
    }

    return `${labelPrefix} (${range.minScore}-${range.maxScore})`;
  };

  const getRiskScoreLabelColor = (range: RiskScoreRange): colorVars => {
    if (range.maxScore <= 25) {
      return "green700V3";
    } else if (range.maxScore <= 50) {
      return "yellow700V3";
    } else if (range.maxScore <= 75) {
      return "orange500";
    } else {
      return "red700V3";
    }
  };

  return (
    <div>
      <div
        className={sprinkles({
          fontFamily: "body",
          fontSize: "textSm",
          marginBottom: "sm",
        })}
      ></div>
      <div
        className={sprinkles({
          display: "flex",
          gap: "sm",
          alignItems: "center",
        })}
      >
        <PaginatedAppDropdown
          value={filter?.appFilter}
          onChange={handleSetAppFilter}
          placeholder="Filter by app"
          placeHolderIcon={{ type: "name", icon: "dots-grid" }}
          selectOnly
        />
        <Select
          value={filter?.riskScoreFilter}
          options={allRiskScoreRanges}
          getOptionLabel={getRiskScoreOptionLabel}
          placeholder="All risk scores"
          placeholderIcon={{
            type: "name",
            icon: "odometer",
          }}
          placeHolderIconColor={
            filter?.riskScoreFilter
              ? getRiskScoreLabelColor(filter.riskScoreFilter)
              : undefined
          }
          alwaysShowPlaceholder={true}
          onChange={handleSetRiskScoreFilter}
          size="md"
        />
        <TagDropdown
          value={filter?.tagFilter}
          onChange={handleSetTagFilter}
          placeholder="All Tags"
        />

        <EntityTypeDropdown
          value={filter?.resourceTypeFilter || filter?.groupTypeFilter}
          onChange={handleSetEntityTypeFilter}
          placeholder="Entity Type"
        />
        <Select
          value={filter?.riskFactor}
          options={AllFilterableRiskFactors}
          getOptionLabel={(riskFactor) => riskFactor}
          placeholder="All Risk Factors"
          alwaysShowPlaceholder={true}
          onChange={handleSetRiskFactorFilter}
          size="md"
          getIcon={(riskFactor) => {
            if (riskFactor === perpetualAccessRiskFactorText) {
              return permanentAccessIcon;
            } else if (riskFactor === unusedAccessRiskFactorText) {
              return unusedAccessIcon;
            } else if (riskFactor == outsideAccessRiskFactorText) {
              return outsideAccessIcon;
            } else {
              throw new Error(`Unexpected risk factor: ${riskFactor}`);
            }
          }}
        />
        {!isEmptyFilter(filter) && (
          <ButtonV3
            size="sm"
            outline
            type="mainBorderless"
            label="Clear all filters"
            onClick={() => setFilter({})}
          />
        )}
      </div>
    </div>
  );
};
export default RecommendationFilterSection;
