import {
  IdpConnectionFragment,
  Maybe,
  OrganizationSettingsFragment,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
} from "api/generated/graphql";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  useReducer,
} from "react";

export enum OrgContextActionType {
  OrgSettings,
  OrgThirdPartyIntegrations,
  OrgIdpConnection,
}

export type OrgContextState = {
  orgSettings: Maybe<OrganizationSettingsFragment>;
  orgThirdPartyIntegrations: Maybe<ThirdPartyIntegrationFragment[]>;
  orgIdpConnection: Maybe<IdpConnectionFragment>;
  isHrEnabled: boolean;
  isIdpEnabled: boolean;
  isOktaCustomAttributesEnabled: boolean;
};

export type OrgContextEvent = {
  type: OrgContextActionType;
  payload: {
    orgSettings?: Maybe<OrganizationSettingsFragment> | undefined | null;
    orgThirdPartyIntegrations?:
      | Maybe<ThirdPartyIntegrationFragment[]>
      | undefined
      | null;
    orgIdpConnection?: Maybe<IdpConnectionFragment> | undefined | null;
    isIdpEnabled?: boolean;
    isHrEnabled?: boolean;
  };
};

const initialOrgContext: OrgContextState = {
  orgSettings: null,
  orgThirdPartyIntegrations: null,
  orgIdpConnection: null,
  isHrEnabled: false,
  isIdpEnabled: false,
  isOktaCustomAttributesEnabled: false,
};

const OrgContext = createContext<{
  orgState: OrgContextState;
  orgDispatch: Dispatch<OrgContextEvent>;
}>({
  orgState: initialOrgContext,
  orgDispatch: () => null,
});

const reducer = (
  state: OrgContextState,
  action: OrgContextEvent
): OrgContextState => {
  switch (action.type) {
    case OrgContextActionType.OrgSettings: {
      const orgSettings = action.payload.orgSettings!;
      return {
        ...state,
        orgSettings: orgSettings,
      };
    }
    case OrgContextActionType.OrgThirdPartyIntegrations: {
      const orgThirdPartyIntegrations = action.payload
        .orgThirdPartyIntegrations!;
      const isHrEnabled =
        orgThirdPartyIntegrations.some(
          (integration) =>
            integration.thirdPartyProvider === ThirdPartyProvider.Merge
        ) || false;

      return {
        ...state,
        orgThirdPartyIntegrations: orgThirdPartyIntegrations,
        isHrEnabled: isHrEnabled,
      };
    }
    case OrgContextActionType.OrgIdpConnection: {
      const orgIdpConnection = action.payload.orgIdpConnection!;
      var isIdpEnabled = false;
      if (orgIdpConnection) {
        isIdpEnabled = true;
      }
      return {
        ...state,
        orgIdpConnection: orgIdpConnection,
        isIdpEnabled: isIdpEnabled,
      };
    }
    default:
      return state;
  }
};

export const OrgContextProvider: FunctionComponent = (
  props: PropsWithChildren<{}>
) => {
  const [orgState, orgDispatch] = useReducer(reducer, initialOrgContext);

  return (
    <OrgContext.Provider value={{ orgState, orgDispatch }}>
      {props.children}
    </OrgContext.Provider>
  );
};

export default OrgContext;
