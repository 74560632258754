import { Column, ColumnContainer } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeaderV3";
import { Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { usePageTitle } from "utils/hooks";
import RecommendationFilterSection, {
  allRiskScoreRanges,
  isEmptyFilter,
  RecommendationsFilter,
  RiskScoreRange,
} from "views/recommendations/RecommendationFilters";
import RiskyApps from "views/recommendations/RiskyApps";
import * as styles from "views/recommendations/ThreatCenter.css";
import ThreatResolutionNew from "views/recommendations/ThreatResolutionNew";

import ThreatCenter from "./ThreatCenter";

const Recommendations = () => {
  usePageTitle("Homepage");

  const [
    recommendationsFilter,
    setRecommendationsFilter,
  ] = useState<RecommendationsFilter>({});

  const handleSetRiskScoreFilter = (
    riskScoreRange: RiskScoreRange | undefined
  ): void => {
    if (!riskScoreRange) {
      setRecommendationsFilter((prevFilter) => ({
        ...prevFilter,
        riskScoreFilter: undefined,
      }));
      return;
    }

    const filteredRange = allRiskScoreRanges.find(
      (range) => riskScoreRange.maxScore <= range.maxScore
    );

    setRecommendationsFilter((prevFilter) => ({
      ...prevFilter,
      riskScoreFilter: filteredRange,
    }));
  };

  const clearFilter = (): void => {
    return setRecommendationsFilter({});
  };

  return (
    <ColumnContainer>
      <Column isContent maxWidth="none">
        <ColumnHeader
          title="Home"
          icon={{ type: "name", icon: "home-2" }}
          includeDefaultActions
        />
        <ColumnContent>
          <ThreatCenter setRiskScoreFilter={handleSetRiskScoreFilter} />
          <Divider margin="lg" />
          <div className={styles.threatContainer}>
            <div
              className={sprinkles({
                display: "flex",
                flexDirection: "column",
                gap: "xl",
              })}
            >
              <RecommendationFilterSection
                filter={recommendationsFilter}
                setFilter={setRecommendationsFilter}
              />
              <ThreatResolutionNew
                filter={recommendationsFilter}
                clearFilter={clearFilter}
              />
              {isEmptyFilter(recommendationsFilter) && (
                <RiskyApps filter={recommendationsFilter} />
              )}
            </div>
          </div>
        </ColumnContent>
      </Column>
    </ColumnContainer>
  );
};

export default Recommendations;
