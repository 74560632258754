import {
  useNumRequestsToReviewQuery,
  useRequestStatsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { Checkbox, Input, Skeleton, TabsV3 } from "components/ui";
import { TabInfo } from "components/ui/tabs/TabsV3";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import useLogEvent from "utils/analytics";
import { hasBasicPermissions } from "utils/auth/auth";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import RequestsTable from "./RequestsTable";
import * as styles from "./RequestsViewV3.css";
import { RequestsType } from "./utils";

const HIDE_COMPLETED_PARAM = "hideCompleted";

const RequestsViewV3 = ({ selectedType }: { selectedType?: RequestsType }) => {
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  const logEvent = useLogEvent();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const hideCompleted = query.get(HIDE_COMPLETED_PARAM) === "true";

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const { data: requestStatsWrapped, error } = useRequestStatsQuery({
    fetchPolicy: "cache-and-network",
  });

  const {
    data: numRequestsToReviewData,
    loading: numRequestsToReviewLoading,
    error: numRequestsToReviewError,
  } = useNumRequestsToReviewQuery({ fetchPolicy: "cache-and-network" });

  if (error) {
    logError(error, `failed to list request stats`);
    return (
      <ColumnContainer>
        <Column isContent maxWidth="none">
          <UnexpectedErrorPage error={error} />
        </Column>
      </ColumnContainer>
    );
  }

  if (numRequestsToReviewError) {
    logError(numRequestsToReviewError, `failed to list num requests to review`);
    return (
      <ColumnContainer>
        <Column isContent maxWidth="none">
          <UnexpectedErrorPage error={numRequestsToReviewError} />
        </Column>
      </ColumnContainer>
    );
  }

  const handleToggleHideCompleted = () => {
    const newHideCompleted = !hideCompleted;
    const newQuery = new URLSearchParams(location.search);
    newQuery.set(HIDE_COMPLETED_PARAM, newHideCompleted.toString());
    history.push({
      pathname: location.pathname,
      search: newQuery.toString(),
    });
  };

  const handleRequestTypeClick = (key: string) => {
    logEvent({
      name: "request_type_click",
      properties: {
        type: key,
        hasAdmin: Boolean(showAdminTab),
        hasInbox: showReviewTab,
      },
    });
    history.push(`/requests/${key}`);
  };

  const currentUserRequestStats =
    requestStatsWrapped?.currentUserStats.currentUserStats;

  const showReviewTab =
    currentUserRequestStats?.reviewsItems ||
    (numRequestsToReviewData?.numRequestsToReview ?? 0) > 0;

  const showAdminTab =
    (currentUserRequestStats?.numGroupsAdmined ?? 0) > 0 ||
    (currentUserRequestStats?.numResourcesAdmined ?? 0) > 0 ||
    !hasBasicPermissions(authState.user);

  let requestCountForSelectedType: number | undefined = undefined;
  switch (selectedType) {
    case "inbox":
      requestCountForSelectedType =
        numRequestsToReviewData?.numRequestsToReview;
      break;
    case "sent":
      requestCountForSelectedType = hideCompleted
        ? (currentUserRequestStats?.numRequestsSentPending ?? 0) +
          (currentUserRequestStats?.numRequestsSentPendingOnBehalfOfUser ?? 0)
        : (currentUserRequestStats?.numRequestsSent ?? 0) +
          (currentUserRequestStats?.numRequestsSentOnBehalfOfUser ?? 0);
      break;
  }

  const tabs: TabInfo[] = [
    {
      title: "Inbox",
      isSelected: history.location.pathname.endsWith("/requests/inbox"),
      onClick: () => handleRequestTypeClick("inbox"),
    },
    {
      title: "Sent",
      isSelected: history.location.pathname.endsWith("/requests/sent"),
      onClick: () => handleRequestTypeClick("sent"),
    },
  ];
  if (showAdminTab) {
    tabs.push({
      title: "Admin",
      isSelected: history.location.pathname.endsWith("/requests/admin"),
      onClick: () => handleRequestTypeClick("admin"),
    });
  }

  return (
    <ColumnContainer>
      <Column isContent maxWidth="none">
        <ColumnHeaderV3
          title="Requests"
          icon={{ type: "name", icon: "check-circle" }}
          includeDefaultActions
        />
        <div className={sprinkles({ alignSelf: "flex-start", width: "100%" })}>
          <TabsV3 tabInfos={tabs} />
        </div>
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "md",
            marginBottom: "lg",
            marginLeft: "mdlg",
          })}
        >
          {(selectedType === "inbox" || selectedType === "admin") && (
            <div className={styles.searchInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Filter by requester name or email"
              />
            </div>
          )}
          {(selectedType === "sent" || selectedType === "admin") && (
            <Checkbox
              size="sm"
              label="Hide Completed Requests"
              checked={hideCompleted}
              onChange={handleToggleHideCompleted}
            />
          )}{" "}
        </div>

        <span
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "md",
          })}
        >
          {selectedType === "admin" ? null : numRequestsToReviewLoading ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            pluralize(
              selectedType === "sent" ? "Sent Request" : "Request",
              displayedRowCount,
              true
            )
          )}
        </span>

        <RequestsTable
          requestsType={selectedType || "inbox"}
          searchQuery={debouncedSearchQuery}
          numTotalRequests={
            selectedType === "admin"
              ? Number.MAX_SAFE_INTEGER // Hack - we don't query the total number of org requests so just keep loading as long as we have a cursor
              : requestCountForSelectedType
          }
          setDisplayedRequestCount={setDisplayedRowCount}
        />
      </Column>
    </ColumnContainer>
  );
};

export default RequestsViewV3;
