import { EntityType } from "api/generated/graphql";
import { ButtonV3, Icon, Skeleton } from "components/ui";
import pluralize from "pluralize";
import { entityTypeToString } from "utils/entity";

import * as styles from "./TableHeader.css";

type RightAction = {
  type?: PropsFor<typeof ButtonV3>["type"];
  label?: string;
  onClick: () => void;
  iconName?: PropsFor<typeof Icon>["name"];
  disabledTooltip?: string;
};

type Props = {
  entityType?: EntityType;
  /** Used as label name if entityType is not provided */
  entityName?: string;
  totalNumRows: number;
  selectedNumRows?: number;
  customLabel?: React.ReactNode;
  loading?: boolean;
  bulkRightActions?: RightAction[];
  defaultRightActions?: RightAction[];
  /** Overrides defaultRightActions and bulkRightActions */
  rightElement?: React.ReactNode;
  size?: "sm" | "md";
};

const TableHeader = (props: Props): JSX.Element => {
  const {
    totalNumRows,
    selectedNumRows,
    loading,
    bulkRightActions,
    defaultRightActions,
    size = "md",
    customLabel,
  } = props;
  const bulkMode = (selectedNumRows ?? 0) > 0;
  const isSmall = size === "sm";

  const bulkRightActionsElements = bulkRightActions?.map((action) => {
    return (
      <ButtonV3
        size={isSmall ? "xs" : "sm"}
        key={action.label}
        type={action.type}
        label={action.label}
        onClick={action.onClick}
        leftIconName={action.iconName}
        disabled={action.disabledTooltip !== undefined}
        disabledTooltip={action.disabledTooltip}
      />
    );
  });

  const defaultRightActionsElements = defaultRightActions?.map((action) => {
    return (
      <ButtonV3
        size={isSmall ? "xs" : "sm"}
        key={action.label}
        type={action.type}
        label={action.label}
        onClick={action.onClick}
        leftIconName={action.iconName}
        disabled={action.disabledTooltip !== undefined}
        disabledTooltip={action.disabledTooltip}
      />
    );
  });

  const entityTypeLabel = pluralize(
    props.entityType
      ? entityTypeToString(props.entityType)
      : props.entityName ?? "",
    bulkMode ? selectedNumRows : totalNumRows
  );

  const numRows = bulkMode ? selectedNumRows : totalNumRows;
  const numRowsLabel =
    numRows === 0 ? `No ${entityTypeLabel}` : `${numRows} ${entityTypeLabel}`;

  const renderHeaderLabel = () => {
    if (loading) {
      return <Skeleton width="100px" variant="text" />;
    }
    return bulkMode ? `${numRowsLabel} selected` : customLabel ?? numRowsLabel;
  };

  const renderRightElement = () => {
    if (props.rightElement) {
      return props.rightElement;
    }
    return bulkMode ? bulkRightActionsElements : defaultRightActionsElements;
  };

  return (
    <div className={styles.tableHeader({ bulkMode, small: isSmall })}>
      <label className={styles.tableHeaderText({ small: isSmall })}>
        {renderHeaderLabel()}
      </label>
      <div className={styles.tableHeaderActions({ bulkMode })}>
        {renderRightElement()}
      </div>
    </div>
  );
};

export default TableHeader;
export type { RightAction };
