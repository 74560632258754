import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GroupAccessLevel,
  GroupAccessLevelInput,
  GroupPreviewWithUsersFragment,
  useAddGroupUsersMutation,
  useGroupAddUsersQuery,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Select,
} from "components/ui";
import List from "components/ui/list/ListV3";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionManage,
  AuthorizedActionManageUser,
} from "utils/auth/auth";
import { groupTypeHasRoles } from "utils/directory/groups";
import { serviceTypeHasMaxOneRole } from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./ResourceAddUsersView.css";

type UserWithExpiration = {
  user: UserPreviewSmallFragment;
  expiration: ExpirationValue;
};

const GroupAddUsersView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { groupId } = useParams<{ groupId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [roleByUserIdToAdd, setRolesByUserIdToAdd] = useState<
    Record<string, GroupAccessLevel[]>
  >({});
  const [usersToAddByUserId, setUsersToAddByUserId] = useState<
    Record<string, UserWithExpiration>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [addUsersErrorMessage, setAddUsersErrorMessage] = useState("");

  const [
    addGroupUsers,
    { loading: addUsersLoading },
  ] = useAddGroupUsersMutation();
  const { data, previousData, loading, error } = useGroupAddUsersQuery({
    variables: {
      id: groupId,
      searchQuery: debouncedSearchQuery,
    },
  });
  let group: GroupPreviewWithUsersFragment | undefined;
  if (previousData?.group.__typename === "GroupResult") {
    group = previousData.group.group;
  }
  if (data?.group.__typename === "GroupResult") {
    group = data.group.group;
  }
  const allUsers = data?.users.users ?? previousData?.users.users ?? [];
  const roles: GroupAccessLevel[] = group?.accessLevels ?? [];

  if (loading && !(data || previousData)) {
    return <FullscreenSkeleton />;
  }
  if (
    !(
      group?.authorizedActions?.includes(AuthorizedActionManage) ||
      group?.authorizedActions?.includes(AuthorizedActionManageUser)
    )
  ) {
    return <ForbiddenPage />;
  }
  if (!group || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    transitionBack(`/groups/${groupId}#users`);
  };

  const directRolesByUserId: Record<string, GroupAccessLevel[]> = {};
  const numDirectAccessPointsByUserId: Record<string, number> = {};
  group.groupUsers.forEach((groupUser) => {
    if (groupUser.access?.directAccessPoint) {
      if (!directRolesByUserId[groupUser.userId]) {
        directRolesByUserId[groupUser.userId] = [];
      }
      if (groupUser.accessLevel) {
        directRolesByUserId[groupUser.userId] = _.uniqBy(
          [...directRolesByUserId[groupUser.userId], groupUser.accessLevel],
          "accessLevelRemoteId"
        );
      }

      if (!numDirectAccessPointsByUserId[groupUser.userId]) {
        numDirectAccessPointsByUserId[groupUser.userId] = 0;
      }
      numDirectAccessPointsByUserId[groupUser.userId] += 1;
    }
  });

  // Filter out users who have direct access already
  const users = allUsers.filter((user) => {
    const directRoleCount = numDirectAccessPointsByUserId[user.id] || 0;
    // Group does not have access levels
    if (user.isSystemUser || directRoleCount > 0) {
      return false;
    }
    return true;
  });

  const title = (
    <>
      Add Users:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <EntityIcon type={group.groupType} size="lg" />
      </div>
      {group.name}
    </>
  );

  const groupHasRoles = groupTypeHasRoles(group.groupType);
  const numUsersToAdd = Object.keys(usersToAddByUserId).length;

  const handleAddUsers = async () => {
    if (groupHasRoles) {
      if (Object.keys(roleByUserIdToAdd).length !== numUsersToAdd) {
        setAddUsersErrorMessage(
          "You must select at least one role for each user"
        );
        return;
      }
      for (let roles of Object.values(roleByUserIdToAdd)) {
        if (roles.length === 0) {
          setAddUsersErrorMessage(
            "You must select at least one role for each user"
          );
          return;
        }
      }
    }

    logEvent({
      name: "apps_add_user",
      properties: {
        type: "group",
        numUsers: Object.entries(expirationValueToDurationInMinutes).length,
      },
    });
    try {
      const { data } = await addGroupUsers({
        variables: {
          input: {
            groupUsers: Object.keys(usersToAddByUserId).map((userId) => {
              const expirationVal =
                usersToAddByUserId[userId].expiration ||
                ExpirationValue.Indefinite;
              const accessDurationInMinutes = expirationValueToDurationInMinutes(
                expirationVal
              )?.asMinutes();

              let role: GroupAccessLevelInput | null = null;
              if (roleByUserIdToAdd[userId]) {
                role = {
                  accessLevelName: roleByUserIdToAdd[userId][0].accessLevelName,
                  accessLevelRemoteId:
                    roleByUserIdToAdd[userId][0].accessLevelRemoteId,
                };
              }

              return {
                groupId,
                userId,
                durationInMinutes: accessDurationInMinutes,
                accessLevel: role,
              };
            }),
          },
        },
        refetchQueries: ["Group"],
      });
      switch (data?.addGroupUsers.__typename) {
        case "AddGroupUsersResult":
          startPushTaskPoll(data.addGroupUsers.taskId);
          handleClose();
          break;
        case "CannotAddSystemUserToGroupError":
          logError(new Error(data.addGroupUsers.message));
          setAddUsersErrorMessage(data.addGroupUsers.message);
          break;
        case "CallToWebhookFailedError":
          setAddUsersErrorMessage(data.addGroupUsers.message);
          break;
        case "GroupUserAlreadyExists":
          setAddUsersErrorMessage(data.addGroupUsers.message);
          break;
        default:
          logError(new Error(`failed to add group users`));
          setAddUsersErrorMessage("Error: failed to add group users");
      }
    } catch (error) {
      logError(error, "failed to add group users");
      setAddUsersErrorMessage(
        getModifiedErrorMessage("Error: failed to add group users", error)
      );
    }
  };

  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddUsers}
      primaryButtonLabel={`Add ${
        numUsersToAdd > 0 ? numUsersToAdd : ""
      } ${pluralize("user", numUsersToAdd)}`}
      primaryButtonDisabled={numUsersToAdd === 0}
      primaryButtonLoading={addUsersLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select users to add to the group:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or email"
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 users. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          <List
            items={users}
            getItemKey={(user) => user.id}
            getItemLabel={(user) => user.fullName}
            getItemSublabel={(user) => user.email}
            getIcon={getUserAvatarIcon}
            getActionLabel={(user) => {
              return user.id in usersToAddByUserId ? "Remove" : "Add";
            }}
            onSelectItem={(user) => {
              if (user.id in usersToAddByUserId) {
                if (groupHasRoles) {
                  const newRolesByUserIdToAdd = { ...roleByUserIdToAdd };
                  delete newRolesByUserIdToAdd[user.id];
                  setRolesByUserIdToAdd(newRolesByUserIdToAdd);
                }
                const newUsersToAddByUserId = {
                  ...usersToAddByUserId,
                };
                delete newUsersToAddByUserId[user.id];
                setUsersToAddByUserId(newUsersToAddByUserId);
              } else {
                if (groupHasRoles) {
                  const newRolesByUserIdToAdd = { ...roleByUserIdToAdd };
                  newRolesByUserIdToAdd[user.id] = [];
                  setRolesByUserIdToAdd(newRolesByUserIdToAdd);
                }
                const newUsersToAddByUserId = {
                  ...usersToAddByUserId,
                };
                const userWithExpiration: UserWithExpiration = {
                  user: user,
                  expiration: ExpirationValue.Indefinite,
                };
                newUsersToAddByUserId[user.id] = userWithExpiration;
                setUsersToAddByUserId(newUsersToAddByUserId);
              }
            }}
            getActionIcon={(user) => {
              return user.id in usersToAddByUserId ? "x" : "plus";
            }}
            noItemsMessage="No users found"
          />
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addUsersErrorMessage && (
          <Banner
            message={addUsersErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numUsersToAdd} {pluralize("User", numUsersToAdd)}
        </div>
        {Object.keys(usersToAddByUserId).map((userId) => {
          const user = usersToAddByUserId[userId].user;
          if (!user) {
            return null;
          }
          const expiration = usersToAddByUserId[userId].expiration;

          return (
            <div key={userId} className={styles.userCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                  marginBottom: "lg",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={getUserAvatarIcon(user)} />
                </div>
                <div className={styles.userInfoSection}>
                  <div className={styles.userCardHeader}>{user.fullName}</div>
                  <div className={styles.userCardSubtitle}>{user.email}</div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      const newUsersToAddByUserId = {
                        ...usersToAddByUserId,
                      };
                      delete newUsersToAddByUserId[user.id];
                      setUsersToAddByUserId(newUsersToAddByUserId);
                    }}
                  />
                </div>
              </div>
              <Select
                key={userId}
                options={Object.values(ExpirationValue)}
                value={expiration}
                onChange={(val) => {
                  if (val) {
                    const newUsersToAddByUserId = {
                      ...usersToAddByUserId,
                    };
                    newUsersToAddByUserId[user.id].expiration = val;
                    setUsersToAddByUserId(newUsersToAddByUserId);
                  }
                }}
                disableBuiltInFiltering
                getOptionLabel={(expirationVal) =>
                  expirationVal === ExpirationValue.Indefinite
                    ? "Indefinite access"
                    : `Access for ${expirationVal}`
                }
              />
              {groupHasRoles && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    key={userId}
                    options={roles.filter((role) => {
                      // Only include roles that the user does not already have
                      // or are currently selected to be added
                      if (
                        directRolesByUserId[user.id]
                          ?.map((r) => r.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      if (
                        roleByUserIdToAdd[user.id]
                          .map((role) => role.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      return true;
                    })}
                    selectOnly
                    placeholder="Select role"
                    onChange={(val) => {
                      if (!val) {
                        return;
                      }
                      const selectedAccessLevel = {
                        accessLevelName: val.accessLevelName,
                        accessLevelRemoteId: val.accessLevelRemoteId,
                      };
                      const newRolesByUserIdToAdd = { ...roleByUserIdToAdd };
                      if (
                        serviceTypeHasMaxOneRole(group?.serviceType) ||
                        (newRolesByUserIdToAdd[user.id].length === 1 &&
                          newRolesByUserIdToAdd[user.id][0].accessLevelName ===
                            "")
                      ) {
                        newRolesByUserIdToAdd[user.id] = [selectedAccessLevel];
                      } else {
                        newRolesByUserIdToAdd[user.id] = [
                          ...newRolesByUserIdToAdd[user.id],
                          selectedAccessLevel,
                        ];
                      }
                      setRolesByUserIdToAdd(newRolesByUserIdToAdd);
                    }}
                    getOptionLabel={(role) => role.accessLevelName}
                  />
                  {roleByUserIdToAdd[user.id].map((role) => {
                    if (role.accessLevelName === "") {
                      return null;
                    }
                    return (
                      <div
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            const newRolesByUserIdToAdd = {
                              ...roleByUserIdToAdd,
                            };
                            newRolesByUserIdToAdd[
                              user.id
                            ] = newRolesByUserIdToAdd[user.id].filter(
                              (r) =>
                                r.accessLevelRemoteId !==
                                role.accessLevelRemoteId
                            );
                            setRolesByUserIdToAdd(newRolesByUserIdToAdd);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default GroupAddUsersView;
