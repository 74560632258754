import { RecommendationsSubscoreType } from "api/generated/graphql";
import { PillV3 } from "components/pills/PillsV3";
import { PopoverV3 } from "components/ui";
import { IconData } from "components/ui/utils";

interface Props {
  type: RecommendationsSubscoreType;
  hidePopover?: boolean;
  inverse?: boolean;
}

import * as styles from "./ThreatPill.css";

export const permanentAccessIcon = {
  type: "name",
  icon: "alarm-clock",
  style: "rounded",
} as IconData;

export const unusedAccessIcon = {
  type: "name",
  icon: "cube-outline",
  style: "rounded",
} as IconData;

export const resourceSensitivityIcon = {
  type: "name",
  icon: "alert-triangle",
  style: "rounded",
} as IconData;

export const outsideAccessIcon = {
  type: "name",
  icon: "target",
  style: "rounded",
} as IconData;

export const unusedAccessRiskFactorText = "Unused for over 30 days";
export const perpetualAccessRiskFactorText = "Permanent Access";
export const outsideAccessRiskFactorText = "Outside Access";
export const resourceVulnerabilityRiskFactorText = "Resource Sensitivity";

const typeToThreatContext = (type: RecommendationsSubscoreType) => {
  switch (type) {
    case RecommendationsSubscoreType.UnusedAccess:
      return "Unused access to sensitive digital resources risks unauthorized entry.";
    case RecommendationsSubscoreType.PerpetualAccess:
      return "Permanent access causes over-provisioning, while timed permissions reduce attack surface.";
    case RecommendationsSubscoreType.UnmanagedAccess:
      return "Access to sensitive digital resources outside Opal's perimeter poses security risks.";
    case RecommendationsSubscoreType.ResourceVulnerability:
      return "Unsecured access to sensitive resources jeopardizes privacy.";
  }
};
const delay = 100;

const ThreatPill = (props: Props) => {
  const { type, hidePopover, inverse } = props;
  switch (type) {
    case RecommendationsSubscoreType.UnusedAccess:
      if (hidePopover) {
        return (
          <PillV3
            keyText={unusedAccessRiskFactorText}
            pillColor={inverse ? "RedInverse" : "Red"}
            icon={unusedAccessIcon}
          />
        );
      }

      return (
        <>
          <PopoverV3
            content={
              <div className={styles.tooltip}>{typeToThreatContext(type)}</div>
            }
            position={"right"}
            delay={delay}
          >
            <PillV3
              keyText={unusedAccessRiskFactorText}
              pillColor={inverse ? "RedInverse" : "Red"}
              icon={unusedAccessIcon}
            />
          </PopoverV3>
        </>
      );
    case RecommendationsSubscoreType.PerpetualAccess:
      if (hidePopover) {
        return (
          <PillV3
            keyText={perpetualAccessRiskFactorText}
            pillColor={inverse ? "RedInverse" : "Red"}
            icon={permanentAccessIcon}
          />
        );
      }

      return (
        <PopoverV3
          content={
            <div className={styles.tooltip}>{typeToThreatContext(type)}</div>
          }
          position={"right"}
          delay={delay}
        >
          <PillV3
            keyText={perpetualAccessRiskFactorText}
            pillColor={inverse ? "RedInverse" : "Red"}
            icon={permanentAccessIcon}
          />
        </PopoverV3>
      );
    case RecommendationsSubscoreType.UnmanagedAccess:
      if (hidePopover) {
        return (
          <PillV3
            keyText={outsideAccessRiskFactorText}
            pillColor={inverse ? "RedInverse" : "Red"}
            icon={outsideAccessIcon}
          />
        );
      }

      return (
        <PopoverV3
          content={
            <div className={styles.tooltip}>{typeToThreatContext(type)}</div>
          }
          position={"right"}
          delay={delay}
        >
          <PillV3
            keyText={outsideAccessRiskFactorText}
            pillColor={inverse ? "RedInverse" : "Red"}
            icon={outsideAccessIcon}
          />
        </PopoverV3>
      );

    case RecommendationsSubscoreType.ResourceVulnerability:
      if (hidePopover) {
        return (
          <PillV3
            keyText={resourceVulnerabilityRiskFactorText}
            pillColor={inverse ? "RedInverse" : "Red"}
            icon={resourceSensitivityIcon}
          />
        );
      }

      return (
        <PopoverV3
          content={
            <div className={styles.tooltip}>{typeToThreatContext(type)}</div>
          }
          position={"right"}
          delay={delay}
        >
          <PillV3
            keyText={resourceVulnerabilityRiskFactorText}
            pillColor={inverse ? "RedInverse" : "Red"}
            icon={resourceSensitivityIcon}
          />
        </PopoverV3>
      );
  }
  return <></>;
};

export default ThreatPill;
