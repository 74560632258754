import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GroupFragment,
  GroupUserFragment,
  GroupUserSource,
  HrIdpStatus,
  Maybe,
  PropagationStatusCode,
  RemoveGroupUserInput,
  useRemoveGroupUsersMutation,
} from "api/generated/graphql";
import { TimeLabel } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Icon, Input, Modal, Tooltip } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionManage,
  AuthorizedActionManageUser,
} from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { groupTypeHasRoles } from "utils/directory/groups";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { formatHrIdpStatus, getUserAvatarIcon } from "views/users/utils";
import { dropNothings } from "views/utils";

import {
  getGroupUserAccessPathsInfo,
  GroupUserAccessPathsInfo,
  GroupUserAccessPointsLabel,
} from "../resources/GroupUserAccessPointsLabel";
import GroupUserSourceLabel from "./GroupUserSourceLabel";
import * as styles from "./GroupUsersTableV3.css";

interface GroupUserRow {
  id: string;
  userId: string;
  groupUser: GroupUserFragment;
  source: GroupUserSource;
  accessPaths: GroupUserAccessPathsInfo;
  fullName?: string;
  userAvatarUrl?: string;
  email: string;
  role: string;
  expiry?: string | null;
  syncStatus?: PropagationStatusCode | null;
  idpStatus?: HrIdpStatus;
}

type GroupUsersTableV3Props = {
  group: GroupFragment;
};

export const GroupUsersTableV3 = (props: GroupUsersTableV3Props) => {
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [removeUsersErrorMessage, setRemoveUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const canManage =
    props.group.authorizedActions?.includes(AuthorizedActionManage) ||
    props.group.authorizedActions?.includes(AuthorizedActionManageUser);
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();

  const GROUP_USER_COLUMNS: Header<GroupUserRow>[] = [
    {
      id: "fullName",
      label: "User",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={styles.userCellContainer}
            onClick={(event) => {
              transitionTo(
                {
                  pathname: getResourceUrlNew({
                    entityId: row.groupUser.userId,
                    entityType: EntityType.User,
                  }),
                },
                event
              );
            }}
          >
            <div className={sprinkles({ flexShrink: 0 })}>
              <Icon
                size="md"
                data={getUserAvatarIcon({ avatarUrl: row.userAvatarUrl || "" })}
              />
            </div>
            <div className={styles.nameField}>{row.fullName}</div>
          </div>
        );
      },
      width: 130,
    },
    {
      id: "email",
      label: "Email",
      customCellRenderer: (row) => {
        return <div className={styles.emailCell}>{row.email}</div>;
      },
      width: 130,
    },
    {
      id: "source",
      label: "Source Of Access",
      customCellRenderer: (row) => {
        if (row.source === GroupUserSource.RegularIndirect) {
          return (
            <GroupUserAccessPointsLabel
              accessPathsInfo={row.accessPaths}
              user={row.groupUser.user}
              group={props.group}
              icon={{ type: "name", icon: "users" }}
            />
          );
        }
        return <GroupUserSourceLabel source={row.source} />;
      },
      width: 110,
    },
    {
      id: "expiry",
      label: "Expires",
      customCellRenderer: (row) => {
        const expirationTime = row.groupUser.access?.latestExpiringAccessPoint
          ?.expiration
          ? moment(
              new Date(
                row.groupUser.access.latestExpiringAccessPoint.expiration
              )
            )
          : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={
              row.groupUser.access?.latestExpiringAccessPoint.supportTicket
            }
            useExpiringLabel
            indefiniteLablel="Permanent Access"
          />
        );
      },
      width: 125,
    },
    {
      id: "syncStatus",
      label: "Status",
      customCellRenderer: (row) => {
        return (
          <PropagationStatusLabelWithModal
            propagationType={PropagationType.GroupUser}
            propagationStatus={row.groupUser.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              user: row.groupUser.user,
              group: props.group,
              lastExpiringAccessPointExpiration:
                row.groupUser.access?.latestExpiringAccessPoint.expiration,
            }}
          />
        );
      },
      width: 65,
    },
    {
      id: "idpStatus",
      label: "",
      width: 32,
      customCellRenderer: (row) => {
        let iconName: PropsFor<typeof Icon>["name"] = "user";
        let color: PropsFor<typeof Icon>["color"] = "gray400";
        switch (row.idpStatus) {
          case HrIdpStatus.Active:
            iconName = "user-check";
            color = "gray400";
            break;
          default:
            iconName = "user-x";
            color = "red600";
            break;
        }
        return row.idpStatus ? (
          <Tooltip tooltipText={formatHrIdpStatus(row.idpStatus)}>
            <Icon name={iconName} size="xs" color={color} />
          </Tooltip>
        ) : (
          <></>
        );
      },
    },
  ];

  if (groupTypeHasRoles(props.group.groupType)) {
    GROUP_USER_COLUMNS.splice(2, 0, {
      id: "role",
      label: "Role",
    });
  }

  const [
    removeGroupUsers,
    { loading: removeUsersLoading },
  ] = useRemoveGroupUsersMutation();

  const submitRemoval = async (groupUsersToRemove: RemoveGroupUserInput[]) => {
    logEvent({
      name: "apps_remove_user",
      properties: {
        type: "group",
        numUsers: groupUsersToRemove.length,
      },
    });

    try {
      const { data } = await removeGroupUsers({
        variables: {
          input: {
            groupUsers: groupUsersToRemove,
          },
        },
      });
      switch (data?.removeGroupUsers.__typename) {
        case "RemoveGroupUsersResult":
          startPushTaskPoll(data.removeGroupUsers.taskId, {
            refetchOnComplete: { groupId: props.group.id },
          });
          setShowRemoveModal(false);
          setRemoveUsersErrorMessage(null);
          setSelectedItemIds([]);
          setSearchQuery("");
          break;
        case "CallToWebhookFailedError":
          setRemoveUsersErrorMessage(data.removeGroupUsers.message);
          break;
        default:
          logError(new Error(`failed to remove group users`));
          setRemoveUsersErrorMessage("Error: failed to remove group users");
      }
    } catch (error) {
      logError(error, "failed to remove group users");
      setRemoveUsersErrorMessage(
        getModifiedErrorMessage("Error: failed to remove group users", error)
      );
    }
  };

  let groupUsers: GroupUserFragment[] = props.group.groupUsers;
  if (searchQuery) {
    groupUsers = groupUsers.filter((resourceUser) =>
      resourceUser.user?.fullName
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  }
  const rowsById: Record<string, GroupUserRow> = {};
  const rows: GroupUserRow[] = groupUsers.map((groupUser) => {
    const row = {
      id:
        groupUser.userId +
        groupUser.access?.directAccessPoint?.accessLevel?.accessLevelRemoteId,
      userId: groupUser.userId,
      groupUser: groupUser,
      fullName: groupUser.user?.fullName,
      userAvatarUrl: groupUser.user?.avatarUrl,
      email: groupUser.user?.email ?? "\u2014",
      idpStatus: groupUser.user?.hrIdpStatus ?? undefined,
      role:
        groupUser.access?.directAccessPoint?.accessLevel?.accessLevelName ||
        "\u2014",
      accessPaths: getGroupUserAccessPathsInfo({
        user: groupUser.user,
        access: groupUser.access,
        group: props.group,
      }),
      source: groupUser.source,
      expiry: groupUser.access?.latestExpiringAccessPoint.expiration,
      syncStatus: groupUser.propagationStatus?.statusCode,
    };
    rowsById[row.id] = row;
    return row;
  });

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = dropNothings([
    canManage
      ? {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        }
      : null,
  ]);

  const getCheckboxDisabledReason = (row: GroupUserRow) => {
    if (!row.groupUser.access?.directAccessPoint) {
      return "The user has no direct access to remove";
    } else if (row.source === GroupUserSource.OnCall) {
      return "This user was added via an on-call binding. To remove them, please remove the on-call binding.";
    } else if (row.source === GroupUserSource.RegularNested) {
      return "This user was added due to membership in a nested group that grants access to this group. To remove them, please remove them from the nested group.";
    }
    return undefined;
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        paddingTop: "md",
      })}
    >
      <div className={styles.searchInput}>
        <Input
          leftIconName="search"
          type="search"
          style="search"
          placeholder="Filter by name"
          value={searchQuery}
          onChange={setSearchQuery}
        />
      </div>
      <TableHeader
        entityType={EntityType.User}
        totalNumRows={new Set(rows.map((row) => row.userId)).size}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          canManage
            ? [
                {
                  label: "Add Users",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/groups/${props.group.id}/add-users`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={GROUP_USER_COLUMNS}
        defaultSortBy="fullName"
        checkedRowIds={new Set(selectedItemIds)}
        onCheckedRowsChange={
          canManage
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => !getCheckboxDisabledReason(row)).length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => !getCheckboxDisabledReason(row))
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={getCheckboxDisabledReason}
      />
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Group Users"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeUsersErrorMessage && (
              <ModalErrorMessage errorMessage={removeUsersErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct assignment", selectedItemIds.length, true)} from
            this group?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeUsersLoading}
            onPrimaryButtonClick={() => {
              const groupUsersToRemove: RemoveGroupUserInput[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                groupUsersToRemove.push({
                  groupId: props.group.id,
                  userId: row.userId,
                });
              }
              submitRemoval(groupUsersToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default GroupUsersTableV3;
