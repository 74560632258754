import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionCredentialsInput,
  ConnectionMetadataInput,
  ConnectionType,
  IdpConnectionType,
  useBulkUpdateMutation,
  useCreateConnectionForIdpMutation,
  useImportAllMutation,
  useSetupStateQuery,
  Visibility,
} from "api/generated/graphql";
import { Button } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { logError, logWarning } from "utils/logging";
import { validateHostname } from "views/connections/create/common";

import { ImportStep, SETUP_STEPS, SetupStep } from "./common";
import { Credentials, SetupContext, useResolvedState } from "./SetupContext";

const prevalidateCredentials = (
  credentials: Credentials,
  connectionType: IdpConnectionType
): boolean => {
  if (!credentials.name) return false;
  if (!credentials.adminOwnerId) return false;

  switch (connectionType) {
    case IdpConnectionType.Okta:
      if (!credentials.orgUrl) return false;
      if (!credentials.apiToken) return false;
      break;
    case IdpConnectionType.Google:
      if (!credentials.adminEmail) return false;
      if (!credentials.serviceAccountJson) return false;
      if (!credentials.customerId) return false;
      break;
    case IdpConnectionType.AzureAd:
      if (!credentials.tenantId) return false;
      if (!credentials.clientId) return false;
      if (!credentials.clientSecret) return false;
      break;
  }

  return true;
};

const SetupFooter = () => {
  const { data: setupData } = useSetupStateQuery();
  const [
    {
      connectionType: idpConnectionType,
      credentials,
      currentStep,
      hasSuccessfulSync,
      importMode,
      importStep,
      itemsToImport = [],
      hasSuccessfulImport,
    },
  ] = useResolvedState();
  const { setCurrentStep, setCredentialsError, setImportStep } = useContext(
    SetupContext
  );
  const history = useHistory();
  const logEvent = useLogEvent();
  const [
    createConnection,
    { loading: createConnectionLoading },
  ] = useCreateConnectionForIdpMutation();
  const [bulkUpdate, { loading: bulkUpdateLoading }] = useBulkUpdateMutation({
    refetchQueries: ["AppsListColumn"],
  });
  const [importAll, { loading: importAllLoading }] = useImportAllMutation({
    refetchQueries: ["AppsListColumn"],
  });

  useEffect(() => {
    window.azureConsentCallback = async (query: URLSearchParams) => {
      if (query.get("error")) {
        const desc = query.get("error_description");
        if (desc) {
          handleSetCredentialsError(desc);
        } else {
          handleSetCredentialsError("Failed to authorize app registration.");
        }
        return;
      }

      setCredentialsError();
      await handleCreateConnection();
    };
  });

  const connectionId = setupData?.setupState.state.connectionId ?? "";

  const showCancel = currentStep === 0;
  let nextStep = SETUP_STEPS[currentStep + 1]?.label;
  let prevStep =
    currentStep > 0 ? SETUP_STEPS[currentStep - 1].label : undefined;
  let disabled = false;
  switch (SETUP_STEPS[currentStep].step) {
    case SetupStep.SELECT_IDP:
      disabled = !idpConnectionType;
      break;
    case SetupStep.CREDENTIALS:
      disabled =
        !idpConnectionType ||
        !credentials ||
        !prevalidateCredentials(credentials, idpConnectionType);
      break;
    case SetupStep.SYNC_DATA:
      prevStep = undefined;
      disabled = !hasSuccessfulSync;
      break;
    case SetupStep.IMPORT_DATA:
      if (importStep === ImportStep.SELECT_MODE) {
        nextStep = "Import data";
      } else if (importStep === ImportStep.SELECT_DATA) {
        nextStep = "Import selection";
        prevStep = "Import data";
        disabled = itemsToImport.length === 0;
      } else {
        prevStep = undefined;
        disabled = !hasSuccessfulImport;
      }
      break;
    case SetupStep.FINISHED:
      prevStep = undefined;
      nextStep = "Exit";
  }

  const loading =
    createConnectionLoading || bulkUpdateLoading || importAllLoading;

  const handleSetCredentialsError = (error: string) => {
    logEvent({
      name: "setup_credential_error",
      properties: {
        error,
      },
    });
    setCredentialsError(error);
  };

  const handleSubmitCredentials = () => {
    if (!credentials || !idpConnectionType) {
      return;
    }

    if (idpConnectionType === IdpConnectionType.AzureAd) {
      const tenantId = credentials.tenantId ?? "";
      const clientId = credentials.clientId ?? "";
      window.open(
        `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${clientId}&redirect_uri=${window.location.protocol}//${window.location.host}/apps/create/azure_ad/callback`,
        "popUpWindow",
        "height=600,width=600,left=100,top=100,resizable=yes"
      );
    } else {
      handleCreateConnection();
    }
  };

  const handleCreateConnection = async () => {
    if (!credentials || !idpConnectionType) {
      return;
    }

    const name = credentials.name ?? "";
    const description = credentials.description ?? "";
    const adminOwnerId = credentials.adminOwnerId ?? "";

    let connectionType = ConnectionType.OktaDirectory;
    let metadataInput: ConnectionMetadataInput | undefined;
    let credentialsInput: ConnectionCredentialsInput | undefined;
    switch (idpConnectionType) {
      case IdpConnectionType.Okta: {
        const orgUrl = credentials.orgUrl ?? "";
        const apiToken = credentials.apiToken ?? "";

        let hostnameErrorMessage = validateHostname(
          orgUrl,
          "Organization hostname"
        );
        if (orgUrl.includes("-admin")) {
          hostnameErrorMessage =
            'Organization hostname should not contain "-admin"';
        }
        if (hostnameErrorMessage) {
          handleSetCredentialsError(`Error: ${hostnameErrorMessage}`);
          return;
        }

        metadataInput = {
          connectionType: ConnectionType.OktaDirectory,
          oktaDirectory: {
            orgUrl,
          },
        };

        credentialsInput = {
          authType: AuthType.OktaDirectory,
          oktaDirectory: {
            adminToken: apiToken,
          },
        };

        break;
      }
      case IdpConnectionType.Google: {
        connectionType = ConnectionType.GoogleWorkspace;

        const serviceAccountInfo = credentials.serviceAccountJson;
        const adminEmail = credentials.adminEmail;
        const customerId = credentials.customerId;
        if (!serviceAccountInfo || !adminEmail || !customerId) {
          return;
        }

        metadataInput = {
          connectionType: ConnectionType.GoogleWorkspace,
          googleWorkspace: {
            serviceAccount: {
              type: serviceAccountInfo.type,
              projectId: serviceAccountInfo.project_id,
              clientEmail: serviceAccountInfo.client_email,
              clientId: serviceAccountInfo.client_id,
              authUri: serviceAccountInfo.auth_uri,
              tokenUri: serviceAccountInfo.token_uri,
              authProviderX509CertUrl:
                serviceAccountInfo.auth_provider_x509_cert_url,
              clientX509CertUrl: serviceAccountInfo.client_x509_cert_url,
            },
            adminUserEmail: adminEmail,
            customerId: customerId,
          },
        };

        credentialsInput = {
          authType: AuthType.GoogleWorkspace,
          googleWorkspace: {
            privateKeyId: serviceAccountInfo.private_key_id,
            privateKey: serviceAccountInfo.private_key,
          },
        };

        break;
      }
      case IdpConnectionType.AzureAd: {
        connectionType = ConnectionType.AzureAd;

        const tenantId = credentials.tenantId;
        const clientId = credentials.clientId;
        const clientSecret = credentials.clientSecret;
        if (!tenantId || !clientId || !clientSecret) {
          return;
        }

        metadataInput = {
          connectionType: ConnectionType.AzureAd,
          azureAd: {
            clientId,
            tenantId,
            azureInfraEnabled: false,
            eventHubNamespace: "",
            eventHub: "",
          },
        };

        credentialsInput = {
          authType: AuthType.AzureAd,
          azureAd: {
            clientSecret,
          },
        };
        break;
      }
    }

    try {
      const { data } = await createConnection({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType,
            idpConnectionType,
            adminOwnerId: adminOwnerId ?? "",
            visibility: Visibility.Global,
            visibilityGroupIds: [],
            importVisibility: Visibility.Global,

            metadata: metadataInput,
            credentials: credentialsInput,
          },
        },
        refetchQueries: [
          "AppsListColumn",
          "Connections",
          "IdpConnection",
          "SetupState",
        ],
      });
      switch (data?.createConnectionForIdp.__typename) {
        case "CreateIdpConnectionResult":
          setCurrentStep(currentStep + 1);
          break;
        case "IdpConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnectionForIdp.message));
          handleSetCredentialsError(data.createConnectionForIdp.message);
          break;
        default:
          logError(new Error(`failed to create idp connection`));
          handleSetCredentialsError(`Error: failed to create idp connection`);
      }
    } catch (error) {
      logError(error, "failed to create idp connection");
      handleSetCredentialsError(
        getModifiedErrorMessage("Error: failed to create idp connection", error)
      );
    }
  };

  const handleImportItems = async () => {
    const resourceIds: string[] = [];
    const groupIds: string[] = [];

    itemsToImport.forEach((item) => {
      if (item.resourceType) {
        resourceIds.push(item.id);
      } else {
        groupIds.push(item.id);
      }
    });

    try {
      const { data } = await bulkUpdate({
        variables: {
          input: {
            resourceIds,
            groupIds,
            importUnmanagedItems: true,
            fromSetup: true,
          },
        },
      });
      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          setImportStep(ImportStep.PROGRESS);
          break;
        default:
          logError("Failed to bulk import items");
      }
    } catch (err) {
      logError(err, "Failed to bulk import items");
    }
  };

  const handleImportAll = async () => {
    if (!connectionId) {
      return;
    }

    try {
      const { data } = await importAll({
        variables: {
          input: {
            connectionId,
          },
        },
      });
      switch (data?.importAll.__typename) {
        case "BulkUpdateItemsResult":
          setImportStep(ImportStep.PROGRESS);
          break;
        default:
          logError("Failed to bulk import items");
      }
    } catch (err) {
      logError(err, "Failed to bulk import items");
    }
  };

  const handleNext = () => {
    switch (SETUP_STEPS[currentStep].step) {
      case SetupStep.CREDENTIALS:
        handleSubmitCredentials();
        break;
      case SetupStep.IMPORT_DATA:
        if (importStep === ImportStep.SELECT_MODE) {
          logEvent({
            name: "setup_import_mode_select",
            properties: {
              mode: importMode,
            },
          });
          if (importMode === "all") {
            handleImportAll();
          } else {
            setImportStep(ImportStep.SELECT_DATA);
          }
        } else if (importStep === ImportStep.SELECT_DATA) {
          handleImportItems();
        } else {
          setCurrentStep(currentStep + 1);
        }
        break;
      case SetupStep.FINISHED:
        history.push("/");
        break;
      default:
        setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    switch (SETUP_STEPS[currentStep].step) {
      case SetupStep.IMPORT_DATA:
        if (importStep === ImportStep.SELECT_DATA) {
          setImportStep(ImportStep.SELECT_MODE);
        } else {
          setCurrentStep(currentStep - 1);
        }
        break;
      default:
        setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      })}
    >
      <div>
        {prevStep && (
          <Button
            leftIconName="arrow-left"
            label={prevStep}
            type="primary"
            borderless
            size="lg"
            onClick={handleBack}
          />
        )}
      </div>
      <div className={sprinkles({ display: "flex", gap: "lg" })}>
        {showCancel && (
          <Button
            label="Cancel"
            size="lg"
            outline
            onClick={() => history.push("/")}
          />
        )}
        {nextStep && (
          <Button
            rightIconName="arrow-right"
            label={nextStep}
            size="lg"
            type="primary"
            onClick={handleNext}
            disabled={disabled}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default SetupFooter;
