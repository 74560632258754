import {
  EndUserGroupDetailsGroupGroupsFragment,
  EndUserGroupDetailsGroupResourcesFragment,
  EntityType,
  GroupType,
  ResourceType,
} from "api/generated/graphql";
import clsx from "clsx";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { EntityIcon, Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { formatResourceBreadcrumb } from "utils/resources";
import { ItemAccessInfo } from "views/apps/enduser_exp/types";
import {
  getGroupUserAccessInfo,
  getResourceUserAccessInfo,
} from "views/apps/enduser_exp/utils";

import AccessLabel from "./AccessLabel";
import * as styles from "./GroupDetailsAccessTable.css";

type GroupDetailsAccessTableProps = {
  resources: EndUserGroupDetailsGroupResourcesFragment[] | null;
  groups: EndUserGroupDetailsGroupGroupsFragment[] | null;
};

interface ItemRow {
  id: string;
  name?: string;
  description?: string;
  breadcrumb?: string;
  typeFriendlyName: string;
  type?: ResourceType | GroupType;
  entityType: EntityType.Group | EntityType.Resource;
  accessInfo: ItemAccessInfo;
}

const ITEM_COLUMNS: Header<ItemRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div className={styles.nameCell}>
          {row.type && (
            <EntityIcon type={row.type} onlyBrandIcon={true} size="lgr" />
          )}
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
            })}
          >
            <Label
              fontWeight="bold"
              oneLine
              linkTo={
                row.entityType === EntityType.Resource
                  ? `/resources/${row.id}`
                  : `/groups/${row.id}`
              }
              label={row.name || ""}
            />
            {row.breadcrumb && (
              <div className={styles.breadcrumb}>{row.breadcrumb}</div>
            )}
          </div>
        </div>
      );
    },
  },
  {
    id: "description",
    label: "Description",
    customCellRenderer: (row) => {
      return <div className={styles.cell}>{row.description}</div>;
    },
    sortable: false,
  },
  {
    id: "typeFriendlyName",
    label: "Type",
    sortable: false,
    customCellRenderer: (row) => {
      return (
        <div className={clsx(styles.cell)}>{row.typeFriendlyName || ""}</div>
      );
    },
  },
  {
    id: "accessInfo",
    label: "Access",
    sortable: false,
    customCellRenderer: (row) => {
      return <AccessLabel {...row.accessInfo} />;
    },
  },
];

export const GroupDetailsAccessTable = (
  props: GroupDetailsAccessTableProps
) => {
  let rows: ItemRow[] = [];
  let accessMessage: string = "";

  props.resources?.forEach((item) => {
    rows.push({
      id: item.resourceId,
      entityType: EntityType.Resource,
      name: item.resource?.name,
      description: item.resource?.description,
      breadcrumb: formatResourceBreadcrumb(
        item.resource?.ancestorPathToResource,
        50,
        item.resource?.connection?.name
      ),
      typeFriendlyName:
        getResourceTypeInfo(item.resource?.resourceType)?.fullName ?? "",
      type: item.resource?.resourceType,
      accessInfo: getResourceUserAccessInfo(item.resource?.currentUserAccess),
    } as ItemRow);
  });
  props.groups?.forEach((item) => {
    rows.push({
      id: item.containingGroupId,
      entityType: EntityType.Group,
      name: item.containingGroup?.name,
      description: item.containingGroup?.description,
      typeFriendlyName:
        getGroupTypeInfo(item.containingGroup?.groupType)?.name ?? "",
      type: item?.containingGroup?.groupType,
      accessInfo: getGroupUserAccessInfo(
        item.containingGroup?.currentUserAccess
      ),
    } as ItemRow);
  });

  const numAccessResources = props?.resources?.length ?? 0;
  const numAccessGroups = props?.groups?.length ?? 0;
  if (numAccessResources > 0) {
    accessMessage += `${numAccessResources} ${pluralize(
      "item",
      numAccessResources
    )}`;
  }
  if (numAccessGroups > 0) {
    accessMessage += `${
      numAccessResources > 0 ? " and " : ""
    } ${numAccessGroups} ${pluralize("group", numAccessGroups)}`;
  }

  if (accessMessage.length > 0) {
    return (
      <>
        <div className={sprinkles({ fontSize: "textXs", fontWeight: "bold" })}>
          <Label
            label={`This item gives you access to ${
              props?.resources?.length
            } ${pluralize("item", props?.resources?.length)}`}
          />
        </div>
        <div className={styles.table}>
          <Table
            rows={rows}
            columns={ITEM_COLUMNS}
            totalNumRows={rows.length}
            getRowId={(row) => row.id}
            defaultSortBy="name"
          />
        </div>
      </>
    );
  }

  return <></>;
};
