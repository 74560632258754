import {
  BundleItemFragment,
  BundleItemsInput,
  BundleItemsSortByField,
  ConnectionType,
  EntityType,
  GroupAccessLevel,
  GroupPreviewLargeFragment,
  GroupType,
  ResourceAccessLevel,
  ResourcePreviewLargeFragment,
  ResourceType,
  SortDirection,
  useBundleItemsQuery,
  useBundleQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContentNoOverflow from "components/column/ColumnContextNoOverflow";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Icon, Input, Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AuthorizedActionManage } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { useTransitionTo } from "utils/router/hooks";
import { AppsContext } from "views/apps/AppsContext";
import BulkRequestModal from "views/apps/BulkRequestModal";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import BundleAddGroupsModal from "./BundleAddGroupsModal";
import BundleAddResourcesModal from "./BundleAddResourcesModal";
import BundleRemoveItemsModal from "./BundleRemoveItemsModal";
import * as styles from "./BundleResourcesV3.css";
import { bundleItemIsGroup, bundleItemIsResource } from "./utils";

const NAME_COL_ID = BundleItemsSortByField.Name;
const TYPE_COL_ID = BundleItemsSortByField.Type;
const ROLE_COL_ID = BundleItemsSortByField.Role;

function isSortableField(str: string): str is BundleItemsSortByField {
  return Object.values<string>(BundleItemsSortByField).includes(str);
}

type SortValue = {
  field: BundleItemsSortByField;
  direction: SortDirection;
};

interface BundleResourceRow {
  id: string; // Bundle item key - not the resource ID
  [NAME_COL_ID]: string;
  [TYPE_COL_ID]: GroupType | ResourceType;
  [ROLE_COL_ID]: string;
  requestable: boolean;
  data: {
    entityType: EntityType;
    entityId: string;
    connectionType?: ConnectionType;
    userCanManage: boolean;
  };
}

const BUNDLE_COLUMNS: Header<BundleResourceRow>[] = [
  {
    id: NAME_COL_ID,
    label: "Name",
    sortable: true,
  },
  {
    id: TYPE_COL_ID,
    label: "Type",
    sortable: true,
    customCellRenderer: (row) => {
      const formattedType =
        (row.data.entityType === EntityType.Group
          ? getGroupTypeInfo(row[TYPE_COL_ID] as GroupType)?.name
          : getResourceTypeInfo(row[TYPE_COL_ID] as ResourceType)?.fullName) ||
        "";

      return (
        <Label
          label={formattedType}
          icon={{ type: "entity", entityType: row[TYPE_COL_ID] }}
        />
      );
    },
  },
  {
    id: ROLE_COL_ID,
    label: "Role",
    sortable: true,
    customCellRenderer: (row) => {
      return <span>{row[ROLE_COL_ID] || "—"}</span>;
    },
  },
  {
    id: "requestable",
    label: "Requestable",
    customCellRenderer: (row) => {
      return (
        <span>
          {row.requestable ? <Icon name="check-circle" size="sm" /> : "—"}
        </span>
      );
    },
  },
];

interface GroupWithAccessLevel {
  kind: "group";
  key: string;
  group: GroupPreviewLargeFragment;
  role: GroupAccessLevel;
}

interface ResourceWithAccessLevel {
  kind: "resource";
  key: string;
  resource: ResourcePreviewLargeFragment;
  role: ResourceAccessLevel;
}

type ItemWithAccessLevel = ResourceWithAccessLevel | GroupWithAccessLevel;

const BundleResourcesColumnV3 = () => {
  const { bundleId } = useParams<Record<string, string>>();
  const [showAddResourcesModal, setShowAddResourcesModal] = useState(false);
  const [showAddGroupsModal, setShowAddGroupsModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 350);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: BundleItemsSortByField.Name,
    direction: SortDirection.Asc,
  });

  const {
    selectedBundleItems,
    selectBundleItems,
    clearBundleItems,
    clearSelectedItems,
  } = useContext(AppsContext);

  const transitionTo = useTransitionTo();

  // Clear selected items when navigating away from this page.
  useEffect(() => {
    return clearSelectedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is essentially always getting pulled from cache, as the parent component will have fetched it
  // prior to rendering.
  const {
    data: bundleData,
    error: bundleError,
    loading: bundleLoading,
  } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
  });

  // Conditionally include the searchQuery param so we hit the cache populated by the network call
  // from the parent component when no searchQuery is present.
  var bundleItemsQuery: BundleItemsInput = {
    bundleId: bundleId,
    sortBy: sortBy,
  };

  if (debouncedSearchQuery != "") {
    bundleItemsQuery.searchQuery = debouncedSearchQuery;
  }

  const { data, error, loading } = useBundleItemsQuery({
    variables: {
      input: bundleItemsQuery,
    },
    skip: !bundleId,
  });

  const allItems = data?.bundleItems.items ?? [];

  if (error || bundleError) {
    return null;
  }

  if (bundleLoading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let canManage = false;
  if (bundleData?.bundle.__typename === "BundleResult") {
    canManage =
      bundleData.bundle.bundle.authorizedActions?.includes(
        AuthorizedActionManage
      ) ?? false;
  }

  const existingRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  const existingRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  const itemsWithRole: ItemWithAccessLevel[] = [];
  const bundleItemByKey: Record<string, BundleItemFragment> = {};

  allItems.forEach((item) => {
    bundleItemByKey[item.key] = item;

    if (item.resource) {
      if (item.resource.id in existingRolesByResourceId) {
        existingRolesByResourceId[item.resource.id].push({
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        });
      } else {
        existingRolesByResourceId[item.resource.id] = [
          {
            accessLevelRemoteId: item.accessLevelRemoteId,
            accessLevelName: item.accessLevelName,
          },
        ];
      }
      itemsWithRole.push({
        kind: "resource",
        key: item.key,
        resource: item.resource,
        role: {
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        },
      });
    } else if (item.group) {
      if (item.group.id in existingRolesByGroupId) {
        existingRolesByGroupId[item.group.id].push({
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        });
      } else {
        existingRolesByGroupId[item.group.id] = [
          {
            accessLevelRemoteId: item.accessLevelRemoteId,
            accessLevelName: item.accessLevelName,
          },
        ];
      }
      itemsWithRole.push({
        kind: "group",
        key: item.key,
        group: item.group,
        role: {
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        },
      });
    }
  });

  const rows = itemsWithRole.map<BundleResourceRow>((item) => {
    if (item.kind === "group") {
      const { group, role } = item;
      return {
        id: item.key,
        [NAME_COL_ID]: group.name,
        [TYPE_COL_ID]: group.groupType,
        [ROLE_COL_ID]: role.accessLevelName,
        requestable: group.isRequestable,
        data: {
          entityType: EntityType.Group,
          entityId: group.id,
          connectionType: group.connection?.connectionType,
          userCanManage: canManage,
        },
      };
    } else {
      const { resource, role } = item;
      return {
        id: item.key,
        [NAME_COL_ID]: resource.name,
        [TYPE_COL_ID]: resource.resourceType,
        [ROLE_COL_ID]: role.accessLevelName,
        requestable: resource.isRequestable,
        data: {
          entityType: EntityType.Resource,
          entityId: resource.id,
          connectionType: resource.connection?.connectionType,
          userCanManage: canManage,
        },
      };
    }
  });

  const selectableItemsForUser = canManage
    ? allItems
    : allItems.filter((item) =>
        Boolean(item.resource?.isRequestable || item.group?.isRequestable)
      );

  const requestButtonEnabled = selectedBundleItems.every(
    (item) =>
      Boolean(item.resource?.isRequestable) ||
      Boolean(item.group?.isRequestable)
  );

  const bulkRightActions: PropsFor<typeof TableHeader>["bulkRightActions"] = [
    {
      label: "Request",
      type: "main",
      onClick: () => setShowRequestModal(true),
      disabledTooltip: requestButtonEnabled
        ? undefined
        : "You have selected one or more resources that are not requestable.",
    },
  ];
  if (canManage) {
    bulkRightActions.unshift({
      label: "Remove",
      type: "danger",
      onClick: () => setShowRemoveModal(true),
      iconName: "x",
    });
  }

  return (
    <>
      <ColumnContentNoOverflow>
        <div className={styles.searchInput}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Filter Resources by name"
          />
        </div>

        <TableHeader
          entityType={EntityType.Resource}
          totalNumRows={itemsWithRole.length}
          selectedNumRows={selectedBundleItems.length}
          loading={loading}
          defaultRightActions={
            canManage
              ? [
                  {
                    label: "Resource",
                    type: "mainSecondary",
                    onClick: () => setShowAddResourcesModal(true),
                    iconName: "plus",
                  },
                  {
                    label: "Group",
                    type: "mainSecondary",
                    onClick: () => setShowAddGroupsModal(true),
                    iconName: "plus",
                  },
                ]
              : undefined
          }
          bulkRightActions={bulkRightActions}
        />

        <Table
          rows={rows}
          totalNumRows={rows.length}
          loadingRows={loading}
          getRowId={(ru) => ru.id}
          columns={BUNDLE_COLUMNS}
          defaultSortBy={NAME_COL_ID}
          onRowClick={(row, event) => {
            transitionTo(
              {
                pathname: getResourceUrlNew({
                  entityId: row.data.entityId,
                  entityType: row.data.entityType,
                }),
              },
              event
            );
          }}
          checkedRowIds={new Set(selectedBundleItems.map((item) => item.key))}
          onCheckedRowsChange={(ids, checked) => {
            if (checked) {
              selectBundleItems(ids.map((id) => bundleItemByKey[id]));
            } else {
              clearBundleItems(ids.map((id) => bundleItemByKey[id]));
            }
          }}
          getCheckboxDisabledReason={(row) => {
            if (row.data.userCanManage) {
              return; // Admins can always select items
            }
            if (row.requestable) {
              return;
            }
            return "You do not have permission to request this resource";
          }}
          selectAllChecked={
            selectedBundleItems.length > 0 &&
            selectedBundleItems.length === selectableItemsForUser.length
          }
          onSelectAll={(checked) => {
            if (checked) {
              selectBundleItems(selectableItemsForUser);
            } else {
              clearSelectedItems();
            }
          }}
          manualSortDirection={
            sortBy && {
              sortBy: sortBy.field,
              sortDirection: sortBy.direction,
            }
          }
          handleManualSort={(sortBy, sortDirection) => {
            if (!sortDirection) {
              setSortBy(undefined);
              return;
            }
            if (!isSortableField(sortBy)) {
              return;
            }
            const direction: SortDirection =
              sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;

            setSortBy({
              field: sortBy,
              direction,
            });
          }}
        />
      </ColumnContentNoOverflow>
      {showAddResourcesModal && (
        <BundleAddResourcesModal
          bundleId={bundleId}
          existingRolesByResourceId={existingRolesByResourceId}
          onClose={() => setShowAddResourcesModal(false)}
        />
      )}
      {showAddGroupsModal && (
        <BundleAddGroupsModal
          bundleId={bundleId}
          existingRolesByGroupId={existingRolesByGroupId}
          onClose={() => setShowAddGroupsModal(false)}
        />
      )}
      {showRemoveModal && (
        <BundleRemoveItemsModal
          bundleId={bundleId}
          resourcesToRemove={selectedBundleItems
            .filter(bundleItemIsResource)
            .map((item) => {
              return {
                resourceId: item.resource.id,
                accessLevelRemoteId: item.accessLevelRemoteId,
                key: item.key,
              };
            })}
          groupsToRemove={selectedBundleItems
            .filter(bundleItemIsGroup)
            .map((item) => {
              return {
                groupId: item.group.id,
                accessLevelRemoteId: item.accessLevelRemoteId,
                key: item.key,
              };
            })}
          onClose={() => setShowRemoveModal(false)}
          onSuccess={({ keysRemoved }) => {
            const itemsRemoved = keysRemoved.map((key) => {
              return bundleItemByKey[key];
            });
            clearBundleItems(itemsRemoved);
          }}
        />
      )}
      <BulkRequestModal
        isOpen={showRequestModal}
        onClose={() => setShowRequestModal(false)}
      />
    </>
  );
};

export default BundleResourcesColumnV3;
