import { Icon } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import Label from "components/ui/label/Label";
import { generateUniqueId, IconData } from "components/ui/utils";
import React from "react";

import * as styles from "./RadioGroup.css";

type RadioGroupProps<Option> = {
  value?: Option;
  options: Option[];
  onSelectValue: (value: Option) => void;
  /** Used to determine the label. */
  getOptionLabel: (option: Option) => string;
  /** Used to determine the icon to display next to the label */
  getOptionIcon?: (option: Option) => IconData | undefined;
  /** Used to determine the description. */
  getOptionDescription?: (option: Option) => string | undefined;
  /** Returns a unique key for the radio option. */
  getOptionKey: (option: Option) => string | number | boolean;
  /** Returns whether or not the option is disabled. */
  isOptionDisabled?: (option: Option) => boolean | undefined;
  /** Used to render an additional action beside the option */
  getOptionRightSideAction?: (
    option: Option
  ) => { iconName: IconName; onClick: () => void } | undefined;
  disabled?: boolean;
  inline?: boolean;
  marginBottom?: "sm" | "md" | "lg";
};

const RadioGroup = <Option extends {}>(props: RadioGroupProps<Option>) => {
  const radioGroupId = React.useMemo(() => generateUniqueId("radio"), []);

  const getChecked = (option: Option) => {
    if (props.value === undefined) {
      return false;
    }
    return props.getOptionKey(option) === props.getOptionKey(props.value);
  };

  const renderOption = (option: Option) => {
    const optionId = `${radioGroupId}-${props.getOptionKey(option)}`;
    const checked = getChecked(option);
    const optionDisabled =
      props.disabled ||
      (props.isOptionDisabled && props.isOptionDisabled(option));
    const rightSideAction =
      props.getOptionRightSideAction && props.getOptionRightSideAction(option);

    return (
      <div className={styles.radioItemContainer} key={optionId}>
        <div
          className={styles.radioItem({
            disabled: optionDisabled,
          })}
          onClick={() => !optionDisabled && props.onSelectValue(option)}
        >
          <button
            className={styles.radioButton({ selected: checked })}
            id={optionId}
          >
            <div className={styles.radioInner()} />
          </button>
          <label className={styles.radioItemLabelContainer} htmlFor={optionId}>
            {props.getOptionIcon && props.getOptionIcon(option) ? (
              <Label
                label={props.getOptionLabel(option)}
                icon={props.getOptionIcon(option)}
              />
            ) : (
              props.getOptionLabel(option)
            )}
            <div className={styles.radioItemDescription}>
              {props.getOptionDescription && props.getOptionDescription(option)
                ? props.getOptionDescription(option)
                : null}
            </div>
          </label>
        </div>
        {rightSideAction && (
          <button
            className={styles.radioItemRightAction}
            onClick={rightSideAction.onClick}
          >
            <Icon size="xs" name={rightSideAction.iconName} />
          </button>
        )}
      </div>
    );
  };

  return (
    <div
      className={styles.radioGroup({
        inline: props.inline,
        marginBottom: props.marginBottom,
      })}
    >
      {props.options.map(renderOption)}
    </div>
  );
};

export default RadioGroup;
