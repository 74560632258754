import {
  EntityType,
  SortDirection,
  TagsSortByField,
  useTagsTableQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeaderV3";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { ButtonV3, Input, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo, useURLSearchParam } from "utils/router/hooks";
import { SEARCH_QUERY_URL_KEY } from "views/apps/AppsContext";

import TagCreateModal from "./TagCreateModal";
import TagDetailV3 from "./TagDetailV3";
import * as styles from "./TagsColumnV3.css";

const CREATED_AT_COL_ID = TagsSortByField.CreatedAt;
const KEY_COL_ID = TagsSortByField.Key;

function isSortableField(str: string): str is TagsSortByField {
  return Object.values<string>(TagsSortByField).includes(str);
}

type SortValue = {
  field: TagsSortByField;
  direction: SortDirection;
};

interface TagRow {
  id: string;
  [KEY_COL_ID]: string;
  value: string;
  itemCount: number;
  userCount: number;
  [CREATED_AT_COL_ID]: string;
}

const TAG_COLUMNS: Header<TagRow>[] = [
  {
    id: KEY_COL_ID,
    label: "Key",
    sortable: true,
    customCellRenderer: (row) => {
      return <div className={styles.tagCell}>{row[KEY_COL_ID]}</div>;
    },
  },
  {
    id: "value",
    label: "Value",
    sortable: false,
    customCellRenderer: (row) => {
      return <div className={styles.tagCell}>{row.value}</div>;
    },
  },
  {
    id: "itemCount",
    label: "Resources",
    width: 80,
    customCellRenderer: (row) => {
      return <div>{`${pluralize("Resource", row.itemCount, true)}`}</div>;
    },
  },
  {
    id: "userCount",
    label: "Users",
    width: 80,
    customCellRenderer: (row) => {
      return <div>{`${pluralize("User", row.userCount, true)}`}</div>;
    },
  },
  {
    id: CREATED_AT_COL_ID,
    label: "Created",
    width: 80,
    customCellRenderer: (row) => {
      return <div>{moment(row[CREATED_AT_COL_ID]).fromNow()}</div>;
    },
  },
];

const TagsColumn = () => {
  const transitionTo = useTransitionTo();
  const { tagId } = useParams<Record<string, string>>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const [searchQueryParam, setSearchQuery] = useURLSearchParam(
    SEARCH_QUERY_URL_KEY
  );
  const searchQuery = searchQueryParam || "";
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: TagsSortByField.Key,
    direction: SortDirection.Asc,
  });
  const isAdmin = authState.user?.isAdmin;

  const { data, error, loading, fetchMore } = useTagsTableQuery({
    variables: {
      input: {
        limit: 50,
        searchQuery:
          debouncedSearchQuery.length > 0 ? debouncedSearchQuery : undefined,
        sortBy: sortBy,
      },
    },
  });

  if (error) {
    logError(error, `failed to list tags`);
  }

  const cursor = data?.tags.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              limit: 50,
              cursor,
              searchQuery:
                debouncedSearchQuery.length > 0
                  ? debouncedSearchQuery
                  : undefined,
              sortBy: sortBy,
            },
          },
        });
      }
    : undefined;

  const tags = data?.tags.tags ?? [];
  const tagsHeaderCountLabel = `${data?.tags.totalNumTags} Tags`;

  const rows: TagRow[] = tags.map((tag) => {
    let itemCount = tag.tagResources.length + tag.tagGroups.length;

    return {
      id: tag.id,
      [KEY_COL_ID]: tag.key,
      value: tag.value ?? "",
      itemCount: itemCount,
      userCount: tag.tagUsers.length,
      [CREATED_AT_COL_ID]: tag.createdAt,
    };
  });

  if (tagId) {
    return <TagDetailV3 />;
  }

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          title="Tags"
          icon={{ type: "name", icon: "tag" }}
          includeDefaultActions
        />
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "md",
            marginBottom: "lg",
          })}
        >
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Filter Tags"
            />
          </div>
        </div>
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "md",
          })}
        >
          <span
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "medium",
            })}
          >
            {loading ? (
              <Skeleton variant="text" width="100px" />
            ) : (
              tagsHeaderCountLabel
            )}
          </span>
          {isAdmin && (
            <ButtonV3
              label="Tag"
              type="main"
              leftIconName="plus"
              onClick={() => {
                setShowCreateModal(true);
              }}
              size="sm"
            />
          )}
        </div>

        <>
          {loading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              rows={rows}
              totalNumRows={Number.MAX_SAFE_INTEGER}
              emptyState={{ title: "No tags to display." }}
              getRowId={(ru) => ru.id}
              columns={TAG_COLUMNS}
              onRowClick={(row, event) => {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: row.id,
                      entityType: EntityType.Tag,
                    }),
                  },
                  event
                );
              }}
              onLoadMoreRows={loadMoreRows}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;

                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </>
      </Column>
      {showCreateModal ? (
        <TagCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default TagsColumn;
