import {
  ConnectionPreviewSmallFragment,
  EntityType,
  ResourcePreviewSmallFragment,
  useNonHumanIdentityResourcesQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { ResourceLabel } from "components/label/Label";
import { Input, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import { SEARCH_QUERY_URL_KEY } from "views/apps/AppsContext";

import * as styles from "./NonHumanIdentitiesColumn.css";

interface NonHumanIdentityRow {
  id: string;
  name: string;
  email: string;
  resourceCount: number;
  createdAt: string;
  resource: ResourcePreviewSmallFragment;
  connection?: ConnectionPreviewSmallFragment | null;
}

const NON_HUMAN_IDENTITY_COLUMNS: Header<NonHumanIdentityRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            fontWeight: "medium",
            whiteSpace: "nowrap",
          })}
        >
          <ResourceLabel
            text={row.name}
            pointerCursor={true}
            entityId={row.resource.id}
            entityTypeNew={EntityType.Resource}
            resourceType={row.resource.resourceType}
          />
        </div>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    sortable: true,
    customCellRenderer: (row) => {
      return <div className={styles.emailCell}>{row.email}</div>;
    },
    width: 200,
  },
  {
    id: "connection",
    label: "App",
    sortable: true,
    width: 100,
    customCellRenderer: (row) => {
      if (!row.connection) {
        return "--";
      }
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            fontWeight: "medium",
            whiteSpace: "nowrap",
          })}
        >
          <ResourceLabel
            text={row.connection.name}
            pointerCursor={true}
            entityId={row.connection.id}
            entityTypeNew={EntityType.Connection}
            connectionType={row.connection.connectionType}
          />
        </div>
      );
    },
  },
  {
    id: "resourceCount",
    label: "Resources",
    sortable: false,
    width: 75,
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            color: row.resourceCount > 0 ? undefined : "gray600",
          })}
        >
          {`${row.resourceCount || "No"} ${pluralize(
            "Resource",
            row.resourceCount
          )}`}
        </div>
      );
    },
  },
  {
    id: "createdAt",
    label: "Created",
    sortable: true,
    width: 75,
    customCellRenderer: (row) => {
      return <div>{moment(row.createdAt).fromNow()}</div>;
    },
  },
];

const NonHumanIdentitiesColumn = () => {
  const [searchQueryParam, setSearchQuery] = useURLSearchParam(
    SEARCH_QUERY_URL_KEY
  );
  const debouncedSearchQuery = useDebouncedValue(searchQueryParam, 300);

  const {
    data: resourcesData,
    error: resourcesError,
    loading: resourcesLoading,
    fetchMore,
  } = useNonHumanIdentityResourcesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        searchQuery: debouncedSearchQuery,
        nonHumanIdentitiesOnly: true,
      },
    },
  });

  if (resourcesError) {
    logError(
      resourcesError,
      `failed to list resources home: non-human identities`
    );
  }

  const loading = resourcesLoading && !resourcesData;
  const resources = resourcesData?.resources.resources ?? [];
  const cursor = resourcesData?.resources.cursor;
  const totalNumResources = resourcesData?.resources.totalNumResources ?? 0;
  const rows: NonHumanIdentityRow[] = resources.map((resource) => {
    return {
      id: resource.id,
      name: resource.name,
      email: resource.remoteName ?? "",
      resourceCount: resource.numEntityAssignmentsForPrincipal,
      createdAt: resource.createdAt,
      resource: resource,
      connection: resource.connection,
    };
  });

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery: debouncedSearchQuery,
              nonHumanIdentitiesOnly: true,
            },
          },
        });
      }
    : undefined;

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeaderV3
          title="Non-human Identities"
          icon={{ type: "name", icon: "service-account" }}
          includeDefaultActions
        />
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "md",
            marginBottom: "lg",
          })}
        >
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQueryParam || ""}
              onChange={(query) => {
                query.length > 0 ? setSearchQuery(query) : setSearchQuery(null);
              }}
              placeholder="Filter by name"
            />
          </div>
        </div>

        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "md",
          })}
        >
          <span
            className={sprinkles({
              fontSize: "displayXs",
              fontWeight: "medium",
            })}
          >
            {loading ? (
              <Skeleton variant="text" width="100px" />
            ) : (
              `${totalNumResources} Non-human Identities`
            )}
          </span>
        </div>

        <>
          {loading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              rows={rows}
              totalNumRows={totalNumResources ?? Number.MAX_SAFE_INTEGER}
              getRowId={(ru) => ru.id}
              columns={NON_HUMAN_IDENTITY_COLUMNS}
              loadingRows={loading}
              onLoadMoreRows={loadMoreRows}
            />
          )}
        </>
      </Column>
    </>
  );
};

export default NonHumanIdentitiesColumn;
